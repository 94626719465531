import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Footer from "../components/Footer";
import PrimaryNavbar from "../components/PrimaryNavbar";
import homeBanner1 from "./../assets/banner-one.jpg";
import homeBanner2 from "./../assets/banner-two.jpg";
import homeBanner3 from "./../assets/banner-three.jpg";
import homeBanner4 from "./../assets/banner-four.jpg";
import football from "./../assets/images/football.png";
import blackFav from "./../assets/black_fav.png";

import Carousel from "react-bootstrap/Carousel";
import inplayimg from "./../assets/images/inplayimg.png";
import rou1 from "./../assets/images/rou1.png";
import rou2 from "./../assets/images/rou2.png";
import rou3 from "./../assets/images/rou4.png";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import royalty1 from "../assets/images/royalty1.0.png";
import royalty2 from "../assets/images/royalty2.png";
import axios from "axios";

import BetSlipComponent from "../components/BetSlipComponent/BetSlipComponent";

import list1 from "./../assets/images/royaltyImage1.svg";
import list2 from "./../assets/images/list2.png";
import icon2 from "../assets/sidenavicon.svg";
import icon3 from "../assets/mobile_main_image.svg";
import specialMarket from "../assets/specialMarket.png";
import exchnageBanner from "../assets/exchnageBanner.jpg";
import teenpattiBanner from "../assets/teenpattiBanner.png";
import casino1 from "../assets/casino1.png";
import casino2 from "../assets/casino2.png";
import casino3 from "../assets/casino3.png";
import casino4 from "../assets/casino4.jpg";

import tshirt from "../assets/images/tshirt.svg";
import soliddice from "../assets/images/soliddice.svg";
import cardsplaying from "../assets/cardsplaying.svg";

import fb from "./../assets/images/fb.svg";
import telegram from "./../assets/images/telegram.png";
import insta from "./../assets/images/insta.svg";
import twiter from "./../assets/images/twiter.svg";
import logo from "./../assets/images/logo.png";
import fire from "./../assets/images/noto_fire.svg";
import star from "./../assets/images/noto_glowing-star.svg"

import "./Homepage.css";

import cricket from "./../assets/cricket.png";
import flag from "./../assets/flag.png";
import Indian_flag from "./../assets/indian_flag.png";
import { Link, useNavigate } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import MobileSideBar from "../components/MobileSideBar/MobileSideBar";
import MobileFooter from "../components/MobileFooter";
import Whatsapp from "../components/Whatsapp/Whatsapp";
import cricketicon from "../assets/cricketicon.png";
import footballicon from "../assets/footballicon.png";
import tenisicon from "../assets/tenis.png";
import baseUrl from "../services/baseUrl";
import refillBonus from "../assets/images/RefillBonus.svg"
import cashbackBonus from "../assets/images/CashbackBonus.svg"
import welcomeBonus from "../assets/images/WelcomeBonus.svg"
import BlackJackBanner from "../assets/images/BlackJackBanner.jpg" 
import RouletteBanner from "../assets/images/RouletteBanner.jpg" 
import TeenPattiBanner from "../assets/images/TeenPattiBanner.jpg" 
import Shimmer from "../components/Shimmer/Shimmer";
import ShimmeredImage from "../components/Shimmer/Shimmer";
import { accessDenied, checkAccess } from "../services/helper";
import { ACCESS_TYPE } from "../services/constants";


function HomePage() {
  const user = JSON.parse(localStorage.getItem("betUser")) ? JSON.parse(localStorage.getItem("betUser")) : {username: "BETKARODEMO123", password: "DEMOUSER12@"};

  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settingsfootball = {

    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      // {
      //   breakpoint: 600,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2,
      //     initialSlide: 2,
      //   },
      // },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  };

  const [allList, setAllList] = useState([]);
  const [event, setEvent] = useState([]);
  const [popularType, setPopularType] = useState(["Today", "Tomorrow", "Upcoming"])
  const [gameType, setGameType] = useState(["Football", "Tennis", "Cricket"])

  const isPlaceBet = useState(false);
  const [placeBetData, setPlaceBetData] = useState([]);
  const [placeBetDataColor, setPlaceBetDataColor] = useState([]);
  const [placeBetDataMrId, setPlaceBetDataMrId] = useState([]);
  const [placeBetDataTeam, setPlaceBetDataTeam] = useState([]);
  const [placeBetDataAllData, setPlaceBetDataAllData] = useState([]);
  const [topGamesLoader, setTopGamesLoader] = useState(false);
  const [allData, setAllData] = useState([]);
  const [allDataLoader, setAllDataLaoder] = useState(false);
  const [showDetailView, setShowDetailView] = useState(false);
  const [sportsTab, setSportsTab] = useState("4");
  const [eventType, setEventType] = useState(1);
  const [gamesTabs, setGamesTabs] = useState("sports");
  const [oddsRefreshTime, setOddsRefreshTIme] = useState(1000)
  const [marqueeText, setMarqueeText] = useState("")
  const [gamesList, setGamesLIst] = useState([])
  const [gamesLoader, setGamesLoader] = useState(false)
  const [iframeData, setIframeData] = useState({})
  const [bannerImages, setBannerImages] = useState(false)
  const [popularTab, setPopularTab] = useState("Today")
  // const [popularTab, setPopularTab] = useState("Today")
  const [popularData, setPopularData] = useState([])
  // Shimmer States
  const [shimmer1, setShimmer1] = useState(true)


  const navigate = useNavigate()
  const userData = JSON.parse(localStorage.getItem("betUser"));

  useEffect(() => {
    // getData()
    getBannerImages()
    getSiteSetting()
    getPopularGame()
    // getEventList()
    // getPopularData()
    
    // handelClickEvent({eventType: "1"})
  }, []);

  // useEffect(() => {
  //   getPopularData()
  // }, [popularTab])

  // const getEventList = async () => {
  //   setTopGamesLoader(true);
  //   let eventList = await axios.get(
  //     `${baseUrl}/user/event_list`
  //   );
  //   const eventData = eventList.data?.data?.filter((value, index, self) => index === self.findIndex((t) => (t?.eventType === value?.eventType)))
  //   setSportsTab(eventData?.find(el => el?.eventType == "4"));
  //   setEvent(eventData);
  //   setTopGamesLoader(false);
  // };
  // const fun = sportsTab?.eventType;

  const getSiteSetting = async () => {
    try {
      const Token = localStorage.getItem('token')
      const headers = {
        headers: {
          "x-access-token": Token
        }
      }
      const res = await axios.get(baseUrl + '/site-setting', headers)
      if (res?.status == 200) {
        setOddsRefreshTIme(res?.data?.data?.refreshTime)
        setMarqueeText(res?.data?.data?.marqueeText || "")
      }
    } catch (error) { }
  }

  // const handelClickEvent = async (ele) => {
  //   // setAllDataLaoder(true);  
  //   let dataAllList = await axios.get(
  //     `${baseUrl}/user/load_matches?eventId=${ele.name?.toLowerCase()}&inplay=true`
  //   );
  //   setAllData(dataAllList.data.data);
  //   setAllDataLaoder(false);
  // };

  // const getMatchData = () => {
  //   if (sportsTab?.eventType) {
  //     handelClickEvent(sportsTab);
  //   }
  // };

  // const getPopularData = async () => {
  //   let data = await axios.get(`${baseUrl}/user/load_matches?status=${popularTab.toLowerCase()}`)
  //   setPopularData(data.data.data)
  // }

  // const toggleSidebar = () => {
  //   const sidebar = document.getElementById("mobile_sidebar");
  //   sidebar?.classList.toggle("d-none");
  // };

  // const getSportsImage = (eventType) => {
  //   if (eventType === "1") {
  //     return <img src={footballicon} className="px-0 me-1" height="20" />
  //   } else if (eventType === "2") {
  //     return <img src={tenisicon} className="px-0 me-1" height="20" />
  //   } else {
  //     return <img src={cricketicon} className="px-0 me-1" height="20" />
  //   }
  // }

  const getPopularGame = async () => {
    try {
        setGamesLoader(true)
        const Token = JSON.parse(localStorage.getItem('betUser'))
        const headers = { headers: { "x-access-token": Token?.token } }
        const responce = await axios.get(baseUrl + `/casino/popular?Type=popular`, headers)
        if (responce.status == 200) {
            setGamesLIst(responce?.data?.data)
        }
        setGamesLoader(false)
    } catch (error) {
      setGamesLoader(false)
      console.log(error);
    }
  }

  const getIFrameData = async data => {
    const res = await axios.get(`${baseUrl}/casino/game_preview?username=${user?.username}&password=${user?.casinopassword || user?.password}&system=${iframeData?.MerchantID || iframeData?.System}&page=${iframeData?.PageCode}&Timezone=2023-05-09T17:20:41Z`)
    navigate("/iframe-game", { state: { iFrame: "<iframe " + (res?.data?.data).split("<iframe").pop() } })
  }

  useEffect(() => {
    if (iframeData?.PageCode && (iframeData?.MerchantID || iframeData?.System)) {
      getIFrameData(iframeData)
    }
  }, [ iframeData ])

  // useEffect(() => {
  //   getMatchData()
  //   getPopularData()
  //   getPopularGame()

  //   if (oddsRefreshTime) {
  //     const intervalCall = setInterval(() => {
  //       getMatchData()
  //       getPopularData()
  //     }, oddsRefreshTime);
  //     return () => clearInterval(intervalCall)
  //   }
  // }, [sportsTab, popularTab, oddsRefreshTime]);

  const getBannerImages = async() => {
    try{
      const res = await axios.get(baseUrl+ "/user/bannerList/top")
      if(res?.data?.data){
        setBannerImages(res?.data?.data?.reverse())
      }
    } catch (err){
      console.log(err)
    }
  }

  return (
    <div className="">
      <div className="position-relative">
        <MobileSideBar />
      </div>

      <PrimaryNavbar />
      {
        marqueeText
        ?
        <marquee className="bet-karo-marquee-heading" >{marqueeText}</marquee>
        :
        ""        
      }
      {
        !bannerImages
        ?
        <Shimmer className="m-0 p-0 shimmer" width={"98vw"} height={"150px"} />
        :
        <OwlCarousel items={1} loop={true} dots={true} autoplay={true} autoplayTimeout={3000} className='owl-theme' center={true} >
          {
            bannerImages?.map(el => {
              return <div className='item'>
                <img className="d-block w-100" height={150} style={{objectFit: "cover"}} src={el?.image} alt="First slide" />
              </div>
            }) 
          }
        </OwlCarousel>
      }

      <div className="PlayLiveGamesdivHome text-Dark">
        <div className="container-fluid px-5 py-3 hello">
          <h2 className="headinghome">Play Live Games</h2>
          <div className="row ">
            <div className="col-md-12 ">
              <div>
                <Slider {...settings} dots={false}>
                  <div className="px-2 ">
                    <div className="p-3">
                      <img src={rou1} className="w-100" />
                    </div>
                  </div>
                  <div className="px-2">
                    <div className="  p-3">
                      <img src={rou2} className="w-100" />
                    </div>
                  </div>
                  <div className="px-2">
                    <div className="  p-3">
                      <img src={rou3} className="w-100" />
                    </div>
                  </div>
                  <div className="px-2 ">
                    <div className="  p-3">
                      <img src={rou1} className="w-100" />
                    </div>
                  </div>
                  <div className="px-2">
                    <div className="  p-3">
                      <img src={rou2} className="w-100" />
                    </div>
                  </div>
                  <div className="px-2">
                    <div className="  p-3">
                      <img src={rou3} className="w-100" />
                    </div>
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="PlayLiveGamesdivHome_mobile w-100 pt-2 ">
        <div className="PlayLiveGamesdivHome_mobile_container text-white sidebar-between-section">
          <div className="d-flex pt-2 p-0 m-0 mb-4" style={{height: "45vh"}}>
            <div className="col-3 p-0 pe-2">

              <div onClick={() => { setGamesTabs("sports"); }} className={`d-flex flex-column align-items-center justify-content-center h-25 position-relative w-100 py-2 me-4 fs_10 homapge-border-radius ${gamesTabs == "sports" ? "green_glass" : "white_glass"}`} >
                <img src={icon2} className="w-40 mb-1" alt="" />
                <p className="m-0">SPORTS</p>
              </div>
              
              <div onClick={() => { setGamesTabs("casino"); }} className={`d-flex flex-column align-items-center justify-content-center h-25 position-relative w-100 py-2 me-4 fs_10 homapge-border-radius ${gamesTabs == "casino" ? "green_glass" : "white_glass"}`} >
                <img src={soliddice} className="w-40 mb-1" alt="" />
                <p className="m-0">CASINO</p>
              </div>

              <div onClick={() => { setGamesTabs("hotGames"); }} className={`d-flex flex-column align-items-center justify-content-center h-25 position-relative w-100 py-2 me-4 fs_10 homapge-border-radius ${gamesTabs == "hotGames" ? "green_glass" : "white_glass"}`} >
                <div className="w-40 mb-1 mt-2">
                  <svg width="25" height="30" viewBox="0 0 30 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M29.2844 22.6033C29.2844 30.3911 24.4927 33.8082 20.7901 34.9595C19.9994 35.2047 19.4814 34.2841 19.9536 33.6051C21.5643 31.2898 23.4275 27.7574 23.4275 24.4354C23.4275 20.8645 20.4185 16.6494 18.0685 14.0486C17.5322 13.4556 16.5951 13.8473 16.5658 14.6489C16.4688 17.3028 16.0497 20.8499 14.245 23.6319C14.1811 23.7359 14.0929 23.8227 13.988 23.8851C13.8831 23.9474 13.7646 23.9834 13.6428 23.9899C13.5209 23.9964 13.3993 23.9732 13.2884 23.9223C13.1774 23.8714 13.0805 23.7944 13.0059 23.6978C12.4422 22.984 11.8785 22.1036 11.3148 21.4063C11.0109 21.0329 10.4618 21.0274 10.1123 21.3587C8.74505 22.6527 6.83241 24.6697 6.83241 27.179C6.83241 28.8811 7.49131 30.6657 8.23623 32.1024C8.64621 32.8895 7.91777 33.8412 7.13075 33.4276C3.86737 31.7181 0 28.2479 0 22.6033C0 16.8453 7.88848 8.8671 10.9011 1.33004C11.377 0.140359 12.8412 -0.417875 13.8607 0.358161C20.0287 5.0583 29.2844 14.1438 29.2844 22.6033Z" fill="white" />
                  </svg>
                </div>
                <p className="m-0">HOT GAMES</p>
              </div>

              <div onClick={() => { setGamesTabs("bonusTab"); }} className={`d-flex flex-column align-items-center justify-content-center h-25 position-relative w-100 py-2 me-4 fs_10 homapge-border-radius ${gamesTabs == "bonusTab" ? "green_glass" : "white_glass"}`} >
                <div className="w-40 mb-1 mt-2">
                  <svg width="29" height="30" viewBox="0 0 34 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd" d="M31.3158 20.2632V29.4737C31.3158 30.9394 30.7336 32.345 29.6972 33.3814C28.6608 34.4178 27.2551 35 25.7895 35H18.4211V20.2632H31.3158ZM14.7368 20.2632V35H7.36842C5.90275 35 4.49711 34.4178 3.46073 33.3814C2.42434 32.345 1.84211 30.9394 1.84211 29.4737V20.2632H14.7368ZM11.0526 5.17381e-06C12.0982 -0.00123532 13.132 0.220609 14.085 0.650724C15.0379 1.08084 15.8882 1.70933 16.5789 2.49422C17.2697 1.70933 18.12 1.08084 19.0729 0.650724C20.0259 0.220609 21.0597 -0.00123532 22.1053 5.17381e-06H23.0263C23.7592 5.17381e-06 24.462 0.291123 24.9802 0.809315C25.4984 1.32751 25.7895 2.03033 25.7895 2.76316V3.68422C25.7895 5.02711 25.4303 6.28527 24.8039 7.36843H29.4737C30.4508 7.36843 31.3879 7.75658 32.0788 8.44751C32.7697 9.13843 33.1579 10.0755 33.1579 11.0526V14.7368C33.1579 15.4074 32.9792 16.0374 32.6642 16.579H18.4211V11.0526C18.4211 10.5641 18.227 10.0955 17.8815 9.75007C17.5361 9.40461 17.0675 9.21053 16.5789 9.21053C16.0904 9.21053 15.6218 9.40461 15.2764 9.75007C14.9309 10.0955 14.7368 10.5641 14.7368 11.0526V16.579H0.493687C0.169486 16.0192 -0.000839549 15.3837 3.11158e-06 14.7368V11.0526C3.11158e-06 10.0755 0.38816 9.13843 1.07908 8.44751C1.77001 7.75658 2.7071 7.36843 3.68421 7.36843H8.35395C7.70635 6.24873 7.36635 4.9777 7.36842 3.68422V2.76316C7.36842 2.03033 7.65954 1.32751 8.17773 0.809315C8.69593 0.291123 9.39875 5.17381e-06 10.1316 5.17381e-06H11.0526ZM11.0526 3.68422C11.0526 4.66133 11.4408 5.59842 12.1317 6.28935C12.8226 6.98027 13.7597 7.36843 14.7368 7.36843C14.7368 6.39131 14.3487 5.45422 13.6578 4.7633C12.9668 4.07237 12.0297 3.68422 11.0526 3.68422ZM22.1053 3.68422C21.1282 3.68422 20.1911 4.07237 19.5001 4.7633C18.8092 5.45422 18.4211 6.39131 18.4211 7.36843C19.3982 7.36843 20.3353 6.98027 21.0262 6.28935C21.7171 5.59842 22.1053 4.66133 22.1053 3.68422Z" fill="white" />
                  </svg>
                </div>

                {/* <img src={tshirt} className="w-40 mb-1" alt="" /> */}
                <p className="m-0">BONUS</p>
              </div>
            </div>
            <div className="col-8 p-0 pe-2 h-100  ">
              {
                gamesTabs === "sports"
                  ?
                  shimmer1 
                  ?
                  <>
                    <Shimmer className={"w-100 h-100 of_cover rounded-8"} />
                    <img onLoad={() => setShimmer1(false)} src={exchnageBanner} className="d-none w-100 h-100 of_cover rounded-8" />
                  </> 
                  :
                  checkAccess(ACCESS_TYPE?.EXCHANGE)
                  ?
                  <Link to="/exchange-page" className="h-100">
                    <img onLoad={() => setShimmer1(false)} src={exchnageBanner} className="w-100 h-100 of_cover rounded-8" />
                  </Link>
                  :
                  <img onClick={accessDenied} onLoad={() => setShimmer1(false)} src={exchnageBanner} className="w-100 h-100 of_cover rounded-8" />
                  :
                  ""
              }
              {
                gamesTabs === "casino"
                  ?
                  <div className="d-flex flex-column h-100">
                    <div className="flex-fill mb-2 overflow-hidden rounded-8">
                      <img 
                        onClick={() => checkAccess(ACCESS_TYPE?.CASINO) ? navigate("/all-games", { state: {title: "Slot and Bingo Games"} }) : accessDenied() } 
                        src={casino3} 
                        className="w-100 h-100 of_cover rounded-8" 
                      />
                    </div>
                    <div className="flex-fill mb-2 overflow-hidden rounded-8">
                    <img
                      onClick={() => checkAccess(ACCESS_TYPE?.CASINO) ? navigate("/popular-games", { state: {title: "Popular-Games"} }) : accessDenied() } 
                      src={casino2} 
                      className="w-100 h-100 of_cover rounded-8" 
                      alt="" 
                    />
                    </div>
                    {
                      checkAccess(ACCESS_TYPE?.CASINO)
                      ?
                      <Link to="/online-casino-games" className="flex-fill overflow-hidden rounded-8">
                        <img  src={casino4}  className="w-100 h-100 of_cover rounded-8" />
                      </Link>
                      :
                      <span onClick={accessDenied} className="flex-fill overflow-hidden rounded-8">
                        <img src={casino4} className="w-100 h-100 of_cover rounded-8"/>
                      </span>
                    }
                  </div>
                  :
                  ""
              }
              {
                gamesTabs === "hotGames"
                  ?
                  <div className="d-flex flex-column h-100">
                    <div className="flex-fill mb-2 overflow-hidden rounded-8">
                      <img onClick={() => checkAccess(ACCESS_TYPE?.CASINO) ? navigate("/all-games", { state: {title: "Slot and Bingo Games", search: "blackjack"} }) : accessDenied() } src={BlackJackBanner} className="w-100 h-auto of_cover rounded-8" />
                    </div>
                    <div className="flex-fill mb-2 overflow-hidden rounded-8">
                      <img onClick={() => checkAccess(ACCESS_TYPE?.CASINO) ? navigate("/all-games", { state: {title: "Slot and Bingo Games", search: "teen patti"} }) : accessDenied() } src={TeenPattiBanner} className="w-100 h-auto of_cover rounded-8" alt="" />
                    </div>
                    <div className="flex-fill overflow-hidden rounded-8">
                      <img onClick={() => checkAccess(ACCESS_TYPE?.CASINO) ? navigate("/all-games", { state: {title: "Slot and Bingo Games", search: "roulette"} }) : accessDenied() } src={RouletteBanner} className="w-100 h-auto of_cover rounded-8" alt="" />
                    </div>
                  </div>
                  :
                  ""
              }
              {
                gamesTabs === "bonusTab"
                  ?
                  <div className="d-flex flex-column h-100">
                    <div className="flex-fill mb-2 overflow-hidden rounded-8">
                      <img onClick={() => { navigate("/promotion-internal", { state: 1 }) }} src={welcomeBonus} className="w-100 h-auto of_cover rounded-8" />
                    </div>
                    <div className="flex-fill mb-2 overflow-hidden rounded-8">
                      <img onClick={() => navigate("/promotion-internal", { state: 2 })} src={cashbackBonus} className="w-100 h-auto of_cover rounded-8" />
                    </div>
                    <div className="flex-fill overflow-hidden rounded-8">
                      <img onClick={() => navigate("/promotion-internal", { state: 3 })} src={refillBonus} className="w-100 h-auto of_cover rounded-8" />
                    </div>
                  </div>
                  :
                  ""
              }

            </div>
            <div className="col-1 p-0 d-flex align-items-center  ">
              <div className="white_glass2 d-flex flex-column w-100 h-36 justify-content-center rounded-social-media">
                <a href="https://instagram.com/betkaroindia" className="d-flex justify-content-center my-2" target="_blank" rel="noopener noreferrer" >
                  <img src={insta} className="w-75" />
                </a>
                <a href="https://twitter.com/betkaroindia" className="d-flex justify-content-center my-2" target="_blank" rel="noopener noreferrer" >
                  <img src={twiter} className="w-75" />
                </a>
                <a href="https://facebook.com/betkaroindia" className="d-flex justify-content-center my-2" target="_blank" rel="noopener noreferrer" >
                  <img src={fb} className="w-75" />
                </a>
              </div>
            </div>
          </div>
          {/* <Link to="#" className="td-none">
            <div className="mb-4 px-3 ">
              <div className="row m-0 p-0 ">
                <div className="col-3 p-2 d-flex flex-column align-items-center fs_10 justify-content-center white_glass bordeer-right-2-w refrences-card-history-3">
                  <img src={list1} alt="lisr" className="" />
                  <span className="text-yellow_01 td-none">LOYALTY</span>
                </div>
                <div className="col-9 p-2 d-flex justify-content-center white_glass down-arrow-radius refrences-card-history-4">
                  <img src={royalty1} alt="lisr" className="mx-2 rounded-6" />
                  <img src={royalty2} alt="lisr" className="mx-2 rounded-6" />
                </div>
              </div>
            </div>
          </Link> */}



          {
            gamesLoader
            ?
            <div className="white_glass d-flex hideScrollbar overflow-hidden">
              <Shimmer className="" height="100px" width="98vw" />
            </div>
            :
            <div className="white_glass d-flex hideScrollbar overflow-auto p-2">
              {
                gamesList?.map((ele, ind) => (
                  <span className="me-2" onClick={() => checkAccess(ACCESS_TYPE?.CASINO) ? setIframeData(ele) : accessDenied() }>
                    <img className="rounded-8" style={{width: "120px", height: "85px", objectFit: "cover"}} src={ele?.ImageFullPath} />
                  </span>
                ))
              }
            </div>
          }

          {/* <div className="d-flex justify-content-center my-3">
            <img src={fire} className="mx-1" />
            <span className="text-white fw_800">TOP GAMES</span>
          </div> */}

          {/* <div className='green_glass py-2 border-0 row px-3 mx-0'>
            <div className='col-12 d-flex m-0 p-0'>
              {
                event?.sort((a, b) => Number(a?.number) > Number(b?.number) ? 1 : -1)?.map((ele, index) => index < 3 ? (
                  <div className="col-4 m-0 p-0 pe-2 " onClick={() => setSportsTab(ele)} >
                    <button className={ele?.name === sportsTab?.name ? "btn w-100 d-flex align-items-center justify-content-center py-0 fw_700 fs_14 h-100 rounded-0 shadow-none btn_commn_yellow_1 rounded-6" : " btn w-100 d-flex align-items-center justify-content-center btn-light py-0 fw_700 fs_14 h-100 rounded-0 shadow-none rounded-6"}>
                      {
                        getSportsImage(ele?.eventType)
                      }
                      <div className="mb-0 text-dark fs_12 fw_800">
                        {ele?.name}
                      </div>
                    </button>
                  </div>
                ) : "")
              }
            </div>
          </div> */}
        </div>
      </div>

      {/* <BetSlipComponent eventType={fun} allData={allData} inPlayText={"In Play"} allDataLoader={allDataLoader} /> */}

      {/* <div className="PlayLiveGamesdivHome_mobile_container pt-3">
        <div className="d-flex justify-content-center mb-3">
          <img src={star} className="mx-1" />
          <span className="text-white fw_800">POPULAR GAMES</span>
        </div>

        <div className='green_glass py-2 border-0 row px-3 mx-0'>
          <div className='col-12 d-flex m-0 p-0'>
            {
              popularType.map((ele) => (
                <div className={`col-4 m-0 p-0 pe-2`} onClick={() => setPopularTab(ele)}>
                  <button className={popularTab === ele ? "btn w-100 d-flex align-items-center justify-content-center btn-warning py-0 fw_700 fs_14 h-100 rounded-0 shadow-none btn_commn_yellow_1 rounded-6" : "btn w-100 d-flex align-items-center justify-content-center btn-light py-0 fw_700 fs_14 h-100 rounded-0 shadow-none rounded-6"}>
                    <div className="br-0 card-body"  >
                      <div className="mb-0 text-dark fs_12 fw_800">
                        {ele}
                      </div>
                    </div>
                  </button>
                </div>
              ))}
          </div>
        </div>
      </div> */}

      {/* <BetSlipComponent eventType={fun} allData={popularData} inPlayText={"Popular"} allDataLoader={allDataLoader} /> */}

      <div className="Offersforyoudiv pb-5 ">
        <div className="container-fluid px-5 py-5">
          <h2 className="headinghomeastrologers my-3">
            Talk to our astrologers
          </h2>

          <div className="row">
            <div className="col-md-6">
              <div className="card astrologers1 p-3">
                <div className="card-body">
                  <h5 className="card-title-casino text-white">
                    70% more <br /> chances of
                  </h5>
                  <p className="text-white">Talk to our astologers</p>

                  <a href="#" className="btn btn-primary">
                    Talk Now
                  </a>
                </div>
              </div>{" "}
            </div>
            <div className="col-md-6">
              <div className="card astrologers2 p-3">
                <div className="card-body">
                  <h5 className="card-title-casino text-white">
                    Get Your <br /> Match
                  </h5>
                  <p className="text-white">Talk to our astologers</p>

                  <a href="#" className="btn btn-primary">
                    Talk Now
                  </a>
                </div>
              </div>{" "}
            </div>
          </div>
        </div>
      </div>

      <div className="offermainDiv">
        <div className=" ">
          <div className="container-fluid px-5 py-3">
            <h2 className="headinghomeOffers my-3">Offers for you</h2>

            <div className="row">
              <div className="col-md-4">
                <div className="card Offerscard1 p-3">
                  <div className="card-body">
                    <h5 className="card-title-casino text-white">
                      WIN BONUS <br /> COINS
                    </h5>
                    <p className="text-white">500 Coins on first Deposit</p>

                    <a href="#" className="btn btn-primary">
                      Know More{" "}
                    </a>
                  </div>
                </div>{" "}
              </div>
              <div className="col-md-4">
                <div className="card Offerscard2 p-3">
                  <div className="card-body">
                    <h5 className="card-title-casino text-white">
                      WIN EXLUSIVE <br /> PRIZES
                    </h5>
                    <p className="text-white">On Every Bid</p>

                    <a href="#" className="btn btn-primary">
                      Know More{" "}
                    </a>
                  </div>
                </div>{" "}
              </div>
              <div className="col-md-4">
                <div className="card Offerscard3 p-3">
                  <div className="card-body">
                    <h5 className="card-title-casino text-white">
                      CASHBACK <br /> Bonus
                    </h5>
                    <p className="text-white">On All Deposits</p>

                    <a href="#" className="btn btn-primary">
                      Know More{" "}
                    </a>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>

        <div className="casioncard">
          <div className="container-fluid px-5 py-3">
            <h2 className="headinghomeOffers">Play live casino games</h2>
            <div className="row pb-5 pt-3">
              <div className="col-md-4">
                <div className="card cardcasinogame1 p-3">
                  <div className="card-body">
                    <p className="pcasino">The Spin Game</p>
                    <h5 className="card-title-casino text-white">Roulette</h5>

                    <a href="#" className="btn btn-primary">
                      Play Now{" "}
                    </a>
                  </div>
                </div>{" "}
              </div>
              <div className="col-md-4">
                <div className="card cardcasinogame p-3">
                  <div className="card-body">
                    <p className="pcasino">The Spin Game</p>
                    <h5 className="card-title-casino text-white">Roulette</h5>

                    <a href="#" className="btn btn-primary">
                      Play Now{" "}
                    </a>
                  </div>
                </div>{" "}
              </div>
              <div className="col-md-4">
                <div className="card cardcasinogame p-3">
                  <div className="card-body">
                    <p className="pcasino">The Spin Game</p>
                    <h5 className="card-title-casino text-white">Roulette</h5>

                    <a href="#" className="btn btn-primary">
                      Play Now{" "}
                    </a>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div className="row py-5">
              <div className="col-md-4">
                <div className="card cardcasinogame p-3">
                  <div className="card-body">
                    <p className="pcasino">The Spin Game</p>
                    <h5 className="card-title-casino text-white">Roulette</h5>
                    <a href="#" className="btn btn-primary">
                      Play Now
                    </a>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <div className="card cardcasinogame1 p-3">
                  <div className="card-body">
                    <p className="pcasino">The Spin Game</p>
                    <h5 className="card-title-casino text-white">Roulette</h5>

                    <a href="#" className="btn btn-primary">
                      Play Now
                    </a>
                  </div>
                </div>{" "}
              </div>
              <div className="col-md-4">
                <div className="card cardcasinogame p-3">
                  <div className="card-body">
                    <p className="pcasino">The Spin Game</p>
                    <h5 className="card-title-casino text-white">Roulette</h5>

                    <a href="#" className="btn btn-primary">
                      Play Now{" "}
                    </a>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div className="row py-5">
              <div className="col-md-4">
                <div className="card cardcasinogame1 p-3">
                  <div className="card-body">
                    <p className="pcasino">The Spin Game</p>
                    <h5 className="card-title-casino text-white">Roulette</h5>

                    <a href="#" className="btn btn-primary">
                      Play Now{" "}
                    </a>
                  </div>
                </div>{" "}
              </div>
              <div className="col-md-4">
                <div className="card cardcasinogame p-3">
                  <div className="card-body">
                    <p className="pcasino">The Spin Game</p>
                    <h5 className="card-title-casino text-white">Roulette</h5>

                    <a href="#" className="btn btn-primary">
                      Play Now{" "}
                    </a>
                  </div>
                </div>{" "}
              </div>
              <div className="col-md-4">
                <div className="card cardcasinogame p-3">
                  <div className="card-body">
                    <p className="pcasino">The Spin Game</p>
                    <h5 className="card-title-casino text-white">Roulette</h5>

                    <a href="#" className="btn btn-primary">
                      Play Now{" "}
                    </a>
                  </div>
                </div>{" "}
              </div>
            </div>
            <div className="row py-5">
              <div className="col-md-4">
                <div className="card cardcasinogame p-3">
                  <div className="card-body">
                    <p className="pcasino">The Spin Game</p>
                    <h5 className="card-title-casino text-white">Roulette</h5>

                    <a href="#" className="btn btn-primary">
                      Play Now{" "}
                    </a>
                  </div>
                </div>{" "}
              </div>
              <div className="col-md-4">
                <div className="card cardcasinogame1 p-3">
                  <div className="card-body">
                    <p className="pcasino">The Spin Game</p>
                    <h5 className="card-title-casino text-white">Roulette</h5>

                    <a href="#" className="btn btn-primary">
                      Play Now{" "}
                    </a>
                  </div>
                </div>{" "}
              </div>
              <div className="col-md-4">
                <div className="card cardcasinogame p-3">
                  <div className="card-body">
                    <p className="pcasino">The Spin Game</p>
                    <h5 className="card-title-casino text-white">Roulette</h5>

                    <a href="#" className="btn btn-primary">
                      Play Now{" "}
                    </a>
                  </div>
                </div>{" "}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
      {
        userData && userData?._id
          ?
          ""
          :
          <Whatsapp />
      }
      <MobileFooter />
    </div >
  );
}

export default HomePage;
