import axios from "axios";
import React, { useState } from "react";
import { Form, Button } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import leftarrow from "../../assets/images/leftarrow.png";
import "../../components/Password/password.css"
import baseUrl from "../../services/baseUrl";
const Password = ({ ref2 }) => {
  const navigate = useNavigate();
  const [pageId, setPageID] = useState("1");
  const [Responce , setResponce ] = useState("")
  const [passwordForm, setPasswordForm] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    loader: false,
  });
  const [passwordFormError, setPasswordFormError] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // validate form
    let errors = {};
    let isValid = true;

    if (!passwordForm.currentPassword) {
      errors.currentPassword = "Current password is required";
      isValid = false;
    }

    if (!passwordForm.newPassword) {
      errors.newPassword = "New password is required";
      isValid = false;
    } else if (passwordForm.newPassword.length < 6) {
      errors.newPassword = "New password must be  8 characters long";
      isValid = false;
    }

    if (!passwordForm.confirmNewPassword) {
      errors.confirmNewPassword = "Confirm new password is required";
      isValid = false;
    } else if (passwordForm.newPassword !== passwordForm.confirmNewPassword) {
      errors.confirmNewPassword = "Passwords do not match";
      isValid = false;
    }

    setPasswordFormError(errors);
    if (isValid) {
      const token = JSON.parse(localStorage.getItem("betUser"))?.token;
      const headers = {
        headers: {
          "x-access-token": token,
        },
      };
      setPasswordForm({ ...passwordForm, loader: true });
      axios
        .put(
          `${baseUrl}/user/change_password`,
          {
            old_password: passwordForm.currentPassword,
            new_password: passwordForm.newPassword,
          },
          headers
        )
        .then((response) => {
          if (response.data.error) {
            setPasswordForm({ ...passwordForm, loader: false });
            if (response.data.message === "Invalid password") {
              toast.error("Invalid password. Please try again.");
            } else {
              toast.error(response.data.message);
            }
          } else {
            setPasswordForm({ ...passwordForm, loader: false });
            toast.success("Updated Successfully..");
          }
        })
        .catch((error) => {
          if (error.response && error.response.data && error.response.data.message === "Invalid password") {
            toast.error( error.response.data.message);
          } else {
            toast.error(error.message);
          }
          setPasswordForm({ ...passwordForm, loader: false });
        });
    }
  }
    const handleChange = (e) => {
      setPasswordForm({ ...passwordForm, [e.target.name]: e.target.value });
      setPasswordFormError({ ...passwordFormError, [e.target.name]: null });
    };
  
  return (
    <>
      <ToastContainer/>
     <div className="dashboard-account col-md mb-3">
        <div className="symbols-dashboard-back">
          <img
            className="back-arrow"
            src={leftarrow}
            alt="Symbols"
            onClick={() =>
              ref2?.current?.scrollIntoView({ behaviour: "smooth" })
            }
          />
          <p className="dashboard-p">Password</p>
        </div>
        <div className="inputs-banks-holders-div actions-buttons-submit pass-mobile-bg">
          <div className="form-group">
            <label htmlFor="currentPasswor">Current Password</label>
            <input
              type="password"
              className={`form-control ${
                passwordFormError.currentPassword ? "is-invalid" : ""
              }`}
              id="currentPassword"
              name="currentPassword"
              placeholder="Current Password"
              value={passwordForm.currentPassword}
              onChange={handleChange}
              required
            />
            {passwordFormError.currentPassword && (
              <div className="invalid-feedback">
                {passwordFormError.currentPassword}
              </div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="newPassword">New Password</label>
            <input
              type="password"
              className={`form-control ${
                passwordFormError.newPassword ? "is-invalid" : ""
              }`}
              id="newPassword"
              name="newPassword"
              placeholder="New Password"
              value={passwordForm.newPassword}
              onChange={handleChange}
              required
            />
            {passwordFormError.newPassword && (
              <div className="invalid-feedback">
                {passwordFormError.newPassword}
              </div>
            )}
          </div>

          <div className="form-group">
            <label htmlFor="confirmNewPassword">Confirm Password</label>
            <input
              type="password"
              className={`form-control ${
                passwordFormError.confirmNewPassword ? "is-invalid" : ""
              }`}
              id="confirmNewPassword"
              name="confirmNewPassword"
              placeholder="Confirm Password"
              value={passwordForm.confirmNewPassword}
              onChange={handleChange}
              required
            />
            {passwordFormError.confirmNewPassword && (
              <div className="invalid-feedback">
                {passwordFormError.confirmNewPassword}
              </div>
            )}
          </div>

          <div className="actions-buttons-div">
            <button
              type="submit"
              className="btn btn-primary mr-3"
              onClick={handleSubmit}
            >
              {passwordForm.loader ? (
                <span
                  className="spinner-border spinner-border-sm mr-2"
                  role="status"
                  aria-hidden="true"
                ></span>
              ) : null}
              Submit
            </button>
            <button
              type="button"
              className="btn btn-danger"
              // onClick={handleReset}
            >
              Reset
            </button>
          </div>
        </div>
        <br />
      </div>
    </>
  );
}

export default Password;

