import React, { useEffect, useState } from 'react';
import PrimaryNavbar from '../../components/PrimaryNavbar'
import ArrowLeft from "../../assets/arrow_left.svg"
import CleanderIcon from "../../assets/cleander_icon.svg"
import DownArrow from "../../assets/down_arrow.svg"
import { useNavigate } from 'react-router-dom'
import MobileFooter from '../../components/MobileFooter';
import baseUrl from '../../services/baseUrl';
import { allWithdraHistoryList } from '../../services/api';
import axios from 'axios';
import MobileSideBar from '../../components/MobileSideBar/MobileSideBar';
import ReactPaginate from 'react-paginate';

const History = () => {
    const navigate = useNavigate()
    const [historyList, setHistoryList] = useState({ data: [], loader: false, betType: 'deposit' })
    const [filterStartDate, setFilterStartDate] = useState("")
    const [filterEndDate, setFilterEndDate] = useState("")
    const [filterType, setFilterType] = useState("all")

    const handleStartDate = (e) => {
        let date = e.target.value
        handleStatesSync("filterStDt", date)
    }

    const handleEndDate = (e) => {
        let date = e.target.value
        handleStatesSync("filterEdDt", date)
    }

    const handleType = (e) => {
        let value = e.target.value;
        setFilterType(value)
    }

    const handleStatesSync = (stateName, value) => {
        if (stateName === "filterStDt") { setFilterStartDate(value) }
        if (stateName === "filterEdDt") { setFilterEndDate(value) }
    }

    const createStEnDate = () => {
        let dtStr = "";
        if (filterStartDate.length !== 0 && filterEndDate.length !== 0) {
            dtStr = `&dateFrom=${filterStartDate}&dateTo=${filterEndDate}`;
        } else if (filterStartDate.length !== 0 && filterEndDate.length === 0) {
            dtStr = `&dateFrom=${filterStartDate}`;
        } else if (filterStartDate.length === 0 && filterEndDate.length !== 0) {
            dtStr = `&dateTo=${filterEndDate}`;
        } else {
            dtStr = "";
        }
        return dtStr;
    };

    const createTypeStr = () => {
        let typeStr = "";
        if (filterType === "success") {
            typeStr = "&type=sucess"
        } else if (filterType === "pending") {
            typeStr = "&type=sucess"
        } else {
            typeStr = ""
        }
        return typeStr;
    }

    const handleHistoryList = async () => {
        setHistoryList({ ...historyList, loader: true })
        const user = JSON.parse(localStorage.getItem("betUser"));
        const headers = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": user?.token
            }
        }
        try {
            if (historyList.betType == 'deposit') {
                const status = 'deposit'
                const dateStr = createStEnDate()
                const typeStr = createTypeStr()
                const responce = await axios.get(baseUrl + allWithdraHistoryList + `?status=${status}` + typeStr + dateStr, headers)
                if (responce.status == 200) {
                    setHistoryList({ ...historyList, data: responce?.data?.data, loader: false })
                }
            }

            if (historyList.betType == 'withdrawl') {
                const status = 'withdraw'
                const dateStr = createStEnDate()
                const typeStr = createTypeStr()
                const responce = await axios.get(baseUrl + allWithdraHistoryList + `?status=${status}` + typeStr + dateStr, headers)
                if (responce.status == 200) {
                    setHistoryList({ ...historyList, data: responce?.data?.data, loader: false })
                }
            }

            if (historyList.betType == 'all') {
                const dateStr = createStEnDate()
                const typeStr = createTypeStr()
                const responce = await axios.get(baseUrl + allWithdraHistoryList + typeStr + dateStr, headers)
                if (responce.status == 200) {
                    setHistoryList({ ...historyList, data: responce?.data?.data, loader: false })
                }
            }

        } catch (error) {
            setHistoryList({ ...historyList, loader: false })
        }
    }

    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 3
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = historyList?.data?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(historyList?.data?.length / itemsPerPage);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % historyList?.data?.length;
        setItemOffset(newOffset);
    };

    useEffect(() => {
        handleHistoryList()
    }, [historyList?.betType, filterEndDate, filterStartDate, filterType])

    


    return (
        <div>
            <PrimaryNavbar />
            <div className="position-relative">
                <MobileSideBar />
            </div>
            <div className="container py-3 historyBG">
                <div className="row mb-3 text-white">
                    <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
                        <img src={ArrowLeft} className="cursor-pointer arrow-icone-size rounded-4" />
                    </div>
                    <div className="col-10">
                        <p className="mb-0 currentbets_title fw_700 text-center">
                            History
                        </p>
                    </div>
                    <div className="col-1"></div>
                </div>
                <div className='row mb-2 mx-0 mb-3'>
                    <div className='col-12 d-flex m-0 p-0'>
                        <div className="col-5 m-0 p-0 pe-2" onClick={() => setHistoryList({ ...historyList, betType: "deposit" })} >
                            <button className={`${historyList.betType == 'deposit' ? "btn w-100 h-auto py-0 fw_700 fs_14 lh_14 btn-warning rounded-6 shadow-none btn_commn_yellow_1" : "w-100 h-100 btn btn-light rounded-6 px-4 fw_700 fs_14 lh_14 shadow-none"}`}>Deposit</button>
                        </div>
                        <div className="col-5 m-0 p-0 pe-2" onClick={() => setHistoryList({ ...historyList, betType: "withdrawl" })} >
                            <button className={`${historyList.betType == 'withdrawl' ? "btn w-100 h-auto py-0 fw_700 fs_14 lh_14 btn-warning rounded-6 shadow-none btn_commn_yellow_1" : "w-100 h-100 btn btn-light rounded-6 px-4 fw_700 fs_14 lh_14 shadow-none"}`}>Withdrawl</button>
                        </div>
                        <div className="col-2 m-0 p-0" onClick={() => setHistoryList({ ...historyList, betType: "all" })}>
                            <button className={`${historyList.betType == 'all' ? "btn w-100 h-auto py-0 fw_700 fs_14 lh_14 btn-warning rounded-6 shadow-none btn_commn_yellow_1" : "w-100 btn btn-light rounded-6 px-4 fw_700 fs_14 lh_14 shadow-none h-42p"}`}>All</button>
                        </div>
                    </div>
                </div>

                <div className='row mb-2 mx-0 mb-3'>
                    <div className='col-12 d-flex m-0 p-0'>
                        <div className="col-4 m-0 p-0 pe-2 ">
                            <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2 rounded-6'>
                                <div className='date_input d-flex align-items-center me-3 rounded-6'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>{filterStartDate ? (new Date(filterStartDate))?.toLocaleDateString() : "MM/DD/YYYY"}</p>
                                </div>
                                <div className='cleander_dropedown down-arrow-radius'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input onChange={handleStartDate} type="date" name='date' max={new Date().toISOString().split("T")[0]} className="position-absolute w-18 opacity-0" />
                                </div>
                            </div>
                        </div>
                        <div className="col-4 m-0 p-0 pe-2">
                            <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2 rounded-6'>
                                <div className='date_input d-flex align-items-center me-3 rounded-6'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>{filterEndDate ? (new Date(filterEndDate))?.toLocaleDateString() : "MM/DD/YYYY"}</p>
                                </div>
                                <div className='cleander_dropedown down-arrow-radius'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input onChange={handleEndDate} type="date" name='date' max={new Date().toISOString().split("T")[0]} className="position-absolute w-18 opacity-0" />
                                </div>
                            </div>
                        </div>
                        <div className="col-4 m-0 p-0 rounded-6">
                            <div className='start_date_Wrapper d-flex align-items-center rounded-6'>
                                <select onChange={handleType} className="fs_14 w-100 p-1 outline-0 shadow-0 h-32 rounded-6">
                                    <option value="all">All</option>
                                    <option value="success">Success</option>
                                    <option value="pending">Pending</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                {console.log(historyList, 44444444444)}


                {

                    historyList?.loader == false && historyList?.data?.length != 0
                        ?
                        currentItems.map((ele, ind) => {
                            return <div > 
                                <div className={`bet_card_wrapper mb-3  `}>
                                    <div className="div m-0 green_glass d-flex border-success py-2 align-items-center justify-content-between px-2 fw_700 position_relative refrences-card-history-1">
                                        <span className="fs_14 text-white">Reference No.</span>
                                        <span className="fs_14 text-white text-uppercase">:{" "}{ele?._id}</span>
                                    </div>
                                    <div className="row m-0 white_glass py-3 align-items-end green_btm_border refrences-card-history-2">
                                        <div className='col-8 border-right text-white fs_12 fw_400'>
                                            <div className='row '>
                                                <div className='col-6'>
                                                    <p className='mb-0'>Type</p>
                                                </div>
                                                <div className='col-6'>
                                                    <p className='mb-0 text-green_01'>:{" "}{ele.type == 'deposit_request' && 'DEPOSIT' || ele.type == 'withdrawal_request' && 'WITHDRAWL' || ele.type == 'deposit_request' && 'DEPOSIT'}</p>
                                                </div>
                                            </div>
                                            <div className='row '>
                                                <div className='col-6'>
                                                    <p className='mb-0'>Description</p>
                                                </div>
                                                <div className='col-6'>
                                                    <p className='mb-0'>: Fast Deposit UPI</p>
                                                </div>
                                            </div>
                                            <div className='row text-white'>
                                                <div className='col-6'>
                                                    <p className='mb-0'> Amount</p>
                                                </div>
                                                <div className='col-6'>
                                                    <p className='mb-0'>: {ele?.amount}</p>
                                                </div>
                                            </div>
                                            <div className='row'>
                                                <div className='col-6 text-yellow_01'>
                                                    <p className='mb-0'>Status</p>
                                                </div>
                                                <div className='col-6 text-yellow_01'>
                                                    <p className='mb-0 fw_700'><span className="text-white fw_400">: </span> {ele?.status == 'pending' ? 'PENDING' : ""}  {ele?.status == 'approved' ? 'SUCCESS' : ""} </p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-4 text-white fs_10 fw_400'>
                                            <p className='mb-0'>Time And Date</p>
                                            <p className='mb-0'>{ele.createdAt.slice(0, 10)}{" "} {ele?.createdAt?.slice(11, 19)}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        })
                        :
                        <div>
                            {
                                historyList?.data?.length == 0 && historyList?.loader == false
                                    ?
                                    <div className="bet_card_wrapper mb_4rem ">
                                        <div className="row m-0 white_glass py-3 align-items-end d-flex align-items-center justify-content-center py-5 rounded-8">
                                            <span className="text-white fs_12 text-center">No content</span>
                                        </div>
                                    </div>
                                    :
                                    ''
                            }
                            {
                                historyList?.loader == true
                                    ?
                                    <div className='text-center'>
                                        <div className="spinner-border text-primary" role="status"></div>
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                }

                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    pageClassName="page-item me-1"
                    subContainerClassName="pages"
                    pageLinkClassName="page-link"
                    previousClassName="page-item me-1"
                    previousLinkClassName="page-link"
                    nextClassName="page-item me-1"
                    nextLinkClassName="page-link"
                    breakClassName="page-item me-1"
                    breakLinkClassName="page-link"
                    marginPagesDisplayed={1}
                    containerClassName="pagination"
                    activeClassName="active"
                />
            </div>
            <MobileFooter />
        </div>
    );
};

export default History;