import React from 'react';
import PrimaryNavbar from '../../components/PrimaryNavbar'
import ArrowLeft from "../../assets/arrow_left.svg"
import CleanderIcon from "../../assets/cleander_icon.svg"
import DownArrow from "../../assets/down_arrow.svg"
import { useNavigate } from 'react-router-dom';
import MobileFooter from '../../components/MobileFooter';
import MobileSideBar from '../../components/MobileSideBar/MobileSideBar';
import { get_Message_List, update_view_messge } from '../../services/api';
import baseUrl from '../../services/baseUrl';
import { useState, useEffect } from 'react'
import axios from 'axios';
import ReactPaginate from 'react-paginate';

const Messages = () => {
    const user = JSON.parse(localStorage.getItem('betUser'))
    const navigate = useNavigate()
    const [messageList, setMessageList] = useState({ data: [], count: 0, loader: false })
    const [filterStartDate, setFilterStartDate] = useState("")
    const [filterEndDate, setFilterEndDate] = useState("")


    function convertTo12HourFormat(time) {
        let hours = parseInt(time.substring(0, 2));
        let minutes = time.substring(3, 5);
        let ampm = hours >= 12 ? 'PM' : 'AM';
        hours = hours % 12;
        hours = hours ? hours : 12;
        return hours + ':' + minutes + ' ' + ampm;
    }

    let time24 = '18:45';
    let time12 = convertTo12HourFormat(time24);

    const handleEndDate = (e) => {
        let date = e.target.value
        handleStatesSync("filterEdDt", date)
    }

    const handleStartDate = (e) => {
        let date = e.target.value
        handleStatesSync("filterStDt", date)
    }

    const handleStatesSync = (stateName, value) => {
        if (stateName === "filterStDt") { setFilterStartDate(value) }
        if (stateName === "filterEdDt") { setFilterEndDate(value) }
    }

    const createStEnDate = () => {
        let dtStr = "";
        if (filterStartDate.length !== 0 && filterEndDate.length !== 0) {
            dtStr = `&dateFrom=${filterStartDate}&dateTo=${filterEndDate}`;
        } else if (filterStartDate.length !== 0 && filterEndDate.length === 0) {
            dtStr = `&dateFrom=${filterStartDate}`;
        } else if (filterStartDate.length === 0 && filterEndDate.length !== 0) {
            dtStr = `&dateTo=${filterEndDate}`;
        } else {
            dtStr = "";
        }
        return dtStr;
    };


    const getMessageList = async () => {
        try {
            const dateStr = createStEnDate()
            setMessageList({ ...messageList, count: 0,loader: true })

            const token = user?.token
            const headers = {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": token,
                },
            };
            const res = await axios.get(baseUrl + get_Message_List + "?" + dateStr, headers)
            if (res?.status == 200) {
                setMessageList({ data: res?.data?.data.reverse(), count: res?.data?.unreadCount || 0, loader: false })
            } else {
                setMessageList({ ...messageList, loader: false })
            }
        } catch (error) {
            setMessageList({ ...messageList, loader: false })
        }
    }

    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 5
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = messageList?.data?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(messageList?.data?.length / itemsPerPage);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % messageList?.data?.length;
        setItemOffset(newOffset);
    }

    const updateMessageRead = async (id, isRead) => {
        try {
            if(isRead){
                return
            }
            const token = user?.token
            const headers = {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": token,
                },
            };
            const res = await axios.put(baseUrl + update_view_messge + `?messageId=${id}`, {}, headers)
            if (res?.status == 200) {
                getMessageList()
            } 
        } catch (error) {
            setMessageList({ ...messageList, loader: false })
        }
    }


    useEffect(() => {
        getMessageList()
    }, [filterEndDate, filterStartDate])

    return (
        <div>
            <PrimaryNavbar />
            <div className="position-relative">
                <MobileSideBar />
            </div>
            <div className="container px-0 py-3 messegeBG">
                <div className="row px-3 mb-3 text-white mx-0">
                    <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
                        <img src={ArrowLeft} className="cursor-pointer arrow-icone-size rounded-4" />
                    </div>
                    <div className="col-10">
                        <p className="mb-0 currentbets_title fw_700 text-center">
                            Notifications
                        </p>
                    </div>
                    <div className="col-1"></div>
                </div>

                <div className='row px-3 mb-2 mx-0 mb-3'>
                    <div className='col-12 d-flex m-0 p-0 text-white'>
                        {messageList?.count ? messageList?.count : " "} Unread messages
                    </div>
                </div>

                {/* <div className='row px-3 mb-2 mx-0 mb-3'>
                    <div className='col-12 d-flex m-0 p-0'>
                        <div className="col-4 m-0 p-0 pe-2">
                            <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2'>
                                <div className='date_input d-flex align-items-center me-3'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>01/03/2023</p>
                                </div>
                                <div className='cleander_dropedown'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input type="date" className="position-absolute w-18 opacity-0" />
                                </div>
                            </div>
                        </div>
                        <div className="col-4 m-0 p-0 pe-2">
                            <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2'>
                                <div className='date_input d-flex align-items-center me-3'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>01/03/2023</p>
                                </div>
                                <div className='cleander_dropedown'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input type="date" className="position-absolute w-18 opacity-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                <div className='row mb-2 px-3 mx-0 mb-3'>
                    <div className='col-12  d-flex m-0 p-0'>
                        <div className="col-4 m-0 p-0 pe-2">
                            {/* <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2 rounded-6'>
                                <div className='date_input d-flex align-items-center me-3 runded-6'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>{filterStartDate ? (new Date(filterStartDate))?.toLocaleDateString() : "MM/DD/YYYY"}</p>
                                </div>
                                <div className='cleander_dropedown down-arrow-radius'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input onChange={handleStartDate} type="date" name='date' max={new Date().toISOString().split("T")[0]} className="position-absolute w-18 opacity-0" />
                                </div>
                            </div> */}
                        </div>
                        <div className="col-4 m-0 p-0 pe-2">
                            {/* <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2 rounded-6'>
                                <div className='date_input d-flex align-items-center me-3 rounded-6'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>{filterEndDate ? (new Date(filterEndDate))?.toLocaleDateString() : "MM/DD/YYYY"}</p>
                                </div>
                                <div className='cleander_dropedown down-arrow-radius'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input onChange={handleEndDate} type="date" name='date' max={new Date().toISOString().split("T")[0]} className="position-absolute w-18 opacity-0" />
                                </div>
                            </div> */}
                        </div>
                        <div className="col-4 m-0 p-0">
                            <div className='start_date_Wrapper d-flex align-items-center'>
                                {/* <div className='date_input d-flex align-items-center me-3'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>01/03/2023</p>
                                </div>
                                <div className='cleander_dropedown'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row mb-3 px-3 mb_4rem mx-0">
                    <div className="accordion px-0" id="accordionExample">
                        {/* <div className="accordion-item border-0 rounded-0 border-0 mb-3">
                            <h2 className="accordion-header border-0 rounded-0 border-0">
                                <button className=" position-relative text-white accordion-button accordionButton text-white border-0 rounded-0 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    <div className="text-white w-100">
                                        <p className="mb-1">Maintainence from 4 AM to 5 AM</p>
                                        <p className="fs_10 mb-0">24-06-2023, 4:00AM</p>
                                    </div>
                                    <span className="position-absolute text-dark zIndex_9 right-10 btn rounded-0 btn_commn_yellow_1 p-2 px-3 fw_700 d-flex justify-content-center align-items-center fs_12 " data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">View</span>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div className="accordion-body fs_12">
                                    To provide an improved experience, BetKaro India will undergo maintenance between 04:00am to 05:00am on 25-06-2023. We apologize for any inconvenience this may cause.
                                </div>
                                <div className="bg-white p-1 d-flex fs_12 text-center d-flex justify-content-center" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Close</div>
                            </div>
                        </div> */}

                        {
                            messageList?.data?.length != 0
                                ?
                                currentItems.map((ele, ind) => {
                                    return <div className="accordion-item border-0 rounded-6 border-0 mb-3">
                                        <h2 className="border-0 rounded-6 border-0 mb-0">
                                            <button className=" position-relative  bg-white accordion-button text-dark border-0 rounded-6 border-0" type="button" data-bs-toggle="collapse" data-bs-target={`#collapse-${ind}`} aria-expanded="false" aria-controls={`collapse-${ind}`} onClick={() => updateMessageRead(ele?._id, ele?.read)}>
                                                <div className="text-dark w-100">
                                                    <p className="mb-1">
                                                        {ele?.title} 
                                                        {!ele?.read ? <span className="ms-2 badge badge-success counded-corner">New</span> : ""}
                                                    </p>
                                                    <p className="fs_10 mb-0">{ele?.push_date + " @ " + ele?.push_time}</p>
                                                </div>
                                                <span 
                                                    className="cursor-pointer position-absolute text-dark zIndex_9 right-10 btn rounded-0 btn_commn_yellow_1 p-2 px-3 fw_700 d-flex justify-content-center align-items-center fs_12 rounded-6 " 
                                                    data-bs-toggle="collapse" 
                                                    data-bs-target={`#collapse-${ind}`} 
                                                    aria-expanded="false" 
                                                    aria-controls={`collapse-${ind}`}
                                                    onClick={e => {e.stopPropagation(); updateMessageRead(ele?._id, ele?.read)}}
                                                >
                                                    View
                                                </span>
                                            </button>
                                        </h2>
                                        <div id={`collapse-${ind}`} className="accordion-collapse collapse rounded-6" data-bs-parent="#accordionExample">
                                            <div className="accordion-body fs_12 "> {ele?.description}
                                            </div>
                                            <div className="cursor-pointer bg-white p-1 d-flex fs_12 text-center d-flex justify-content-center refrences-card-history-2" data-bs-toggle="collapse" data-bs-target={`#collapse-${ind}`} aria-expanded="false" aria-controls={`collapse-${ind}`}>Close</div>
                                        </div>
                                    </div>
                                })
                                :
                                <div>
                                    {
                                        messageList?.data?.length == 0 && messageList?.loader == false
                                            ?
                                            <div className="bet_card_wrapper mb_4rem">
                                                <div className="row m-0 white_glass py-3 align-items-end d-flex align-items-center justify-content-center py-5">
                                                    <span className="text-white fs_12 text-center">No content</span>
                                                </div>
                                            </div>
                                            :
                                            ''
                                    }
                                    {
                                        messageList?.loader == true
                                            ?
                                            <div className='text-center'>
                                                <div className="spinner-border text-primary" role="status"></div>
                                            </div>
                                            :
                                            ''
                                    }
                                </div>
                        }

                        <ReactPaginate
                            breakLabel="..."
                            nextLabel=">"
                            onPageChange={handlePageClick}
                            pageRangeDisplayed={1}
                            pageCount={pageCount}
                            previousLabel="<"
                            renderOnZeroPageCount={null}
                            pageClassName="page-item me-1"
                            subContainerClassName="pages"
                            pageLinkClassName="page-link"
                            previousClassName="page-item me-1"
                            previousLinkClassName="page-link"
                            nextClassName="page-item me-1"
                            nextLinkClassName="page-link"
                            breakClassName="page-item me-1"
                            breakLinkClassName="page-link"
                            marginPagesDisplayed={1}
                            containerClassName="pagination"
                            activeClassName="active"
                        />

                    </div>
                </div>

            </div>
            <MobileFooter />
        </div>
    );
};

export default Messages;