import React, { useEffect, useState, useCallback } from "react";
import PrimaryNavbar from "../../components/PrimaryNavbar";
import MobileFooter from "../../components/MobileFooter";
import MobileSideBar from "../../components/MobileSideBar/MobileSideBar";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { casino_game_category, casino_game_list } from "../../services/api";
import baseUrl from "../../services/baseUrl";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import "./AllGames.css"
import popularIcon from "../../assets/popular gaming.svg"
import BingoIcon from "../../assets/slot-bingo.svg"
import { accessDenied, checkAccess, debounce } from "../../services/helper";
import { ACCESS_TYPE } from "../../services/constants";


function PopularGames() {
    const user = JSON.parse(localStorage.getItem("betUser")) ? JSON.parse(localStorage.getItem("betUser")) : {username: "BETKARODEMO123", password: "DEMOUSER12@"};
    const navigate = useNavigate()

    const [page, setPage] = useState(1)
    const [menuTab, setMenuTab] = useState({ ID: 0 })
    const [categories, setCategories] = useState([])

    const [gamesList, setGamesLIst] = useState([])
    const [iframeData, setIframeData] = useState({})
    const [loader, setLoader] = useState(false)
    const location = useLocation()
    const [search, setSearch] = useState("")
    // console.log(location.state, 5252525214)


    var settings = {
        dots: false,
        infinite: false,
        speed: 300,
        centerMode: false,
        // slidesToShow: 2,
        variableWidth: true,
        swipe: true,
        swipeToSlide: true,
        arrows: false,
        overflow: "hidden"
    };

    const getCategories = async () => {
        try {
            const Token = JSON.parse(localStorage.getItem('betUser'))
            const headers = { headers: { "x-access-token": Token?.token } }
            const responce = await axios.get(baseUrl + casino_game_category, headers)
            if (responce.status == 200) {
                setCategories(responce?.data?.data)
                setMenuTab({ ID: 0 })
            }
        } catch (error) {
            console.log(error);
        }
    }


    const handleGetGamesList = async (page, search, gamesList) => {
        try {
            const Token = JSON.parse(localStorage.getItem('betUser'))
            const headers = { headers: { "x-access-token": Token?.token } }
            const responce = await axios.get(baseUrl + casino_game_list + `?page=${page}&${menuTab?.ID ? `categoryId=${menuTab?.ID}&` : ""}${search ? `search=${search}` : ""}`, headers)
            if (responce.status == 200) {
                if(page === 1){
                    setGamesLIst(responce?.data?.data)
                } else {
                    setGamesLIst([...gamesList, ...responce?.data?.data])
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleCategoryChange = async (Id) => {
        try {
            setSearch("")
            const Token = JSON.parse(localStorage.getItem('betUser'))
            const headers = { headers: { "x-access-token": Token?.token } }
            const responce = await axios.get(baseUrl + `/casino/popular?Type=popular`, headers)
            if (responce.status == 200) {
                setPage(1)
                setGamesLIst(responce?.data?.data || [])
            }
        } catch (error) {
            console.log(error);
        }
    }

    const debounced = useCallback(
        debounce((page, search, gamesList) => {
            handleGetGamesList(page, search, gamesList)  
        }, 300),
        []
    )

    useEffect(() => {        
        debounced(page, search, gamesList)
    }, [page, search])


    const getIFrameData = async data => {
        const res = await axios.get(`${baseUrl}/casino/game_preview?username=${user?.username}&password=${user?.casinopassword}&system=${iframeData?.MerchantID || iframeData?.System}&page=${iframeData?.PageCode}&Timezone=2023-05-09T17:20:41Z`)
        navigate("/iframe-game", { state: { iFrame: "<iframe " + (res?.data?.data).split("<iframe").pop() } })
    }

    useEffect(() => {
        getCategories()
    }, [])

    useEffect(() => {
        handleCategoryChange(menuTab?.ID)
    }, [menuTab])

    useEffect(() => {
        if ((iframeData?.MerchantID || iframeData?.System) && iframeData?.PageCode) {
            getIFrameData(iframeData)
        }
    }, [iframeData])

    useEffect(() => {
        if(user?.access?.length){
          if(!checkAccess(ACCESS_TYPE?.CASINO)){
            accessDenied()
            navigate("/")
          }
        }
    }, [user])

    return (
        <div className="casinoBg">
            <PrimaryNavbar />
            <div className="position-relative">
                <MobileSideBar />
            </div>
            <div className="casino-top-section">
                {/* <div className="py-4">
                    <div className="">
                        <div className="row px-2rem d-flex align-items-center">
                            <div className="col-1 d-flex p-0">
                                <img src={Crown} />
                            </div>
                            <div className="col-5 d-flex p-0 ps-2">
                                <p className='mb-0 text-dark currentbets_title'>Providers</p>
                            </div>
                            <div className="col-6 p-0">
                                <div className=" rounded-8 border-dark border d-flex w-100 p-3 py-1 align-items-center">
                                    <i className="bi bi-search fs_14"></i>
                                    <input type="text" placeholder="Search" className="text-dark fs_14 w-90 px-2 online-casino-search" style={{background: "transparent"}} />
                                </div>
                            </div>
                            
                        </div>
                    </div>
                </div> */}
                <div className="container-all-games ">
                    <div className="w-50">
                        {
                            location?.state?.title === "Slot and Bingo Games" ?
                                <div className="games-page-all-games ">
                                    <img src={BingoIcon} /><span className="games-title-names">Slot Games & Bingo </span>
                                </div>
                                :
                                null
                        }
                        {
                            location?.state?.title === "Popular-Games"
                                ?
                                <div className="games-page-all-games">
                                    <img src={popularIcon} /><span className="games-title-names">Popular Games</span>
                                </div>

                                :
                                null
                        }

                    </div>
                    <div className="input-all-games-search w-50">
                        <input type="text " value={search} placeholder="Search Games " onChange={e => {setPage(1); setSearch(e.target.value)}}/>
                    </div>
                </div>

                {/* {

                    categories?.length && location?.state !== "Popular-Games"
                        ?
                        <div className="py-2 overflow-auto d-flex all-games-tabs-container">
                            <div className="d-flex align-items-center justify-content-center mx-1" onClick={() => setMenuTab({ ID: 0 })}>
                                <div className={`py-2 px-4 rounded-6  ${menuTab?.ID == 0 ? "casino-tabs-active" : "bg-black"} text-white lh_33`}>All</div>
                            </div>
                            {
                                categories?.map(el => (
                                    <div className="d-flex align-items-center justify-content-center mx-1" onClick={() => { setMenuTab(el); setPage(1) }}>
                                        <div className={`py-2 px-4 all-games-tabs rounded-6  ${menuTab?.ID === el?.ID ? "casino-tabs-active" : "bg-black"} text-white lh_33`}>{el?.Name?.en}</div>
                                    </div>
                                ))
                            }
                        </div>
                        :
                        ""
                } */}
                {/* <div className="pb-3">
                    <Slider {...settings}>
                        <div className="d-flex align-items-center justify-content-center mx-1" onClick={() => setMenuTab({...menuTab, type: 0})}>
                            <div className={`py-2 px-3 rounded-6 text-white casino-tabs fs_14 ${menuTab?.type === 0 ? "casino-tabs-active" : "bg-black"}`}>
                                <span className="fs_14">All</span>
                            </div>
                        </div>
                        <div className="h-100 d-flex align-items-center justify-content-center mx-1" onClick={() => setMenuTab({...menuTab, type: 1})}>
                            <div className={`p-1 py-2 rounded-6 text-white casino-tabs d-flex justify-content-between align-items-center ${menuTab?.type === 1 ? "casino-tabs-active" : "bg-black"}`}>
                                <img className="mx-2" src={Teenpatti} height="20" />
                                <span className="me-2 fs_14">BLACK JACK</span>
                            </div>
                        </div>
                        <div className="h-100 d-flex align-items-center justify-content-center mx-1" onClick={() => setMenuTab({...menuTab, type: 2})}>
                            <div className={`p-1 py-2 rounded-6 text-white casino-tabs d-flex justify-content-between align-items-center ${menuTab?.type === 2 ? "casino-tabs-active" : "bg-black"}`}>
                                <img className="mx-2" src={BlackJack} height="20" />
                                <span className="me-2 fs_14">TEENPATTI</span>
                            </div>
                        </div>
                        <div className="h-100 d-flex align-items-center justify-content-center mx-1" onClick={() => setMenuTab({...menuTab, type: 3})}>
                            <div className={`p-1 py-2 rounded-6 text-white casino-tabs d-flex justify-content-between align-items-center ${menuTab?.type === 3 ? "casino-tabs-active" : "bg-black"}`}>
                                <img className="mx-2" src={Teenpatti} height="20" />
                                <span className="me-2 fs_14">ROULETTE</span>
                            </div>
                        </div>
                        <div className="h-100 d-flex align-items-center justify-content-center mx-1" onClick={() => setMenuTab({...menuTab, type: 4})}>
                            <div className={`p-1 py-2 rounded-6 text-white casino-tabs d-flex justify-content-between align-items-center ${menuTab?.type === 4 ? "casino-tabs-active" : "bg-black"}`}>
                                <img className="mx-2" src={Dragon} height="20" />
                                <span className="me-2 fs_14">DRAGON TIGER</span>
                            </div>
                        </div>
                    </Slider>
                </div> */}
            </div>

            <div>
                {
                    loader
                        ?
                        <div className='text-center mt-4'>
                            <div className="spinner-border text-primary" role="status"></div>
                        </div>
                        :
                        gamesList?.length
                            ?
                            <>
                                <div className="g-0 m-0 p-0 py-1 d-flex align-content-around flex-wrap justify-content-center mb_5rem"  >
                                    {
                                        gamesList?.map((ele, ind) => (
                                            <div onClick={() => setIframeData(ele)} className="onlineGamesCard w-50 d-flex justify-content-center">
                                                <img src={ele.ImageFullPath} className='border-rounded p-1 w-100' style={{ borderRadius: '11px' }} />
                                            </div>
                                        ))
                                    }
                                    <button className="btn btn-success w-75 m-auto mx-3 mt-3" onClick={() => setPage(page + 1)}>Load More</button>
                                </div>
                            </>
                            :
                            "No data Found"
                }
            </div>

            {/* <div className="row m-0 p-0 px-2 mb_4rem">
                <div className="col-6 p-2 ">
                    <img src={CasinoCard1} className="w-100 h-100" />
                </div>
                <div className="col-6 p-2 ">
                    <img src={CasinoCard2} className="w-100 h-100" />
                </div>
                <div className="col-6 p-2 ">
                    <img src={CasinoCard3} className="w-100 h-100" />
                </div>
                <div className="col-6 p-2 ">
                    <img src={CasinoCard4} className="w-100 h-100" />
                </div>

                <div className="col-6 p-2 ">
                    <img src={CasinoCard1} className="w-100 h-100" />
                </div>
                <div className="col-6 p-2 ">
                    <img src={CasinoCard2} className="w-100 h-100" />
                </div>
                <div className="col-6 p-2 ">
                    <img src={CasinoCard3} className="w-100 h-100" />
                </div>
                <div className="col-6 p-2 ">
                    <img src={CasinoCard4} className="w-100 h-100" />
                </div>
            </div> */}
            <MobileFooter />
        </div >
    );
}

export default PopularGames;
