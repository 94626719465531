import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useSearchParams } from "react-router-dom";
import login from "./../assets/images/login.png";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrimaryNavbar from "../components/PrimaryNavbar";
import ArrowLeft from "../assets/images/profileDashboard/arrowLeft.svg"
import baseUrl from "../services/baseUrl";
import { checkPassword } from "../services/helper";

function RegistrationPage() {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = new useSearchParams()
  const referralCodeFetched = searchParams.get("referral")

  const [registerForm, setRegisterForm] = useState({
    username: "",
    password: "",
    phonenumber: "",
    otp: "",
    firstname: "",
    lastname: "",
    email: "",
    referralCode: referralCodeFetched ? referralCodeFetched :"",
    loader: false,
  });
  const [registerFormError, setRegisterFormError] = useState({
    username: "",
    password: "",
    phonenumber: "",
    otp: "",
    firstname: "",
    lastname: "",
    email: "",
  });
  const [otp, setOtp] = useState("Send OTP")

  const handleChange = (e) => {
    setRegisterForm({ ...registerForm, [e.target.name]: e.target.value });
    setRegisterFormError({ ...registerFormError, [e.target.name]: null });
  };

  const handleCheckboxChange = (e) => {
    setRegisterForm({ ...registerForm, [e.target.name]: e.target.checked });
    setRegisterFormError({ ...registerFormError, [e.target.name]: null });
  };

  const handleValidation = () => {
    const { username, password, phonenumber, otp, firstname, lastname, email, age } =
      registerForm;
    var remailReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const newErrors = {};
    if (!password) {
      newErrors.password = "Enter Password";
    } else if(!checkPassword(password)){
      newErrors.password = "Min 8 letter password, with at least a symbol, upper and lower case letters and a number"
    }
    if (!phonenumber) {
      newErrors.phonenumber = "Phone Number Required";
    } else if (phonenumber && phonenumber.length != 10) {
      newErrors.phonenumber = "Invalid Phone Number";
    }
    // if (!otp) {
    //   newErrors.otp = "Please Enter OTP";
    // }
    if (!firstname) {
      newErrors.firstname = "Enter First Name";
    }
    // if (!lastname) {
    //   newErrors.lastname = "please enter lastname";
    // }
    if (!email) {
      newErrors.email = "Email Required";
    } else if (email && !remailReg.test(email)) {
      newErrors.email = "Invalid Email";
    }
    if(!age){
      newErrors.age = "Required"
    }
    return newErrors;
  };
  let [userData, setUserData] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const handleValidationObject = handleValidation();
    if (Object.keys(handleValidationObject).length > 0) {
      setRegisterFormError(handleValidationObject);
    } else {
      setRegisterForm({ ...registerForm, loader: true });
      try {
        let payLoad = {
          name: registerForm.firstname,
          email: registerForm.email,
          phone: registerForm.phonenumber,
          password: registerForm.password,
          // otp: registerForm.otp,
          referral: registerForm.referralCode
        };
        let data = await axios.post(
          `${baseUrl}/user/sign-up`,
          payLoad
        );
        if (data.status == 200) {
          toast.success("Sign-up success", {
            position: "top-center",
            autoClose: 2000,
          });
          setRegisterForm({ ...registerForm, loader: false });
          setUserData(data?.data?.data);
          // localStorage.setItem("betUser", JSON.stringify(data?.data?.data));
          // console.log(data?.data?.data, "userData-Registartion");
          setTimeout(() => {
            navigate("/login-page");
          }, 1000);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, {
          position: "top-center",
          autoClose: 2000,
        });
        setRegisterForm({ ...registerForm, loader: false });
      }
    }
  };

  const handleOtpSend = async () => {
    try{
      setOtp("OTP Sent")
      if(!(registerForm?.phonenumber && String(registerForm?.phonenumber)?.length === 10)){
        return toast.error("Invalid Phone Number", { position: "top-center", autoClose: 2000, });
      }
      let res = await axios.put( `${baseUrl}/user/send-otp-signup?phone=${registerForm?.phonenumber}` );
      if (res.status == 200) {
        toast.success("OTP: " + res?.data?.data?.otp, { position: "top-center", autoClose: 2000 });
        // localStorage.setItem("betUser", JSON.stringify(data?.data?.data));
        setTimeout(() => {
          setOtp("Send OTP")
        }, 2000)
      }
    } catch(err){
      console.log(err)
    }
  }
  return (
    <div className="loginBg">
      <PrimaryNavbar></PrimaryNavbar>
      <div className="container-fluid g-0 text-white p-4">
        <ToastContainer />
        <div className="pt-5">
          <div className="black_glass border-light_01 position-relative  rounded-8">
            <i className="bi bi-x-lg position-absolute d-flex justify-content-end cursor-pointer" onClick={() => navigate("/")} style={{ right: "10px", top: "10px", fontSize: "25px" }}></i>
            <Form className="px-4 py-5" onSubmit={handleSubmit}>
              <Form.Group className="mb-3 ">
                <div className='row mb-3 text-white text-start'>
                  {/* <div className='col-1' onClick={() => navigate(-1)}>
                    <img src={ArrowLeft} className="" />
                  </div> */}
                  <div className='col-11'>
                    <h3 className="text-center text-white">Create New Account</h3>
                  </div>
                </div>
                <h2 className="m-auto text-center  text-white my">

                </h2>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  type="text"
                  name="firstname"
                  className={`br0  rounded-6 ${registerFormError.firstname ? "border border-danger" : ""
                    }`}
                  onChange={handleChange}
                  placeholder="FULL NAME"
                />
                <span className="  text-danger">
                  {registerFormError && registerFormError?.firstname}
                </span>
              </Form.Group>

              <Form.Group className="mb-3 position-relative" controlId="formBasicPassword">
                <Form.Control
                  type="number"
                  name="phonenumber"
                  className={`br0  rounded-6 ${registerFormError.phonenumber ? "border border-danger" : ""
                    }`}
                  onChange={handleChange}
                  placeholder="ENTER PHONE NUMBER"
                  maxLength={10}
                  onInput={(e) => {
                    if (e.target.value > e.target.maxLength) {
                      e.target.value = e.target.value.slice(0, e.target.maxLength)
                    }
                  }}
                />
                {/* <span onClick={handleOtpSend  } className="btn btn-primary px-2 d-flex justify-content-center align-items-center position-absolute" style={{height: "50px", right: 0, top: 0}}>Send OTP</span> */}
                <span className="text-danger">
                  {registerFormError && registerFormError?.phonenumber}
                </span>
              </Form.Group>
              {/* <Form.Group className="mb-3 position-relative" controlId="formBasicOtp">
                <Form.Control
                  type="number"
                  name="otp"
                  className={`br0  rounded-6 ${registerFormError.otp ? "border border-danger" : ""
                    }`} 
                  onChange={handleChange}
                  placeholder="ENTER OTP"
                  maxLength={4}
                  onInput={(e) => { if (e.target.value > e.target.maxLength) { e.target.value = e.target.value.slice(0, e.target.maxLength) } }}
                />
                <span className="text-danger">
                  {registerFormError && registerFormError?.otp}
                </span>
              </Form.Group> */}
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  type="text"
                  name="email"
                  className={`br0  rounded-6 ${registerFormError.email ? "border border-danger" : "" }`}
                  onChange={handleChange}
                  placeholder="ENTER EMAIL ID"
                />
                <span className="text-danger">
                  {registerFormError && registerFormError?.email}
                </span>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  type="password"
                  name="password"
                  className={`br0  rounded-6  ${registerFormError.password ? "border border-danger" : "" }`}
                  onChange={handleChange}
                  placeholder="CREATE PASSWORD"
                />
                <span className="text-danger">
                  {registerFormError && registerFormError?.password}
                </span>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Control
                  type="referralCode"
                  name="referralCode"
                  disabled={referralCodeFetched}
                  className={`br0  rounded-6  ${registerFormError.referralCode ? "border border-danger" : "" }`}
                  onChange={handleChange}
                  value={registerForm?.referralCode}
                  placeholder="Referral Code (Optional)"
                  style={{cursor: referralCodeFetched ? "not-allowed" : "auto"}}
                />
                <span className="text-danger">
                  {registerFormError && registerFormError?.referralCode}
                </span>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Check
                  type="checkbox"
                  name="age"
                  className={`text-white`}
                  label="I am 18 year old."
                  onClick={handleCheckboxChange}
                />
                <span className="text-danger">
                  {registerFormError && registerFormError?.age}
                </span>
              </Form.Group>
              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Button
                  variant="primary"
                  type="submit"
                  className="br0  rounded-6 m-auto w-100  text-white"
                >
                  SIGN UP{" "}
                  {registerForm.loader && (
                    <div
                      className="spinner-border spinner-border-sm"
                      role="status"
                    ></div>
                  )}
                </Button>
              </Form.Group>
              <Form.Group className="mb-3">
                <p className="text-center  text-white">
                  Already have a account?{" "}
                  <Link
                    to="/login-page"
                    className=" text-warning mx-2 text-decoration-none"
                  >
                    <b>LOG IN</b>
                  </Link>
                </p>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RegistrationPage;
