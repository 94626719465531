import React, { useEffect, useRef, useState } from "react";
import bat from "../../assets/images/bat.png";
import table from "../../assets/images/table.png";
import basket from "../../assets/images/basket.png";
import vollyball from "../../assets/images/vollyball.png";
import tenis from "../../assets/images/tenis.png";
import BetSlipCard from "../../components/BetSlipCard/BetSlipCard";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import inplayimg from "../../assets/images/inplayimg.png";
import cricket from "../../assets/cricket.png";
import cricketicon from "../../assets/cricketicon.png";
import footballicon from "../../assets/footballicon.png";
import tenisicon from "../../assets/tenis.png";

import flag from "../../assets/flag.png";

import star from "../../assets/star.png";
import Fullstar from "../../assets/fullstar.png";

import Indian_flag from "../../assets/indian_flag.png";

import "../../pages/Homepage.css";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";
import baseUrl from "../../services/baseUrl";
import { ToastContainer } from "react-toastify";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { placeBetAPI } from "../../services/api";
import { BetSlipNumberDesktop } from "./BetSlipNumberDesktop";
import BetSlipNumberMobile from "./BetSlipNumberMobile";
import Shimmer from "../Shimmer/Shimmer";

const BetSlipComponent = ({
  eventType,
  // sportsTab,
  allData,
  allDataLoader,
  inPlayText = "In Play",
}) => {
  const navigate = useNavigate();
  const ref1 = useRef(null);
  const [betImage, setBetImage] = useState(null);
  const isPlaceBet = useState(false);
  const [placeBetData, setPlaceBetData] = useState([]);
  const [placeBetDataColor, setPlaceBetDataColor] = useState([]);
  const [placeBetDataMrId, setPlaceBetDataMrId] = useState([]);
  const [placeBetDataTeam, setPlaceBetDataTeam] = useState([]);
  const [placeBetDataAllData, setPlaceBetDataAllData] = useState([]);
  const [teamNumber, setTeamNumber] = useState();

  const [showDetailView, setShowDetailView] = useState(true);

  const [mobileBetslipId, setMobileBetslipId] = useState("");
  const [clickState, setClickSate] = useState("");
  const [oneClickButton, setOneClickButton] = useState([
    { id: "1", value: "50", selected: false },
    { id: "2", value: "200", selected: false },
    { id: "3", value: "300", selected: false },
  ]);
  const [oneClickId, setOneClickId] = useState("");
  const [oneClickInput, setOneClickInput] = useState(false);
  const [finalNumber, setFinalDataNumber] = useState();

  const [shimmer, setShimmer] = useState(true)

  const [favIndex, setFavIndex] = useState(null);
  const cancellData = () => {
    setPlaceBetDataColor("");
  };

  const placeBet = (id, elem, color, market_id, team, alldata, number) => {
    if (!(clickState === "oneClickBet")) {
      setClickSate("betclipCard");
    }
    setMobileBetslipId(id);
    setPlaceBetData(elem);
    setPlaceBetDataMrId(market_id);
    setPlaceBetDataTeam(team);
    setPlaceBetDataColor(color);
    setPlaceBetDataAllData(alldata);
    setTeamNumber(number);

    if (clickState === "oneClickBet") {
      handleSubmit(id, elem, color, market_id, team, alldata, number);
    }
  };

  const handleSubmit = async ( id, elem, color, market_id, team, alldata, number ) => {
    if (finalNumber && clickState) {
      try {
        
        const payload = {
          odds: elem,
          market_id: market_id.marketId,
          stake: +finalNumber,
          team: team?.selectionId,
          type: color == "blue" ? "Back" : "Lay",
          match_id: market_id.eventid,
        };
        const Token = JSON.parse(localStorage.getItem("betUser"));
        const headers = {
          headers: {
            "Content-Type": "application/json",
            "x-access-token": Token?.token,
          },
        };
        const responce = await axios.post(
          baseUrl + placeBetAPI,
          payload,
          headers
        );
        if (responce.status == 200) {
          toast.success("Placed suceess", {
            position: "top-center",
            autoClose: 2000,
          });
          // setClickSate(!clickState)
          setMobileBetslipId([]);
          setPlaceBetData([]);
          setPlaceBetDataMrId([]);
          setPlaceBetDataTeam([]);
          setPlaceBetDataColor([]);
          setPlaceBetDataAllData([]);
          setTeamNumber();
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, {
          position: "top-center",
          autoClose: 2000,
        });
        // setClickSate(!clickState)
        setMobileBetslipId([]);
        setPlaceBetData([]);
        setPlaceBetDataMrId([]);
        setPlaceBetDataTeam([]);
        setPlaceBetDataColor([]);
        setPlaceBetDataAllData([]);
        setTeamNumber();
      }
    }
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const navg = useNavigate();
  const handleClick = (ele) => {
    navg("/home-page-mobile", { state: ele });
  };

  const handleClickOne = (e) => {
    if (e.target.checked) {
      setClickSate("oneClickBet");
    } else {
      setClickSate("");
    }
    // setClickSate(!clickState);
  };

  const handleOneClickButtonClick = (id, ele) => {
    setOneClickButton(
      oneClickButton?.map((el) =>
        el?.id === id ? { ...el, selected: true } : { ...el, selected: false }
      )
    );
    setFinalDataNumber(ele);
  };

  const handleOneClickInput = (e, id) => {
    setOneClickButton(
      oneClickButton?.map((el) =>
        el?.id === id ? { ...el, value: e.target.value } : el
      )
    );
  };

  const handleFavorite = async (matchID, ind) => {
    const token = JSON.parse(localStorage.getItem("betUser"));
    const headers = {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token?.token,
      },
    };
    try {
      const response = await axios.put(
        `${baseUrl}/user/favourite/${matchID}`,
        {},
        headers
      );

      if (response.status === 200) {
        toast.success(response?.data?.data || "Favourite Updated");
      }
    } catch (error) {
      console.error(error);
      toast.error("Error  request.");
    }
  };

  // let eventData = JSON.parse(localStorage.getItem("eventData"))
  let imgSrc;
  if (eventType === 1) {
    imgSrc = footballicon;
  } else if (eventType === 2) {
    imgSrc = "tennis.jpg";
  } else if (eventType === 4) {
    imgSrc = "cricket.jpg";
  }

  useEffect(() => {
    setTimeout(() => setShimmer(false), 1500)
  }, [])

  return (
    <>
      <ToastContainer />

      <div className="inplaydiv">
        <div className="container-fluid px-5 py-3">
          <h2 className="headinghomeinplay my-3">{inPlayText}</h2>
          <div className="row dashboardBoxInplay p-5">
            <div className="col-md-9 inplaymaindiv ">
              <div className="row ">
                <div className="col-md-2 ">
                  <p className="text-white fs-5 "> In-Play</p>
                </div>
                <div className="col-md-2 ">
                  <p className="text-white fs-5"> Favourites</p>
                </div>
                <div className="col-md-2 ">
                  <p className="text-white fs-5"> My Markets</p>
                </div>
                <div className="col-md-3 "></div>
                <div className="col-md-3 "></div>
              </div>

              <div className="row px-0 mx-0">
                <div className="matchpointHome px-0 mx-0">
                  <div className="pointdetailsHome text-center py-3 pb-4">
                    <div className="col-md-1">
                      <p className="pointdetailsname">
                        {" "}
                        <b> Sports</b>{" "}
                      </p>
                    </div>
                    <div className="col-md-1">
                      <p className="pointdetailsname">
                        {" "}
                        <b> Match</b>
                      </p>
                    </div>
                    <div className="col-md-5">
                      <p className="pointdetailsname">
                        {" "}
                        <b> Click On Odds To BET</b>{" "}
                      </p>
                    </div>
                    <div className="col-md-6">
                      <p className="pointdetailsname">
                        {" "}
                        <b>Boost Your Bet </b>{" "}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              {/* betslip table */}
              <div className="row my-3 ">
                <div className="col-md-1"></div>
                <div className="col-md-5 d-flex justify-content-start text-white">
                  Teams
                </div>
                <div className="col-md-6">
                  <div className="row">
                    <div className="col-4 d-flex justify-content-center text-white">
                      1
                    </div>
                    <div className="col-4 d-flex justify-content-center text-white">
                      X
                    </div>
                    <div className="col-4 d-flex justify-content-center text-white">
                      2
                    </div>
                  </div>
                </div>
                {allDataLoader ? (
                  <div className="d-flex align-items-center justify-content-center">
                    <div className="spinner-border" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <div className="">
                    {allData?.length ? (
                      allData.map((ele) => (
                        <div className="px-0 mx-0">
                          <div className="matchpointHome ">
                            <div className="pointdetailsHome py-1">
                              <div className="col-md-1">
                                <img src={inplayimg} className="w-100" />
                              </div>
                              <div className="col-md-5">
                                {ele?.marketType?.[0]?.runners.map(
                                  (market, index) => (
                                    <p
                                      className="pointdetailsname"
                                      onClick={() => setShowDetailView(true)}
                                    >
                                      {" "}
                                      {index + 1} {market?.runnerName}
                                    </p>
                                  )
                                )}
                              </div>
                              <div className="col-md-6">
                                <div className="row">
                                  <div className="col-4 ">
                                    <div className="row pe-2">
                                      <BetSlipNumberDesktop
                                        className="col-6  p-0 px-1"
                                        onClick={() =>
                                          placeBet(
                                            ele?.event?.id,
                                            ele?.marketType?.[0]?.odds?.[0]
                                              ?.runners?.[0]?.ex
                                              ?.availableToBack?.[0]?.price,
                                            "blue",
                                            ele?.marketType?.[0]?.odds?.[0],
                                            ele?.marketType?.[0]?.odds?.[0]
                                              ?.runners?.[0],
                                            ele
                                          )
                                        }
                                      >
                                        <div className="w-100 d-flex justify-content-center pointdetailsnumber">
                                          {
                                            ele?.marketType?.[0]?.odds?.[0]
                                              ?.runners?.[0]?.ex
                                              ?.availableToBack?.[0]?.price
                                          }
                                        </div>
                                      </BetSlipNumberDesktop>
                                      <BetSlipNumberDesktop
                                        className="col-6 p-0"
                                        onClick={() =>
                                          placeBet(
                                            ele?.event?.id,
                                            ele?.marketType?.[0]?.odds?.[0]
                                              ?.runners?.[0]?.ex
                                              ?.availableToLay?.[0]?.price,
                                            "red",
                                            ele?.marketType?.[0]?.odds?.[0],
                                            ele?.marketType?.[0]?.odds?.[0]
                                              ?.runners?.[0],
                                            ele
                                          )
                                        }
                                      >
                                        <div className="w-100 d-flex justify-content-center pointdetailsnumberlay">
                                          {
                                            ele?.marketType?.[0]?.odds?.[0]
                                              ?.runners?.[0]?.ex
                                              ?.availableToLay?.[0]?.price
                                          }
                                        </div>
                                      </BetSlipNumberDesktop>
                                    </div>
                                  </div>

                                  <div className="col-4 ">
                                    <div className="row pe-2 h-100">
                                      <BetSlipNumberDesktop
                                        className="col-6 h-100  p-0 px-1"
                                        onClick={() => placeBet(ele?.event?.id)}
                                      >
                                        <div
                                          className={
                                            ele?.marketType?.[0]?.odds?.[0]
                                              ?.runners?.[2]?.ex
                                              ?.availableToBack?.[0]?.price
                                              ? "pointdetailsnumber"
                                              : "pointdetailsnumberdra" +
                                                " w-100 h-100 d-flex justify-content-center "
                                          }
                                        >
                                          {ele?.marketType?.[0]?.odds?.[0]
                                            ?.runners?.[2]?.ex?.availableToBack?.[0]
                                            ?.price
                                            ? ele?.marketType?.[0]?.odds?.[0]
                                                ?.runners?.[2]?.ex
                                                ?.availableToBack?.[0]?.price
                                            : "-"}{" "}
                                        </div>
                                      </BetSlipNumberDesktop>
                                      <BetSlipNumberDesktop
                                        className="col-6 h-100 p-0 "
                                        onClick={() => placeBet(ele?.event?.id)}
                                      >
                                        <div
                                          className={
                                            ele?.marketType?.[0]?.odds?.[0]
                                              ?.runners?.[2]?.ex
                                              ?.availableToLay?.[0]?.price
                                              ? "pointdetailsnumberlay"
                                              : "pointdetailsnumberdra" +
                                                " w-100 h-100 d-flex justify-content-center "
                                          }
                                        >
                                          {ele?.marketType?.[0]?.odds?.[0]
                                            ?.runners?.[2]?.ex?.availableToLay?.[0]
                                            ?.price
                                            ? ele?.marketType?.[0]?.odds?.[0]
                                                ?.runners?.[2]?.ex
                                                ?.availableToLay?.[0]?.price
                                            : "-"}
                                        </div>
                                      </BetSlipNumberDesktop>
                                    </div>
                                  </div>

                                  <div className="col-4 ">
                                    <div className="row pe-2">
                                      <BetSlipNumberDesktop
                                        className="col-6  p-0 px-1"
                                        onClick={() =>
                                          placeBet(
                                            ele?.event?.id,
                                            ele?.marketType?.[0]?.odds?.[0]
                                              ?.runners?.[1]?.ex
                                              ?.availableToBack?.[0]?.price,
                                            "blue",
                                            ele?.marketType?.[0]?.odds?.[0],
                                            ele?.marketType?.[0]?.odds?.[0]
                                              ?.runners?.[0],
                                            ele
                                          )
                                        }
                                      >
                                        <div className="w-100 d-flex justify-content-center pointdetailsnumber">
                                          {
                                            ele?.marketType?.[0]?.odds?.[0]
                                              ?.runners?.[1]?.ex
                                              ?.availableToBack?.[0]?.price
                                          }
                                        </div>
                                      </BetSlipNumberDesktop>
                                      <BetSlipNumberDesktop
                                        className="col-6 p-0"
                                        onClick={() =>
                                          placeBet(
                                            ele?.event?.id,
                                            ele?.marketType?.[0]?.odds?.[0]
                                              ?.runners?.[1]?.ex
                                              ?.availableToLay?.[0]?.price,
                                            "red",
                                            ele?.marketType?.[0]?.odds?.[0],
                                            ele?.marketType?.[0]?.odds?.[0]
                                              ?.runners?.[0],
                                            ele
                                          )
                                        }
                                      >
                                        <div className="w-100 d-flex justify-content-center pointdetailsnumberlay">
                                          {
                                            ele?.marketType?.[0]?.odds?.[0]
                                              ?.runners?.[1]?.ex
                                              ?.availableToLay?.[0]?.price
                                          }
                                        </div>
                                      </BetSlipNumberDesktop>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      ))
                    ) : (
                      <h1 className="text-white">No Data Found</h1>
                    )}
                  </div>
                )}
              </div>
            </div>
            <div className="col-md-3">
              <div className="row ">
                <div className="col-md-3 pb-2">
                  <p className="text-white fs-5 invisible"> s</p>
                </div>
              </div>
              <div className="bg-white rounded d-flex p-2 py-3 align-items-center justify-content-between">
                <span className="d-flex align-items-center">
                  <AntSwitch
                    className="switch"
                    onClick={handleClickOne}
                    checked={clickState === "oneClickBet"}
                  />
                  <p className="switchtext">
                    <small className="font-weight-bold">1-click bet</small>
                  </p>{" "}
                </span>
                <span className="d-flex align-items-center">
                  <AntSwitch className="switch ms-" />
                  <p className="switchtext">
                    <small className="font-weight-bold">
                      Accept all odds for BM
                    </small>
                  </p>
                </span>
              </div>
              {clickState === "oneClickBet" ? (
                <div className="p-2 bg-white rounded mt-1 d-flex justify-content-between">
                  <span>
                    {oneClickInput ? (
                      <>
                        {oneClickButton?.map((el) => (
                          <input
                            type="number"
                            value={el?.value}
                            style={{ width: "60px" }}
                            className={`betsInputShadow oneClickInput btn btn-sm border me-1`}
                            onChange={(e) => handleOneClickInput(e, el?.id)}
                          ></input>
                        ))}
                      </>
                    ) : (
                      <>
                        {oneClickButton?.map((el) => (
                          <button
                            style={{ width: "60px" }}
                            className={`btn btn-sm shadow-noned-flex justify-content-center ${
                              el?.selected ? "btn-info" : "btn-outline-info"
                            } me-1`}
                            onClick={() =>
                              handleOneClickButtonClick(el?.id, el?.value)
                            }
                          >
                            {el?.value}
                          </button>
                        ))}
                      </>
                    )}
                  </span>
                  <span>
                    <button
                      className="btn btn-sm btn-danger"
                      onClick={() => {
                        setOneClickInput(!oneClickInput);
                      }}
                    >
                      { oneClickInput ? <>Save <i className="bi bi-save"></i></> : <>Edit Stake <i className="bi bi-pencil"></i></> }
                    </button>
                  </span>
                </div>
              ) : (
                ""
              )}
              {clickState == "betclipCard" ? (
                <BetSlipCard
                  data={placeBetData}
                  placeBetDataColor={placeBetDataColor}
                  placeBetDataMrId={placeBetDataMrId}
                  placeBetDataTeam={placeBetDataTeam}
                  placeBetDataAllData={placeBetDataAllData}
                  cancellData={cancellData}
                  clickState={clickState}
                />
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
      </div>

      {/* mobile responsive */}
      <div className="in-play-mobile">
        {inPlayText == "Popular" || "In Play" ? (
          <div className="row m-0 py-2 bg-dark text-white align-items-center">
            <div className="col-4 fw_700">
              {inPlayText == "Popular" ? (
                <div>
                  <p className="fw_600 mb-0 fs_13  px-2 text-uppercase">
                    {" "}
                    Play{" "}
                  </p>
                </div>
              ) : (
                <div>
                  {" "}
                  <p className="fw_6 00 mb-0 fs_13  px-2 text-uppercase">
                    {" "}
                    {inPlayText}
                  </p>
                </div>
              )}
            </div>
            <div className="col-3">
              <p className="fw_500 mb-0 fs_13 px-4  d-flex justify-content-center">
                |{" "}
              </p>
            </div>
            <div className="col-5 mb-0  px-2 d-flex justify-content-start align-items-center">
              <AntSwitch
                className="switch bg-muted"
                onClick={handleClickOne}
                checked={clickState === "oneClickBet"}
              />
              <span className="fw_500 fs_13">1-Click Bet</span>
            </div>
          </div>
        ) : null}
        {/* <h1>etftte</h1> */}
        {clickState === "oneClickBet" ? (
          <div className="p-2 bg-white rounded mt-1 d-flex justify-content-between">
            <span className="d-flex">
              {oneClickInput ? (
                <>
                  {oneClickButton?.map((el) => (
                    <input
                      type="number"
                      value={el?.value}
                      style={{ width: "60px" }}
                      className={` h-32 betsInputShadow oneClickInput btn btn-sm border me-1`}
                      onChange={(e) => handleOneClickInput(e, el?.id)}
                    ></input>
                  ))}
                </>
              ) : (
                <>
                  {oneClickButton?.map((el) => (
                    <button
                      style={{ width: "60px" }}
                      className={`btn h-32 fs_12 btn-sm shadow-none d-flex align-items-center justify-content-center ${
                        el?.selected ? "btn-info" : "btn-outline-info"
                      } me-1`}
                      onClick={() =>
                        handleOneClickButtonClick(el?.id, el?.value)
                      }
                    >
                      {el?.value}
                    </button>
                  ))}
                </>
              )}
            </span>
            <button
              className="btn btn-sm h-32 fs_12 btn-warning"
              onClick={() => {
                setOneClickInput(!oneClickInput);
              }}
            >
              { oneClickInput ? <>Save <i className="bi bi-save"></i></> : <>Edit Stake <i className="bi bi-pencil"></i></> }
            </button>
          </div>
        ) : (
          ""
        )}

        {
          shimmer
          ?
          <div className="d-flex flex-column align-items-center justify-contnt-centerr">
            <Shimmer width={"100%"} height={"100vh"} />
          </div>
          :
          ""
        }
        <div className="row m-0 p-0 align-items-center justify-content-center ">
          {allDataLoader ? (
            <div className="d-flex align-items-center justify-content-center">
              <div className="spinner-border" role="status">
                <span className="sr-only"></span>
              </div>
            </div>
          ) : (
            <div className="">
              {allData?.length ? (
                allData?.map((ele, ind111) => {
                  return ele?.marketType?.map((elesub, ind) => {
                    return elesub?.marketName == "Match Odds" || elesub?.marketName == "Winner" ? (
                      <>
                        <div
                          className="row mb-0"
                          style={{
                            boxShadow: "rgba(0, 0, 0, 0.73) 0px 1px 3px",
                          }}
                        >
                          <div className="col-1 px-0 d-flex align-items-center justify-content-center bg-white ">
                            <div className="betslip_icons d-flex">
                              {
                                eventType === "1" || ele?.sport === "soccer" ? <img src={footballicon} alt="" /> :
                                eventType === "2" || ele?.sport === "tennis" ? <img src={tenisicon} alt="" /> :
                                eventType === "4" || ele?.sport === "cricket" ? <img src={cricketicon} alt="123" style={{ height: "27px" }} /> : ""
                              }
                            </div>
                          </div>

                          <div
                            className="col-11 bg-success px-2  py-1 "
                            onClick={() => handleClick(ele)}
                          >
                            <div className="row p-0 mx-0 justify-content-between">
                              <div className="col-auto m-0 px-0 text-left">
                                <span
                                  className=" text-white fs_14 lh_18"
                                  onClick={() => setShowDetailView(true)}
                                >
                                  {
                                    // String(ele?.marketType[0]?.runners?.[0]?.runnerName)?.length > 15
                                    //   ?
                                    //   String(ele?.marketType[0]?.runners?.[0]?.runnerName)?.slice(0, 15) + "..."
                                    //   :
                                    //   String(ele?.marketType[0]?.runners?.[0]?.runnerName)
                                    ele?.marketType?.map((ele1, ind) => {
                                      return ele1?.marketName == "Match Odds" || ele1?.marketName == "Winner"
                                        ? String(ele1?.runners?.[0]?.runnerName)
                                            ?.length > 15
                                          ? String(
                                              ele1?.runners?.[0]?.runnerName
                                            )?.slice(0, 15) + "..."
                                          : String(
                                              ele1?.runners?.[0]?.runnerName
                                            )
                                        : "";
                                    })
                                  }
                                </span>
                              </div>
                              <div className="col-auto m-0 px-2 text-right">
                                <span
                                  className=" text-white fs_14 lh_18"
                                  onClick={() => setShowDetailView(true)}
                                >
                                  {
                                    // String(ele?.marketType[0]?.runners?.[1]?.runnerName)?.length > 15
                                    //   ?
                                    //   String(ele?.marketType[0]?.runners?.[1]?.runnerName)?.slice(0, 15) + "..."
                                    //   :
                                    //   String(ele?.marketType[0]?.runners?.[1]?.runnerName)

                                    ele?.marketType?.map((ele1, ind) => {
                                      return ele1?.marketName == "Match Odds" || ele1?.marketName == "Winner"
                                        ? String(ele1.runners?.[1]?.runnerName)
                                            ?.length > 15
                                          ? String(
                                              ele1?.runners?.[1]?.runnerName
                                            )?.slice(0, 15) + "..."
                                          : String(
                                              ele1?.runners?.[1]?.runnerName
                                            )
                                        : "";
                                    })
                                  }
                                </span>
                              </div>
                            </div>
                          </div>
                        </div>

                        <div className="row betslip_odds bg-white">
                          <div className="col-1 px-0 d-flex align-items-center justify-content-center p-3 py-1">
                            <div className="betslip_icons d-flex">
                              <img
                                src={ele?.favourite ? Fullstar : star}
                                onClick={() => {
                                  handleFavorite(ele?.event?.id, ind);
                                  setFavIndex(ind111);
                                }}
                              />
                            </div>
                          </div>

                          {ele?.marketType?.map((ele2, ind) => {
                            return ele2?.marketName == "Match Odds" || ele2?.marketName == "Winner" && ind === 0 ? (
                              <div className="col-11 px-0 py-1 d-flex justify-content-between">
                                <BetSlipNumberMobile
                                  className={`${ele2?.odds?.[0]?.runners?.[0]?.ex?.availableToBack?.[0]?.price ? "bg-blue-card2" : "bg-grey-card"} me_0-25 d-flex justify-content-center fs_12 fw_600 p-2 pb-3 position-relative` }
                                  style={{ flex: "1" }}
                                  onClick={() => ele2?.odds?.[0]?.runners?.[0]?.ex?.availableToBack?.[0]?.price ?
                                    placeBet(
                                      ele?._id,
                                      ele2?.odds?.[0]?.runners?.[0]?.ex?.availableToBack?.[0]?.price,
                                      "blue",
                                      ele2?.odds?.[0],
                                      ele2?.odds?.[0]?.runners?.[0],
                                      ele,
                                      0
                                    )
                                    : placeBet("", "", "", "", "", "", "")
                                  }
                                  size={ele2?.odds?.[0]?.runners?.[0]?.ex?.availableToBack?.[0]?.size}
                                  sizeClass={"position-absolute fw_600 text-primary"}
                                >
                                  { 
                                    ele2?.odds?.[0]?.runners?.[0]?.ex?.availableToBack?.[0]?.price 
                                    ? 
                                    ele2?.odds?.[0]?.runners?.[0]?.ex?.availableToBack?.[0]?.price 
                                    : 
                                    "-" 
                                  }
                                </BetSlipNumberMobile>
                                <BetSlipNumberMobile
                                  className={`${ele2?.odds?.[0]?.runners?.[0]?.ex?.availableToLay?.[0]?.price ? "bg-red-card" : "bg-grey-card"} me-2 d-flex justify-content-center fs_12 fw_600 p-2 pb-3 position-relative`}
                                  style={{ flex: "1" }}
                                  onClick={() => ele2?.odds?.[0]?.runners?.[0]?.ex?.availableToLay?.[0]?.price ?
                                    placeBet(
                                      ele?._id,
                                      ele2?.odds?.[0]?.runners?.[0]?.ex?.availableToLay?.[0]?.price,
                                      "red",
                                      ele2?.odds?.[0],
                                      ele2?.odds?.[0]?.runners?.[0],
                                      ele,
                                      0
                                    )
                                    : placeBet("", "", "", "", "", "", "")
                                  }
                                  size={ele2?.odds?.[0]?.runners?.[0]?.ex?.availableToLay?.[0]?.size}
                                  sizeClass={"position-absolute fw_600 text-danger"}
                                >
                                  { 
                                    ele2?.odds?.[0]?.runners?.[0]?.ex?.availableToLay?.[0]?.price
                                    ?
                                    ele2?.odds?.[0]?.runners?.[0]?.ex?.availableToLay?.[0]?.price
                                    :
                                    "-"
                                   }
                                </BetSlipNumberMobile>


                                <BetSlipNumberMobile
                                  onClick={() =>
                                    ele2?.odds?.[0]?.runners?.[2]?.ex?.availableToBack?.[0]?.price
                                      ? placeBet(
                                          ele?.Id,
                                          ele2?.odds?.[0]?.runners?.[2]?.ex?.availableToBack?.[0]?.price,
                                          "blue",
                                          ele2?.odds?.[0],
                                          ele2?.odds?.[0]?.runners?.[2],
                                          ele,
                                          2
                                        )
                                      : placeBet("", "", "", "", "", "", "")
                                  }
                                  className={` ${ ele2?.odds?.[0]?.runners?.[2]?.ex?.availableToBack?.[0]?.price ? "bg-blue-card2" : "bg-grey-card" } me_0-25  d-flex justify-content-center fs_12 fw_600 p-2 pb-3 position-relative`}
                                  style={{ flex: "1" }}
                                  size={ele2?.odds?.[0]?.runners?.[2]?.ex?.availableToBack?.[0]?.size}
                                  sizeClass={"position-absolute fw_600 text-primary"}
                                >
                                  { ele2?.odds?.[0]?.runners?.[2]?.ex?.availableToBack?.[0]?.price
                                    ? 
                                    ele2?.odds?.[0]?.runners?.[2]?.ex?.availableToBack?.[0]?.price
                                    : 
                                    "-"
                                  }
                                </BetSlipNumberMobile>
                                <BetSlipNumberMobile
                                  onClick={() =>
                                    ele2?.odds?.[0]?.runners?.[2]?.ex?.availableToLay?.[0]?.price
                                    ? 
                                    placeBet(
                                      ele?._id,
                                      ele2?.odds?.[0]?.runners?.[2]?.ex?.availableToLay?.[0]?.price,
                                      "red",
                                      ele2?.odds?.[0],
                                      ele2?.odds?.[0]?.runners?.[2],
                                      ele,
                                      2
                                    )
                                    : 
                                    placeBet("", "", "", "", "", "", "")
                                  }
                                  className={` ${ ele2?.odds?.[0]?.runners?.[2]?.ex?.availableToLay?.[0]?.price ? "bg-red-card" : "bg-grey-card" }  me-2 d-flex justify-content-center fs_12 fw_600 p-2 pb-3 position-relative`}
                                  style={{ flex: "1" }}
                                  size={ele2?.odds?.[0]?.runners?.[2]?.ex?.availableToLay?.[0]?.size}
                                  sizeClass={"position-absolute fw_600 text-danger"}
                                >
                                  {
                                    ele2?.odds?.[0]?.runners?.[2]?.ex?.availableToLay?.[0]?.price
                                    ? 
                                    ele2?.odds?.[0]?.runners?.[2]?.ex?.availableToLay?.[0]?.price
                                    : "-"
                                  }
                                </BetSlipNumberMobile>


                                <BetSlipNumberMobile
                                  className={`${ele2?.odds?.[0]?.runners?.[1]?.ex?.availableToBack?.[0]?.price ? "bg-blue-card2" : "bg-grey-card"} me_0-25 d-flex justify-content-center fs_12 fw_600 p-2 pb-3 position-relative`}
                                  style={{ flex: "1" }}
                                  onClick={() => ele2?.odds?.[0]?.runners?.[1]?.ex?.availableToBack?.[0]?.price ?
                                    placeBet(
                                      ele?._id,
                                      ele2?.odds?.[0]?.runners?.[1]?.ex?.availableToBack?.[0]?.price,
                                      "blue",
                                      ele2?.odds?.[0],
                                      ele2?.odds?.[0]?.runners?.[1],
                                      ele,
                                      1
                                    )
                                    : placeBet("", "", "", "", "", "", "")
                                  }
                                  size={ele2?.odds?.[0]?.runners?.[1]?.ex?.availableToBack?.[0]?.size}
                                  sizeClass={"position-absolute fw_600 text-primary"}
                                >
                                  { 
                                    ele2?.odds?.[0]?.runners?.[1]?.ex?.availableToBack?.[0]?.price
                                    ?
                                    ele2?.odds?.[0]?.runners?.[1]?.ex?.availableToBack?.[0]?.price
                                    :
                                    "-"
                                  }
                                </BetSlipNumberMobile>

                                <BetSlipNumberMobile
                                  className={` ${ele2?.odds?.[0]?.runners?.[1]?.ex?.availableToLay?.[0]?.price ? "bg-red-card" : "bg-grey-card"} me-2 d-flex justify-content-center fs_12 fw_600 p-2 pb-3 position-relative`}
                                  style={{ flex: "1" }}
                                  onClick={() => ele2?.odds?.[0]?.runners?.[1]?.ex?.availableToLay?.[0]?.price ?
                                    placeBet(
                                      ele?._id,
                                      ele2?.odds?.[0]?.runners?.[1]?.ex?.availableToLay?.[0]?.price,
                                      "red",
                                      ele2?.odds?.[0],
                                      ele2?.odds?.[0]?.runners?.[1],
                                      ele,
                                      1
                                    )
                                    : placeBet("", "", "", "", "", "", "")
                                  }
                                  size={ele2?.odds?.[0]?.runners?.[1]?.ex?.availableToLay?.[0]?.size}
                                  sizeClass={"position-absolute fw_600 text-danger"}
                                >
                                  { ele2?.odds?.[0]?.runners?.[1]?.ex?.availableToLay?.[0]?.price ? ele2?.odds?.[0]?.runners?.[1]?.ex?.availableToLay?.[0]?.price : "-" }
                                </BetSlipNumberMobile>
                              </div>
                            ) : (
                              ""
                            );
                          })}
                        </div>

                        <div>
                          {
                            ele?._id === mobileBetslipId && clickState === "betclipCard"
                            ?
                            <BetSlipCard
                              data={placeBetData}
                              placeBetDataColor={placeBetDataColor}
                              placeBetDataMrId={placeBetDataMrId}
                              placeBetDataTeam={placeBetDataTeam}
                              placeBetDataAllData={placeBetDataAllData}
                              cancellData={cancellData}
                              clickState={clickState}
                              teamNumber={teamNumber}
                            />
                            :
                            ""
                          }
                        </div>
                      </>
                    ) : (
                      " "
                    );
                  });
                })
              ) : (
                <h1 className="text-dark fs_16 fw_800 d-flex justify-content-center py-3">No Data Found</h1>
              )}
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default BetSlipComponent;
