import React from "react";
import { Navigate } from "react-router-dom";
import baseUrl from "../../services/baseUrl";

const PrivateRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem("betUser"));

  return user?._id ? children : <Navigate to="/login-page" />;

  // const [userdata, setUserData] = useState([]);
  // const [loader, setLoader] = useState(false);
  // const config = {
  //   headers: {
  //     "x-access-token":
  //       "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDE5NjZmZTdkODg3NjQ0NzY0YzJjOTEiLCJ0eXBlIjoidXNlciIsImlhdCI6MTY3OTY0MjM4NSwiZXhwIjoxNjc5NzI4Nzg1fQ.F-HiSAUDQxZcHLFp7R8og91Lru8vqoa9xeBhKxVCmi4",
  //   },
  // };
  // const getData = async () => {
  //   try {
  //     setLoader(true);
  //     const res = await axios.get(
  //      `${baseUrl}/user`,
  //       config
  //     );
  //     if (res?.data?.data) {
  //       setUserData(res.data.data);
  //       console.log("data", res.data.data);
  //     }
  //     setTimeout(() => setLoader(false), 1000);
  //   } catch (err) {
  //     console.log("ERR", err);
  //   }
  // };
  // console.log("userData", userdata);

  // useEffect(() => {
  //   getData();
  // }, []);

  // return loader ? (
  //   <div
  //     style={{ height: "100vh", width: "100vw" }}
  //     className="d-flex align-items-center justify-content-center"
  //   >
  //     <div className="spinner-border" role="status">
  //       <span className="sr-only"></span>
  //     </div>
  //   </div>
  // ) : userdata._id ? (
  //   { children }
  // ) : (
  //   <Navigate to="/login-page" />
  // );
  // return userdata._id ? { children } : <Navigate to="/login-page" />;

  // return <>{children}</>;
};

export default PrivateRoute;
