import React from "react";
import Footer from "../components/Footer";
import MobileFooter from "../components/MobileFooter";
import MobileSideBar from "../components/MobileSideBar/MobileSideBar";
import PrimaryNavbar from "../components/PrimaryNavbar";
import { useNavigate } from "react-router-dom";
import ArrowLeft from "../assets/images/profileDashboard/arrowLeft.svg";

const ResponsibleGaming = () => {
  const navigate = useNavigate();

  return (
    <div>
      <PrimaryNavbar></PrimaryNavbar>
      <div className="position-relative">
        <MobileSideBar />
      </div>
      <div className="terms-and-conditions">
        <div className="main-content-tac">
          <div className="row position-relative" onClick={() => navigate(-1)}>
          <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
              <img src={ArrowLeft} className="cursor-pointer arrow-icone-size rounded-4" />
            </div>
          </div>  
          <h2 className="terms-and-cond-heading mt-2">
            Gambling With Responsibility
          </h2>
          <p>Last Updated: 09/06/2022</p>
          <p>
            Please Read This Information Carefully For Your Own Benefit.
            www.BetkaroIndia.com Is Having Its Office At Curacao.
          </p>
          <div className="contentn-bets-terms-and-condition">
            <h2>Interpretation</h2>
            <p>
              Please Read This Information Carefully For Your Own Benefit.
              www.BetkaroIndia.com Is Having Its Office At Curacao.The Words Of
              Which The Initial Letter Is Capitalized Have Meanings Defined
              Under The Following Conditions. The Following Definitions Shall
              Have The Same Meaning Regardless Of Whether They Appear In
              Singular Or In Plural.
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>Definitions</h2>
            <p>
              For The Purposes Of These Terms And Conditions: Account Means A
              Unique Account Created For You To Access Our Service Or Parts Of
              Our Service. Company (Referred To As Either "The Company", "We",
              "Us" Or "Our" In This Agreement) Refers To Curacao Co Service
              Refers To The Website. You Means
              The Individual Accessing Or Using The Service, Or The Company, Or
              Other Legal Entity On Behalf Of Which Such Individual Is Accessing
              Or Using The Service, As Applicable.
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>Responsible Gambling And Self Exclusion</h2>
            <p>
              Gambling Means For The Majority Of Our Users, Entertainment, Fun
              And Excitement. But We Also Know That For Some Of Our Users
              Gambling Has Negative Side Effects. In The Medical Science Is
              Pathologic Gambling Since Many Years As Serious Sickness
              Recognised.
              <br />
              Since Our First Day We Think About This Problematic And Try Out
              Best To Help. Under “Responsible Gambling” We Understand Multiple
              Steps Of Measures, With Which A Gambling Provider Can Help To
              Lower The Possibility Of Negative Side Effects Appearing. -In Case
              They Already Appear We Also Try To Take Active Steps Against Them.
              <br />
              The Most Important Instrument Against Negative Side Effects From
              Gambling Are Knowledge And Education About The Risks Of Gambling
              Too Support Our Users Self-Control In Order To Make Sure They Do
              Not Suffer From Negative Side Effects.
            </p>
            <br />
          </div>

          <div className="contentn-bets-terms-and-condition">
            <h2>Information And Contact</h2>
            <br />
            <p>
              Our Support Will Help You Via Email At All Time Without Any
              Additional Costs For You: support@Betkaro India.com
              <br />
              Our Support Will Of Course Not Give Out Any Information About You
              Without Your Consent To Anyone Else
              <br />
              In Addition You Also Can Take A Self-Test, If You Are Already
              Gambling Addicted At:
              https://www.begambleaware.org/gambling-problems/do-i-have-a-gambling-problem/
              And You Can Also Find Additional Information About Gambling
              Addictions At: https://www.begambleaware.org/safer-gambling/
            </p>
            <br />
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>
              Helpful Hints For Responsible Gambling At www.Betkaro India.com
            </h2>
            <p>
              We Recommend You Think About The Following Hints, Before Gambling
              In Order To Insure Gambling Stays Fun For You And Without Any
              Negative Side Effects: Set Yourself A Deposit Limit
              <br />
              Before You Start To Gambling Think About How Much You Can Afford
              To Gamble With According To Your Financial Situation. Play With
              Amounts Which Are For Fun And For Your Entertainment
              <br />
              Do Not Try To Win Back A Loss At Every Cost
              <br />
              Try To Not Take To Huge Risks To Win Back What You Lost Before At
              Any Cost. Play For Entertainment And Not To Earn Money.
              <br />
              Set Yourself A Time Limit
              <br />
              Set Yourself A Time Limit And Do Not Break It. Keep In Mind
              Gambling Should Stay In Balance With Your Other Hobbies And Not Be
              Your Only Hobby.
              <br />
              Play Smart:
              <br />
              It Is Smarter To Not Play, When You Are Extremely Stressed,
              Depressed Or Under To Much Pressure. Also Do Not Play When You Are
              Under The Influence Of Medications, Drugs Or Alcohol.
              <br />
              Take Breaks: You Should Take Breaks When You Notice, That You Get
              Tired Or Can´T Concentrate Anymore Only One Account:
              <br />
              To Make It Easier To Have An Overview How Much Time And Money You
              Spend On Gambling It Is Highly Advised To Not Create More Than One
              Account Per Person.
            </p>
            <br />
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>Minor Protection</h2>
            <p>
              To Use Our Service, You Have To Be 18 Years Or Older. To Avoid
              Abuse, Keep Your Login Data Save From Any Minors Near You.
              <br />
              Principally We Recommend A Filter Program To Avoid Minors,
              Especially Children, To Access Any Context On The Internet, Which
              Is Not Healthy For Them.
              <br />
              For Parents We Can Recommend A List Of Internet Filters, To
              Support Them, From Keeping Their Children From Any Context, Which
              Was Not Made For Them:
              <br />
              https://famisafe.wondershare.com/internet-filter/best-internet-filters.html
            </p>
            <br />
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>Self-Exclusion:</h2>
            <br />
            <p>
              In Case You Are Diagnosed With A Gambling Addiction Or Try To Stay
              Away From Gambling For A Different Reason, We Want To Assist You
              To Stay Away From Anything, That Does Nothing Good For You.
              “Self-Exclusion” Means, That You Exclude Yourself, Out Of Your Own
              Choice, From All Gambling Services. This Exclusion Cannot Be
              Undone For A Set Amount Of Time. If You Wish To Self-Exclude
              Yourself From Gambling, Please Message Our Support And Give Them A
              Time Span Between 6 Months And 5 Years. They Also Will Explain You
              All Future Steps And What Is Needed From You.
              <br />
              Email: support@Betkaro India.com Please Keep In Mind That Self
              Exclusion Is Permanent For The Set Time Span And Will Not Be
              Undone For Your Own Protection. During Self Exclusion You Are Not
              Allowed To Create A New Account And Every Attempt To Create A New
              Account During Self Exclusion Is A Violation Of Our Terms Of
              Service And May Result In The Permanent Ban Of Your Original
              Account.
              <br />
            </p>
          </div>
        </div>
      </div>
      <Footer />
      <MobileFooter />
    </div>
  );
};

export default ResponsibleGaming;
