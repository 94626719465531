import React, { useState } from "react";

import icicibank from "../../assets/images/icicibank.png";
import axisbank from "../../assets/images/axisbank.png";
import hdfcbank from "../../assets/images/hdfcbank.png";
import sbibank from "../../assets/images/sbibank.png";

const PhonePay = () => {
  const [selectedBank, setSelectedBank] = useState("");
  //   const [pageId, setPageId] = useState("1");

  return (
    <>
      <div className="options-show">
        <div className="container-accordian">
          <div className="accordian-dropdoen-parent">
            <div className="accordian-dropdoen-child">

              {/* <div id="MainMenu">
                <div className="list-group-panel-one">
                  <a
                    href="#demo7"
                    className={
                      "list-group-item-list-group-item-success-deposite"
                    }
                    data-toggle="collapse"
                    data-parent="#MainMenu"
                    onClick={() => setSelectedBank("AXIS")}
                  >
                    <div className="dashboard-user-left-deposite">
                      <div className="input-radio-checked">
                        <input
                          type="radio"
                          id="javascript"
                          name="fav_language"
                          value="JavaScript"
                          checked={selectedBank === "AXIS" ? true : false}
                        />
                        <img className="banks-logos" src={axisbank} /> Axis Bank{" "}
                      </div>
                    </div>
                  </a>
                  <div className="collapse" id="demo7">
                    <a href="javascript:;" className="list-group-item">
                      <p className="blue-tier-action-payment">Pay Now</p>
                    </a>
                  </div>
                  <a
                    href="#demo8"
                    className={
                      "list-group-item-list-group-item-success-deposite"
                    }
                    data-toggle="collapse"
                    data-parent="#MainMenu"
                    onClick={() => setSelectedBank("HDFC")}
                  >
                    <div className="dashboard-user-left-deposite">
                      <div className="input-radio-checked">
                        <input
                          type="radio"
                          name="payButton"
                          value="1234567"
                          id="453rewhds"
                          checked={selectedBank === "HDFC" ? true : false}
                        />
                        <img className="banks-logos" src={hdfcbank} /> HDFC Bank{" "}
                      </div>
                    </div>
                  </a>
                  <div className="collapse" id="demo8">
                    <a href="javascript:;" className="list-group-item">
                      <p className="blue-tier-action-payment">Pay Now</p>
                    </a>
                  </div>
                  <a
                    href="#demo9"
                    className={
                      "list-group-item-list-group-item-success-deposite"
                    }
                    data-toggle="collapse"
                    data-parent="#MainMenu"
                    onClick={() => setSelectedBank("ICICI")}
                  >
                    <div className="dashboard-user-left-deposite">
                      <div className="input-radio-checked">
                        <input
                          type="radio"
                          name="payButton"
                          value="1234568"
                          id="sdd"
                          checked={selectedBank === "ICICI" ? true : false}
                        />
                        <img className="banks-logos" src={icicibank} /> ICICI
                        Bank{" "}
                      </div>
                    </div>
                  </a>
                  <div className="collapse" id="demo9">
                    <a href="javascript:;" className="list-group-item">
                      <p className="blue-tier-action-payment">Pay Now</p>
                    </a>
                  </div>
                  <a
                    href="#demo10"
                    className={
                      "list-group-item-list-group-item-success-deposite"
                    }
                    data-toggle="collapse"
                    data-parent="#MainMenu"
                    onClick={() => setSelectedBank("SBI")}
                  >
                    <div className="dashboard-user-left-deposite">
                      <div className="input-radio-checked">
                        <input
                          type="radio"
                          name="payButton"
                          value="1234569"
                          id="12321"
                          checked={selectedBank === "SBI" ? true : false}
                        />
                        <img className="banks-logos" src={sbibank} /> SBI Bank{" "}
                      </div>
                    </div>
                  </a>
                  <div className="collapse" id="demo10">
                    <a href="javascript:;" className="list-group-item">
                      <p className="blue-tier-action-payment">Pay Now</p>
                    </a>
                  </div>
                </div>
              </div> */}

            </div>
          </div>
        </div>
        {/* <div className="other-banks">
          <select>
            <option>Other Banks</option>
          </select>
        </div> */}
      </div>
    </>
  );
};

export default PhonePay;
