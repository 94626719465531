import React, { useEffect, useState } from "react";
import leftarrow from "../../assets/images/leftarrow.png";
import Copy from "../../assets/Copy.png"
import qrcode from "../../assets/images/Frame.png";
import axios from "axios";
import baseUrl from "../../services/baseUrl";

const ProfileDashboard = ({ ref2 }) => {
  const user = JSON.parse(localStorage.getItem("betUser"));
  console.log("user", user);
  const [userdata, setUserData] = useState([]);
  const config = {
    headers: {
      "x-access-token":
        "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VySWQiOiI2NDFhYTliMzY2ZTNkNmU2ZGQ3ZWZkNjciLCJ0eXBlIjoidXNlciIsImlhdCI6MTY3OTU2NTAxMiwiZXhwIjoxNjc5NjUxNDEyfQ.G9GWqpzjnvL6MObyJiPXovBfCOeUvC7CX2aIzNwnUmU",
    },
  };
  const url = `${baseUrl}/user`;
  const getData = () => {
    axios
      .get(url, config)
      .then((res) => setUserData(res.data.data))
      .catch((err) => console.log("err", err));
  };
  useEffect(() => {
    getData();
  }, []);

  console.log("userdata", userdata);
  return (
    <>
      <div className="dashboard-account  mb-3">
        <div className="d-flex align-items-center p-2 pt-3 position-relative">
          <img className="back-arrow ms-4 position-absolute" width="40" height="40" src={leftarrow} alt="Symbols" onClick={() => ref2?.current?.scrollIntoView({ behaviour: "smooth" })} />
          <span className=" text-white h3 d-flex justify-content-between m-auto">My dashboard</span>
        </div>
        <div className="account-infos-details">
          <p className="title-user">username </p>
          <p className="name-user">{user.username}</p>
        </div>
        <div className="account-infos-details">

          <p className="title-user">User ID</p>


          <div className="flex-copy-section"> <p className="name-user">9999999999</p>
            <img src={Copy} className='copy-icone-size' />
          </div>

        </div>
        <div className="account-infos-details">
          <p className="title-user">Email ID </p>
          <p className="name-user">{user.email}</p>
        </div>
        <div className="account-infos-details">
          <p className="title-user">Account Number </p>
          <p className="name-user"> {user.acnumber || "---"}</p>
        </div>
        <div className="account-infos-details">
          <p className="title-user">Referral Code </p>
          <div className=" flex-copy-section">
            <p className="name-user"> {user.referral_code || "-"}</p>
            <img src={Copy} className='copy-icone-size' />
          </div>

        </div>
        <div className="account-infos-details">
          <p className="title-user">Date of Birth </p>
          <p className="name-user"> {user.dateofbirth || "-"}</p>
        </div>
        <div className="account-infos-details">
          <p className="title-user">Country </p>
          <p className="name-user"> {user.country || "-"}</p>
        </div>
        <div className="account-infos-details">
          <p className="title-user">City </p>
          <p className="name-user"> {user.city || "-"}</p>
        </div>
        <div className="qr-code">
          <p className="blue-tier1 d-none d-md-block">Edit Profile</p>
          <img src={qrcode} alt="qrcode" />
          <p className="blue-tier1 d-block d-md-none mt-5 w-100 py-3 h3">Edit Profile</p>
        </div>
      </div>
    </>
  );
};

export default ProfileDashboard;
