import React, { useEffect } from 'react'
import GameLoader from "../../assets/gameLoading2.webp"
import { useLocation } from 'react-router-dom'
import PrimaryNavbar from '../../components/PrimaryNavbar'
import MobileFooter from '../../components/MobileFooter'
import baseUrl from '../../services/baseUrl'
import { transfer_to_casino, transfer_to_main } from '../../services/api'
import axios from 'axios'

export const Iframe = props => {
    const location = useLocation()
    

    const transferToCasino = async () => {
        const Token = JSON.parse(localStorage.getItem('betUser'))
        const headers = {headers: {"x-access-token": Token?.token} }
        await axios.post(baseUrl + transfer_to_casino, {}, headers)
    }

    const transferToMain = async () => {
        const Token = JSON.parse(localStorage.getItem('betUser'))
        const headers = {headers: {"x-access-token": Token?.token} }
        await axios.post(baseUrl + transfer_to_main, {}, headers)
    }

    useEffect(() => {
        transferToCasino()


        const handleTabClose = event => {
            event.preventDefault();
            transferToMain()
            console.log('beforeunload event triggered');
            return (event.returnValue = 'Are you sure you want to exit?');
        };
      
        window.addEventListener('beforeunload', handleTabClose);
      
        return () => {
            window.removeEventListener('beforeunload', handleTabClose);
        };

    }, [])

    return (
        <>
            <PrimaryNavbar />
            <div
                className="bg-white d-flex align-items-center justify-content-center iFrameParent" 
                style={{width: "100vw", height: "89vh", top: 0, overflow: "hidden", }}
                dangerouslySetInnerHTML={{__html: location?.state?.iFrame || ""}}
            />
            {/* <MobileFooter /> */}
        </>
    )
}
