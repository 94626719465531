import axios from "axios";
import React, { useEffect, useState } from "react";
import { allBetsList } from "../../services/api";
import baseUrl from "../../services/baseUrl";

const PLStatement = () => {
  const [allBetsListData, setAllBetsListdata] = useState([])
  const [loader, setLoader] = useState(false)
  const handleBetList = async () => {
    setLoader(true)
    const user = JSON.parse(localStorage.getItem("betUser"));
    const headers = {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": user?.token
      }
    }
    try {
      const responce = await axios.get(baseUrl + `/user/profit_loss`, headers)
      if (responce.status == 200) {
        setLoader(false)
        setAllBetsListdata(responce?.data?.data[0]?.bets)
      }
    } catch (error) {
      setLoader(false)
    }
  }



  useEffect(() => {
    handleBetList()
  }, [])

  return (
    <div>
      {/* <div className="bets-description-data">
        <div className="my-bets-desc">Description</div>
        <div className="bets-desc-tabs">
          <div>Placed</div>
          <div>Type</div>
          <div>Odds</div>
          <div>Stake</div>
          <div>Liability</div>
          <div>Potential Profit</div>
        </div>
      </div>
      <div className="current-no-bets">
        <p>No Current Bets </p>
      </div> */}

      <div className='card p-3 m-3' >
        <table className="table table-responsive table-striped">
          <thead>
            <tr>
              <th >Sl.No</th>
              <th >Start Time</th>
              <th >Settle Time</th>
              <th >Type</th>
              <th >Status</th>
            </tr>
          </thead>
          <tbody>
            {
              allBetsListData && allBetsListData?.map((ele, ind) => {
                return <tr>
                  <th scope="row">{ind + 1}</th>
                  <td>{ele.createdAt?.slice(0, 7)}</td>
                  <td>{ele.updatedAt.slice(0, 7)}</td>
                  <td>{ele.type}</td>
                  <td>{ele.status}</td>
                </tr>

              })
            }
          </tbody>
        </table>

        {
          loader == true && <div>
            <button className="btn btn-primary" type="button" disabled>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        }
      </div>

    </div>
  );
};

export default PLStatement;
