import React from "react";
import "./Shimmer.css"; // Create a CSS file for styling the shimmer effect

const Shimmer = ({ width, height, className }) => {
	const shimmerStyle = {
		width: width,
		height: height,
	};	

	return (
		<div className={`shimmer position-realtive ${className} `} style={shimmerStyle}>
			<div className="shimmer-line" />
		</div>
  	);
};

export default Shimmer;
