import React, { useEffect, useState } from "react";
import leftarrow from "../../assets/images/leftarrow.png";
import addBank from "../../assets/images/addBank2.png";
import delete_icon from "../../assets/images/delete.png";
import edit from "../../assets/images/edit.png";
import bankentry from "../../assets/images/bankentry.png";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";
import baseUrl from "../../services/baseUrl";
import { getBankDetals, updateBank } from "../../services/api";
import { isValidIFSC } from "../../services/helper";


const BankDetail = ({ ref2 }) => {
  const [details, setDeatails] = useState([])
  const [loader, setLoder] = useState(false)
  const [bankDetails, setBankDetails] = useState({
    bankName: '',
    accountHoldeName: '',
    accountNumber: '',
    ifsc: '',
    branch: '',
    address: '',
    remarks: '',
    loader: false
  })

  const [bankDetailsError, setBankDetailsError] = useState({
    bankName: '',
    accountHoldeName: '',
    accountNumber: '',
    ifsc: '',
    branch: '',
    address: '',
    remarks: '',
    loader: false

  })


  const handleChange = (e) => {
    setBankDetails({ ...bankDetails, [e.target.name]: e.target.value })
    setBankDetailsError({ ...bankDetailsError, [e.target.name]: null })
  }

  const handleValidation = () => {
    const newErrors = {}
    console.log(333333333);
    if (bankDetails.bankName == '') {
      newErrors.bankName = 'please selct bank name'
    }
    if (bankDetails.accountHoldeName == '') {
      newErrors.accountHoldeName = 'please enter account holder name'
    }
    if (bankDetails.accountNumber == '') {
      newErrors.accountNumber = 'please enter account number '
    }
    if (bankDetails.ifsc == '') {
      newErrors.ifsc = 'please enter ifsc code'
    }
    if(!isValidIFSC(bankDetails.ifsc)){
      newErrors.ifsc = 'Invalid IFSC'
    }
    if (bankDetails.branch == '') {
      newErrors.branch = 'please enter branch name'
    }
    if (bankDetails.bankName == '') {
      newErrors.bankDetails = 'please selct branch name'
    }

    return newErrors
  }


  const handleSubmit = async (e) => {
    e.preventDefault()
    const handleValidationObject = handleValidation()
    if (Object.keys(handleValidationObject).length > 0) {
      setBankDetailsError(handleValidationObject)
    } else {
      try {
        setBankDetails({ ...bankDetails, loader: true })
        const payload = {
          bankDetails: {
            bankname: bankDetails.bankName,
            accountHolderName: bankDetails.accountHoldeName,
            accountNumber: bankDetails.accountNumber,
            ifsc: bankDetails.ifsc,
            branch: bankDetails.branch,
            remarks: bankDetails.ref2
          }
        }
        const Token = JSON.parse(localStorage.getItem('betUser'))
        const headers = {
          headers: {
            // "Content-Type": "application/json",
            "x-access-token": Token?.token
          }
        }

        const responce = await axios.put(baseUrl + updateBank, payload, headers)
        if (responce.status == 200) {
          setBankDetails({
            ...bankDetails,
            bankName: '',
            accountHoldeName: '',
            accountNumber: '',
            ifsc: '',
            branch: '',
            address: '',
            remarks: '',
            loader: false
          })
          toast.success('created bank details', { position: "top-center", autoClose: 2000 });
        }
      } catch (error) {
        setBankDetails({
          ...bankDetails,
          bankName: '',
          accountHoldeName: '',
          accountNumber: '',
          ifsc: '',
          branch: '',
          address: '',
          remarks: '',
          loader: false
        })
        toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
      }
    }
  }



  const handleGetBankDetails = async () => {
    try {
      setLoder(true)
      const Token = JSON.parse(localStorage.getItem('betUser'))
      const headers = {
        headers: {
          // "Content-Type": "application/json",
          "x-access-token": Token?.token
        }
      }

      const responce = await axios.get(baseUrl + getBankDetals, headers)
      if (responce.status == 200) {
        setLoder(false)
        setDeatails(responce?.data?.data[0].bankDetails)
      }

    } catch (error) {
      // setLoder(false)
    }
  }


  useEffect(() => {
    handleGetBankDetails()
  }, [])

  const handleReset = () => {
    setBankDetails({
      ...bankDetails,
      bankName: '',
      accountHoldeName: '',
      accountNumber: '',
      ifsc: '',
      branch: '',
      address: '',
      remarks: '',
      loader: false
    })
  }

  return (
    <>
      <ToastContainer />
      <div className="dashboard-account col-md mb-3">
        <div className="symbols-dashboard-back">
          <img className="back-arrow" src={leftarrow} alt="Symbols" onClick={() => ref2?.current?.scrollIntoView({ behaviour: "smooth" })} />
          <p className="dashboard-p">Bank Details</p>
        </div>
        <div className="bansk-add-details">
          <div className="add-bank-bg">
            <img className="add-bank" src={addBank} alt="addBank" />
            <p>+ Add New bank account</p>
          </div>
          <h2>Add Bank Details</h2></div>
        <div className="card px-5 py-5" >
          <form onSubmit={handleSubmit} >
            <div className="form-group text-start">
              <label for="exampleFormControlSelect1" className="text-dark text-start" >Bank</label>
              <select className={`${bankDetailsError.bankName ? 'border border-danger' : ''} form-control`} name="bankName" onChange={handleChange} id="exampleFormControlSelect1">
                <option value=''>Select</option>
                <option value='sbi' >SBI</option>
                <option value='icici' >ICICI</option>
                <option value='indian' >Inain</option>
                <option value='city bank'>City Bank</option>
              </select>
              <small id="emailHelp" className="form-text text-start text-danger">{bankDetailsError.bankName}</small>
            </div>
            <div className="form-group  text-start">
              <label for="" className="text-dark text-start"  >Account Holder</label>
              <input value={bankDetails.accountHoldeName} type="text" autoComplete="off" name="accountHoldeName" onChange={handleChange} className={`${bankDetailsError.accountHoldeName ? 'border border-danger' : ''} form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Account Holder" />
              <small id="emailHelp" className="form-text text-start text-danger">{bankDetailsError.accountHoldeName}</small>
            </div>
            <div className="form-group text-start">
              <label for="" className="text-dark text-start"  >Account Number</label>
              <input value={bankDetails.accountNumber} type="number" autoComplete="off" name="accountNumber" onChange={handleChange} className={`${bankDetailsError.accountNumber ? 'border border-danger' : ''} form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Account Number" />
              <small id="emailHelp" className="form-text text-start text-danger">{bankDetailsError.accountNumber}</small>
            </div>
            <div className="form-group text-start">
              <label for="" className="text-dark text-start"  >IFSC Code</label>
              <input value={bankDetails.ifsc} type="text"   autoComplete="off"  name="ifsc" onChange={handleChange} className={`${bankDetailsError.ifsc ? 'border border-danger' : ''} form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="IFSC Code" />
              <small id="emailHelp" className="form-text text-start text-danger">{bankDetailsError.ifsc}</small>
            </div>
            <div className="form-group text-start">
              <label for="" className="text-dark text-start"  >Branch Name</label>
              <input value={bankDetails.branch} type="text"  autoComplete="off"  name="branch" onChange={handleChange} className={`${bankDetailsError.branch ? 'border border-danger' : ''} form-control`} id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Branch Name" />
              <small id="emailHelp" className="form-text text-start text-danger">{bankDetailsError.branch}</small>

            </div>
            <div className="form-group text-start">
              <label for="" className="text-dark text-start"  >Bank Address</label>
              <input value={bankDetails.address} type="text"  autoComplete="off"  name="address" onChange={handleChange} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Bank Address" />
              <small id="emailHelp" className="form-text text-start text-danger"></small>

            </div>
            <div className="form-group text-start">
              <label for="" className="text-dark text-start"  >Remarks</label>
              <input type="text" value={bankDetails.remarks}  autoComplete="off"  name="remarks" onChange={handleChange} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Remarks" />
              <small id="emailHelp" className="form-text text-start"></small>
            </div>
            <div className="row justify-content-cnter" >
              <div className="col-6" >
                <button type="submit" className="btn btn-danger" onClick={handleReset} >Reset</button>
              </div>
              <div className="col-6" >
                <button type="submit" className="btn btn-primary">Submit
                  {
                    bankDetails.loader && (
                      <div
                        className="spinner-border spinner-border-sm"
                        role="status"
                      ></div>
                    )
                  }
                </button>
              </div>
            </div>
          </form>
        </div>

        <div className="bats-past-current-1  bank-header">
          <div className="border-bottom-blue bank-details-blue">
            <p>Bank</p>
          </div>
        </div>
        <div className="bank-details  ">
          <div className="bank-details-div">
            <div className="bank-acc">
              <button className="bank-btn-submit">DEFAULT ACCOUNT </button>
              <div className="bank-edit-div">
                <div>
                  <img className="edit" src={edit} alt="edit" /><p className="bank-edit">Edit</p>
                </div>
                <div>
                  <img className="delete" src={delete_icon} alt="delete_icon" /><p className="bank-edit">Delete</p>
                </div>
              </div>
            </div>
            <div style={{ overflowY: 'scroll', height: '400px' }}  >
              {
                bankDetails.loader == false && details && details.map((ele, ind) => {
                  return (
                    <div className="card px-3 py-3 m-3">
                      <div className="bank-detail-entry">
                        <img src={bankentry} />
                        <p className="entry-name">{ele.accountHolderName}</p>
                        <p className="entry-name2">{ele.bankname}</p>
                        <p className="entry-name2">{ele.accountNumber}</p>
                        <p className="entry-name2">{ele.branch}</p>
                        <p className="entry-name2">{ele.ifsc}</p>
                        <p className="entry-name2">{ele.branch}</p>
                      </div>
                    </div>
                  )
                })


              }
              {
                loader &&
                <div className="tex-center mt-5" >
                  <div className="spinner-border text-primary" role="status">
                    <span className="sr-only">Loading...</span>
                  </div>
                </div>
              }
            </div>

          </div>
        </div>
        <br />
      </div>
    </>
  );
};

export default BankDetail;
