import React from 'react'
import homeWhite from "../assets/homeWhite.svg";
import exchange from "../assets/exchange2.svg";
import exchangeGreen from "../assets/exchangeGreen.svg"
import profile from "../assets/profileWhite.svg";
import profileCircle from "../assets/profileCircle.svg";
import menu from "../assets/menu2.svg";
import blackFav from "./../assets/fav2.svg";
import favGreen from "../assets/favGreen.svg"
import { Link } from 'react-router-dom';
import greenProfile from "../assets/greenProfile.svg"
import homeGreen from "../assets/homeGreen.svg"
import HamburgerMenu from "../assets/HamburgerIcon.svg"
import myBetsWhite from "../assets/myBetsWhite.svg"
import myBetsGreen from "../assets/myBetsGreen.svg"
import Favourite from "../assets/fav2.svg"
import FooterCurveBottom from "../assets/footerCurveBottom.svg"
import FooterCurveCenter from "../assets/footerCurveCenter.svg"
import WithdrawCircle from "../assets/withdraw-circle.svg"
import DepositeCircle from "../assets/deposit-circle.svg"
import CasinoCircle from "../assets/casino.svg"
import CasinoCircleGreen from "../assets/casino-green.svg"
import MyBetsCircle from "../assets/MyBetsCircle.svg"
import FavouriteCircle from "../assets/favourite_circle.svg"
import MobileFooterOverlay from "../assets/mobileFooterOverlay1.svg"
import { useState } from 'react';
import MobileSideBar from './MobileSideBar/MobileSideBar';
import { showMobileSidebar, toogleMobileSidebar } from '../services/helper';
import chatSuppost from "../assets/chatSuppost.svg"

const MobileFooter = () => {
    const [menuActive, setMenuActive] = useState(false)

    const path = window.location?.pathname

    const toggleSidebar = () => {
        const sidebar = document.getElementById("mobile_sidebar")
        sidebar?.classList.toggle("d-none")
    }
    // return <>

    //     // <div className="mobile-footer">
    //     //     <div className="mobileBottomDiv w-100">

    //     //         <div>
    //     //             <Link to="/" className="footer-link" >
    //     //                 <div className='footer_icons d-flex align-items-center'>
    //     //                     <img src={path === "/" ? homeGreen : homeWhite} />
    //     //                 </div>
    //     //                 <p className={` ${path === "/" ? "footer-text" : "footer-text2"} `}>Home</p>
    //     //             </Link>
    //     //         </div>
    //     //         <div>
    //     //             <Link to="/exchange-page" className="footer-link" >
    //     //                 <div className='footer_icons d-flex align-items-center'>
    //     //                     <img src={path === "/exchange-page" ? exchangeGreen : exchange} />
    //     //                 </div>
    //     //                 <p className={` ${path === "/exchange-page" ? "footer-text" : "footer-text2"} `}>Exchange</p>
    //     //             </Link>
    //     //         </div>
    //     //         <div>
    //     //             <Link to="/user-profile" className="footer-link" >
    //     //                 <div className='footer_icons d-flex align-items-center'>
    //     //                     <img src={path === "/user-profile" ? greenProfile : profile} />
    //     //                 </div>
    //     //                 <p className={` ${path === "/user-profile" ? "footer-text" : "footer-text2"} `}>Profile</p>
    //     //             </Link>
    //     //         </div>
    //     //         <div>
    //     //             <Link to="/my-bets" className="footer-link" >
    //     //                 <div className='footer_icons d-flex align-items-center'>
    //     //                     <img src={path === "/my-bets" ? myBetsGreen : myBetsWhite} />
    //     //                 </div>
    //     //                 <p className={` ${path === "/my-bets" ? "footer-text" : "footer-text2"} `}>My Bets</p>
    //     //             </Link>
    //     //         </div>
    //     //         <div>
    //     //             <Link className="footer-link" onClick={toggleSidebar}>
    //     //                 <div className='footer_icons d-flex align-items-center'>
    //     //                     <img src={menu} />
    //     //                 </div>
    //     //                 <p className="footer-text2">Menu</p>
    //     //             </Link>
    //     //         </div>
    //     //         {/* <a href="/exchange-page" className="footer-link" >
    //     //             <img src={exchange} />
    //     //             <p className="footer-text2">Exchange</p>
    //     //         </a>
    //     //         <div>
    //     //             <a href="/accounts" className="footer-link" >
    //     //                 <img src={profile} />
    //     //                 <p className="footer-text2">Profile</p>
    //     //             </a>
    //     //         </div>

    //     //         <a href="/Favourite" className="footer-link">
    //     //             <img src={blackFav} />
    //     //             <p className="footer-text2">Favourites</p>
    //     //         </a>
    //     //           <span className="footer-link" onClick={toggleSidebar}>
    //     //             <img src={menu} />
    //     //             <p className="footer-text2">Menu</p>
    //     //         </span> */}
    //     //     </div>
    //     // </div>

    //     <div className="container-fluid m-0 p-0 position-fixed  mobile_footer_new" >
    //         <img src={FooterCurveBottom} className="w-100" />
    //         <div className="w-100 position-relative">
    //             <img src={FooterCurveCenter} className="position-absolute footer_center_menu" />
    //         </div>
    //     </div>

    //     <div className="row footer_mobile_overlay"></div>
    // </>


    return <>
        <div className="container-fluid m-0 p-0 position-fixed d-block d-sm-none mobile_footer_new" >
            <img src={MobileFooterOverlay} style={{ opacity: "0.8" }} className={`position-absolute footer_center_menu ${menuActive ? "menu00" : "menu0"}`} />
            <img src={FooterCurveBottom} className="w-100" />
            <div className="w-100 position-relative d-flex justify-content-center">
                <span className="position-absolute">
                    <Link to="/withdrawl" className={`td-none d-flex flex-column  align-items-center footer_menu_circle position-absolute footer_center_menu ${menuActive ? "menu1" : ""}`}>
                        <img src={WithdrawCircle} width="35" className="green_round_border" />
                        <div className="text-white fs_10">{menuActive ? "Withdraw" : ""}</div>
                    </Link>
                    <Link to="/deposit" className={`td-none d-flex flex-column  align-items-center footer_menu_circle position-absolute footer_center_menu ${menuActive ? "menu2" : ""}`}>
                        <img src={DepositeCircle} width="35" className="green_round_border" />
                        <div className="text-white fs_10">{menuActive ? "Deposit" : ""}</div>
                    </Link>
                    <Link to="/Favourite" className={`td-none d-flex flex-column  align-items-center footer_menu_circle position-absolute footer_center_menu ${menuActive ? "menu3" : ""}`}>
                        <img src={FavouriteCircle} width="35" className="green_round_border" />
                        <div className="text-white fs_10">{menuActive ? "Favourite" : ""}</div>
                    </Link>
                    <Link to="/user-profile" className={`td-none d-flex flex-column  align-items-center footer_menu_circle position-absolute footer_center_menu ${menuActive ? "menu4" : ""}`}>
                        <img src={profileCircle} width="35" className="green_round_border" />
                        <div className="text-white fs_10">{menuActive ? "Profile" : ""}</div>
                    </Link>
                    <a href="javascript:void(Tawk_API.toggle())" className={`td-none d-flex flex-column  align-items-center footer_menu_circle position-absolute footer_center_menu ${menuActive ? "menu5" : ""}`}>
                        <img src={chatSuppost} width="35" className="green_round_border" />
                        <div className="text-white fs_10">{menuActive ? "Support" : ""}</div>
                    </a>
                </span>
                <img src={FooterCurveCenter} className="position-absolute footer_center_menu zIndex_999" onClick={() => setMenuActive(!menuActive)} />

                <div className="position-absolute text-white w-100" style={{ bottom: 0 }}>
                    <div className="row">
                        <div className="col-5">
                            <div className="row px-2">
                                {/* <Link to="/" className='td-none'> */}
                                <Link to="/" className=" td-none col-6 d-flex justify-content-center align-items-center">
                                    <img width="20" height="20" src={path === "/" ? homeGreen : homeWhite} />
                                </Link>
                                {/* </Link> */}
                                {/* <Link to="/exchange-page" className='td-none'> */}
                                <Link to="/exchange-page" className=" td-none col-6 d-flex justify-content-center align-items-center">
                                    <img width="20" height="20" src={path === "/exchange-page" ? exchangeGreen : exchange} />
                                </Link>
                                {/* </Link> */}
                            </div>
                        </div>
                        <div className="col-2"></div>
                        <div className="col-5">
                            <div className="row px-2">
                                {/* <Link to="/user-profile" className='td-none'> */}
                                <Link to="/online-casino-games" className=" td-none col-6 d-flex justify-content-center align-items-center">
                                    <img width="20" height="20" src={path === "/online-casino-games" ? CasinoCircleGreen : CasinoCircle} />
                                </Link>
                                {/* </Link> */}
                                {/* <Link to="/my-bets" className='td-none'> */}
                                <Link to="/my-bets" className=" td-none col-6 d-flex justify-content-center align-items-center"  >
                                    <img width="20" height="20" src={myBetsWhite} />
                                </Link>
                                {/* <Link to="/#" className=" td-none col-6 d-flex justify-content-center align-items-center">
                                        <img className="" src={HamburgerMenu} />
                                    </Link> */}
                                {/* </Link> */}
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="col-5">
                            <div className="row px-2">
                                {/* <Link to="/" className='td-none'> */}
                                <Link to="/" className="py-1 td-none col-6 d-flex justify-content-center mb-1">
                                    <p className={` ${path === "/" ? "footer-text" : "footer-text2"} td-none `}>Home</p>
                                </Link>
                                {/* </Link> */}
                                {/* <Link to="/exchange-page" className='td-none'> */}
                                <Link to="/exchange-page" className="py-1 td-none col-6 d-flex justify-content-center mb-1">
                                    <p className={` ${path === "/exchange-page" ? "footer-text" : "footer-text2"} td-none `}>Exchange</p>
                                </Link>
                                {/* </Link> */}
                            </div>
                        </div>
                        <div className="col-2"></div>
                        <div className="col-5">
                            <div className="row px-2">
                                {/* <Link to="/user-profile" className='td-none'> */}
                                <Link to="/online-casino-games" className="py-1 td-none col-6 d-flex justify-content-center mb-1">
                                    <p className={` ${path === "/online-casino-games" ? "footer-text" : "footer-text2"} td-none `}>Casino</p>
                                </Link>
                                {/* </Link> */}
                                {/* <Link to="/my-bets" className='td-none'> */}
                                <Link to="/my-bets" className="py-1 td-none col-6 d-flex justify-content-center mb-1">
                                    <p className={` ${path === "/my-bets" ? "footer-text2" : "footer-text2"} `}>My Bets</p>
                                </Link>
                                {/* <span className="py-1 td-none col-6 d-flex justify-content-center mb-1" onClick={toogleMobileSidebar}>
                                        <p className={`footer-text2 `}>Menu</p>
                                    </span> */}
                                {/* </Link> */}
                            </div>
                        </div>
                    </div>
                </div>

            </div>

        </div >
        <MobileSideBar />

    </>
}

export default MobileFooter
