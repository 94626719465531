import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import Footer from "../components/Footer";
import PrimaryNavbar from "../components/PrimaryNavbar";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import axios from "axios";
import BetSlipComponent from "../components/BetSlipComponent/BetSlipComponent";
import "./Homepage.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import MobileFooter from "../components/MobileFooter";
import MobileSideBar from "../components/MobileSideBar/MobileSideBar.js"
import cricketicon from "../assets/cricketicon.png";
import footballicon from "../assets/footballicon.png";
import tenisicon from "../assets/tenis.png";
import baseUrl from "../services/baseUrl";
import { accessDenied, checkAccess } from "../services/helper.js";
import { ACCESS_TYPE } from "../services/constants.js";
import { useNavigate } from "react-router-dom";

const toggleSidebar = () => {
  const sidebar = document.getElementById("mobile_sidebar");
  sidebar?.classList.toggle("d-none");
};

function ExchangePage() {
  const user = JSON.parse(localStorage.getItem("betUser"));
  const navigate = useNavigate()
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settingsfootball = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3 ,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      // {
      //   breakpoint: 600,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2,
      //     initialSlide: 2,
      //   },
      // },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  };
  const [popularType, setPopularType] = useState(["Today", "Tomorrow", "Upcoming"])

  const [allList, setAllList] = useState([]);
  const [event, setEvent] = useState([]);
  const isPlaceBet = useState(false);
  const [placeBetData, setPlaceBetData] = useState([]);
  const [placeBetDataColor, setPlaceBetDataColor] = useState([]);
  const [placeBetDataMrId, setPlaceBetDataMrId] = useState([]);
  const [placeBetDataTeam, setPlaceBetDataTeam] = useState([]);
  const [placeBetDataAllData, setPlaceBetDataAllData] = useState([]);
  const [topGamesLoader, setTopGamesLoader] = useState(false);
  const [favData, setFavData] = useState([]);
  const [allData, setAllData] = useState([]);
  const [popularTab, setPopularTab] = useState("Today")

  const [allDataLoader, setAllDataLaoder] = useState(false);
  const [allDataLoader1, setAllDataLaoder1] = useState(false);

  const [showDetailView, setShowDetailView] = useState(false);
  const [sportsTab, setSportsTab] = useState({name: "All"});
  const [gamesTabs, setGamesTabs] = useState("sports");
  const [oddsRefreshTime, setOddsRefreshTIme] = useState(1000)

  useEffect(() => {
    // getData()
    getEventList();
    // handelClickEvent({eventType: "1"})
  }, []);
  const fun = sportsTab.eventType;

  const getEventList = async () => {
    setTopGamesLoader(true);
    let eventList = await axios.get(
      `${baseUrl}/user/event_list`
    );
    // setSportsTab(eventList.data.data?.[0]);
    setEvent(eventList.data.data);
    setTopGamesLoader(false);
  };
  const [popularData, setPopularData] = useState([])

  const getPopularData = async (sportsTab) => {
    let data = await axios.get(`${baseUrl}/user/load_matches?status=${popularTab.toLowerCase()}${sportsTab?.eventType ? `&eventId=${sportsTab?.eventType}`: ""}` + (user?._id ? `&userId=${user?._id}` : "") )
    setPopularData(data.data.data)
  }

  useEffect(() => {
    // getData()
    getEventList()
    getPopularData()
  }, []);

  useEffect(() => {
    getPopularData()
  }, [popularTab])


  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const getSiteSetting = async () => {
    try {
      const Token = localStorage.getItem('token')
      const headers = {
          headers: {
            "x-access-token": Token
          }
      }
      const res = await axios.get(baseUrl + '/site-setting', headers)
      if (res?.status == 200) {
        setOddsRefreshTIme(res?.data?.data?.refreshTime)
      }
    } catch (error) { }
  }

  // function compare(a, b) {    
  //   if (a is less than b by some ordering criterion) {    
  //   return -1;    
  //   }    
  //   if (a is greater than b by the ordering criterion) {    
  //   return 1;    
  //   }    
  //   // a must be equal to b    
  //   return 0;    
  // } 

  const handelClickEvent = async (ele) => {
    let dataAllList = await axios.get(
      `${baseUrl}/user/load_matches?eventId=${ele.eventType}&status=live` + (user?._id ? `&userId=${user?._id}` : "")
    );
    
    setAllData(dataAllList.data.data);
  };

  const getAllEventData = async () => {
    let dataAllList = await axios.get(
      `${baseUrl}/user/load_matches?status=live` + (user?._id ? `&userId=${user?._id}` : "")
    );
    setAllData(dataAllList.data.data);
  };

  const handleFavoriteData = async (ele) => {
    const token = JSON.parse(localStorage.getItem("betUser"));

    const headers = {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token?.token,
      },
    };
    setAllDataLaoder1(true);
    let favData = await axios.get(
      `${baseUrl}/user/favouritelist`,
      headers
    );

    setFavData(favData.data.data);
    // console.log(favData.data.data, "hqq");

    setAllDataLaoder1(false);
  };

  const getSportsImage = (eventType) => {
    if(eventType === "1"){
      return <img src={footballicon} className="px-0 me-1" height="20" />
    } else if (eventType === "2") {
      return <img src={tenisicon} className="px-0 me-1" height="20" />
    }else {
      return <img src={cricketicon} className="px-0 me-1" height="20" />
    }
  }

  const getDivBackground = () => {
    if(sportsTab?.eventType === "1"){
      return "sportsBackgroundFootball"
    } else if (sportsTab?.eventType === "2") {
      return "sportsBackgroundTennis"
    }else {
      return "sportsBackgroundCricket"
    }
  }

  useEffect(() => {
    getSiteSetting()
    handleFavoriteData();
  }, []);

  const getMatchData = () => {
    if (sportsTab?.eventType) {
      handelClickEvent(sportsTab);
      getPopularData(sportsTab)
    } else {
      getAllEventData()
      getPopularData()
    }
  };

  function GetSortOrder(prop) {    
    return function(a, b) {    
        if (a[prop] > b[prop]) {    
            return 1;    
        } else if (a[prop] < b[prop]) {    
            return -1;    
        }    
        return 0;    
    }    
} 

  useEffect(() => {
    getMatchData();

    if(oddsRefreshTime){
      const intervalCall = setInterval(() => {
        getMatchData()
      }, oddsRefreshTime);
      return () => clearInterval(intervalCall)
    }

  }, [sportsTab, oddsRefreshTime, popularTab]);

  useEffect(() => {
    if(user?.access?.length){
      if(!checkAccess(ACCESS_TYPE?.EXCHANGE)){
        accessDenied()
        navigate("/")
      }
    }
  }, [user])

  return (
    <div className={`homepageInternalBg ${getDivBackground()}`}>
      <PrimaryNavbar />
      <div className="position-relative">
        <MobileSideBar />
      </div>

      {/* <div className="PlayLiveGamesdivHome_mobile w-100 pt-2 ">
        <div className="PlayLiveGamesdivHome_mobile_container text-white  ">
          <div className="d-flex">
            <div className="col-3 pt-4 side-nav">

              <div onClick={() => { setGamesTabs('sports') }} className={`position-relative btrr20 ${gamesTabs == 'sports' ? "side-nav-items-active" : "side-nav-items"}`}>
                <img src={icon2} alt="" />
                {
                  gamesTabs === "sports"
                    ?
                    <img src={ArrowIcon} className="position-absolute" style={{ right: "-24px" }} />
                    :
                    ""
                }
                <p className="m-0">SPORTS</p>
              </div>
              <div onClick={() => { setGamesTabs('teenpati') }} className={`position-relative  ${gamesTabs == 'teenpati' ? "side-nav-items-active" : "side-nav-items"}`}>
                <img src={cardsplaying} alt="" />
                {
                  gamesTabs === "teenpati"
                    ?
                    <img src={ArrowIcon} className="position-absolute" style={{ right: "-24px" }} />
                    :
                    ""
                }
                <p className="m-0">TEENPATI</p>
              </div>{" "}
              <div onClick={() => { setGamesTabs('casino') }} className={`position-relative   ${gamesTabs == 'casino' ? "side-nav-items-active" : "side-nav-items"}`}>
                <img src={soliddice} alt="" />
                {
                  gamesTabs === "casino"
                    ?
                    <img src={ArrowIcon} className="position-absolute" style={{ right: "-24px" }} />
                    :
                    ""
                }
                <p className="m-0">CASINO</p>
              </div>{" "}
              <div onClick={() => { setGamesTabs('visual') }} className={`position-relative  bbrr20 ${gamesTabs == 'visual' ? "side-nav-items-active" : "side-nav-items"}`}>
                <img src={tshirt} alt="" />
                {
                  gamesTabs === "visual"
                    ?
                    <img src={ArrowIcon} className="position-absolute" style={{ right: "-24px" }} />
                    :
                    ""
                }
                <p className="m-0">VISUAL </p>
              </div>
            </div>

            <div className="col-9 pt-4 mx-0">
              <Link to="/home-page-mobile" >
                <div>
                  <img src={icon3} alt="" />
                </div>
              </Link>
              <div className="pt-2">
              </div>
            </div>
          </div>

        </div>
      </div > */}

      {/* <div></div> */}

      {/* <div className="PlayLiveGamesdivHome text-Dark">
        <div className="container-fluid px-5 py-3">
          <p className="font-weight-bold mb-0 mt-2 px-4 d-flex justify-content-center mb-3 topGames">
            {" "}
            Top Games
          </p>
          <div className="row">
            {topGamesLoader ? (
              <div className="d-flex align-items-center justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="col-md-12">
              <div>
                {event?.length ? (
                  <Slider {...settingsfootball}>
                    {event.map((ele) => (
                      <div className="px-2">
                        <div
                          className="br0 card mx-1 cardfootball"
                          onClick={() => setSportsTab(ele)}
                        >
                          <div className="br-0 card-body footballcard">
                            <img src={football} className="px-1" />
                            <h3 className="mb-0 text-dark Footballtext px-2">
                              {ele?.name}{" "}
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    ))}
                  </Slider>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div> */}

          <div className='black_glass py-2 border-0 row px-3 mx-0'>
            <div className='col-12 d-flex m-0 p-0'>
                <div className={`col-3 m-0 p-0 pe-2`} onClick={() => setSportsTab({name: "All"})} >
                  <button className={"All" === sportsTab?.name ? "btn w-100 d-flex align-items-center justify-content-center py-0 fw_700 fs_14 h-100 rounded-6 shadow-none btn_commn_yellow_1" : " btn w-100 d-flex align-items-center justify-content-center btn-light py-0 fw_700 fs_14 h-100 rounded-6 shadow-none "}>
                    <div className="mb-0 text-dark fs_12 fw_800">
                      All
                    </div>
                  </button>
                </div>
                
              {
                event?.sort((a,b) => Number(a?.number) > Number(b?.number) ? 1 : -1)?.map((ele, index) => index<3 ? (
                  <div className={`col-3 m-0 p-0 rounded-6 ${index!==event?.length-1 ?  "pe-2": ""}`} onClick={() => setSportsTab(ele)} >
                    <button className={ele?.eventType === sportsTab?.eventType ? "btn w-100 d-flex align-items-center justify-content-center py-0 fw_700 fs_14 h-100 rounded-6 shadow-none btn_commn_yellow_1" : " btn w-100 d-flex align-items-center justify-content-center btn-light py-0 fw_700 fs_14 h-100 rounded-6 shadow-none "}>
                      {
                        getSportsImage(ele?.eventType)
                      }
                      <div className="mb-0 text-dark fs_12 fw_800">
                        {ele?.name}
                      </div>
                    </button>
                  </div>
                ) : "")
              }
            </div>
          </div>
      {/* <div className="PlayLiveGamesdivHome text-Dark">
        <div className="container-fluid ">
          <div className=" ">
            {topGamesLoader ? (
              <div className="d-flex align-items-center justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="row-games">
              <div className="">
                {event?.length ? (
                  <Slider {...settingsfootball}>
                    {event?.sort((a,b) =>Number (a?.number) - Number(b?.number))?.map((ele) => (
                      <div className="px-2 content">
                        <div
                          className="br0 card mx-1 cardfootball"
                          onClick={() => setSportsTab(ele)}
                        >
                          <div className="br-0 card-body footballcard " onClick={() => {
                            localStorage.removeItem("eventData")
                            localStorage.setItem("eventData", JSON.stringify({ "eventType": ele.eventType, "eventImage": ele.image }));
                            // setEventType(ele.eventType)
                          }}>
                            <img src={ele.image} className="px-1" />
                            <h3 className="mb-0 text-dark Footballtext px-2 fs_14">
                              {ele?.name}
                            </h3>
                          </div>
                        </div>
                      </div>
                    ))}
                  </Slider>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div> */}

      <BetSlipComponent
        eventType={fun}
        allData={allData.sort(GetSortOrder("sport"))}
        inPlayText={"In Play"}
        allDataLoader={allDataLoader}
      />
      {/* <BetSlipComponent
        allData={favData}
        inPlayText={"/ Favourites"}
        allDataLoader={allDataLoader1}
      /> */}
      <div className="PlayLiveGamesdivHome text-Dark shadow mt-5">
        <div className="container-fluid px-md-5 py-md-4">
          <p className="font-weight-bold mb-0 px-4 d-flex justify-content-center py-2 fs_16 topGames"> Popular Games</p>
          <div className="row-games">
            {
              topGamesLoader
                ?
                <div className="d-flex align-items-center justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
                :
                ""
            }
            <div className=" m-0 p-0 traslate-copm">
              <div>
                {
                  popularType?.length
                    ?
                    <Slider {...settingsfootball}>
                      {popularType.map((ele) => ( 
                        <div className="content m-0 p-0">
                          <div
                            className={"br0 card cardfootball footballcard slant-container" + (popularTab == ele ? ' popularSelected' : '')}
                            onClick={() => setPopularTab(ele)}
                          >
                            <div className={"br-0 card-body slant-container-0"}>
                              {/* <img src={football} className="px-1" /> */}
                              <h3 className="mb-0 text-dark Footballtext">
                                {ele}
                              </h3>
                            </div>
                          </div>{" "}
                        </div>
                      ))}
                    </Slider>
                    :
                    ""
                }

              </div>
            </div>
          </div>
        </div>
      </div>

      <BetSlipComponent
        eventType={fun}
        allData={popularData}
        // allData={allData}
        inPlayText={"Popular"}
        allDataLoader={allDataLoader}
      />

      {/* <Footer /> */}

      <MobileFooter />
      <div className="p-5"></div>
    </div>
  );
}

export default ExchangePage;
