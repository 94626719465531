import React from "react";
import Slider from "react-slick";
import football from "./../assets/images/football.png";
import bat from "./../assets/images/bat.png";
import vollyball from "./../assets/images/vollyball.png";
import tenis from "./../assets/images/tenis.png";
import inplayimg from "./../assets/images/inplayimg.png";
import rightarrow from "../assets/images/rightarrow.png";
import leftarrow from "../assets/images/leftarrow.png";
import downarrow from "../assets/images/downarrow.png";
import bang_flag from "../assets/images/bang_flag.png";
import eng_flag from "../assets/images/eng_flag.png";
import livescore from "../assets/images/livescore.png";
import livetv from "../assets/images/livetv.png";
import "../custom.css";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";
import PrimaryNavbar from "../components/PrimaryNavbar";
import Footer from "../components/Footer";

function Cricket() {
  var settingsfootball = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  return (
    <div className="">
      <PrimaryNavbar />
      <div className="PlayLiveGamesdiv text-white">
        <div className="container-fluid px-5 py-3">
          <div className="crickettitle">
            <h2 className="headingcricket">Bangladesh vs England</h2>
            <p className="paracricket">
              {" "}
              Homepage <img src={rightarrow} /> Cricket Betting Odds{" "}
              <img src={rightarrow} />{" "}
              <span className="paracricket2">Bangladesh vs England</span>
            </p>
          </div>

          <div className="row ">
            <div className="col-md-12">
              <div>
                <Slider {...settingsfootball}>
                  <div className="px-2">
                    <div className="card mx-1  cardfootball">
                      <div className="card-body footballcard">
                        <img src={football} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">
                          Football
                        </h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={bat} className="px-1" />
                        <a href="/Cricket">
                          <h1 className="text-dark Footballtext px-2">
                            Cricket
                          </h1>
                        </a>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={tenis} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">Tennis</h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={vollyball} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">
                          Volleyball
                        </h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={tenis} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">Tennis</h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1  cardfootball">
                      <div className="card-body footballcard">
                        <img src={football} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">
                          Football
                        </h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={bat} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">Cricket</h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={tenis} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">Tennis</h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={vollyball} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">
                          Volleyball
                        </h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={tenis} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">Tennis</h1>
                      </div>
                    </div>{" "}
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="cricketbg">
        <div className="cricketdiv">
          <div className="container-fluid px-5 py-3">
            <div className="row firsthead">
              <div className="col-md-3">
                <img src={leftarrow} />
              </div>
              <div className="col-md-3 teamnames">
                <img src={bang_flag} className="bang_flag" />{" "}
                <p className="inplay">Bangladesh Vs England</p>{" "}
                <img src={eng_flag} className="eng_flag" />
              </div>
              <div className="col-md-2 teamnames">
                <img src={livescore} className="bang_flag" />{" "}
                <p className="inplay">Live Score</p>
              </div>
              <div className="col-md-3 teamnames">
                <img src={livetv} className="livetv" />{" "}
                <p className="inplay">Live Tv</p>
              </div>
              <div className="col-md-1">
                <p className="bit">Bet Slip</p>
              </div>
            </div>

            <div className="row sec">
              <div className="col-md-9">
                <div className="matchpoint">
                  <div className="row">
                    <div className="col-md-5">
                      <p className="matchborder">
                        <span className="textmatch">Match Odds</span>
                      </p>
                    </div>
                    <div className="col-md-1">
                      <p className="matchback">Back</p>
                    </div>
                    <div className="col-md-5">
                      <p className="matchlay">Lay</p>
                    </div>
                    <div className="col-md-1">
                      <img src={downarrow} />
                    </div>
                  </div>

                  <div className="pointdetails">
                    <div className="col-md-4">
                      <p className="pointdetailsname">Bangladesh</p>
                    </div>
                    <div className="col-md-2">
                      <div className="pointback">
                        <p className="pointdetailsnumber">01.2</p>
                        <p className="pointdetailsnumber">01.2</p>
                        <p className="pointdetailsnumber">01.2</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pointback">
                        <p className="pointdetailsnumberlay">01.2</p>
                        <p className="pointdetailsnumberlay">01.2</p>
                        <p className="pointdetailsnumberlay">01.2</p>
                      </div>
                    </div>
                  </div>
                  <div className="pointdetails">
                    <div className="col-md-4">
                      <p className="pointdetailsname">England</p>
                    </div>
                    <div className="col-md-2">
                      <div className="pointback">
                        <p className="pointdetailsnumber">01.2</p>
                        <p className="pointdetailsnumber">01.2</p>
                        <p className="pointdetailsnumber">01.2</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pointback">
                        <p className="pointdetailsnumberlay">01.2</p>
                        <p className="pointdetailsnumberlay">01.2</p>
                        <p className="pointdetailsnumberlay">01.2</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="col-md-3">
                <div className="tooglebutton">
                  <AntSwitch className="switch" />
                  <p className="switchtext">1-click bet</p>{" "}
                  <AntSwitch className="switch" />
                  <p className="switchtext">Accept all odds for BM</p>
                </div>
              </div>
            </div>

            <div className="row third">
              <div className="col-md-9">
                <div className="matchpoint">
                  <div className="row">
                    <div className="col-md-5">
                      <p className="matchborder">
                        <span className="textmatch">Bookmaker</span>
                      </p>
                    </div>
                    <div className="col-md-1">
                      <p className="matchback">Back</p>
                    </div>
                    <div className="col-md-5">
                      <p className="matchlay">Lay</p>
                    </div>
                    <div className="col-md-1">
                      <img src={downarrow} />
                    </div>
                  </div>

                  <div className="pointdetails">
                    <div className="col-md-4">
                      <p className="pointdetailsname">Bangladesh</p>
                    </div>
                    <div className="col-md-2">
                      <div className="pointback">
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                        <p className="pointdetailsnumber">01.2</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pointback">
                        <p className="pointdetailsnumberlay">01.2</p>
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                      </div>
                    </div>
                  </div>
                  <div className="pointdetails">
                    <div className="col-md-4">
                      <p className="pointdetailsname">England</p>
                    </div>
                    <div className="col-md-2">
                      <div className="pointback">
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pointback">
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row four">
              <div className="col-md-9">
                <div className="matchpoint">
                  <div className="row">
                    <div className="col-md-5">
                      <p className="matchborder">
                        <span className="textmatch">Fancy</span>
                      </p>
                    </div>
                    <div className="col-md-1">
                      <p className="matchback">Back</p>
                    </div>
                    <div className="col-md-5">
                      <p className="matchlay">Lay</p>
                    </div>
                    <div className="col-md-1">
                      <img src={downarrow} />
                    </div>
                  </div>

                  <div className="pointdetails">
                    <div className="col-md-4">
                      <p className="pointdetailsname">
                        20th over runs only England
                      </p>
                    </div>
                    <div className="col-md-2">
                      <div className="pointback">
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                        <p className="pointdetailsnumber">01.2</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pointback">
                        <p className="pointdetailsnumberlay">01.2</p>
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="">
                        <p className="pointaveg">Max Bet: 1,004</p>
                        <p className="pointaveg">Max Market: 10,004</p>
                      </div>
                    </div>
                  </div>
                  <div className="pointdetails">
                    <div className="col-md-4">
                      <p className="pointdetailsname">
                        20th over runs only England
                      </p>
                    </div>
                    <div className="col-md-2">
                      <div className="pointback">
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                        <p className="pointdetailsnumber">01.2</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pointback">
                        <p className="pointdetailsnumberlay">01.2</p>
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="">
                        <p className="pointaveg">Max Bet: 1,004</p>
                        <p className="pointaveg">Max Market: 10,004</p>
                      </div>
                    </div>
                  </div>
                  <div className="pointdetails">
                    <div className="col-md-4">
                      <p className="pointdetailsname">
                        20th over runs only England
                      </p>
                    </div>
                    <div className="col-md-2">
                      <div className="pointback">
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                        <p className="pointdetailsnumber">01.2</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pointback">
                        <p className="pointdetailsnumberlay">01.2</p>
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="">
                        <p className="pointaveg">Max Bet: 1,004</p>
                        <p className="pointaveg">Max Market: 10,004</p>
                      </div>
                    </div>
                  </div>
                  <div className="pointdetails">
                    <div className="col-md-4">
                      <p className="pointdetailsname">
                        20th over runs only England
                      </p>
                    </div>
                    <div className="col-md-2">
                      <div className="pointback">
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                        <p className="pointdetailsnumber">01.2</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pointback">
                        <p className="pointdetailsnumberlay">01.2</p>
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="">
                        <p className="pointaveg">Max Bet: 1,004</p>
                        <p className="pointaveg">Max Market: 10,004</p>
                      </div>
                    </div>
                  </div>
                  <div className="pointdetails">
                    <div className="col-md-4">
                      <p className="pointdetailsname">
                        20th over runs only England
                      </p>
                    </div>
                    <div className="col-md-2">
                      <div className="pointback">
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                        <p className="pointdetailsnumber">01.2</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pointback">
                        <p className="pointdetailsnumberlay">01.2</p>
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="">
                        <p className="pointaveg">Max Bet: 1,004</p>
                        <p className="pointaveg">Max Market: 10,004</p>
                      </div>
                    </div>
                  </div>
                  <div className="pointdetails">
                    <div className="col-md-4">
                      <p className="pointdetailsname">
                        20th over runs only England
                      </p>
                    </div>
                    <div className="col-md-2">
                      <div className="pointback">
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                        <p className="pointdetailsnumber">01.2</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pointback">
                        <p className="pointdetailsnumberlay">01.2</p>
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="">
                        <p className="pointaveg">Max Bet: 1,004</p>
                        <p className="pointaveg">Max Market: 10,004</p>
                      </div>
                    </div>
                  </div>
                  <div className="pointdetails">
                    <div className="col-md-4">
                      <p className="pointdetailsname">
                        20th over runs only England
                      </p>
                    </div>
                    <div className="col-md-2">
                      <div className="pointback">
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                        <p className="pointdetailsnumber">01.2</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pointback">
                        <p className="pointdetailsnumberlay">01.2</p>
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="">
                        <p className="pointaveg">Max Bet: 1,004</p>
                        <p className="pointaveg">Max Market: 10,004</p>
                      </div>
                    </div>
                  </div>
                  <div className="pointdetails">
                    <div className="col-md-4">
                      <p className="pointdetailsname">
                        20th over runs only England
                      </p>
                    </div>
                    <div className="col-md-2">
                      <div className="pointback">
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                        <p className="pointdetailsnumber">01.2</p>
                      </div>
                    </div>
                    <div className="col-md-4">
                      <div className="pointback">
                        <p className="pointdetailsnumberlay">01.2</p>
                        <p className="pointempty">-</p>
                        <p className="pointempty">-</p>
                      </div>
                    </div>
                    <div className="col-md-2">
                      <div className="">
                        <p className="pointaveg">Max Bet: 1,004</p>
                        <p className="pointaveg">Max Market: 10,004</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Cricket;
