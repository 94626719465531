import React, { useEffect, useState } from "react";
import Footer from "../components/Footer";
import PrimaryNavbar from "../components/PrimaryNavbar";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";

import axios from "axios";

import BetSlipComponent from "../components/BetSlipComponent/BetSlipComponent";
import "./Homepage.css";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import MobileFooter from "../components/MobileFooter";
import MobileSideBar from "../components/MobileSideBar/MobileSideBar";
import cricketicon from "../assets/cricketicon.png";
import footballicon from "../assets/footballicon.png";
import tenisicon from "../assets/tenis.png";
import star from "./../assets/yellowStar.svg"
import baseUrl from "../services/baseUrl";

function ExchangePage() {
  var settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 4,
    slidesToScroll: 4,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  var settingsfootball = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      // {
      //   breakpoint: 600,
      //   settings: {
      //     slidesToShow: 2,
      //     slidesToScroll: 2,
      //     initialSlide: 2,
      //   },
      // },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  };
  const toggleSidebar = () => {
    const sidebar = document.getElementById("mobile_sidebar");
    sidebar?.classList.toggle("d-none");
  };
  const [allList, setAllList] = useState([]);
  const [event, setEvent] = useState([]);
  const isPlaceBet = useState(false);
  const [placeBetData, setPlaceBetData] = useState([]);
  const [placeBetDataColor, setPlaceBetDataColor] = useState([]);
  const [placeBetDataMrId, setPlaceBetDataMrId] = useState([]);
  const [placeBetDataTeam, setPlaceBetDataTeam] = useState([]);
  const [placeBetDataAllData, setPlaceBetDataAllData] = useState([]);
  const [topGamesLoader, setTopGamesLoader] = useState(false);
  const [favData, setFavData] = useState([]);
  const [allData, setAllData] = useState([]);

  const [allDataLoader, setAllDataLaoder] = useState(false);
  const [allDataLoader1, setAllDataLaoder1] = useState(false);

  const [showDetailView, setShowDetailView] = useState(false);
  const [sportsTab, setSportsTab] = useState("1");
  const [gamesTabs, setGamesTabs] = useState("sports");

  useEffect(() => {
    // getData()
    getEventList();
    // handelClickEvent({eventType: "1"})
  }, []);

  const getEventList = async () => {
    setTopGamesLoader(true);
    let eventList = await axios.get(
      `${baseUrl}/user/event_list`
    );
    setSportsTab(eventList.data.data?.[0]);
    setEvent(eventList.data.data);
    setTopGamesLoader(false);
  };

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const handelClickEvent = async (ele) => {
    setAllDataLaoder(true);
    let dataAllList = await axios.get(
      `${baseUrl}/user/load_matches?eventId=${ele.eventType}`
    );
    setAllData(dataAllList.data.data);
    setAllDataLaoder(false);
  };

  const handleFavoriteData = async (ele) => {
    const token = JSON.parse(localStorage.getItem("betUser"));

    const headers = {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": token?.token,
      },
    };
    setAllDataLaoder1(true);
    let favData = await axios.get(
      `${baseUrl}/user/favouritelist`,
      headers
    );


    setFavData(favData?.data?.data);

    // console.log(favData.data.data, "hqq");

    setAllDataLaoder1(false);
  };
  
  useEffect(() => {
    handleFavoriteData();
  }, []);
  
  const getMatchData = () => {
    if (sportsTab?.eventType) {
      handelClickEvent(sportsTab);
    }
  };

  const getSportsImage = (eventType) => {
    if(eventType === "1"){
      return <img src={footballicon} className="px-0 me-1" height="20" />
    } else if (eventType === "2") {
      return <img src={tenisicon} className="px-0 me-1" height="20" />
    }else {
      return <img src={cricketicon} className="px-0 me-1" height="20" />
    }
  }

  const getDivBackground = () => {
    if(sportsTab?.eventType === "1"){
      return "sportsBackgroundFootball"
    } else if (sportsTab?.eventType === "2") {
      return "sportsBackgroundTennis"
    }else {
      return "sportsBackgroundCricket"
    }
  }

  useEffect(() => {
    getMatchData();
  }, [sportsTab]);

  return (
    <div className="favouriteBg">
      <PrimaryNavbar />
      <div className="position-relative">
        <MobileSideBar />
      </div>

      {/* <div className="PlayLiveGamesdivHome_mobile w-100 pt-2 ">
        <div className="PlayLiveGamesdivHome_mobile_container text-white  ">
          <div className="d-flex">
            <div className="col-3 pt-4 side-nav">

              <div onClick={() => { setGamesTabs('sports') }} className={`position-relative btrr20 ${gamesTabs == 'sports' ? "side-nav-items-active" : "side-nav-items"}`}>
                <img src={icon2} alt="" />
                {
                  gamesTabs === "sports"
                    ?
                    <img src={ArrowIcon} className="position-absolute" style={{ right: "-24px" }} />
                    :
                    ""
                }
                <p className="m-0">SPORTS</p>
              </div>
              <div onClick={() => { setGamesTabs('teenpati') }} className={`position-relative  ${gamesTabs == 'teenpati' ? "side-nav-items-active" : "side-nav-items"}`}>
                <img src={cardsplaying} alt="" />
                {
                  gamesTabs === "teenpati"
                    ?
                    <img src={ArrowIcon} className="position-absolute" style={{ right: "-24px" }} />
                    :
                    ""
                }
                <p className="m-0">TEENPATI</p>
              </div>{" "}
              <div onClick={() => { setGamesTabs('casino') }} className={`position-relative   ${gamesTabs == 'casino' ? "side-nav-items-active" : "side-nav-items"}`}>
                <img src={soliddice} alt="" />
                {
                  gamesTabs === "casino"
                    ?
                    <img src={ArrowIcon} className="position-absolute" style={{ right: "-24px" }} />
                    :
                    ""
                }
                <p className="m-0">CASINO</p>
              </div>{" "}
              <div onClick={() => { setGamesTabs('visual') }} className={`position-relative  bbrr20 ${gamesTabs == 'visual' ? "side-nav-items-active" : "side-nav-items"}`}>
                <img src={tshirt} alt="" />
                {
                  gamesTabs === "visual"
                    ?
                    <img src={ArrowIcon} className="position-absolute" style={{ right: "-24px" }} />
                    :
                    ""
                }
                <p className="m-0">VISUAL </p>
              </div>
            </div>

            <div className="col-9 pt-4 mx-0">
              <Link to="/home-page-mobile" >
                <div>
                  <img src={icon3} alt="" />
                </div>
              </Link>
              <div className="pt-2">
              </div>
            </div>
          </div>

        </div>
      </div > */}

      {/* <div></div> */}

      {/* <div className="PlayLiveGamesdivHome text-Dark">
        <div className="container-fluid px-5 py-3">
          <p className="font-weight-bold mb-0 mt-2 px-4 d-flex justify-content-center mb-3 topGames">
            {" "}
            Top Games
          </p>
          <div className="row">
            {topGamesLoader ? (
              <div className="d-flex align-items-center justify-content-center">
                <div className="spinner-border" role="status">
                  <span className="sr-only"></span>
                </div>
              </div>
            ) : (
              ""
            )}
            <div className="col-md-12">
              <div>
                {event?.length ? (
                  <Slider {...settingsfootball}>
                    {event.map((ele) => (
                      <div className="px-2">
                        <div
                          className="br0 card mx-1 cardfootball"
                          onClick={() => setSportsTab(ele)}
                        >
                          <div className="br-0 card-body footballcard">
                            <img src={football} className="px-1" />
                            <h3 className="mb-0 text-dark Footballtext px-2">
                              {ele?.name}{" "}
                            </h3>
                          </div>
                        </div>{" "}
                      </div>
                    ))}
                  </Slider>
                ) : (
                  ""
                )}
              </div>
            </div>
          </div>
        </div>
      </div> */}

      {/* <BetSlipComponent
        allData={allData}
        inPlayText={"In Play"}
        allDataLoader={allDataLoader}
      /> */}
      <div className="black_glass px-3 py-2 text-center text-white fw_800 d-flex align-items-center justify-content-center">
        <img src={star} className="mx-1" width="20"/>
        Favourites
      </div>
      <div className='green_glass py-2 border-0 row px-3 mx-0'>
        <div className='col-12 d-flex m-0 p-0'>
            <div className={`col-3 m-0 p-0 pe-2 `} onClick={() => setSportsTab({name: "All"})} >
              <button className={"All" === sportsTab?.name ? "btn w-100 d-flex align-items-center justify-content-center py-0 fw_700 fs_14 h-100 rounded-0 border shadow-none btn_commn_yellow_1 rounded-6" : " btn w-100 d-flex align-items-center justify-content-center btn-light py-0 fw_700 fs_14 h-100 rounded-0 border shadow-none rounded-6 "}>
                <div className="mb-0 text-dark fs_12 fw_800">
                  All
                </div>
              </button>
            </div>
            
          {
            event?.sort((a,b) => Number(a?.number) > Number(b?.number) ? 1 : -1)?.map((ele, index) => index<3 ? (
              <div className={`col-3 m-0 p-0 ${index!==event?.length-1 ?  "pe-2": ""}`} onClick={() => setSportsTab(ele)} >
                <button className={ele?.eventType === sportsTab?.eventType ? "btn w-100 d-flex align-items-center justify-content-center py-0 fw_700 fs_14 h-100 rounded-0 shadow-none btn_commn_yellow_1 rounded-6" : " btn w-100 d-flex align-items-center justify-content-center btn-light py-0 fw_700 fs_14 h-100 rounded-0 shadow-none rounded-6"}>
                  {
                    getSportsImage(ele?.eventType)
                  }
                  <div className="mb-0 text-dark fs_12 fw_800">
                    {ele?.name}
                  </div>
                </button>
              </div>
            ) : "")
          }
        </div>
      </div>
      <BetSlipComponent
        allData={favData}
        inPlayText={"Favourites"}
        allDataLoader={allDataLoader1}
      />
      {/* 
      <BetSlipComponent
        allData={allData}
        inPlayText={"Popular"}
        allDataLoader={allDataLoader}
      /> */}

      {/* <Footer /> */}

      <MobileFooter />
    </div>
  );
}

export default ExchangePage;
