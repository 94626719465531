import React from "react";
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import AccountsInfo from "../pages/Accounts";
import Deposite from "../pages/Deposite";
import PrimaryNavbar from "../components/PrimaryNavbar";
import HomePage from "../pages/HomePage";
import LoginPage from "../pages/LoginPage";
import OddInternal from "../pages/OddInternal";
import OddMainPage from "../pages/OddMainPage";
import Cricket from "../pages/Cricket";
import RegistrationPage from "../pages/RegistrationPage";
import SportsBook from "../pages/SportsBook/SportsBook";
import PrivateRoute from "../components/PrivateRoute/PrivateRoute";
import HomePageMobile from "../pages/HomePageMobile/HomePageMobile";
import HomePageDesktop from "../pages/HomePageDesktop";
import ExchangePage from "../pages/Exchange";
import Favorite from "../pages/Favorite";

import TermsAndCondition from "../pages/TermsAndCondition";
import ResponsibleGaming from "../pages/ResponsibleGaming";
import PrivacyPolicy from "../pages/PrivacyPolicy";
import KYCPolicy from "../pages/KYCPolicy";
import ExclusivePolicy from "../pages/ExclusivePolicy";
import Cashback from "../pages/Cashback";
import History from "../pages/History/History";
import UserProfile from "../pages/UserProfile/UserProfile";
import BankDetails from "../pages/BankDetails/BankDetails";
import ChangePassword from "../pages/ChangePasssword/ChangePassword";
import WalletTransfer from "../pages/WalletTransfer/WalletTransfer";
import BankCards from "../pages/BankCards/BankCards";
import AcconutsMobile from "../pages/AcconutsMobile/AcconutsMobile";
import WithDrawl from "../pages/Withdrawl/Withdrawl";
import MyBetsMobile from "../pages/MyBetsMobile/MyBetsMobile";
import DepositMobile from "../pages/DepositMobile/DepositMobile";
import FAQMobilePage from "../pages/FAQMobile/FAQMobilePage"
import AccountStatement from "../pages/AccountStatement/AccountStatement";
import CasinoStatement from "../pages/CasinoStatement/CasinoStatement";
import ProfitAndLoss from "../pages/ProfitAndLoss/ProfitAndLoss";
import TurnAroundStatement from "../pages/TurnAroundStatement/TurnAroundStatement";
import Messages from "../pages/Messages/Messages";
import PromotionPage from "../pages/PromotionPage/PromotionPage";
import PromotionInternalPage from "../pages/PromotionPage/PromotionalInternal";
import UserBankDetails from "../pages/UserBankDetails/UserBankDetails";
import UpdateBankDetails from "../pages/UpdateBankDetails/UpdateBankDetails";
import SendOTP from "../pages/SendOTP/SendOTP";
import Otp from "../pages/Otp/Otp";
import AllGames from "../pages/AllGames/AllGames";
import PopularGames from "../pages/AllGames/PopularGames";
import OnlineCasinoGames from "../pages/OnlineCasinoGames/OnlineCasinoGames";
import { Iframe } from "../pages/Iframe/Iframe";

function ScreenRoute() {
  return (
    <Router>
      <Routes>
        <Route exact path="/" element={<HomePage />}></Route>
        <Route exact path="/login-page" element={<LoginPage />}></Route>
        <Route exact path="/send-otp" element={<SendOTP />}></Route>
        <Route exact path="/otp" element={<Otp />}></Route>
        <Route exact path="/registration-page" element={<RegistrationPage />} ></Route>
        <Route exact path="/Cricket" element={<PrivateRoute><Cricket /></PrivateRoute>} ></Route>
        <Route exact path="/accounts" element={<PrivateRoute><AccountsInfo /></PrivateRoute>} ></Route>
        <Route exact path="/deposite" element={<PrivateRoute><Deposite /></PrivateRoute>} ></Route>
        <Route exact path="/Favourite" element={<PrivateRoute><Favorite /></PrivateRoute>}></Route>
        <Route exact path="/odd-main-page" element={<PrivateRoute><OddMainPage /></PrivateRoute>}></Route>
        <Route exact path="/odd-internal" element={<PrivateRoute><OddInternal /></PrivateRoute>} ></Route>
        <Route exact path="/sports-book" element={<PrivateRoute><SportsBook /></PrivateRoute>} ></Route>
        <Route exact path="/home-page-desktop" element={<PrivateRoute><HomePageDesktop /></PrivateRoute>}></Route>
        <Route exact path="/home-page-mobile" element={<HomePageMobile />}></Route>
        <Route exact path="/cashback" element={<PrivateRoute><Cashback /></PrivateRoute>}></Route>
        <Route exact path="/exchange-page" element={<ExchangePage />}></Route>
        <Route exact path="/terms-and-condition" element={<TermsAndCondition />}></Route>
        <Route exact path="/responsible-gambling" element={<ResponsibleGaming />}></Route>
        <Route exact path="/privacy-policy" element={<PrivacyPolicy />}></Route>
        <Route exact path="/kyc-policy" element={<KYCPolicy />}></Route>
        <Route exact path="/exclusive-policy" element={<ExclusivePolicy />}></Route>
        <Route exact path="/user-profile" element={<PrivateRoute><UserProfile /></PrivateRoute>}></Route>
        <Route exact path="/accounts-mobile" element={<AcconutsMobile />}></Route>
        <Route exact path="/bank-details" element={<PrivateRoute><BankDetails /></PrivateRoute>}></Route>
        <Route exact path="/update-bank-details" element={<UpdateBankDetails />}></Route>
        <Route exact path="/change-password" element={<ChangePassword />}></Route>
        <Route exact path="/withdrawl" element={<PrivateRoute><WithDrawl /></PrivateRoute>}></Route>
        <Route exact path="/FAQ-page" element={<FAQMobilePage />}></Route>
        <Route exact path="/promotion-page" element={<PromotionPage />}></Route>
        <Route exact path="/promotion-internal" element={<PromotionInternalPage />}></Route>
        <Route exact path="/user-bank-details" element={<UserBankDetails />}></Route>
        <Route exact path="/my-bets" element={<PrivateRoute><MyBetsMobile /></PrivateRoute>}></Route>
        <Route exact path="/deposit" element={<PrivateRoute><DepositMobile /></PrivateRoute>} ></Route>
        <Route exact path="/account-statement" element={<PrivateRoute><AccountStatement /></PrivateRoute>} ></Route>
        <Route exact path="/casino-statement" element={<PrivateRoute><CasinoStatement /></PrivateRoute>} ></Route>
        <Route exact path="/bets-history" element={<PrivateRoute><History /></PrivateRoute>} ></Route>
        <Route exact path="/profit-and-loss" element={<PrivateRoute><ProfitAndLoss /></PrivateRoute>} ></Route>
        <Route exact path="/turnover-statement" element={<PrivateRoute><TurnAroundStatement /></PrivateRoute>} ></Route>
        <Route exact path="/messages" element={<PrivateRoute><Messages /></PrivateRoute>} ></Route>
        <Route exact path="/wallet-transfer" element={<PrivateRoute><WalletTransfer /></PrivateRoute>} ></Route>
        <Route exact path="/bank-cards" element={<PrivateRoute><BankCards /></PrivateRoute>} ></Route>
        <Route exact path="/all-games" element={<AllGames />} ></Route>
        <Route exact path="/popular-games" element={<PopularGames />} ></Route>
        <Route exact path="/online-casino-games" element={<OnlineCasinoGames />} ></Route>
        <Route exact path="/iframe-game" element={<Iframe />} ></Route>
      </Routes>
    </Router>
  )
}

export default ScreenRoute;
