import axios from "axios";
import React from "react";
import { useState } from 'react'
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrimaryNavbar from "../../components/PrimaryNavbar";
import { put_forget_password, put_send_Otp } from "../../services/api";
import baseUrl from "../../services/baseUrl";
import ArrowLeft from "../../assets/images/profileDashboard/arrowLeft.svg";
import { checkPassword } from "../../services/helper";

function Otp() {
    const navigate = useNavigate();
    const location = useLocation();
    const [otpForm, setOtpForm] = useState({ otp: "", password: "", confirmPassword: "", loader: false });
    const [otpFormError, setOtpFormError] = useState({ otp: "", password: "", confirmPassword: "" });
    const [resetLoader, setResetLoader] = useState({ phone: '', loader: false })

    const handleChange = (e) => {
        setOtpForm({ ...otpForm, [e.target.name]: e.target.value });
        setOtpFormError({ ...otpFormError, [e.target.name]: null });
    };

    const handleValidation = () => {
        const { otp, password, confirmPassword } = otpForm;
        var rphoneNumberReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const newErrors = {};
        if (!otp) {
            newErrors.otp = "Please Enter OTP";
        }

        if (!password) {
            newErrors.password = "Please enter password";
        } else if(!checkPassword(password)){
            newErrors.password = "Min 8 letter password, with at least a symbol, upper and lower case letters and a number"
        }

        if (!confirmPassword) {
            newErrors.confirmPassword = "Please enter confirm password";
        } else if (password !== confirmPassword) {
            newErrors.confirmPassword = "Password & confirm password should be same";
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const handleValidationObject = handleValidation();
        if (Object.keys(handleValidationObject).length > 0) {
            setOtpFormError(handleValidationObject);
        } else {
            setOtpForm({ ...otpForm, loader: true });
            try {
                const getPhone = localStorage.getItem('otpdata')
                const pareseOTPData = JSON.parse(getPhone)
                let payLoad = {
                    // phone: location?.state.phone,
                    phone: pareseOTPData?.phone,
                    otp: +otpForm?.otp,
                    password: otpForm?.password,
                    type: "forgetpassword",
                };
                let data = await axios.put(baseUrl + put_forget_password, payLoad);
                if (data?.status == 200) {
                    setOtpForm({ ...otpForm, loader: false });
                    //   setUserData(data?.data?.data);
                    toast.success(data?.data?.message, { position: "top-center", autoClose: 2000 });
                    setTimeout(() => {
                        navigate("/");
                    }, 1000);
                }
            } catch (error) {
                toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
                setOtpForm({ ...otpForm, loader: false });
            }
        }
    };

    const handleResendOTP = async () => {
        try {
            const getPhone = localStorage.getItem('otpdata')
            const pareseOTPData = JSON.parse(getPhone)
            let data = await axios.put(baseUrl + put_send_Otp + '?' +
                `phone=${pareseOTPData.phone}`);
            setResetLoader({ ...resetLoader, phone: pareseOTPData.phone });
            if (data?.data?.data) {
                // setLoginForm({ ...loginForm, loader: false });
                toast.success(data?.data?.data?.otp, { position: "top-center", autoClose: 2000 });
                setResetLoader({ ...resetLoader, loader: false });
                // setTimeout(() => {
                //     if (data?.data?.data.otp) {
                //         navigate('/otp', { state: data?.data?.data })
                //     }
                // }, 1000);
            }
        } catch (error) {
            toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000, });
            setResetLoader({ ...resetLoader, loader: false });
        }
    }

    return (
        <div className="loginBg">
            <PrimaryNavbar></PrimaryNavbar>
            <div className="container-fluid g-0 text-white p-4">
                <ToastContainer />
                <div className="pt-5">
                    <div className="black_glass border-light_01 position-relative rounded-8">
                        <i className="bi bi-x-lg position-absolute d-flex justify-content-end cursor-pointer "
                            onClick={() => navigate("/")}
                            style={{ right: "10px", top: "10px", fontSize: "25px" }}></i>
                        <Form className="px-4 py-5" onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <div className='row mb-3 text-white text-start'>
                                    <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
                                        <img src={ArrowLeft} className="cursor-pointer rounded-4 arrow-icone-size" />
                                    </div>
                                    <div className='col-10'>
                                        <h2 className="text-center  text-white">Change Password</h2>
                                    </div>
                                    <div className='col-1'></div>
                                </div>

                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicphoneNumber">
                                <Form.Label className=" text-white">OTP</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="otp"
                                    className={`br0 rounded-6 ${otpFormError.otp ? "border border-danger " : ""
                                        }`}
                                    onChange={handleChange}
                                    placeholder="Enter OTP"
                                    maxLength={4}
                                    autoComplete='off'
                                    onInput={(e) => {
                                        if (e.target.value > e.target.maxLength) {
                                            e.target.value = e.target.value.slice(0, e.target.maxLength)
                                        }
                                    }}
                                />
                                <span className="text-danger">
                                    {otpFormError && otpFormError?.otp}
                                </span>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="password"
                                    autoComplete='off'
                                    className={`br0 rounded-6 ${otpFormError.password ? "border border-danger" : ""
                                        }`}
                                    onChange={handleChange}
                                    placeholder="New Password"
                                />
                                <span className="text-danger">
                                    {otpFormError && otpFormError?.password}
                                </span>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicPassword">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    name="confirmPassword"
                                    autoComplete='off'
                                    className={`br0 rounded-6 ${otpFormError.confirmPassword ? "border border-danger" : ""
                                        }`}
                                    onChange={handleChange}
                                    placeholder="Confirm Password"
                                />
                                <span className="text-danger">
                                    {otpFormError && otpFormError?.confirmPassword}
                                </span>
                            </Form.Group>

                            {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Text className="text-white text-end ">Forgot Password?</Form.Text>
              </Form.Group> */}

                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="br0 w-100  text-white rounded-6"
                                >
                                    Reset Password{" "}
                                    {otpForm.loader && (
                                        <div
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                        ></div>
                                    )}
                                </Button>
                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Link onClick={handleResendOTP} className="text-decoration-none" ><Form.Text className="text-white text-center" > Did you receive? Resend OTP</Form.Text></Link>
                            </Form.Group>


                            {/* <Form.Group className="mb-3">
                <p className="text-center">
                  Do not have an Account?{" "}
                  <Link
                    to="/registration-page"
                    className="text-decoration-none  text-warning mx-2"
                  >
                    <b>SIGN UP</b>
                  </Link>
                </p>
              </Form.Group> */}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Otp;
