import axios from 'axios';
import React from 'react';
import { useEffect, useState } from 'react'
import { placeBet, placeBookmakerBet, placeFancyBet, walletBalance } from '../../services/api';
import baseUrl from '../../services/baseUrl';
import "./BetSlipCard.css"
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';

const BetSlipCard = (props) => {
    const user = JSON.parse(localStorage.getItem("betUser"));
    const navigate = useNavigate()


    let [data, setData] = useState();
    const [balanceData, setBalanceData] = useState('0')
    const [loader, setLoader] = useState(false)
    const [amount, setAmount] = useState(0)
    const [stake, setStake] = useState()
    const [oddNumber, setOddNumber] = useState(0)
    const [stackNumber, setStackNumber] = useState(0)
    const [stackSize, setStackSize] = useState(0)
    const [amountMultiple, setAmountMultiple] = useState(0.00)
    const [oneClickButtonObject, setOneClickButtonObject] = useState([
        { id: "1", value: 50, mobileView: true },
        { id: "2", value: 100, mobileView: true },
        { id: "3", value: 500, mobileView: true },
        { id: "4", value: 1000, mobileView: true },
        { id: "5", value: 5000, mobileView: true },
        { id: "6", value: 10000, mobileView: true },
        { id: "7", value: 25000, mobileView: false },
        { id: "8", value: 55000, mobileView: false }
    ]);
    const [oneClickBtn, setOneClickBtn] = useState(true)

    useEffect(() => {
        setOddNumber(0)
        setStackNumber(0)
        setAmountMultiple(0.00)
        if(props?.setSecondOdd){
            props?.setSecondOdd(0)
        }
        
    }, [props?.data])

    const handleChange = (number) => {
        console.log(number,"Sss")
        const main = Number(number) + stackNumber
        setStackNumber(main)
        if(props?.setSecondOdd){
            props?.setSecondOdd(main)
        }
        
    }

    const handleSubmit = async () => {
        if (!user?._id) {
            navigate('/login-page')
            return
        }

        try {
            setLoader(true)
            const payload = {
                odds: oddNumber,
                market_id: props?.placeBetDataMrId?.marketId,
                selectionName: props?.placeBetDataTeam?.runner,
                stake: stackNumber,
                team: props?.placeBetDataTeam?.selectionId,
                type: props.placeBetDataColor == 'blue' ? 'Back' : 'Lay',
                match_id: props?.placeBetDataMrId?.eventid
            }
            const Token = JSON.parse(localStorage.getItem('betUser'))
            const headers = {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": Token?.token
                }
            }
            const responce = await axios.post(baseUrl + placeBet, payload, headers)
            if (responce.status == 200) {
                setLoader(false)
                toast.success('Placed successfully', { position: "top-center", autoClose: 2000 });
                props?.cancellData()
                if(props?.setSecondOdd){
                    props?.setOddNumber(0)
                }
                
            }
        } catch (error) {
            setLoader(false)
            toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
            props?.cancellData()
        }
    }

    const handleFancySubmit = async () => {
        if (!user?._id) {
            navigate('/login-page')
            return
        }

        try {
            setLoader(true)
            const payload = {
                match_id: props?.placeBetDataMrId?.match_id,
                SelectionId: props?.placeBetDataMrId?.SelectionId,
                stake: stackNumber,
                sideSize: props?.placeBetSize,
                sideValue: oddNumber,
                // team: props?.placeBetDataTeam?.SelectionId,
                type: props.placeBetDataColor == 'blue' ? 'Back' : 'Lay',
                // match_id: props?.placeBetDataMrId?.eventid
            }
            const Token = JSON.parse(localStorage.getItem('betUser'))
            const headers = {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": Token?.token
                }
            }
            const responce = await axios.post(baseUrl + placeFancyBet, payload, headers)
            if (responce.status == 200) {
                setLoader(false)
                toast.success('Placed successfully', { position: "top-center", autoClose: 2000 });
                props?.cancellData()
                if(props?.setSecondOdd){
                    props?.setOddNumber(0)
                }
                
            }
        } catch (error) {
            setLoader(false)
            toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
            props?.cancellData()
        }
    }

    const handleBookmakerSubmit = async () => {
        if (!user?._id) {
            navigate('/login-page')
            return
        }

        try {
            setLoader(true)
            const payload = {
                odds: oddNumber,
                market_id: props?.placeBetDataMrId?.marketId,
                stake: stackNumber,
                match_id: props?.matchId,
                selectionId: props?.placeBetDataTeam?.selectionId,
                type: props.placeBetDataColor == 'blue' ? 'Back' : 'Lay',
            }
            const Token = JSON.parse(localStorage.getItem('betUser'))
            const headers = {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": Token?.token
                }
            }

            const responce = await axios.post(baseUrl + placeBookmakerBet, payload, headers)
            if (responce.status == 200) {
                setLoader(false)
                toast.success('Placed successfully', { position: "top-center", autoClose: 2000 });
                props?.cancellData()
                if(props?.setSecondOdd){
                    props?.setOddNumber(0)
                }
                
            }
        } catch (error) {
            setLoader(false)
            toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
            props?.cancellData()
        }
    }

    const handleWalletBalance = async () => {
        try {
            // setLoader(true)
            const Token = JSON.parse(localStorage.getItem('betUser'))
            const headers = {
                headers: {
                    // "Content-Type": "application/json",
                    "x-access-token": Token?.token
                }
            }
            const responce = await axios.get(baseUrl + walletBalance, headers)
            if (responce.status == 200) {
                // setLoader(false)
                setBalanceData(responce.data.data[0])
            }
        } catch (error) {
            // setLoader(false)
        }
    }

    useEffect(() => {
        const userData = localStorage.getItem("betUser");
        const parseUserData = JSON.parse(userData)
        handleWalletBalance()
        setData(parseUserData);
        const { data } = props                                
        // setStackSize(placeBetSize)
        setOddNumber(data)
    }, [props]);


    const handleCancell = () => {
        props?.cancellData()
        if(props?.setSecondOdd){
            props?.setSecondOdd(0)
        }
        
        if(props?.setOdds){
            props?.setOdds({})
        }
    }

    const handleDecrement = () => {
        setOddNumber(parseFloat(parseFloat(oddNumber) - 0.02)?.toFixed(2))
    }
    const handleIncrement = () => {
        setOddNumber(parseFloat(parseFloat(oddNumber) + 0.02)?.toFixed(2))
    }
    const handleChangeOdd = (e) => {
        setOddNumber(e.target.value)
    }
    const handleChangeStack = (e) => {
        setStackNumber(e.target.value)
        if(props?.setSecondOdd){
            props?.setSecondOdd(e.target.value)
        }
        
    }

    const handleResetData = () => {
        setStackNumber(0)
        if(props?.setSecondOdd){
            props?.setSecondOdd(0)
        }
        
    }
    const handleReset = () => {
        setAmount(0)
    }

    const handleOneClickInput = (e, id) => {
        setOneClickButtonObject(
            oneClickButtonObject?.map((el) =>
                el?.id === id ? { ...el, value: e.target.value } : el
            )
        );
    };

    useEffect(() => {
        if(props?.clickState==="fancy" && props?.placeBetDataAllData?.BackSize1<100000 && props?.placeBetDataAllData?.LaySize1<100000 ){              
            const value= props.placeBetSize*stackNumber/100
            setAmountMultiple(value)
            if(props?.setOdds){
                props?.setOdds({ 
                    back: props?.placeBetDataColor === "blue" ?  value : "", 
                    lay: props?.placeBetDataColor === "red" ? value : "",
                    team: props?.placeBetDataTeam
                })
            }
        }else if(props?.clickState == "bookmaker"){
            const multData = stackNumber * oddNumber / 100                             
            setAmountMultiple(parseFloat(multData).toFixed(2)) 

            if(props?.setOdds){
                props?.setOdds({ 
                    back: props?.placeBetDataColor === "blue" ?  parseFloat(multData).toFixed(2) : "", 
                    lay: props?.placeBetDataColor === "red" ? parseFloat(multData).toFixed(2) : "",
                    team: props?.placeBetDataTeam
                })
            }
        } else {
            const multData = stackNumber * oddNumber
            const multiNegetive = multData - stackNumber                                
            setAmountMultiple(parseFloat(multiNegetive).toFixed(2)) 

            if(props?.setOdds){
                props?.setOdds({ 
                    back: props?.placeBetDataColor === "blue" ?  parseFloat(multiNegetive).toFixed(2) : "", 
                    lay: props?.placeBetDataColor === "red" ? parseFloat(multiNegetive).toFixed(2) : "",
                    team: props?.placeBetDataTeam
                })
            }
        }
    }, [stackNumber])


    useEffect(() => {
        const multData = stackNumber * oddNumber
        
        const multiNegetive = multData - stackNumber
        setAmountMultiple(Math.round(multiNegetive))
        if(props?.setOdds){
            props?.setOdds({ 
                back: props?.placeBetDataColor === "blue" ?  Math.round(multiNegetive) : "", 
                lay: props?.placeBetDataColor === "red" ? Math.round(multiNegetive) : "",
                team: props?.placeBetDataTeam
            })
        }
    }, [oddNumber])


    return (
        <div>
            <ToastContainer />
            {
                props.placeBetDataColor &&
                <div className="my-2 betSlipCardDiv shadow-lg card overflow-hidden">
                    <div className={` betSlipCardDiv1  ${props.placeBetDataColor == 'blue' ? "bg-blue-card border-blue-4" : "bg-red-card border-red-4"} `}>
                        <div className="px-3 d-flex justify-content-between pt-1">
                            <span className={"betslip_card_text_1"}>
                                {
                                    props?.clickState === "bookmaker"
                                    ?
                                    props?.placeBetDataTeam?.runnerName
                                    :
                                    ""
                                }
                                {
                                    props.teamNumber == '0'
                                    ?
                                    props?.placeBetDataAllData?.marketType?.[0]?.runners?.[0]?.runnerName || props?.placeBetDataAllData?.marketType?.[0]?.runners?.[0]?.runnerName
                                    :
                                    ""
                                    ||
                                    props.teamNumber == '2'
                                    ?
                                    props?.placeBetDataAllData?.marketType?.[0]?.runners?.[2]?.runnerName
                                    :
                                    ""
                                    ||
                                    props.teamNumber == '1'
                                    ?
                                    props?.placeBetDataAllData?.marketType?.[0]?.runners?.[1]?.runnerName
                                    :
                                    ""

                                }
                            </span>
                            <span className="fs_12 text-muted">Max Bet: 25000</span>
                        </div>
                        <div className="px-3 d-flex justify-content-between pb-1 betslip_card_text_2">
                            <span className="fs_12">
                                {
                                    props?.placeBetDataAllData?.event?.name
                                }
                            </span>
                            <span className="fs_12 text-muted">Max Market: 25000</span>
                        </div>
                    </div>

                    <div className="d-flex px-3 pe-0 fs_12 mt-2 w-100 ">
                        <span className="d-flex">
                            <div className="w-35 me-2 ">
                                <div className="betslip_card_text_2">Odds</div>
                                <div className="row m-0 p-0">
                                    <button onClick={handleDecrement} className="w-25 p-0 px-2 px-sm-1 bg-dark text-white shadow-none btn btn-dark rounded-0 betslip_odds_button">-</button>
                                    <input type="number" value={oddNumber} onChange={handleChangeOdd} className="w-50 shadow-none text-center outline-none border-none betslip_odds_button betslip_odds_input rounded-0 p-0" placeholder="1.56" />
                                    <button onClick={handleIncrement} className="w-25 p-0 px-2 px-sm-1 bg-dark text-white shadow-none btn btn-dark rounded-0 betslip_odds_button">+</button>
                                </div>
                            </div>

                            <div className="w-50 me-2">
                                <div className="betslip_card_text_2">Stake</div>
                                <div className="row m-0 p-0">
                                    <input type="number" onChange={handleChangeStack} value={stackNumber} className="betslip_odds_button px-2 w-100 shadow-none outline-none border-none betslip_odds_input rounded-0 p-0" placeholder="1.5456" />
                                </div>
                            </div>
                        </span>
                        <span style={{ flex: 1 }}></span>
                        <span className={`w-25 rounded-left me-0 d-flex flex-column justify-content-center px-2 ${props.placeBetDataColor == 'blue' ? "bg-blue-card" : "bg-red-card"} `}>
                            <div className="betslip_card_text_2">
                                {
                                    props.placeBetDataColor == 'blue'
                                        ?
                                        "Est.Profit"
                                        :
                                        "Est.Liability"
                                }
                            </div>
                            <div className="betslip_card_text_2 font-weight-bold"  style={{color:props.placeBetDataColor==="blue"?"green":"red"}}>                                
                            {props.placeBetDataColor === "blue" ? (amountMultiple > 0 ? "+" : "") : "-"}{amountMultiple}
                            </div>
                        </span>
                    </div>

                    {/* mobile buttons */}
                    <div className="d-sm-none row p-3 px-4">
                        {
                            oneClickBtn
                                ?
                                oneClickButtonObject?.map(el => (
                                    <span className={`${!el?.mobileView ? "d-none d-sm-flex" : ""} col-3 p-1`}>
                                        <button className={`fs_12 btn_mobile_betslipCard btn px-0 w-100 btn-sm shadow-none btn-outline-dark`} onClick={() => handleChange(el?.value)} >+{el?.value}</button>
                                    </span>
                                ))
                                :
                                oneClickButtonObject?.map(el => (
                                    <span className={`${!el?.mobileView ? "d-none d-sm-flex" : ""} col-3 p-1`}>
                                        <input type="number" value={el?.value} className={`fs_12 betsInputShadow rounded p-1 oneClickInput text-center w-100 outline-none border `} onChange={e => handleOneClickInput(e, el?.id)} ></input>
                                    </span>

                                ))
                        }

                        <span className="d-sm-none col-3 p-1">
                            <button className={`fs_12 btn btn_mobile_betslipCard px-0 w-100 btn-sm shadow-none btn-outline-success `} onClick={() => handleResetData()} >Reset</button>
                        </span>
                        <span className="d-sm-none col-3 p-1">
                            <button className={`fs_12 btn btn_mobile_betslipCard px-0 w-100 btn-sm shadow-none btn-outline-success `} onClick={() => setOneClickBtn(!oneClickBtn)} >Edit</button>
                        </span>
                    </div>

                    {/* desktop button */}
                    <div className="d-none d-sm-flex row p-3 px-4">
                        {
                            oneClickBtn
                                ?
                                oneClickButtonObject?.map(el => (
                                    <span className={`${!el?.mobileView ? "d-none d-sm-flex" : ""} w-20 p-1`}>
                                        <button className={`fs_12 btn px-0 rounded-0 w-100 btn-sm shadow-none btn-outline-dark`} onClick={() => handleChange(el?.value)} >+{el?.value}</button>
                                    </span>
                                ))
                                :
                                oneClickButtonObject?.map(el => (
                                    <span className={`${!el?.mobileView ? "d-none d-sm-flex" : ""} w-20 p-1`}>
                                        <input type="number" value={el?.value} className={`fs_12 rounded-0 betsInputShadow rounded p-1 oneClickInput text-center w-100 outline-none border `} onChange={e => handleOneClickInput(e, el?.id)} ></input>
                                    </span>

                                ))
                        }
                        <span className="w-20 p-1">
                            <button className={`fs_12 btn px-0 w-100 btn-sm rounded-0 shadow-none btn-outline-dark `} onClick={() => handleResetData()} >Reset</button>
                        </span>
                        <span className="w-20 p-1">
                            <button className={`fs_12 btn px-0 w-100 btn-sm rounded-0 shadow-none btn-outline-dark `} onClick={() => setOneClickBtn(!oneClickBtn)} >Edit</button>
                        </span>
                    </div>

                    <div className='p-3 bg-light' >
                        <div className='d-flex justify-content-between'>
                            <span className="w-49 ">
                                <button className='fs_12 btn px-0 w-100 btn-lg rounded-0 shadow-none btn-outline-danger' onClick={handleCancell} >Cancel</button>
                            </span>
                            <span className="w-49 ">
                                <button 
                                    className='fs_12 btn px-0 w-100 btn-lg rounded-0 shadow-none btn-success' 
                                    disabled={loader || !stackNumber} 
                                    onClick={
                                        props?.clickState === "fancy" 
                                        ? 
                                        handleFancySubmit 
                                        : 
                                        props?.clickState === "bookmaker" 
                                        ? 
                                        handleBookmakerSubmit
                                        : 
                                        handleSubmit
                                    }
                                >
                                    Place Bet
                                    {
                                        loader == true && <div className="spinner-border spinner-border-sm" role="status"></div>
                                    }
                                </button>
                            </span>


                        </div>
                    </div>
                </div>
            }
        </div>
    );
};

export default BetSlipCard;