import React, { useEffect, useRef, useState } from "react";
import Slider from "react-slick";
import Footer from "../components/Footer";
import PrimaryNavbar from "../components/PrimaryNavbar";
import football from "./../assets/images/football.png";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import leftarrow from "./../assets/images/leftarrow.png";
import bang_flag from "./../assets/images/bang_flag.png";
import eng_flag from "./../assets/images/eng_flag.png";
import livetv from "./../assets/images/livetv.png";
import livescore from "./../assets/images/livescore.png";
import axios from "axios";
import "../custom.css";
import rightarrow from "../assets/images/rightarrow.png";
import downarrow from "../assets/images/downarrow.png";
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import BetSlipCard from "../components/BetSlipCard/BetSlipCard"
import MobileFooter from "../components/MobileFooter";
import baseUrl from "../services/baseUrl";

function HomePageDesktop() {
  var settingsfootball = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  };

  const [allList, setAllList] = useState([]);
  const [event, setEvent] = useState([]);
  const isPlaceBet = useState(false);
  const [placeBetData, setPlaceBetData] = useState([]);
  const [placeBetDataColor, setPlaceBetDataColor] = useState([]);
  const [placeBetDataMrId, setPlaceBetDataMrId] = useState([]);
  const [placeBetDataTeam, setPlaceBetDataTeam] = useState([]);
  const [placeBetDataAllData, setPlaceBetDataAllData] = useState([]);
  const [topGamesLoader, setTopGamesLoader] = useState(false)
  const [allData, setAllData] = useState([]);
  const [allDataLoader, setAllDataLaoder] = useState(false)
  const [showDetailView, setShowDetailView] = useState(false);
  const [sportsTab, setSportsTab] = useState("1")
  const [gamesTabs, setGamesTabs] = useState('sports')

  const placeBet = (elem, color, market_id, team, alldata) => {

    const one = [{ one: elem, two: color, three: market_id, four: team }]
    setPlaceBetData(elem)
    setPlaceBetDataMrId(market_id)
    setPlaceBetDataTeam(team)
    setPlaceBetDataColor(color)
    setPlaceBetDataAllData(alldata)

    setTimeout(() => ref1.current.scrollIntoView({ inline: 'center', behavior: 'smooth' }), 100)
  }
  const ref1 = useRef(null)
  const cancellData = () => {
    setPlaceBetDataColor('')
  }

  useEffect(() => {
    // getData()
    getEventList()
    // handelClickEvent({eventType: "1"})
  }, []);

  const getEventList = async () => {
    setTopGamesLoader(true)
    let eventList = await axios.get(
      `${baseUrl}/user/event_list`
    );
    setSportsTab(eventList.data.data?.[0])
    setEvent(eventList.data.data);
    setTopGamesLoader(false)
  }

  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));

  const handelClickEvent = async (ele) => {
    setAllDataLaoder(true)
    let dataAllList = await axios.get(
      `${baseUrl}/user/load_matches?eventId=${ele.eventType}`
    );
    setAllData(dataAllList.data.data);
    setAllDataLaoder(false)
  };

  const getMatchData = () => {
    if (sportsTab?.eventType) {
      handelClickEvent(sportsTab)
    }
  }

  useEffect(() => {
    getMatchData()
  }, [sportsTab])

  return (
    <div className="">
      <PrimaryNavbar />
      <div className="PlayLiveGamesdivHome text-Dark">
      </div>

      <div className="PlayLiveGamesdivHome text-Dark">
        <div className="odd-main-internal">
          <h2 className="headingcricket">Bangladesh vs England</h2>
          <p className="paracricket">
            {" "}
            Homepage <img src={rightarrow} />{" "} Cricket Betting Odds <img src={rightarrow} />{" "}
            <span className="paracricket2">Bangladesh vs England</span>
          </p>
        </div>
        <div className="container-fluid px-5 py-3">
          <div className="row">
            {
              topGamesLoader
                ?
                <div className="d-flex align-items-center justify-content-center">
                  <div className="spinner-border" role="status">
                    <span className="sr-only"></span>
                  </div>
                </div>
                :
                ""
            }
            <div className="col-md-12">
              <div>
                {
                  event?.length
                    ?
                    <Slider {...settingsfootball}>
                      {event.map((ele) => (
                        <div className="px-2">
                          <div
                            className="br0 card mx-1 cardfootball"
                            onClick={() => setSportsTab(ele)}
                          >
                            <div className="br-0 card-body footballcard">
                              <img src={football} className="px-1" />
                              <h3 className="mb-0 text-dark Footballtext px-2">
                                {ele?.name}{" "}
                              </h3>
                            </div>
                          </div>{" "}
                        </div>
                      ))}
                    </Slider>
                    :
                    ""
                }

              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="inplaydiv">
        <div className="container-fluid px-5 py-3">
          <div className="row div-live">
            <div className="col-md-3 ">
              <img src={leftarrow} />
            </div>
            <div className="col-md-4 odd-team-flex">
              <img src={bang_flag} /> <p className="odd-team-name">Bangladesh Vs England</p> <img src={eng_flag} />
            </div>
            <div className="col-md-2 odd-team-flex2">
              <img src={livescore} /> <p className="odd-team-name">Live Score</p>
            </div>
            <div className="col-md-1 odd-team-flex2">
              <img src={livetv} /> <p className="odd-team-name">Live Tv</p>
            </div>
            <div className="col-md-1"></div>
            <div className="col-md-1"><p className="bet">Bet Slip</p></div>
          </div>
          <div className="row dashboardBoxInplay p-5 odd-layer">
            <div className="col-md-9 inplaymaindiv ">
              <div className="row ">
                <div className="col-md-2 match-odd-head">
                  <p className="text-white fs-5 my-0">Match odds</p>
                </div>
                <div className="col-md-5 "></div>
                <div className="col-md-1 "> <p className="odd-back">Back</p></div>
                <div className="col-md-1 "><p className="odd-lay">Lay</p></div>
                <div className="col-md-2 "></div>
                <div className="col-md-1 "><img src={downarrow} /></div>
              </div>



              {/* betslip table */}
              <div className="row  ">
                <div className="">
                  <div className="  px-0 mx-0 ">
                    <div className="matchpointHome ">
                      <div className="pointdetailsHome">
                        <div className="col-md-5">
                          <p className="pointdetailsname">Bangladesh</p>
                        </div>
                        <div className="col-md-6">
                          <div className="row">

                            <div className="col-4 ">
                              <div className="row pe-2">
                                <div className="col-6  p-0 px-1" >
                                  <div className="w-100 d-flex justify-content-center pointdetailsnumber">
                                    01.2
                                  </div>
                                </div>
                                <div className="col-6 p-0" >
                                  <div className="w-100 d-flex justify-content-center pointdetailsnumber">
                                    01.2
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-4 ">
                              <div className="row pe-2">
                                <div className="col-6  p-0 px-1" >
                                  <div className="w-100 d-flex justify-content-center pointdetailsnumber">
                                    01.2
                                  </div>
                                </div>
                                <div className="col-6 p-0" >
                                  <div className="w-100 d-flex justify-content-center pointdetailsnumberlay">
                                    01.2
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-4 ">
                              <div className="row pe-2">
                                <div className="col-6  p-0 px-1" >
                                  <div className="w-100 d-flex justify-content-center pointdetailsnumberlay">
                                    01.2
                                  </div>
                                </div>
                                <div className="col-6 p-0" >
                                  <div className="w-100 d-flex justify-content-center pointdetailsnumberlay">
                                    01.2
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row  ">
                <div className="">
                  <div className="  px-0 mx-0 ">
                    <div className="matchpointHome ">
                      <div className="pointdetailsHome">
                        <div className="col-md-5">
                          <p className="pointdetailsname">England</p>
                        </div>
                        <div className="col-md-6">
                          <div className="row">

                            <div className="col-4 ">
                              <div className="row pe-2">
                                <div className="col-6  p-0 px-1" >
                                  <div className="w-100 d-flex justify-content-center pointdetailsnumber">
                                    01.2
                                  </div>
                                </div>
                                <div className="col-6 p-0" >
                                  <div className="w-100 d-flex justify-content-center pointdetailsnumber">
                                    01.2
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-4 ">
                              <div className="row pe-2">
                                <div className="col-6  p-0 px-1" >
                                  <div className="w-100 d-flex justify-content-center pointdetailsnumber">
                                    01.2
                                  </div>
                                </div>
                                <div className="col-6 p-0" >
                                  <div className="w-100 d-flex justify-content-center pointdetailsnumberlay">
                                    01.2
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-4 ">
                              <div className="row pe-2">
                                <div className="col-6  p-0 px-1" >
                                  <div className="w-100 d-flex justify-content-center pointdetailsnumberlay">
                                    01.2
                                  </div>
                                </div>
                                <div className="col-6 p-0" >
                                  <div className="w-100 d-flex justify-content-center pointdetailsnumberlay">
                                    01.2
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
            <div className="col-md-3">
              <div className="row ">
                <div className="col-md-3 pb-2">
                  <p className="text-white fs-5 invisible"> s</p>
                </div>
              </div>
              <div className="tooglebutton py-4">
                <AntSwitch className="switch" />
                <p className="switchtext">1-click bet</p>{" "}
                <AntSwitch className="switch" />
                <p className="switchtext">Accept all odds for BM</p>
              </div>
              {isPlaceBet ? (
                <BetSlipCard
                  data={placeBetData}
                  placeBetDataColor={placeBetDataColor}
                  placeBetDataMrId={placeBetDataMrId}
                  placeBetDataTeam={placeBetDataTeam}
                  placeBetDataAllData={placeBetDataAllData}
                  cancellData={cancellData}
                />
              )
                :
                <>
                </>}

            </div>
          </div>

          <div className="row dashboardBoxInplay p-5 odd-layer">
            <div className="col-md-9 inplaymaindiv ">
              <div className="row ">
                <div className="col-md-2 match-odd-head">
                  <p className="text-white fs-5 my-0">Bookmaker</p>
                </div>
                <div className="col-md-5 "></div>
                <div className="col-md-1 "> <p className="odd-back">Back</p></div>
                <div className="col-md-1 "><p className="odd-lay">Lay</p></div>
                <div className="col-md-2 "></div>
                <div className="col-md-1 "><img src={downarrow} /></div>
              </div>

              {/* betslip table */}
              <div className="row  ">
                <div className="">
                  <div className="  px-0 mx-0 ">
                    <div className="matchpointHome ">
                      <div className="pointdetailsHome">
                        <div className="col-md-5">
                          <p className="pointdetailsname">Bangladesh</p>
                        </div>
                        <div className="col-md-6">
                          <div className="row">

                            <div className="col-4 ">
                              <div className="row pe-2">
                                <div className="col-6  p-0 px-1" >
                                  <div className="w-100 d-flex justify-content-center pointempty">
                                    -
                                  </div>
                                </div>
                                <div className="col-6 p-0" >
                                  <div className="w-100 d-flex justify-content-center pointempty">
                                    -
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-4 ">
                              <div className="row pe-2">
                                <div className="col-6  p-0 px-1" >
                                  <div className="w-100 d-flex justify-content-center pointdetailsnumber">
                                    01.2
                                  </div>
                                </div>
                                <div className="col-6 p-0" >
                                  <div className="w-100 d-flex justify-content-center pointdetailsnumberlay">
                                    01.2
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-4 ">
                              <div className="row pe-2">
                                <div className="col-6  p-0 px-1" >
                                  <div className="w-100 d-flex justify-content-center pointempty">
                                    -
                                  </div>
                                </div>
                                <div className="col-6 p-0" >
                                  <div className="w-100 d-flex justify-content-center pointempty">
                                    -
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row  ">
                <div className="">
                  <div className="  px-0 mx-0 ">
                    <div className="matchpointHome ">
                      <div className="pointdetailsHome">
                        <div className="col-md-5">
                          <p className="pointdetailsname">England</p>
                        </div>
                        <div className="col-md-6">
                          <div className="row">

                            <div className="col-4 ">
                              <div className="row pe-2">
                                <div className="col-6  p-0 px-1" >
                                  <div className="w-100 d-flex justify-content-center pointempty">
                                    -
                                  </div>
                                </div>
                                <div className="col-6 p-0" >
                                  <div className="w-100 d-flex justify-content-center pointempty">
                                    -
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-4 ">
                              <div className="row pe-2">
                                <div className="col-6  p-0 px-1" >
                                  <div className="w-100 d-flex justify-content-center pointempty">
                                    -
                                  </div>
                                </div>
                                <div className="col-6 p-0" >
                                  <div className="w-100 d-flex justify-content-center pointempty">
                                    -
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-4 ">
                              <div className="row pe-2">
                                <div className="col-6  p-0 px-1" >
                                  <div className="w-100 d-flex justify-content-center pointempty">
                                    -
                                  </div>
                                </div>
                                <div className="col-6 p-0" >
                                  <div className="w-100 d-flex justify-content-center pointempty">
                                    -
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>

          <div className="row dashboardBoxInplay p-5 odd-layer">
            <div className="col-md-9 inplaymaindiv ">
              <div className="row ">
                <div className="col-md-2 match-odd-head">
                  <p className="text-white fs-5 my-0">Fancy</p>
                </div>
                <div className="col-md-5 "></div>
                <div className="col-md-1 "> <p className="odd-back">Back</p></div>
                <div className="col-md-1 "><p className="odd-lay">Lay</p></div>
                <div className="col-md-2 "></div>
                <div className="col-md-1 "><img src={downarrow} /></div>
              </div>

              {/* betslip table */}
              <div className="row  ">
                <div className="">
                  <div className="  px-0 mx-0 ">
                    <div className="matchpointHome ">
                      <div className="pointdetailsHome">
                        <div className="col-md-3">
                          <p className="pointdetailsname">20th over runs only England</p>
                        </div>
                        <div className="col-md-6">
                          <div className="row">

                            <div className="col-4 ">
                              <div className="row pe-2">
                                <div className="col-6  p-0 px-1" >
                                  <div className="w-100 d-flex justify-content-center pointempty">
                                    -
                                  </div>
                                </div>
                                <div className="col-6 p-0" >
                                  <div className="w-100 d-flex justify-content-center pointempty">
                                    -
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-4 ">
                              <div className="row pe-2">
                                <div className="col-6  p-0 px-1" >
                                  <div className="w-100 d-flex justify-content-center pointdetailsnumber">
                                    01.2
                                  </div>
                                </div>
                                <div className="col-6 p-0" >
                                  <div className="w-100 d-flex justify-content-center pointdetailsnumberlay">
                                    01.2
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-4 ">
                              <div className="row pe-2">
                                <div className="col-6  p-0 px-1" >
                                  <div className="w-100 d-flex justify-content-center pointempty">
                                    -
                                  </div>
                                </div>
                                <div className="col-6 p-0" >
                                  <div className="w-100 d-flex justify-content-center pointempty">
                                    -
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>
              <div className="row  ">
                <div className="">
                  <div className="  px-0 mx-0 ">
                    <div className="matchpointHome ">
                      <div className="pointdetailsHome">
                        <div className="col-md-5">
                          <p className="pointdetailsname">England</p>
                        </div>
                        <div className="col-md-6">
                          <div className="row">

                            <div className="col-4 ">
                              <div className="row pe-2">
                                <div className="col-6  p-0 px-1" >
                                  <div className="w-100 d-flex justify-content-center pointempty">
                                    -
                                  </div>
                                </div>
                                <div className="col-6 p-0" >
                                  <div className="w-100 d-flex justify-content-center pointempty">
                                    -
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-4 ">
                              <div className="row pe-2">
                                <div className="col-6  p-0 px-1" >
                                  <div className="w-100 d-flex justify-content-center pointdetailsnumber">
                                    01.2
                                  </div>
                                </div>
                                <div className="col-6 p-0" >
                                  <div className="w-100 d-flex justify-content-center pointdetailsnumberlay">
                                    01.2
                                  </div>
                                </div>
                              </div>
                            </div>

                            <div className="col-4 ">
                              <div className="row pe-2">
                                <div className="col-6  p-0 px-1" >
                                  <div className="w-100 d-flex justify-content-center pointempty">
                                    -
                                  </div>
                                </div>
                                <div className="col-6 p-0" >
                                  <div className="w-100 d-flex justify-content-center pointempty">
                                    -
                                  </div>
                                </div>
                              </div>
                            </div>

                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

              </div>

            </div>
          </div>
        </div>
      </div>



      <Footer />


      <MobileFooter />
    </div >
  );
}

export default HomePageDesktop;
