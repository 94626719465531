import React, { useEffect, useState } from 'react'
import Footer from '../../components/Footer'
import PrimaryNavbar from '../../components/PrimaryNavbar'
import '../HomePageMobile/HomePageMobile.css'
import fullstar from '../../assets/fullstar.png'
import star from '../../assets/star.png'
import { useLocation, useNavigate } from 'react-router-dom'
import axios from 'axios'
import BetSlipCard from '../../components/BetSlipCard/BetSlipCard'
import Switch from "@mui/material/Switch"
import { styled } from "@mui/material/styles"
import baseUrl from '../../services/baseUrl'
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { placeBetAPI } from '../../services/api'
import MobileFooter from '../../components/MobileFooter'
import LiveTv from "../../assets/liveTv.svg"
import LiveScore from "../../assets/liveScore.svg"
import Suspended from '../../components/Suspended/Suspended'
import { accessDenied, checkAccess } from '../../services/helper'
import { ACCESS_TYPE } from '../../services/constants'

const toggleSidebar = () => {
    const sidebar = document.getElementById("mobile_sidebar");
    sidebar?.classList.toggle("d-none");
};





function HomePageMobile() {
    const user = JSON.parse(localStorage.getItem("betUser"));
    const navigate = useNavigate()

    const { state } = useLocation()
    const [allData, setAlldata] = useState()
    const [fancyData, setFancyData] = useState([])
    const [bookmakerData, setBookmakerData] = useState([])
    const [selectedFancy, setSelectedFancy] = useState({})
    const [selectedBookmaker, setSelectedBookmaker] = useState({})
    const [placeBetData, setPlaceBetData] = useState([]);
    const [placeBetSize, setPlaceBetSize] = useState("");
    const [placeBetDataColor, setPlaceBetDataColor] = useState([]);
    const [placeBetDataMrId, setPlaceBetDataMrId] = useState([]);
    const [placeBetDataTeam, setPlaceBetDataTeam] = useState([]);
    const [placeBetDataAllData, setPlaceBetDataAllData] = useState([]);
    const [clickState, setClickSate] = useState(false)
    const [oneClickInput, setOneClickInput] = useState(false)
    const [oneClickButton, setOneClickButton] = useState([
        { id: "1", value: "50", selected: false },
        { id: "2", value: "200", selected: false },
        { id: "3", value: "300", selected: false },
    ])
    const [finalNumber, setFinalDataNumber] = useState()
    const [teamNumber, setTeamNumber] = useState()
    const [liveScoreUrl, setLiveScoreUrl] = useState("")
    const [odds, setOdds] = useState({ back: "", lay: "" })
    const [oddsRefreshTime, setOddsRefreshTIme] = useState(2000)
    const [secondOdd, setSecondOdd] = useState()



    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 28,
        height: 16,
        padding: 0,
        display: "flex",
        "&:active": {
            "& .MuiSwitch-thumb": {
                width: 15,
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
                transform: "translateX(9px)",
            },
        },
        "& .MuiSwitch-switchBase": {
            padding: 2,
            "&.Mui-checked": {
                transform: "translateX(12px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                    opacity: 1,
                    backgroundColor:
                        theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
                },
            },
        },
        "& .MuiSwitch-thumb": {
            boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
            width: 12,
            height: 12,
            borderRadius: 6,
            transition: theme.transitions.create(["width"], {
                duration: 200,
            }),
        },
        "& .MuiSwitch-track": {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor:
                theme.palette.mode === "dark"
                    ? "rgba(255,255,255,.35)"
                    : "rgba(0,0,0,.25)",
            boxSizing: "border-box",
        },
    }))

    const handleClickOne = () => {
        setClickSate(clickState ? "" : "oneClick")
    }

    const handleGetMatchOdd = async (id) => {
        const responce = await axios.get(`${baseUrl}/user/load_matches?matchId=${id}`)
        if (responce.status == 200) {
            const allData = responce?.data?.data?.[0]
            const bookmaker = responce?.data?.bookmaker
            const fancyData = responce?.data?.fancy
            setAlldata(allData)
            setFancyData(fancyData)
            setBookmakerData(bookmaker)
        }
    }

    // const handleGetFancyMatchOdd = async (id) => {
    //     const responce = await axios.get(`${baseUrl}/user/load_fancy?matchId=${id}`)
    //     if (responce.status == 200) {
    //         setFancyData(fancyData)
    //     }
    // }

    // const handleGetBookmakerMatchOdd = async (id) => {
    //     const responce = await axios.get(`${baseUrl}/user/load_bookmarker?matchId=${id}`)
    //     if (responce.status == 200) {
    //         setBookmakerData(fancyData)
    //     }
    // }

    // const handleGetFancy = async (id) => {
    //     const response = await axios.get(`${baseUrl}/api/v1/fetch_data?Action=listMarketBookSession&match_id=${id}`)
    //     if (response.status == 200) {
    //         setFancyData(response?.data?.data)
    //     }
    // }

    const getSiteSetting = async () => {
        try {
            const Token = localStorage.getItem('token')
            const headers = {
                headers: {
                    "x-access-token": Token
                }
            }
            const res = await axios.get(baseUrl + '/site-setting', headers)
            if (res?.status == 200) {
                setOddsRefreshTIme(res?.data?.data?.refreshTime)
            }
        } catch (error) { }
    }

    useEffect(() => {
        getSiteSetting()
    }, [])

    useEffect(() => {
        if (oddsRefreshTime) {
            const intervalCall = setInterval(() => {
                handleGetMatchOdd(state?.event?.id)
                // handleGetFancyMatchOdd(state?.event?.id)
                // handleGetBookmakerMatchOdd(state?.event?.id)
            }, oddsRefreshTime);
            return () => clearInterval(intervalCall)
        }
    }, [oddsRefreshTime])

    const placeBet = (elem, color, market_id, team, alldata, teamNumber) => {
        if (!(clickState === "oneClick")) {
            setClickSate("card")
        }
        setPlaceBetData(elem)
        setPlaceBetDataMrId(market_id)
        setPlaceBetDataTeam(team)
        setPlaceBetDataColor(color)
        setPlaceBetDataAllData(alldata)
        setTeamNumber(teamNumber)
        if (clickState === 'oneClick') {
            handleSubmit(elem, color, market_id, team, alldata, teamNumber);
        }
    }

    const placeBet2 = (elem, size, color, match_id, team, alldata, teamNumber) => {
        setClickSate("fancy")
        setPlaceBetData(elem)
        setPlaceBetSize(size)
        setPlaceBetDataColor(color)
        setPlaceBetDataMrId({ match_id: match_id, SelectionId: team?.SelectionId })
        setPlaceBetDataTeam(team)
        setPlaceBetDataAllData({ ...team, event: { name: team?.RunnerName } })
        setTeamNumber(teamNumber)
        setSelectedFancy(team)
    }

    const placeBet3 = (elem, color, market_id, team, alldata, teamNumber) => {
        setClickSate("bookmaker")
        setPlaceBetData(elem)
        setPlaceBetDataMrId(market_id)
        setPlaceBetDataTeam(team)
        setPlaceBetDataColor(color)
        setPlaceBetDataAllData(alldata)
        setTeamNumber(teamNumber)
    }


    const handleSubmit = async (elem, color, market_id, team, alldata, teamNumber) => {

        if (finalNumber && clickState) {
            try {
                const payload = {
                    odds: elem,
                    market_id: market_id.marketId,
                    stake: +finalNumber,
                    team: team?.SelectionId,
                    type: color == "blue" ? "Back" : "Lay",
                    match_id: alldata.Id,
                };

                const Token = JSON.parse(localStorage.getItem("betUser"));
                const headers = {
                    headers: {
                        "Content-Type": "application/json",
                        "x-access-token": Token?.token,
                    },
                };
                const responce = await axios.post(
                    baseUrl + placeBetAPI,
                    payload,
                    headers
                );
                if (responce.status == 200) {
                    toast.success("placed sucee", {
                        position: "top-center",
                        autoClose: 2000,
                    });
                    setPlaceBetData([]);
                    setPlaceBetDataMrId([]);
                    setPlaceBetDataTeam([]);
                    setPlaceBetDataColor([]);
                    setPlaceBetDataAllData([]);
                    setSecondOdd(0)
                    setOdds({})
                }
            } catch (error) {
                toast.error(error?.response?.data?.message, {
                    position: "top-center",
                    autoClose: 2000,
                });
                setPlaceBetData([]);
                setPlaceBetDataMrId([]);
                setPlaceBetDataTeam([]);
                setPlaceBetDataColor([]);
                setPlaceBetDataAllData([]);
                setSecondOdd(0)
                setOdds({})
            }
        }

    };

    const cancellData = () => {
        setPlaceBetDataColor('')
        setSecondOdd(0)
        setOdds({})

    }


    const handleOneClickButtonClick = (id, ele) => {
        setOneClickButton(oneClickButton?.map(el => el?.id === id ? { ...el, selected: true } : { ...el, selected: false }))
        setFinalDataNumber(ele);
    }


    const handleOneClickInput = (e, id) => {
        setOneClickButton(oneClickButton?.map(el => el?.id === id ? { ...el, value: e.target.value } : el))
    }

    const getLiveScore = async () => {
        const res = await axios.get(`${baseUrl}/cricket/live_score?matchId=${state?.event?.id}`)
        if (res) {
            setLiveScoreUrl(res?.data?.data)
        }
    }

    const getLiveTV = async () => {
        const res = await axios.get(`${baseUrl}/cricket/live_tv?eventId=${state?.event?.id}`)
        if (res) {
            setLiveScoreUrl(res?.data?.data)
        }
    }

    useEffect(() => {
        if(user?.access?.length){
          if(!checkAccess(ACCESS_TYPE?.EXCHANGE)){
            accessDenied()
            navigate("/")
          }
        }
    }, [user])

    return (
        <div>
            <PrimaryNavbar></PrimaryNavbar>
            <div className='text-center fs_12 min-h-100vh homepageInternalBg'>
                <div className='midle-games-box-black'>
                    <div className='midle-games-box'>
                        <div className='h-75 mb-0 py-3 font-weight-bold h3 text-white d-flex align-items-center justify-content-center'>
                            {allData?.name}
                        </div>
                    </div>
                </div>
                <div className="row m-0 p-0 cursor-pointer position-relative font-weight-bold justify-content-center bg-white text-dark py-2">
                    <span
                        className="w-50 d-flex align-items-center justify-content-center fs_14"
                        // onClick={getLiveScore}
                        onClick={e => { e.stopPropagation(); setLiveScoreUrl() }}
                    >
                        <img className="me-2" src={LiveScore} />Live Score
                    </span>
                    <span className="w-50 d-flex align-items-center justify-content-center fs_14" onClick={getLiveTV} ><img className="me-2" src={LiveTv} />Live TV</span>
                    {
                        liveScoreUrl
                            ?
                            <span onClick={e => { e.stopPropagation(); setLiveScoreUrl() }} ><i className="bi bi-x position-absolute h2" style={{ right: 5, top: 2 }} /></span>
                            :
                            ""
                    }

                </div>
                {
                    liveScoreUrl
                        ?
                        <div className="LiveTv" width="100%" dangerouslySetInnerHTML={{ __html: liveScoreUrl }}></div>
                        // <iframe width="100%" target={"_parent"} src={liveScoreUrl} />
                        :
                        ""
                }

                <div className="row m-0 p-0 bg-dark text-white py-2">
                    <div className='col-4 p-0' >
                        <p className="font-weight-bold mb-0 fs_12 d-flex px-2"> In Play </p>
                    </div>
                    <div className='col-3' >
                        <p className="font-weight-bold mb-0 fs_12 px-4  d-flex justify-content-center">| </p>
                    </div>
                    <div className='col-5 mb-0 d-flex align-items-center px-3 justify-content-end' >
                        <AntSwitch className="switch" onClick={handleClickOne} checked={clickState} value={clickState === "oneClick"} />
                        <span>1-Click Bet</span>
                    </div>
                </div>
                {
                    clickState == "oneClick"
                        ?
                        <div className="p-2 bg-white rounded d-flex justify-content-between">
                            <span>
                                {
                                    oneClickInput
                                        ?
                                        <>
                                            {
                                                oneClickButton?.map(el => (
                                                    <input type="number" value={el?.value} style={{ width: "60px" }} className={`oneClickInput btn btn-sm shadow-none border me-1`} onChange={e => handleOneClickInput(e, el?.id)}></input>
                                                ))
                                            }
                                        </>
                                        :
                                        <>
                                            {
                                                oneClickButton?.map(el => (
                                                    <button style={{ width: "60px" }} className={`btn btn-sm shadow-noned-flex justify-content-center ${el?.selected ? "btn-info" : "btn-outline-info"} me-1`} onClick={() => handleOneClickButtonClick(el?.id, el?.value)}>{el?.value}</button>
                                                ))
                                            }
                                        </>
                                }
                            </span>
                            <span>
                                <button className="btn btn-sm btn-warning" onClick={() => { setOneClickInput(!oneClickInput) }}>
                                    {oneClickInput ? <>Save <i className="bi bi-save"></i></> : <>Edit Stake <i className="bi bi-pencil"></i></>}
                                </button>
                            </span>
                        </div>
                        :
                        ''
                }
                <div className='bg-white overflow-hidden' >
                    <div className="row odd-match-class-name m-0 p-2 py-3">
                        <div className="col-5 px-0 d-flex">
                            <img src={fullstar} className="" />
                            <span className='ml-2 fs_12 text-white' >Match Odds</span>
                        </div>
                        <div className="col-7 px-0 d-flex justify-content-end">
                            <span className='fs_12 mx-3' style={{ color: '#0DC5FF' }}>Back</span>
                            <span className='fs_12 mx-3' style={{ color: '#FFB5BD' }}>Lay</span>
                        </div>
                    </div>

                    {
                        allData ?
                            <div>
                                <div className='row shadow-sm fs_12 text-start px-1 py-1' >                                    
                                    <div className='col-5 d-flex align-items-center' >
                                        <b className='px-2'>
                                            {allData?.marketType?.[0]?.runners?.[0]?.runnerName}
                                            {/* {allData?.marketType?.[0]?.odds?.[0]?.status === "CLOSED" ? ():""                                                                                                                                 */}
                                            {
                                                odds?.team?.selectionId == allData?.marketType?.[0]?.runners?.[0]?.selectionId && (odds?.back || odds?.lay)
                                                    ?
                                                    <span className={odds?.back ? "text-success" : "text-danger"}><br />
                                                        {odds?.back ? "+" + odds?.back : ""}
                                                        {odds?.lay ? "-" + odds?.lay : ""}
                                                    </span>
                                                    :
                                                    odds?.team?.selectionId == allData?.marketType?.[0]?.runners?.[1]?.selectionId && secondOdd && !isNaN(secondOdd)
                                                        ?
                                                        <span className={odds?.back ? "text-danger" : "text-success"} ><br />
                                                            {odds?.back ? "-" + secondOdd : ""}
                                                            {odds?.lay ? "+" + secondOdd : ""}
                                                        </span>
                                                        :
                                                        ""
                                            }
                                        </b>
                                        </div>                                        
                                    <div className='col-7 d-flex justify-content-end' >
                                        <div className='bg-info me_0-25 d-flex justify-content-center w-25 fs_12 fw_600 p-2 pb-3 rounded-4 position-relative' onClick={() => placeBet(
                                            allData?.marketType?.[0].odds?.[0]?.runners?.[0]?.ex?.availableToBack?.[0]?.price,
                                            'blue',
                                            allData?.marketType?.[0]?.odds?.[0],
                                            allData?.marketType?.[0]?.runners?.[0],
                                            allData,
                                            "0"
                                        )} >
                                            <b>
                                                {
                                                    allData?.marketType?.[0]?.odds?.[0]?.runners?.[0]?.ex?.availableToBack?.[0]?.price
                                                        ?
                                                    allData?.marketType?.[0]?.odds?.[0]?.runners?.[0]?.ex?.availableToBack?.[0]?.price
                                                    :
                                                    " "
                                                }
                                            </b>
                                            <small style={{ bottom: 3 }} className="position-absolute fw_600 text-primary">{allData?.marketType?.[0]?.odds?.[0]?.runners?.[0]?.ex?.availableToBack?.[0]?.size}</small>
                                        </div>
                                        <div className='bg-red-card d-flex justify-content-center w-25 fs_12 fw_600 p-2 pb-3 position-relative' onClick={() => placeBet(
                                            allData?.marketType?.[0].odds?.[0]?.runners?.[0]?.ex?.availableToLay?.[0]?.price,
                                            'red',
                                            allData?.marketType?.[0]?.odds?.[0],
                                            allData?.marketType?.[0]?.runners?.[0],
                                            allData,
                                            "0"
                                        )} >
                                            <b>
                                                {
                                                    allData?.marketType?.[0]?.odds?.[0]?.runners?.[0]?.ex?.availableToLay?.[0]?.price
                                                    ?
                                                    allData?.marketType?.[0]?.odds?.[0]?.runners?.[0]?.ex?.availableToLay?.[0]?.price
                                                    :
                                                    " "
                                                }
                                            </b>
                                            <small style={{ bottom: 3 }} className="position-absolute fw_600 text-danger">{allData?.marketType?.[0]?.odds?.[0]?.runners?.[0]?.ex?.availableToLay?.[0]?.size}</small>
                                        </div>
                                    </div>                                                                      
                                </div>

                                <div className='row shadow-sm fs_12 text-start px-1 py-1' >
                                    <div className='col-5 d-flex align-items-center' >
                                        <b className='px-2'>
                                            {allData?.marketType?.[0]?.runners?.[1]?.runnerName?.length <= 16 ? allData?.marketType?.[0]?.runners?.[1]?.runnerName : allData?.marketType?.[0]?.runners?.[1]?.runnerName?.slice(0, 15) + "..."}
                                            {
                                                odds?.team?.selectionId == allData?.marketType?.[0]?.runners?.[1]?.selectionId && (odds?.back || odds?.lay)
                                                    ?
                                                    <span className={odds?.back ? "text-success" : "text-danger"}><br />
                                                        {odds?.back ? "+" + odds?.back : ""}
                                                        {odds?.lay ? "-" + odds?.lay : ""}
                                                    </span>
                                                    :
                                                    odds?.team?.selectionId == allData?.marketType?.[0]?.runners?.[0]?.selectionId && secondOdd && !isNaN(secondOdd)
                                                        ?
                                                        <span className={odds?.back ? "text-danger" : "text-success"} ><br />
                                                            {odds?.back ? "-" + Number(secondOdd) : ""}
                                                            {odds?.lay ? "+" + Number(secondOdd) : ""}
                                                        </span>
                                                        :
                                                        ""
                                            }
                                        </b>
                                    </div>
                                    <div className='col-7 d-flex justify-content-end' >
                                        <div className='bg-info me_0-25 d-flex justify-content-center w-25 fs_12 fw_600 p-2 pb-3 rounded-4 position-relative' onClick={() => placeBet(
                                            allData?.marketType?.[0]?.odds?.[0]?.runners?.[1]?.ex?.availableToBack?.[0]?.price,
                                            'blue',
                                            allData?.marketType?.[0]?.odds?.[0],
                                            allData?.marketType?.[0]?.runners?.[1],
                                            allData,
                                            "1"
                                        )} >
                                            <b>
                                                {
                                                    allData?.marketType?.[0]?.odds?.[0]?.runners?.[1]?.ex?.availableToBack?.[0]?.price
                                                    ?
                                                    allData?.marketType?.[0]?.odds?.[0]?.runners?.[1]?.ex?.availableToBack?.[0]?.price
                                                    :
                                                    " "
                                                }
                                            </b>
                                            <small style={{ bottom: 3 }} className="position-absolute fw_600 text-primary">{allData?.marketType?.[0]?.odds?.[0]?.runners?.[1]?.ex?.availableToBack?.[0]?.size}</small>
                                            {/* <p>500,000+</p> */}
                                        </div>
                                        <div className='bg-red-card d-flex justify-content-center w-25 fs_12 fw_600 p-2 pb-3 position-relative' onClick={() => placeBet(
                                            allData?.marketType?.[0]?.odds?.[0]?.runners?.[1]?.ex?.availableToLay?.[0]?.price,
                                            'red',
                                            allData?.marketType?.[0]?.odds?.[0],
                                            allData?.marketType?.[0]?.runners?.[1],
                                            allData,
                                            "1"
                                        )} >
                                            <b>
                                                {
                                                    allData?.marketType?.[0]?.odds?.[0]?.runners?.[1]?.ex?.availableToLay?.[0]?.price
                                                    ?
                                                    allData?.marketType?.[0]?.odds?.[0]?.runners?.[1]?.ex?.availableToLay?.[0]?.price
                                                    :
                                                    " "
                                                }
                                            </b>
                                            <small style={{ bottom: 3 }} className="position-absolute fw_600 text-danger">{allData?.marketType?.[0]?.odds?.[0]?.runners?.[1]?.ex?.availableToLay?.[0]?.size}</small>
                                            {/* <p>500,000+</p> */}
                                        </div>
                                    </div>
                                </div>
                            </div> :
                            <div className="odd-match-class-name d-flex align-items-center justify-content-center">
                                <div className="spinner-border" role="status">
                                    <span className="sr-only"></span>
                                </div>
                            </div>
                    }

                </div>

                <div className='mx-2' >
                    {
                        clickState == "card"
                            ?
                            <BetSlipCard
                                data={placeBetData}
                                placeBetDataColor={placeBetDataColor}
                                placeBetDataMrId={placeBetDataMrId}
                                placeBetDataTeam={placeBetDataTeam}
                                placeBetDataAllData={placeBetDataAllData}
                                cancellData={cancellData}
                                clickState={clickState}
                                teamNumber={teamNumber}
                                setOdds={setOdds}
                                setSecondOdd={setSecondOdd}
                            />
                            :
                            ""
                    }
                </div>

                {/* BOOKAMKER DATA */}
                {
                    bookmakerData?.length
                        ?
                        <>
                            {/* Bookmaker DATA */}
                            <div className='bg-white overflow-hidden' >
                                <div className="row odd-match-class-name m-0 p-2 py-3">
                                    <div className="col-5 px-0 d-flex">
                                        <img src={fullstar} className="" />
                                        <span className='ml-2 fs_12 text-white' >Bookmaker</span>
                                    </div>
                                    <div className="col-7 px-0 d-flex justify-content-end">
                                        <span className='fs_12 mx-3' style={{ color: '#0DC5FF' }}>Back</span>
                                        <span className='fs_12 mx-3' style={{ color: '#FFB5BD' }}>Lay</span>
                                    </div>
                                </div>

                                {
                                    bookmakerData
                                        ?
                                        <div>
                                            {
                                                bookmakerData?.map(data => (
                                                    <>
                                                        {
                                                            data?.runners?.map(runner => (
                                                                <div className='row shadow-sm fs_12 text-start px-1 py-1' >
                                                                    <div className='col-5 d-flex align-items-center' >
                                                                        <b className='px-2'>
                                                                            {runner?.runnerName}
                                                                            {
                                                                                odds?.team?.selectionId == runner?.selectionId && (odds?.back || odds?.lay)
                                                                                    ?
                                                                                    <span className={odds?.back ? "text-success" : "text-danger"}><br />
                                                                                        {odds?.back ? "+" + odds?.back : ""}
                                                                                        {odds?.lay ? "-" + odds?.lay : ""}
                                                                                    </span>
                                                                                    :
                                                                                    odds?.team?.selectionId == data?.runners?.[1]?.selectionId && <span className={odds?.back ? "text-danger" : "text-success"} ><br />
                                                                                        {odds?.back ? "-" + Number(secondOdd) : ""}
                                                                                        {odds?.lay ? "+" + Number(secondOdd) : ""}
                                                                                    </span>
                                                                            }
                                                                        </b>
                                                                    </div>
                                                                    <div className='col-7 d-flex justify-content-end' >
                                                                        <div
                                                                            className={`bg-info me_0-25 d-flex justify-content-center w-25 fs_12 fw_600 p-2 rounded-4 porition-relative ${runner?.back?.[0]?.price === 0 ? 'disabled' : ''}`}
                                                                            style={{
                                                                                ...(runner?.back?.[0]?.price === 0 && { opacity: 0.5, cursor: "not-allowed" }),
                                                                            }}
                                                                            onClick={() => {
                                                                                // Add a check to prevent the onClick action if runner?.back?.[0]?.price is 0
                                                                                if (runner?.back?.[0]?.price !== 0) {
                                                                                    placeBet3(
                                                                                        runner?.back?.[0]?.price,
                                                                                        'blue',
                                                                                        data,
                                                                                        runner,
                                                                                        bookmakerData,
                                                                                        "0"
                                                                                    );
                                                                                }
                                                                            }}
                                                                        >
                                                                            <b>
                                                                                {runner?.back?.[0]?.price || 0}
                                                                            </b>
                                                                            {/* <small style={{ bottom: 3 }} className="position-absolute fw_600 text-primary">{runner?.back?.[0]?.size}</small> */}
                                                                        </div>

                                                                        <div
                                                                            className={`bg-red-card d-flex justify-content-center w-25 fs_12 fw_600 p-2 position-relative ${runner?.lay?.[0]?.price === 0 ? 'disabled' : ''}`}
                                                                            style={{
                                                                                ...(runner?.lay?.[0]?.price === 0 && { opacity: 0.5, cursor: "not-allowed" }),
                                                                            }}
                                                                            onClick={() => {
                                                                                // Add a check to prevent the onClick action if runner?.lay?.[0]?.price is 0
                                                                                if (runner?.lay?.[0]?.price !== 0) {
                                                                                    placeBet3(
                                                                                        runner?.lay?.[0]?.price,
                                                                                        'red',
                                                                                        data,
                                                                                        runner,
                                                                                        bookmakerData,
                                                                                        "0"
                                                                                    );
                                                                                }
                                                                            }}
                                                                        >
                                                                            <b>
                                                                                {runner?.lay?.[0]?.price || 0}
                                                                            </b>
                                                                            {/* <small style={{ bottom: 3 }} className="position-absolute fw_600 text-danger">{runner?.lay?.[0]?.size}</small> */}
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            ))
                                                        }
                                                    </>
                                                ))
                                            }

                                            {
                                                true
                                                    ?
                                                    <div className='mx-2' >
                                                        {
                                                            clickState == "bookmaker"
                                                                ?
                                                                <BetSlipCard
                                                                    data={placeBetData}
                                                                    placeBetDataColor={placeBetDataColor}
                                                                    placeBetDataMrId={placeBetDataMrId}
                                                                    matchId={state?.event?.id}
                                                                    placeBetDataTeam={placeBetDataTeam}
                                                                    placeBetDataAllData={placeBetDataAllData}
                                                                    cancellData={cancellData}
                                                                    clickState={clickState}
                                                                    teamNumber={teamNumber}
                                                                    setOdds={setOdds}
                                                                    setSecondOdd={setSecondOdd}
                                                                />
                                                                :
                                                                ""
                                                        }
                                                    </div>
                                                    :
                                                    ""
                                            }

                                        </div>
                                        :
                                        <div className="odd-match-class-name d-flex align-items-center justify-content-center">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>
                                }
                            </div>
                        </>
                        :
                        ""
                }


                {/* FANCY DATA */}
                {
                    fancyData?.length
                        ?
                        <>
                            {/* FANCY DATA */}
                            <div className='bg-white overflow-hidden mb_5rem' >
                                <div className="row odd-match-class-name m-0 p-2 py-3">
                                    <div className="col-5 px-0 d-flex">
                                        <img src={fullstar} className="" />
                                        <span className='ml-2 fs_12 text-white' >Fancy</span>
                                    </div>
                                    <div className="col-7 px-0 d-flex justify-content-end">
                                        <span className='fs_12 mx-3' style={{ color: '#0DC5FF' }}>Yes</span>
                                        <span className='fs_12 mx-3' style={{ color: '#FFB5BD' }}>No</span>
                                    </div>
                                </div>

                                {
                                    fancyData ?
                                        <div className="">
                                            {
                                                fancyData?.map((data, index) => (
                                                    <>
                                                        <div className='position-relative row shadow-sm fs_12 text-start px-1 py-1' >
                                                            <div className='col-5 d-flex align-items-center' >
                                                                <b className='px-2'>
                                                                    {data?.RunnerName}
                                                                    {
                                                                        odds?.SelectionId == data?.SelectionId && (odds?.BackPrice1 || odds?.LayPrice1)
                                                                            ?
                                                                            <span className={odds?.BackPrice1 ? "text-success" : "text-danger"}><br />
                                                                                {odds?.BackPrice1 ? "+" + odds?.BackPrice1 : ""}
                                                                                {odds?.LayPrice1 ? "-" + odds?.LayPrice1 : ""}
                                                                            </span>
                                                                            :
                                                                            odds?.SelectionId == data?.SelectionId && <span className={odds?.BackPrice1 ? "text-danger" : "text-success"} ><br />
                                                                                {odds?.BackPrice1 ? "-" + Number(secondOdd) : ""}
                                                                                {odds?.LayPrice1 ? "+" + Number(secondOdd) : ""}
                                                                            </span>
                                                                    }
                                                                </b>
                                                            </div>
                                                            <div className='col-7 d-flex justify-content-end' >
                                                                <div className='bg-info me_0-25 d-flex position-relative justify-content-center w-25 fs_12 fw_600 p-2 pb-3 rounded-4' onClick={() => placeBet2(
                                                                    data?.BackPrice1,
                                                                    data?.BackSize1,
                                                                    'blue',
                                                                    data?.matchId,
                                                                    data,
                                                                    fancyData,
                                                                    "0"
                                                                )} >
                                                                    <b>
                                                                        {data?.BackPrice1 ? data?.BackPrice1 : " "}
                                                                    </b>
                                                                    <small style={{ bottom: 3 }} className="position-absolute fw_600 text-primary">{data?.BackSize1}</small>
                                                                </div>
                                                                <div className='bg-red-card d-flex position-relative justify-content-center w-25 fs_12 fw_600 p-2 pb-3' onClick={() => placeBet2(
                                                                    data?.LayPrice1,
                                                                    data?.LaySize1,
                                                                    'red',
                                                                    data?.matchId,
                                                                    data,
                                                                    fancyData,
                                                                    "0"
                                                                )}>
                                                                    <b>
                                                                        {data?.LayPrice1 ? data?.LayPrice1 : " "}
                                                                    </b>
                                                                    <small style={{ bottom: 3 }} className="position-absolute fw_600 text-danger">{data?.LaySize1}</small>
                                                                </div>
                                                            </div>
                                                            {
                                                                data?.GameStatus === "SUSPENDED" || data?.GameStatus === "Ball Running"
                                                                    ?
                                                                    <Suspended gameStatus={data?.GameStatus || "sd"} />
                                                                    :
                                                                    ""
                                                            }
                                                        </div>

                                                        {
                                                            selectedFancy?._id === data?._id
                                                                ?
                                                                <div className='mx-2' >
                                                                    {
                                                                        clickState == "fancy"
                                                                            ?
                                                                            <BetSlipCard
                                                                                data={placeBetData}
                                                                                placeBetSize={placeBetSize}
                                                                                placeBetDataColor={placeBetDataColor}
                                                                                placeBetDataMrId={placeBetDataMrId}
                                                                                placeBetDataTeam={placeBetDataTeam}
                                                                                placeBetDataAllData={placeBetDataAllData}
                                                                                cancellData={cancellData}
                                                                                clickState={clickState}
                                                                                teamNumber={teamNumber}
                                                                                setOdds={setOdds}
                                                                                setSecondOdd={setSecondOdd}
                                                                            />
                                                                            :
                                                                            ""
                                                                    }
                                                                </div>
                                                                :
                                                                ""
                                                        }
                                                        {/* <div className='row shadow-sm fs_12 text-start px-1 py-1' >
                                                        <div className='col-5 d-flex align-items-center' >
                                                            <b className='px-2'>
                                                                {data?.runners?.[1]?.runnerName?.length <= 16 ? data?.runners?.[1]?.runnerName : data?.runners?.[1]?.runnerName?.slice(0, 15) + "..."}
                                                                {
                                                                    odds?.team?.selectionId == data?.runners?.[1]?.selectionId && (odds?.back || odds?.lay) 
                                                                    ? 
                                                                    <span className={odds?.back ? "text-success" : "text-danger"}><br />
                                                                        {odds?.back ? "+"+odds?.back : ""}
                                                                        {odds?.lay ? "-"+odds?.lay : ""}
                                                                    </span> 
                                                                    : 
                                                                    odds?.team?.selectionId == data?.runners?.[0]?.selectionId && <span className={odds?.back ? "text-danger" : "text-success"} ><br />
                                                                        {odds?.back ? "-"+Number(secondOdd) : ""}
                                                                        {odds?.lay ? "+"+Number(secondOdd) : ""}
                                                                    </span>
                                                                }
                                                            </b>
                                                        </div>
                                                        <div className='col-7 d-flex justify-content-end' >
                                                            <div className='bg-info me_0-25 d-flex justify-content-center w-25 fs_12 fw_600 p-2 rounded-4' onClick={() => placeBet2(
                                                                data?.odds?.[0]?.runners?.[1]?.ex?.availableToBack?.[0]?.price, 
                                                                'blue', 
                                                                data?.odds?.[0], 
                                                                data?.runners?.[1], 
                                                                fancyData,
                                                                "1"
                                                            )} >
                                                                <b>
                                                                    {
                                                                        data?.odds?.[0]?.runners?.[1]?.ex?.availableToBack?.[0]?.price
                                                                        ?
                                                                        data?.odds?.[0]?.runners?.[1]?.ex?.availableToBack?.[0]?.price
                                                                        :
                                                                        " "
                                                                    }
                                                                </b>
                                                            </div>
                                                            <div className='bg-red-card d-flex justify-content-center w-25 fs_12 fw_600 p-2' onClick={() => placeBet2(
                                                                data?.odds?.[0]?.runners?.[1]?.ex?.availableToLay?.[0]?.price, 
                                                                'red', 
                                                                data?.odds?.[0],
                                                                data?.runners?.[1],
                                                                fancyData, 
                                                                "1"
                                                            )} >
                                                                <b>
                                                                    {
                                                                        data?.odds?.[0]?.runners?.[1]?.ex?.availableToLay?.[0]?.price
                                                                        ?
                                                                        data?.odds?.[0]?.runners?.[1]?.ex?.availableToLay?.[0]?.price
                                                                        :
                                                                        " "
                                                                    }
                                                                </b>
                                                            </div>
                                                        </div>
                                                    </div> */}
                                                    </>
                                                ))
                                            }
                                        </div>
                                        :
                                        <div className="odd-match-class-name d-flex align-items-center justify-content-center">
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only"></span>
                                            </div>
                                        </div>
                                }
                            </div>
                        </>
                        :
                        ""
                }

            </div>
            <MobileFooter />
        </div>
    )
}

export default HomePageMobile