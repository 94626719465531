import React from 'react';
import { useState } from 'react';
import PrimaryNavbar from '../../components/PrimaryNavbar'
import ArrowLeft from "../../assets/arrow_left.svg"
import CleanderIcon from "../../assets/cleander_icon.svg"
import DownArrow from "../../assets/down_arrow.svg"
import { useNavigate } from 'react-router-dom'
import MobileFooter from '../../components/MobileFooter';
import MobileSideBar from '../../components/MobileSideBar/MobileSideBar';
import { useEffect } from 'react';
import axios from 'axios';
import baseUrl from '../../services/baseUrl';
import { profitLoss } from '../../services/api';

const AccountStatement = () => {
    const navigate = useNavigate()
    const [btnState, setBtnState] = useState(1);
    const [profitList, setProfitList] = useState({ data: [], loader: false})
    const [filterStartDate, setFilterStartDate] = useState("")
    const [filterEndDate, setFilterEndDate] = useState("")

    const createStEnDate = () => {
        let dtStr = "";
        if (filterStartDate.length !== 0 && filterEndDate.length !== 0) {
            dtStr = `&dateFrom=${filterStartDate}&dateTo=${filterEndDate}`;
        } else if (filterStartDate.length !== 0 && filterEndDate.length === 0) {
            dtStr = `&dateFrom=${filterStartDate}`;
        } else if (filterStartDate.length === 0 && filterEndDate.length !== 0) {
            dtStr = `&dateTo=${filterEndDate}`;
        } else {
            dtStr = "";
        }
        return dtStr;
    };

    const handleGetProfitList = async () => {
        setProfitList({ ...profitList, loader: true })
        const user = JSON.parse(localStorage.getItem("betUser"));
        const headers = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": user?.token
            }
        }
        try {
            const dateStr = createStEnDate()
            const responce = await axios.get(baseUrl + profitLoss + "?" + dateStr, headers)
            if (responce.status == 200) {
                setProfitList({ ...profitList, data: responce?.data?.data, loader: false })
            }

        } catch (error) {
            setProfitList({ ...profitList, loader: false })
        }
    }


    const handleBtnState = (e) => {
        setBtnState(parseInt(e.target.value))
    }

    useEffect(() => {
        handleGetProfitList()
    }, [filterEndDate, filterStartDate])
    
    return (
        <div>
            <PrimaryNavbar />
            <div className="position-relative">
                <MobileSideBar />
            </div>
            <div className="container py-3 accountStatementBG">
                <div className="row mb-3 text-white">
                    <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
                        <img src={ArrowLeft} className="cursor-pointer arrow-icone-size rounded-4" />
                    </div>
                    <div className="col-10">
                        <p className="mb-0 currentbets_title fw_700 text-center">
                            Account Statement
                        </p>
                    </div>
                    <div className="col-1"></div>
                </div>
                <div className='row mb-2 mx-0 mb-3'>
                    <div className='col-12 d-flex m-0 p-0'>
                        <div className="col-5 m-0 p-0 pe-2">
                            <button value={1} onClick={handleBtnState} className={`border-0 w-100 btn btn-light rounded-6 px-4 fw_700 fs_14 lh_14 shadow-none h-42p ${btnState === 1 ? "btn-warning btn_commn_yellow_1" : null} `}>Betting P/L</button>
                        </div>
                        {/* <div className="col-5 m-0 p-0 pe-2">
                            <button value={2} onClick={handleBtnState} className={`border-0 w-100 btn btn-light rounded-6 px-4 fw_700 fs_14 lh_14 shadow-none h-42p  ${btnState === 2 ? "btn-warning btn_commn_yellow_1" : null}`}>Settling Statement</button>
                        </div>
                        <div className="col-2 m-0 p-0">
                            <button value={3} onClick={handleBtnState} className={`border-0 w-100 btn btn-light rounded-6 px-4 fw_700 fs_14 lh_14 shadow-none h-42p  ${btnState === 3 ? "btn-warning btn_commn_yellow_1" : null}`}>All</button>
                        </div> */}
                    </div>
                </div>
                <div className='row mb-2 mx-0 mb-3'>
                    <div className='col-12 d-flex m-0 p-0'>
                        <div className="col-4 m-0 p-0 pe-2">
                            <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2 rounded-6'>
                                <div className='date_input d-flex align-items-center me-3 rounded-6'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>{filterStartDate ? (new Date(filterStartDate))?.toLocaleDateString() : "MM/DD/YYYY"}</p>
                                </div>
                                <div className='cleander_dropedown down-arrow-radius'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input type="date" onChange={e => setFilterStartDate(e.target.value)} name='date' max={new Date().toISOString().split("T")[0]} className="position-absolute w-18 opacity-0" />
                                </div>
                            </div>
                        </div>
                        <div className="col-4 m-0 p-0 pe-2">
                            <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2 rounded-6'>
                                <div className='date_input d-flex align-items-center me-3 rounded-6'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>{filterEndDate ? (new Date(filterEndDate))?.toLocaleDateString() : "MM/DD/YYYY"}</p>
                                </div>
                                <div className='cleander_dropedown down-arrow-radius'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input type="date" onChange={e => setFilterEndDate(e.target.value)} name='date' max={new Date().toISOString().split("T")[0]} className="position-absolute w-18 opacity-0" />
                                </div>


                            </div>
                        </div>
                        <div className="col-4 m-0 p-0">
                            <div className='start_date_Wrapper d-flex align-items-center'>
                                {/* <div className='date_input d-flex align-items-center me-3'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>01/03/2023</p>
                                </div>
                                <div className='cleander_dropedown'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                </div> */}
                                {/* <select className="fs_14 w-100 p-1 outline-0 shadow-0 h-32">
                                    <option value="Matched">Matched</option>
                                    <option value="saab">Saab</option>
                                    <option value="mercedes">Mercedes</option>
                                    <option value="audi">Audi</option>
                                </select> */}
                            </div>
                        </div>
                    </div>
                </div>

                {
                    profitList?.data?.length != 0 ? profitList?.data?.map((ele, ind) => {
                        return <div className="row rounded-8 overflow-hidden mb-2 mx-0 mb-3">
                            <div className='col-12 d-flex m-0 p-1 fw_700 fs_12 black_gradient text-white d-flex justify-content-center'>
                                { ele.bets?.[0]?.updatedAt.slice(0, 10) ? ele.bets?.[0]?.updatedAt.slice(0, 10) : 'null' }
                            </div>
                            <div className='col-12 m-0 p-0'>
                                <div className="accordion " id={"accordionExample"+ind}>
                                    <div className="accordion-item border-0 rounded-0 border-0 ">
                                        <h2 className="accordion-header border-0 rounded-0 border-0">
                                            <button className="accordion-button profilAndLossAccordian p-2 px-3 d-flex flex-column border-0 rounded-0 collapsed border-0 " type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne"+ind} aria-expanded="false" aria-controls={"collapseOne"+ind}>
                                                <div className="fs_8 w-100 text-white">Match Odds</div>
                                                <div className="fs_12 w-100 text-white">
                                                    {
                                                        ele?.odds?.[0]?.marketType?.[0]?.runners?.[0]?.runnerName && ele?.odds?.[0]?.marketType?.[0]?.runners?.[1]?.runnerName
                                                        ?
                                                        ele?.odds?.[0]?.marketType?.[0]?.runners?.[0]?.runnerName + " V " + ele?.odds?.[0]?.marketType?.[0]?.runners?.[1]?.runnerName
                                                        :
                                                        ""
                                                    }
                                                </div>
                                            </button>
                                        </h2>
                                        <div className="row m-0 py-2 align-items-end ">
                                            <div className='col-6 border-right text-white fs_12 fw_400'>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <p className='mb-0'>Start Time</p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className='mb-0'>: {ele.odds?.[0]?.createdAt ? ele.odds?.[0]?.createdAt?.slice(11, 19) : 'null'}</p>
                                                    </div>
                                                </div>
                                                <div className='row text-white'>
                                                    <div className='col-6'>
                                                        <p className='mb-0'>Settle Time</p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className='mb-0'> :  {ele.bets?.[0]?.updatedAt ? ele.bets?.[0]?.updatedAt?.slice(11, 19) : 'null'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6 text-white fs_12 fw_700 d-flex justify-content-end px-4'>
                                                <span className="row profitLossBg p-1 w-100">
                                                    <span className="col-6 text-yellow_01">Net</span>
                                                    <span className="col-6">: {ele?.netTotal || ele?.netTotal == 0 ? ele?.netTotal : 'null'}</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div id={"collapseOne"+ind} className="accordion-collapse collapse" data-bs-parent={"#accordionExample"+ind}>
                                            <div className="accordion-bodys profitLossBorder p-0">

                                                {
                                                    ele?.bets?.map((ele, ind) => {
                                                        return <div className="row m-0 py-2 align-items-end ">
                                                            <div className='col-6 border-right text-white fs_12 fw_400'>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <p className='mb-0'>Selection</p>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <p className='mb-0'>: <span className='fw_700 text-yellow_01'> {ele?.selectionId ? ele?.selectionId : 'null'}</span></p>
                                                                    </div>
                                                                </div>
                                                                <div className='row text-white'>
                                                                    <div className='col-6'>
                                                                        <p className='mb-0'>Type</p>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <p className='mb-0'><span className="badge badge-pill badge-primary rounded-0">{ele?.type ? ele?.type : 'null'}</span></p>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <p className='mb-0'>Profit/Loss</p>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <p className='mb-0'>: {
                                                                            Object.keys(ele).includes('loss')
                                                                                ?
                                                                                <span className='fw_700 text-red_01'>- {ele?.loss || ele?.loss  == 0 ? ele?.loss : 'null'}</span>
                                                                                :
                                                                                <span className='fw_700 text-green_01'>+ {ele?.profit || ele?.profit == 0 ? ele?.profit : 'null'}</span>
                                                                        }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='row text-white'>
                                                                    <div className='col-6'>
                                                                        <p className='mb-0'>Status</p>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <p className='mb-0'> : {Object.keys(ele)?.includes('loss') ? 'LOST' : 'WON'}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 text-white fs_12 fw_700 d-flex justify-content-end px-4'>
                                                                <span className="row profitLossBg p-1 w-100">
                                                                    <span className="col-6 text-yellow_01">Status</span>
                                                                    {
                                                                        Object.keys(ele)?.includes('loss')
                                                                            ?
                                                                            <span className="col-6 text-red_01">: LOST</span>
                                                                            :
                                                                            <span className="col-6 text-green_01">: WON</span>
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    })
                                                }


                                                <div className="p-3 py-2 profitLossCardBlue d-flex border-succes-5">
                                                    <span className="text-center textAutoAdjust  text-primary">
                                                        <div className="fs_10"><i>Back Subtotal</i></div>
                                                        <div className="fw_600 fs_12">{ele?.back_total || ele?.back_total == 0 ? ele?.back_total : 'null'}</div>
                                                    </span>
                                                    <span className="text-center w-auto">
                                                        <div className="fs_12">+</div>
                                                    </span>
                                                    <span className="text-center textAutoAdjust  text-red_01">
                                                        <div className="fs_10"><i>Lay Subtotal</i></div>
                                                        <div className="fw_600 fs_12">{ele?.lay_total || ele?.lay_total == 0 ? ele?.lay_total : 'null'}</div>
                                                    </span>
                                                    <span className="text-center">
                                                        <div className="fs_12">+</div>
                                                    </span>
                                                    <span className="text-center textAutoAdjust ">
                                                        <div className="fs_10"><i>Commission</i></div>
                                                        <div className="fw_600 fs_12">{ele?.total_commission || ele?.total_commission == 0 ? ele?.total_commission : 'null'}</div>
                                                    </span>
                                                    <span className="text-center">
                                                        <div className="fs_12">=</div>
                                                    </span>
                                                    <span className="text-center textAutoAdjust text-primary">
                                                        <div className="fs_10"><i>Net Total</i></div>
                                                        <div className="fw_600 fs_12">{ele?.netTotal || ele?.netTotal == 0 ? ele?.netTotal : 'null'}</div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                        :
                        <div>
                            {
                                profitList?.data?.length == 0 && profitList?.loader == false
                                    ?
                                    <div className="bet_card_wrapper mb_4rem rounded-8  ">
                                    <div className="row m-0 white_glass py-3 align-items-end d-flex align-items-center justify-content-center py-5 rounded-8">
                                        <span className="text-white fs_12 text-center">No content</span>
                                    </div>
                                </div>
                                    :
                                    ''
                            }
                            {
                                profitList?.loader == true
                                    ?
                                    <div className='text-center'>
                                        <div className="spinner-border text-primary" role="status"></div>
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                }

                {/* <div className="accordion " id="accordionExample">
                    <div className="accordion-item accordian-border mb-3">
                        <h2 className="accordion-header border rounded-6 border-0">
                            <button className="accordion-button profilAndLossAccordian p-2 px-3 d-flex flex-column border-0 rounded-6 collapsed border-0 " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                <div className="fs_8 w-100 text-white">Match Odds</div>
                                <div className="fs_12 w-100 text-white">India Vs Pakistan</div>
                            </button>
                        </h2>
                        <div id="collapseOne" className="accordion-collapse collapse">
                            <div className="accordion-bodys profitLossBorder p-0">
                                <div className="row m-0 white_glass py-3 align-items-end green_btm_border ">
                                    <div className='col-8 border-right text-white fs_12 fw_400'>
                                        <div className='row '>
                                            <div className='col-6'>
                                                <p className='mb-0'>Time</p>
                                            </div>
                                            <div className='col-6'>
                                                <p className='mb-0'>: 05:01:26</p>
                                            </div>
                                        </div>
                                        <div className='row text-white'>
                                            <div className='col-6'>
                                                <p className='mb-0'> Type</p>
                                            </div>
                                            <div className='col-6'>
                                                <p className='mb-0'>: Betting P/L</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p className='mb-0'>Amount</p>
                                            </div>
                                            <div className='col-6 text-red_01'>
                                                <p className='mb-0 fw_700'><span className="text-white fw_400">: </span> +105.00</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4 m-0 p-0 text-white px-2 fs_10 fw_700'>
                                        <span className="profitLossBg p-1 d-block d-flex justify-content-between rounded-6">
                                            <span className="col-6 p-0 text-yellow_01 ">Balance</span>
                                            <span className="col-6 p-0 text-yellow_01 text-end">: 56.69</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="accordion-item accordian-border mb-3">
                        <h2 className="accordion-header border-0 rounded-0 border-0">
                            <button className="accordion-button profilAndLossAccordian p-2 px-3 d-flex flex-column border-0 rounded-0 collapsed border-0 " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                <div className="fs_8 w-100 text-white">Match Odds</div>
                                <div className="fs_12 w-100 text-white">India Vs Pakistan</div>
                            </button>
                        </h2>
                        <div id="collapseTwo" className="accordion-collapse collapse border-0">
                            <div className="accordion-bodys profitLossBorder p-0">
                                <div className="row m-0 white_glass py-3 align-items-end green_btm_border">
                                    <div className='col-8 border-right text-white fs_12 fw_400'>
                                        <div className='row '>
                                            <div className='col-6'>
                                                <p className='mb-0'>Time</p>
                                            </div>
                                            <div className='col-6'>
                                                <p className='mb-0'>: 05:01:26</p>
                                            </div>
                                        </div>
                                        <div className='row text-white'>
                                            <div className='col-6'>
                                                <p className='mb-0'> Type</p>
                                            </div>
                                            <div className='col-6'>
                                                <p className='mb-0'>: Betting P/L</p>
                                            </div>
                                        </div>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p className='mb-0'>Amount</p>
                                            </div>
                                            <div className='col-6 text-red_01'>
                                                <p className='mb-0 fw_700'><span className="text-white fw_400">: </span> +105.00</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-4 m-0 p-0 text-white px-2 fs_10 fw_700 rounded-6' >
                                        <span className="profitLossBg p-1 d-block d-flex justify-content-between rounded-6">
                                            <span className="col-6 p-0 text-yellow_01 ">Balance</span>
                                            <span className="col-6 p-0 text-yellow_01 text-end">: 56.69</span>
                                        </span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

            </div>
            <MobileFooter />
        </div>
    );
};

export default AccountStatement;