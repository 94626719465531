import React, { useState, useEffect } from "react";
import addbank from "../../assets/images/addBank.png";
import viewbank from "../../assets/images/viewbank.png";
import axios from "axios";
import { walletBalance } from "../../services/api";
import baseUrl from "../../services/baseUrl";
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
const QuickWithdraw = () => {
  const [withdrawlAmount, setWithdrawlAmount] = useState(0)
  const [amountError, setAmountError] = useState('')
  const [bank, setBank] = useState('')
  const [bankError, setBankError] = useState(0)
  const [bankAccounts, setBankAccounts] = useState('')
  const [balanceData, setBalanceData] = useState();
  const [loader, setLoader] = useState(false)

  const createWithdrawRequest = async (e) => {
    e.preventDefault()
    if (handleValidation()) {
      setLoader(true)
      const Token = JSON.parse(localStorage.getItem("betUser"));
      const headers = {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": Token?.token,
        },
      };
      try {
        const response = await axios.post(baseUrl + '/user/withdrawal_request', { 'accountNumber': parseInt(bank), 'amount': withdrawlAmount }, headers);
        if (response.status == 200) {
          toast.success('Withdraw Requested', { position: "top-center", autoClose: 2000 });
          setLoader(false)
          setAmountError('')
          setBank('')
          setBankError('')
          setWithdrawlAmount('')
        }
      }
      catch (e) {
        toast.error(e.response?.data?.message, { position: "top-center", autoClose: 2000 });
        setLoader(false)
        setAmountError('')
        setBank('')
        setBankError('')
        setWithdrawlAmount('')
      }
    }
  }

  function handleValidation() {
    let amount = withdrawlAmount
    const regex = new RegExp('^[0-9]*[1-9][0-9]*$');
    if (regex.test(amount)) {
      console.log("AMOUNT TRUE")
      if (amount > 100000) {
        setAmountError('Max Withdrawl Amount:1,00,000')
        return false
      }
    }
    else {
      setAmountError('enter Valid emount')
      return false
    }
    if (bank == '') {
      setBankError("select bank")
      return false
    }
    return true
  }


  const getWalletBalance = async () => {
    try {
      // setLoader(true);
      const Token = JSON.parse(localStorage.getItem("betUser"));
      const headers = {
        headers: {
          // "Content-Type": "application/json",
          "x-access-token": Token?.token,
        },
      };
      const responce = await axios.get(baseUrl + walletBalance, headers);
      if (responce.status == 200) {
        // setLoader(false);
        console.log(responce.data.data[0].wallet_amount, "responce");
        setBalanceData(responce.data.data[0].wallet_amount);
      }
    } catch (error) {
      // setLoader(false);
    }
  };

  const getBanks = async () => {
    try {
      // setLoader(true);
      const Token = JSON.parse(localStorage.getItem("betUser"));
      const headers = {
        headers: {
          // "Content-Type": "application/json",
          "x-access-token": Token?.token,
        },
      };
      const responce = await axios.get(baseUrl + '/user', headers);
      if (responce.status == 200) {
        console.log(responce.data.data.bankDetails, "response");
        setBankAccounts(responce.data.data.bankDetails);
      }
    } catch (error) {
      // setLoader(false);
    }
  };

  useEffect(() => {
    getWalletBalance()
    getBanks()
  }, [])
  return (
    <div className="d-flex" >
      <div className="card m-3 col-7">
        <ToastContainer />
        <div className="bansk-holder-one">
          <div className="">
            {/* <input type='text'
            className="form-control"
            alue={withdrawlAmount}
            placeholder="Enter withdrawal amount"
            onChange={(e) => {
              setAmountError('')
              setWithdrawlAmount(e.target.value)
            }} />
          <div>
            <span className="text-danger">
              {amountError ? amountError : null}
            </span>
          </div>
          <select onChange={(e) => {
            setBankError("")
            console.log("BANK DATA", typeof parseInt(e.target.value))
            setBank(e.target.value)
          }}>
            <option value={""} disabled selected>Select Banks</option>
            {
              bankAccounts ? (
                bankAccounts.map(account => (
                  <option value={account.accountNumber}>{account.bankname}</option>
                ))
              ) : null
            }
          </select>
          <div>
            <span className="text-danger">
              {bankError ? bankError : null}
            </span>
          </div>
          <div className="actions-buttons-submit">
            <button className="btns-2" onClick={() => createWithdrawRequest()}>Submit </button>
            <button className="btns-1" onClick={() => {
              setAmountError('')
              setBank('')
              setWithdrawlAmount('')
            }}>Reset</button>
          </div> */}

            <div className="mb-3" >
              <form onSubmit={createWithdrawRequest} >
                <div className="form-group text-start">
                  <input
                    type="number"
                    className={`form-control`}
                    id="exampleInputEmail1"
                    aria-describedby="emailHelp"
                    placeholder="enter withdrawal amount"
                    onChange={(e) => {
                      setAmountError('')
                      setWithdrawlAmount(e.target.value)
                    }}
                    value={withdrawlAmount}
                  />
                  <small className="text-danger" > {amountError ? amountError : null}</small>
                </div>
                <div className="form-group text-start">
                  <select
                    className={`form-control`}
                    onChange={(e) => {
                      setBankError("")
                      setBank(e.target.value)
                    }}
                    id="exampleFormControlSelect1">
                    <option value='' >please selct</option>
                    {
                      bankAccounts && bankAccounts.map(account => (
                        <option value={account.accountNumber}>{account.bankname}</option>
                      ))
                    }
                  </select>
                  <small className="text-danger" >{bankError ? bankError : null}</small>
                </div>

                <div className="d-flex justify-content-around">
                  <button type="submit" className="btn btn-primary">Submit {" "}
                    {
                      loader && <div className="spinner-border spinner-border-sm" role="status"></div>
                    }
                  </button>
                  <button type="reset" className="btn btn-danger" onClick={() => {
                    setAmountError('')
                    setBank('')
                    setBankError('')
                    setWithdrawlAmount('')
                  }}>Reset</button>
                </div>
              </form>
            </div>

          </div>
        </div>

        {/* <div className="bansk-holder-two bg-danger">
        <div className="two-banks-div">
          <div>
            <div onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} className="text-decoration-underline">
              Go to Profile <span>&#62;</span> Bank Details to add an account
            </div>
          </div>
        </div>
      </div> */}
      </div>
      <div className="bansk-holder-two col-4">
        {/* <div className="two-banks-div"> */}
        <div>
          <div onClick={() => window.scrollTo({ top: 0, left: 0, behavior: 'smooth' })} className="text-decoration-underline">
            Go to Profile <span>&#62;</span> Bank Details to add an account
          </div>
        </div>
        {/* </div> */}
      </div>
    </div>
  );
};

export default QuickWithdraw;
