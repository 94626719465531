import React from 'react';
import '../DepositMobile/DepositMobile.css'
import { useRef, useState, useEffect } from 'react'
import PrimaryNavbar from "../../components/PrimaryNavbar";
import overviewhome from "../../assets/overview-home.svg"
import miniUPI from "../../assets/miniUPI.svg"
import miniBank from "../../assets/miniBank.svg"
import miniCrypto from "../../assets/miniCrypto.svg"
import UpiIcon from "../../assets/upi.svg"
import FastBankIcon from "../../assets/fastBankIcon.svg"
import CryptoIcon from "../../assets/cryptoIcon.svg"
import UpiIconLarge from "../../assets/upi2.svg"
import cryptoIconLarge from "../../assets/cryptoIconLarge.svg"
import BankIcon from "../../assets/bank_icon.svg"
import QRIcon from "../../assets/QR.svg"
import UploadIcon from "../../assets/upload.svg"
import CopyIcon from "../../assets/images/profileDashboard/copy.svg"
import ArrowLeft from "../../assets/arrow_left.svg"
import axios from 'axios';
import { getBankDetails, userPayment } from '../../services/api';
import baseUrl from '../../services/baseUrl';
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { useNavigate } from 'react-router-dom';
import MobileFooter from '../../components/MobileFooter';
import hdfc from '../../assets/images/hdfc.png'
import MobileSideBar from '../../components/MobileSideBar/MobileSideBar';
import QRCode from "react-qr-code";

const DepositMobile = () => {
    const [depositData, setDepositData] = useState({ amount: "", utr: "", loader: false, image: '', flipBank: true });
    const [depositDataError, setDepositDataError] = useState({ amount: "", utr: "", image: '' });
    const [show, setShow] = useState(false)
    const [accountDetails, setAccountDetails] = useState({ qrImage: "", loader: false })
    const [paymentType, setPaymentType] = useState("REGULAR_UPI")
    const [upiPayment, setUpiPayment] = useState(1);
    const [upiFastPayment, setUpifastPayment] = useState(1);
    const [cryptoPayment, setCryptoPayment] = useState(1);
    const [cryptoQr, setShowCryptoQr] = useState(false)



    const navigate = useNavigate()
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handleChangeUpiBg = (data) => {
        setUpiPayment(data)
    }
    const handleChangeUpiBg1 = (data) => {
        setUpifastPayment(data)
    }

    const handleChange = (e) => {
        setDepositData({ ...depositData, [e.target.name]: e.target.value });
        setDepositDataError({ ...depositDataError, [e.target.name]: null });
    };

    const fileInputRef = useRef(null);
    const handleUploadButtonClick = () => {
        fileInputRef.current.click();
    };

    const handleValidation = () => {
        const { amount, utr, image } = depositData;
        var ramountReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const newErrors = {};
        if (!amount) {
            newErrors.amount = "enter amonut";
        }
        if (!utr) {
            newErrors.utr = "enter utr number";
        }
        if (!image) {
            newErrors.image = "please upload";
        }
        return newErrors;
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        const handleValidationObject = handleValidation();
        if (Object.keys(handleValidationObject).length > 0) {
            setDepositDataError(handleValidationObject);
        } else {
            setDepositData({ ...depositData, loader: true })
            try {
                const formData = new FormData()
                formData.append('amount', depositData.amount)
                formData.append('utr', depositData.utr)
                formData.append('image', depositData.image)
                const Token = JSON.parse(localStorage.getItem('betUser'))
                const headers = {
                    headers: {
                        // "Content-Type": "application/json",
                        "x-access-token": Token?.token
                    }
                }
                let data = await axios.post(baseUrl + userPayment, formData, headers);
                if (data.status == 200) {
                    setDepositData({ ...depositData, loader: false, image: '', amount: "", utr: "", })
                    toast.success('Deposit Success', { position: "top-center", autoClose: 2000 });
                }
            } catch (error) {
                toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
                setDepositData({ ...depositData, loader: false, image: '', amount: "", utr: "", })
            }
        }
    };

    const handleReset = () => {
        setDepositData({ ...depositData, loader: false, image: '', amount: "", utr: "", })
    }

    const handleGetAccountDetails = async () => {
        setAccountDetails({ ...accountDetails, loader: true })
        try {
            const Token = JSON.parse(localStorage.getItem('betUser'))
                const headers = {
                    headers: {
                        // "Content-Type": "application/json",
                        "x-access-token": Token?.token
                    }
                }
            const responce = await axios.get(baseUrl + getBankDetails,headers)
            if (responce.status == 200) {
                setAccountDetails({ ...accountDetails, qrImage: responce.data.data, loader: false })
            }
        } catch (error) {
            setAccountDetails({ ...accountDetails, loader: false })
        }
    }

    useEffect(() => {
        handleGetAccountDetails()
    }, [])


    // console.log(accountDetails?.qrImage[0]?.account_holder, 'accountDetails');
    // console.log(accountDetails?.qrImage[0]?.upi, 'accountDetails');

    const handleCopy = (mes) => {
        navigator.clipboard.writeText(mes);
        toast.success('Text Copied', { position: "top-center", autoClose: 2000, });
    }


    return (
        <div>
            <PrimaryNavbar />
            <div className="position-relative">
                <MobileSideBar />
            </div>
            <ToastContainer />
            <div className="container py-3 depositeBG">
                <div className='row mb-3'>
                    <div className='col-1' onClick={() => navigate(-1)}>
                        <img src={ArrowLeft} className="arrow-icone-size rounded-4" />
                    </div>
                    <div className='col-10 text-center'>
                        <p className='mb-0 currentbets_title'>Deposit</p>
                    </div>
                    <div className='col-1'></div>
                </div>
                <div className="container green_glass py-3 mb-3 border-success rounded-8">
                    <div className="row mb-2 m-0 p-0 align-items-center">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">Deposit Method</span>
                    </div>
                    <div className="row">
                        <div className="col-4 pe-2 m-0 pb-2" onClick={() => setPaymentType("REGULAR_UPI")}>
                            <div className={`${paymentType === "REGULAR_UPI" ? "buttonGradientBackground border-yellow_01 fw_700" : "black_gradient_background border-white_01"} rounded-6 text-white p-3 h-100 py-3 mb-2`}>
                                <div className="d-flex flex-column justify-content-between align-items-center">
                                    <img className="mt-2" width={60} src={UpiIcon} />
                                    <span className="mt-2 fs_14 text-center">Fast UPI</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 ps-0 pe-2 pb-2" onClick={() => setPaymentType("FAST_BANK")}>
                            <div className={`${paymentType === "FAST_BANK" ? "buttonGradientBackground border-yellow_01 fw_700" : "black_gradient_background border-white_01"} rounded-6 text-white p-3 h-100 py-3 mb-2`}>
                                <div className="d-flex flex-column justify-content-between align-items-center">
                                    <img className="mt-2" width={60} src={FastBankIcon} />
                                    <span className="mt-2 fs_14 text-center">Fast Bank</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-4 ps-0 pb-2" onClick={() => setPaymentType("FAST_CRYPTO")}>
                            <div className={`${paymentType === "FAST_CRYPTO" ? "buttonGradientBackground border-yellow_01 fw_700" : "black_gradient_background border-white_01"} rounded-6 text-white p-3 h-100 py-3 mb-2`}>
                                <div className="d-flex flex-column justify-content-between align-items-center">
                                    <img className="mt-2" width={60} src={CryptoIcon} />
                                    <span className="mt-2 fs_14 text-center">Fast Crypto</span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="row">
                        {
                            paymentType === "REGULAR_UPI"
                            ?
                            <>
                                <div onClick={() => handleChangeUpiBg(1)}  className="col-12 mb-2">
                                    <div className={`bg-white py-2 rounded-6 px-4 position-relative ${upiPayment === 1 ? "upi-pay-selected" : null}`}>
                                        <span className="d-flex align-items-center">
                                            <span className="fs_12 me-1">1</span> <img src={miniUPI} height="15" />
                                        </span>
                                        {/* <input type="radio" name="fastUPI" className="position-absolute" style={{right: "15px", top: "15px"}} /> */}
                                        <div className="d-flex align-items justify-content-between  mb-2">
                                            {
                                                accountDetails.loader === true
                                                ?
                                                <div className="spinner-border spinner-border-sm" role="status"></div>
                                                :
                                                <div className="mt-2 fw_600 fs_12 d-flex align-items-center justify-content-between">
                                                    <>
                                                        <span className="fs_16">{accountDetails?.qrImage[0]?.upi}</span>
                                                        <img src={CopyIcon} width="15" height="15" onClick={() => handleCopy(accountDetails?.qrImage[0]?.upi)} className="img-fluid mx-2" />
                                                    </>
                                                </div>
                                            }
                                            {/* <div className=""><img src={CopyIcon} onClick={() => handleCopy(accountDetails?.qrImage[0]?.account_holder+"@"+accountDetails?.qrImage[0]?.upi)} className="img-fluid copy-icone-size" /></div> */}
                                        </div>
                                        <div className="d-flex align-items-end justify-content-end mb-4">
                                            <div className="" onClick={handleShow} >
                                                {
                                                    accountDetails?.qrImage[0]?.upi
                                                    ?
                                                    <img src={accountDetails?.qrImage[0]?.qr_image} width="50" height="50" />
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* <div onClick={() => handleChangeUpiBg(2)}  className="col-12 mb-2">
                                    <div className={`bg-white py-2 rounded-6 px-4 position-relative ${upiPayment === 2 ? "upi-pay-selected" : null}`}>
                                        <span className="d-flex align-items-center">
                                            <span className="fs_12 me-1">2</span> <img src={miniUPI} height="15" />
                                        </span>
                                        <div className="d-flex align-items justify-content-between  mb-2">
                                            {
                                                accountDetails.loader === true
                                                    ?
                                                    <div className="spinner-border spinner-border-sm" role="status"></div>
                                                    :
                                                    <div className="mt-2 fw_600 fs_12 text-capitalize d-flex align-items-center justify-content-between">
                                                        <>
                                                            <span className="fs_16">{accountDetails?.qrImage[0]?.upi}</span>
                                                            <img src={CopyIcon} width="15" height="15" onClick={() => handleCopy(accountDetails?.qrImage[0]?.upi)} className="img-fluid mx-2" />
                                                        </>
                                                    </div>
                                            }
                                        </div>
                                        <div className="d-flex align-items-end justify-content-end mb-4">
                                            <div className="" onClick={handleShow} >
                                                {
                                                    accountDetails?.qrImage[0]?.upi
                                                    ?
                                                    <QRCode size={50} value={accountDetails?.qrImage[0]?.upi}/>
                                                    :
                                                    ""
                                                }
                                            </div>
                                        </div>
                                    </div>
                                </div> */}
                            </>
                            :
                            ""
                        }
                        {
                            paymentType === "FAST_BANK"
                            ?
                            <>
                                <div onClick={() => handleChangeUpiBg1(1)}  className="col-12 mb-2">
                                    <div className={`bg-white py-2 rounded-6 px-4 position-relative ${upiFastPayment === 1 ? "upi-pay-selecteded" : null}`}>
                                        <span className="d-flex align-items-center">
                                            <span className="fs_12 me-1">1</span> <img src={miniBank} height="12" />
                                        </span>
                                        <img src={hdfc} className="img-fluid position-absolute" width='40px' style={{ right: "15px", bottom: "15px" }} />
                                        <div className="d-flex align-items justify-content-between  mb-2">
                                            {
                                                accountDetails.loader == true ?
                                                    <div className="spinner-border spinner-border-sm" role="status"></div>
                                                    :
                                                    <div>
                                                        <div className='d-flex p-0' >
                                                            <div className='' >
                                                                <div className="mt-2  fw_600 fs_12 text-capitalize">A/C Name</div>
                                                                <div className="mt-2  fw_600 fs_12 text-capitalize">A/C Number</div>
                                                                <div className="mt-2  fw_600 fs_12 text-capitalize">IFSC</div>
                                                                <div className="mt-2  fw_600 fs_12 text-capitalize">Bank</div>
                                                            </div>
                                                            <div className='col' >
                                                                <div className="mt-2  fw_600 fs_12 text-capitalize">:</div>
                                                                <div className="mt-2  fw_600 fs_12 text-capitalize">:</div>
                                                                <div className="mt-2  fw_600 fs_12 text-capitalize">:</div>
                                                                <div className="mt-2  fw_600 fs_12 text-capitalize">:</div>
                                                            </div>
                                                            <div className='' >
                                                                <div className="mt-2  fw_600 fs_12 text-capitalize">
                                                                    {accountDetails?.qrImage[0]?.account_holder}
                                                                    <img src={CopyIcon} onClick={() => handleCopy(accountDetails?.qrImage[0]?.account_holder)} className="img-fluid mx-2" />
                                                                </div>
                                                                <div className="mt-2  fw_600 fs_12 text-capitalize">
                                                                    {accountDetails?.qrImage[0]?.account_number}
                                                                    <img src={CopyIcon} onClick={() => handleCopy(accountDetails?.qrImage[0]?.account_number)} className="img-fluid mx-2" />
                                                                </div>
                                                                <div className="mt-2  fw_600 fs_12 text-capitalize">
                                                                    {accountDetails?.qrImage[0]?.ifsc}
                                                                    <img src={CopyIcon} onClick={() => handleCopy(accountDetails?.qrImage[0]?.ifsc)} className="img-fluid mx-2" />
                                                                </div>
                                                                <div className="mt-2  fw_600 fs_12 text-capitalize">
                                                                    {accountDetails?.qrImage[0]?.bank_name}
                                                                    <img src={CopyIcon} onClick={() => handleCopy(accountDetails?.qrImage[0]?.bank_name)} className="img-fluid mx-2" />
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                                {/* <div onClick={() => handleChangeUpiBg1(2)}  className="col-12 mb-2">
                                <div className={`bg-white py-2 rounded-6 px-4 position-relative ${upiFastPayment === 2 ? "upi-pay-selecteded" : null}`}>
                                    <span className="d-flex align-items-center">
                                        <span className="fs_12 me-1">2</span> <img src={miniBank} height="12" />
                                    </span>
                                    <img src={hdfc} className="img-fluid position-absolute" width='40px' style={{right: "15px", bottom: "15px"}} />
                                    <div className="d-flex align-items justify-content-between  mb-2">
                                        {
                                            accountDetails.loader == true ?
                                            <div className="spinner-border spinner-border-sm" role="status"></div>
                                            :
                                            <div>
                                                <div className='d-flex p-0' >
                                                    <div className='' >
                                                        <div className="mt-2  fw_600 fs_12 text-capitalize">A/C Name</div>
                                                        <div className="mt-2  fw_600 fs_12 text-capitalize">A/C Number</div>
                                                        <div className="mt-2  fw_600 fs_12 text-capitalize">IFSC</div>
                                                        <div className="mt-2  fw_600 fs_12 text-capitalize">Bank</div>
                                                    </div>
                                                    <div className='col' >
                                                        <div className="mt-2  fw_600 fs_12 text-capitalize">:</div>
                                                        <div className="mt-2  fw_600 fs_12 text-capitalize">:</div>
                                                        <div className="mt-2  fw_600 fs_12 text-capitalize">:</div>
                                                        <div className="mt-2  fw_600 fs_12 text-capitalize">:</div>
                                                    </div>
                                                    <div className='' >
                                                        <div className="mt-2  fw_600 fs_12 text-capitalize">
                                                            {accountDetails?.qrImage[0]?.account_holder}
                                                            <img src={CopyIcon} onClick={() => handleCopy(accountDetails?.qrImage[0]?.account_holder)} className="img-fluid mx-2" />
                                                        </div>
                                                        <div className="mt-2  fw_600 fs_12 text-capitalize">
                                                            {accountDetails?.qrImage[0]?.account_number}
                                                            <img src={CopyIcon} onClick={() => handleCopy(accountDetails?.qrImage[0]?.account_number)} className="img-fluid mx-2" />
                                                        </div>
                                                        <div className="mt-2  fw_600 fs_12 text-capitalize">
                                                            {accountDetails?.qrImage[0]?.ifsc}
                                                            <img src={CopyIcon} onClick={() => handleCopy(accountDetails?.qrImage[0]?.ifsc)} className="img-fluid mx-2" />
                                                        </div>
                                                        <div className="mt-2  fw_600 fs_12 text-capitalize">
                                                            {accountDetails?.qrImage[0]?.bank_name}
                                                            <img src={CopyIcon} onClick={() => handleCopy(accountDetails?.qrImage[0]?.bank_name)} className="img-fluid mx-2" />
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div> */}
                            </>
                            :
                            ""
                        }
                        {
                            paymentType === "FAST_CRYPTO"
                            ?
                            <>
                                <div onClick={() => setCryptoPayment(1)}  className="col-12 mb-2">
                                    <div className={`bg-white py-2 rounded-6 px-4 position-relative ${cryptoPayment === 1 ? "upi-pay-selected" : null}`}>
                                        <span className="d-flex align-items-center">
                                            <span className="fs_12 me-1">1</span> <img src={miniCrypto} height="15" />
                                        </span>
                                        {/* <input type="radio" name="fastUPI" className="position-absolute" style={{right: "15px", top: "15px"}} /> */}
                                        <div className="d-flex align-items justify-content-between  mb-2">
                                            <div className="mt-2 fw_600 fs_12 text-capitalize d-flex align-items-center justify-content-between">
                                                <>
                                                    <span className="fs_16">{accountDetails?.qrImage[0]?.cryptoId}</span>
                                                    <img src={CopyIcon} width="15" height="15" onClick={() => handleCopy(accountDetails?.qrImage[0]?.cryptoId)} className="img-fluid mx-2" />
                                                </>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-end justify-content-end mb-4">
                                            <div className="" onClick={() => setShowCryptoQr(true)} ><QRCode size={50} value={accountDetails?.qrImage[0]?.cryptoId || ""} /></div>
                                        </div>
                                    </div>
                                </div>
                            </>
                            :
                            ""
                        }
                    </div>
                </div>
                
                <div className="container white_glass py-3 border-white border-succes-5 mb_4rem rounded-8">
                    <div className="row m-0 mb-3 p-0 align-items-center">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">Upload Payment Reciept</span>
                    </div>
                    <div className="row mb-3 m-0 p-0 align-items-center">

                        {depositData.image == "" &&
                            <div className="col-12 px-0 text-center" onClick={handleUploadButtonClick} >
                                <div className={`${depositDataError?.image ? 'attachmnet_wrappper_red' : 'attachmnet_wrappper'} p-4`}>
                                    <div className="attachment_image_upload">
                                        <label>
                                            <img src={UploadIcon} />
                                        </label>
                                        <input id="file-input" ref={fileInputRef} type="file" onChange={(event) => setDepositData({ ...depositData, image: event.target.files[0] })} />
                                    </div>
                                    <p className="mb-1 fw_600 fs_12 black_01">
                                        Click to upload or drag and drop
                                    </p>
                                    <p className="mb-0 fs_8 fw_400 black_01">(Maximum file size 5MB)</p>
                                </div>
                            </div>
                        }
                        {
                            depositData.image != "" &&
                            <div onClick={handleUploadButtonClick} className="col-12 px-0 text-center"
                            >
                                <input id="file-input" ref={fileInputRef} type="file" onChange={(event) => setDepositData({ ...depositData, image: event.target.files[0] })} style={{ position: 'absolute', top: '-9999px', left: '-9999px' }} />
                                <img src={URL.createObjectURL(depositData?.image)}
                                    style={{ objectFit: 'fill', width: "100%", height: "100%", borderRadius: '5px' }}
                                />
                            </div>
                        }

                    </div>
                    <div className="row mb-3 m-0 p-0 align-items-center">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">Upload Payment Reciept</span>
                    </div>
                    <div className="row m-0 p-0 mt-3">
                        <input type="number" placeholder="Enter Deposit Amount" value={depositData?.amount} name='amount' onChange={handleChange} className={`${depositDataError.amount ? 'border border-2 border-danger' : ''} fs_14 h-42p py-2 rounded-6`} />
                    </div>
                    <div className="row m-0 p-0 mt-3">
                        <input type="alpha" placeholder="UTR Number" minlength="16" maxLength="22" onInput={(e) => { if (e.target.value.length > e.target.maxLength) { e.target.value = e.target.value.slice(0, e.target.maxLength); } }} value={depositData?.utr} name='utr' onChange={handleChange} className={`${depositDataError.utr ? 'border border-2 border-danger' : ''} fs_14 h-42p py-2 rounded-6`} />
                    </div>

                    <div className='row mt-3 mb-3'>
                        <div className='col-12 d-flex'>
                            <button className='btn  btn-warning rounded-6 shadow-none btn_commn_yellow_1 px-4 me-2' onClick={handleSubmit}>Submit
                                {
                                    depositData.loader && (<div className="spinner-border spinner-border-sm" role="status" ></div>)
                                }
                            </button>
                            <button className='btn btn-light rounded-6 px-4 shadow-none' onClick={handleReset} >Cancel</button>
                        </div>
                    </div>
                    <div className="row p-4">
                        <div className="col-12 bg-dark text-white fs_9 fw_400 p-4 note_font_size border border-2 border-light rounded-6" >
                            <ul>
                                <li className='' >PLEASE VERIFY THE PAYMENT DETAILS BEFORE MAKING A BANK TRANSFER DEPOSIT AS THEY MAY CHANGE FROM TIME TO TIME. BETKARO WILL NOT BE RESPONSIBLE FOR ANY TRANSACTION MADE TO AN OLD/ INACTIVE ACCOUNT.</li>
                                <li className=''>IF YOUR DEPOSIT AMOUNT DOES NOT MATCH THE REQUESTED AMOUNT, IT IS CONSIDERED CHEATING AND THE FUNDS WILL BE WITHHELD. EG: IF YOU DEPOSIT ₹500 AND WRITE ₹1000 IN THE DEPOSIT REQUEST, THE DEPOSIT AMOUNT WILL NOT BE CREDITED TO YOUR WALLET OR REVERSED TO YOUR ACCOUNT.</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <Modal show={show} onHide={handleClose}>
                <Modal.Header className='text-center' closeButton>
                    <Modal.Title>QR CODE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center' >
                        {
                            accountDetails.loader == true 
                            ?
                            <div className="spinner-border text-primary" role="status"></div>
                            :
                            <img width={250} height={250} src={accountDetails?.qrImage[0]?.qr_image}/>
                        }
                    </div>
                </Modal.Body>
            </Modal>
            <Modal show={cryptoQr} onHide={() => setShowCryptoQr(false)}>
                <Modal.Header className='text-center' closeButton>
                    <Modal.Title>CRYPTO QR CODE</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className='text-center' >
                        <QRCode size={250} value="qwertyuiopasdfghjklzxcvbnm1234567890" />
                    </div>
                </Modal.Body>
            </Modal>
            <MobileFooter />
        </div >
    );
};

export default DepositMobile;