import React from "react";
import Footer from "../components/Footer";
import MobileFooter from "../components/MobileFooter";
import MobileSideBar from "../components/MobileSideBar/MobileSideBar";
import PrimaryNavbar from "../components/PrimaryNavbar";
import { useNavigate } from "react-router-dom";
import ArrowLeft from "../assets/images/profileDashboard/arrowLeft.svg";

const PrivacyPolicy = () => {
  const navigate = useNavigate();

  return (
    <div>
      <PrimaryNavbar></PrimaryNavbar>
      <div className="position-relative">
        <MobileSideBar />
      </div>
      <div className="terms-and-conditions">
        <div className="main-content-tac">
          <div className="row position-relative" onClick={() => navigate(-1)}>
          <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
              <img src={ArrowLeft} className="cursor-pointer arrow-icone-size rounded-4" />
            </div>
          </div>
          <h2 className="terms-and-cond-heading mt-2">Privacy Policy</h2>
          <p>Last Updated: 09/06/2022</p>
          <p>
            This Privacy Policy Describes Our Policies And Procedures On The
            Collection, Use, And Disclosure Of Your Information When You Use The
            Service And Tells You About Your Privacy Rights And How The Law
            Protects You. We Use Your Personal Data To Provide And Improve The
            Service. By Using The Service, You Agree To The Collection And Use
            Of Information In Accordance With This Privacy Policy. This Privacy
            Policy Has Been Created With The Help Of The Privacy Policy
            Template.
          </p>
          <h2 className="terms-and-cond-heading">
            Interpretation And Definitions
          </h2>
          <p>
            The Words Of Which The Initial Letter Is Capitalised Have Meanings
            Defined Under The Following Conditions. The Following Definitions
            Shall Have The Same Meaning Regardless Of Whether They Appear In
            Singular Or In Plural.
          </p>
          <div className="contentn-bets-terms-and-condition">
            <h2>Definitions</h2>
            <p>For The Purposes Of This Privacy Policy:</p>
            <p>
              Account Means A Unique Account Created For You To Access Our
              Service Or Parts Of Our Service. Company (Referred To As Either
              "The Company", "We", "Us" Or "Our" In This Agreement) Refers To .
              Cookies Are Small Files That Are Placed On Your Computer, Mobile
              Device Or Any Other Device By A Website, Containing The Details Of
              Your Browsing History On That Website Among Its Many Uses Country
              Refers To: Delhi, India Device Means Any Device That Can Access
              The Service Such As A Computer, A Cellphone Or A Digital Tablet.
              Personal Data Is Any Information That Relates To An Identified Or
              Identifiable Individual. Service Refers To The Website. Service
              Provider Means Any Natural Or Legal Person Who Processes The Data
              On Behalf Of The Company. It Refers To Third-Party Companies Or
              Individuals Employed By The Company To Facilitate The Service, To
              Provide The Service On Behalf Of The Company, To Perform Services
              Related To The Service Or To Assist The Company In Analyzing How
              The Service Is Used. Usage Data Refers To Data Collected
              Automatically, Either Generated By The Use Of The Service Or From
              The Service Infrastructure Itself (For Example, The Duration Of A
              Page Visit). Website Refers To , Accessible From You Mean The
              Individual Accessing Or Using The Service, Or The Company, Or
              Other Legal Entity On Behalf Of Which Such Individual Is Accessing
              Or Using The Service, As Applicable.
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>Collecting And Using Your Personal Data</h2>
            <h2>Types Of Data Collected</h2>
            <h2>Personal Data</h2>
            <p>
              While Using Our Service, We May Ask You To Provide Us With Certain
              Personally Identifiable Information That Can Be Used To Contact Or
              Identify You. Personally Identifiable Information May Include, But
              Is Not Limited To: Email Address First Name And Last Name Phone
              Number Usage Data
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>Usage Data</h2>
            <p>
              Usage Data Is Collected Automatically When Using The Service.Usage
              Data May Include Information Such As Your Device's Internet
              Protocol Address (E.G. IP Address), Browser Type, Browser Version,
              The Pages Of Our Service That You Visit, The Time And Date Of Your
              Visit, The Time Spent On Those Pages, Unique Device Identifiers
              And Other Diagnostic Data. When You Access The Service By Or
              Through A Mobile Device, We May Collect Certain Information
              Automatically, Including, But Not Limited To, The Type Of Mobile
              Device You Use, Your Mobile Device Unique ID, The IP Address Of
              Your Mobile Device, Your Mobile Operating System, The Type Of
              Mobile Internet Browser You Use, Unique Device Identifiers And
              Other Diagnostic Data. We May Also Collect Information That Your
              Browser Sends Whenever You Visit Our Service Or When You Access
              The Service By Or Through A Mobile Device.
            </p>
            <br />
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>Tracking Technologies And Cookies</h2>
            <br />
            <p>
              We Use Cookies And Similar Tracking Technologies To Track The
              Activity On Our Service And Store Certain Information. Tracking
              Technologies Used Are Beacons, Tags, And Scripts To Collect And
              Track Information And To Improve And Analyze Our Service. The
              Technologies We Use May Include: Cookies Or Browser Cookies. A
              Cookie Is A Small File Placed On Your Device. You Can Instruct
              Your Browser To Refuse All Cookies Or To Indicate When A Cookie Is
              Being Sent. However, If You Do Not Accept Cookies, You May Not Be
              Able To Use Some Parts Of Our Service. Unless You Have Adjusted
              Your Browser Setting So That It Will Refuse Cookies, Our Service
              May Use Cookies. Flash Cookies. Certain Features Of Our Service
              May Use Local Stored Objects (Or Flash Cookies) To Collect And
              Store Information About Your Preferences Or Your Activity On Our
              Service. Flash Cookies Are Not Managed By The Same Browser
              Settings As Those Used For Browser Cookies. For More Information
              On How You Can Delete Flash Cookies, Please Read "Where Can I
              Change The Settings For Disabling, Or Deleting Local Shared
              Objects?" Available At Adobe Flash Player Web Beacons Certain
              Sections Of Our Service And Our Emails May Contain Small
              Electronic Files Known As Web Beacons (Also Referred To As Clear
              Gifs, Pixel Tags, And Single-Pixel Gifs) That Permit The Company,
              For Example, To Count Users Who Have Visited Those Pages Or Opened
              An Email And For Other Related Website Statistics (For Example,
              Recording The Popularity Of A Certain Section And Verifying System
              And Server Integrity). Cookies Can Be "Persistent" Or "Session"
              Cookies. Persistent Cookies Remain On Your Personal Computer Or
              Mobile Device When You Go Offline, While Session Cookies Are
              Deleted As Soon As You Close Your Web Browser. Learn More About
              Cookies: Use Of Cookies By Free Privacy Policy. We Use Both
              Session And Persistent Cookies For The Purposes Set Out Below:
              Necessary / Essential Cookies Type: Session Cookies Administered
              By: Us Purpose: These Cookies Are Essential To Provide You With
              Services Available Through The Website And To Enable You To Use
              Some Of Its Features. They Help To Authenticate Users And Prevent
              Fraudulent Use Of User Accounts. Without These Cookies, The
              Services That You Have Asked For Cannot Be Provided, And We Only
              Use These Cookies To Provide You With Those Services. Cookies
              Policy / Notice Acceptance Cookies Type: Persistent Cookies
              Administered By: Us Purpose: These Cookies Identify If Users Have
              Accepted The Use Of Cookies On The Website. Functionality
              CookiesType: Persistent Cookies Administered By: Us Purpose: These
              Cookies Allow Us To Remember Choices You Make When You Use The
              Website, Such As Remembering Your Login Details Or Language
              Preference. The Purpose Of These Cookies Is To Provide You With A
              More Personal Experience And To Avoid You Having To Re-Enter Your
              Preferences Every Time You Use The Website. For More Information
              About The Cookies We Use And Your Choices Regarding Cookies,
              Please Visit Our Cookies Policy Or The Cookies Section Of Our
              Privacy Policy.
            </p>
            <br />
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>Use Of Your Personal Data</h2>
            <p>
              The Company May Use Personal Data For The Following Purposes: To
              Provide And Maintain Our Service, Including To Monitor The Usage
              Of Our Service. To Manage Your Account: To Manage Your
              Registration As A User Of The Service. The Personal Data You
              Provide Can Give You Access To Different Functionalities Of The
              Service That Are Available To You As A Registered User. For The
              Performance Of A Contract: The Development, Compliance And
              Undertaking Of The Purchase Contract For The Products, Items Or
              Services You Have Purchased Or Of Any Other Contract With Us
              Through The Service. To Contact You: To Contact You By Email,
              Telephone Calls, SMS, Or Other Equivalent Forms Of Electronic
              Communication, Such As A Mobile Application's Push Notifications
              Regarding Updates Or Informative Communications Related To The
              Functionalities, Products Or Contracted Services, Including The
              Security Updates, When Necessary Or Reasonable For Their
              Implementation. To Provide You With News, Special Offers And
              General Information About Other Goods, Services And Events Which
              We Offer That Are Similar To Those That You Have Already Purchased
              Or Enquired About Unless You Have Opted Not To Receive Such
              Information.To Manage Your Requests To Attend And Manage Your
              Requests To Us. For Business Transfers:We May Use Your Information
              To Evaluate Or Conduct A Merger, Divestiture, Restructuring,
              Reorganization, Dissolution, Or Other Sale Or Transfer Of Some Or
              All Of Our Assets, Whether As A Going Concern Or As Part Of
              Bankruptcy, Liquidation, Or Similar Proceeding, In Which Personal
              Data Held By Us About Our Service Users Is Among The Assets
              Transferred. For Other Purposes:We May Use Your Information For
              Other Purposes, Such As Data Analysis, Identifying Usage Trends,
              Determining The Effectiveness Of Our Promotional Campaigns And To
              Evaluate And Improve Our Service, Products, Services, Marketing
              And Your Experience. We May Share Your Personal Information In The
              Following Situations: With Service Providers: We May Share Your
              Personal Information With Service Providers To Monitor And Analyze
              The Use Of Our Service, To Contact You. For Business Transfers: We
              May Share Or Transfer Your Personal Information In Connection
              With, Or During Negotiations Of, Any Merger, Sale Of Company
              Assets, Financing, Or Acquisition Of All Or A Portion Of Our
              Business To Another Company. With Affiliates: We May Share Your
              Information With Our Affiliates, In Which Case We Will Require
              Those Affiliates To Honor This Privacy Policy. Affiliates Include
              Our Parent Company And Any Other Subsidiaries, Joint Venture
              Partners Or Other Companies That We Control Or That Are Under
              Common Control With Us. With Business Partners: We May Share Your
              Information With Our Business Partners To Offer You Certain
              Products, Services Or Promotions . With Other Users: When You
              Share Personal Information Or Otherwise Interact In The Public
              Areas With Other Users, Such Information May Be Viewed By All
              Users And May Be Publicly Distributed Outside. With Your Consent:
              We May Disclose Your Personal Information For Any Other Purpose
              With Your Consent. Removal Of Your Personal Information: In Case,
              If You Want To Delete/Remove Your Personal Information From Your
              Account, You Can Do So With The Help Of Customer Service. You Need
              To Contact Customer Service Team Through LIVE CHAT Option.
            </p>
            <br />
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>Retention Of Your Personal Data</h2>
            <p>
              The Company Will Retain Your Personal Data Only For As Long As Is
              Necessary For The Purposes Set Out In This Privacy Policy. We Will
              Retain And Use Your Personal Data To The Extent Necessary To
              Comply With Our Legal Obligations (For Example, If We Are Required
              To Retain Your Data To Comply With Applicable Laws), Resolve
              Disputes, And Enforce Our Legal Agreements And Policies. The
              Company Will Also Retain Usage Data For Internal Analysis
              Purposes. Usage Data Is Generally Retained For A Shorter Period Of
              Time, Except When This Data Is Used To Strengthen The Security Or
              To Improve The Functionality Of Our Service, Or We Are Legally
              Obligated To Retain This Data For Longer Time Periods.
            </p>
            <br />
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>Transfer Of Your Personal Data</h2>
            <br />
            <p>
              Your Information, Including Personal Data, Is Processed At The
              Company's Operating Offices And In Any Other Places Where The
              Parties Involved In The Processing Are Located. It Means That This
              Information May Be Transferred To — And Maintained On — Computers
              Located Outside Of Your State, Province, Country Or Other
              Governmental Jurisdiction Where The Data Protection Laws May
              Differ From Those From Your Jurisdiction. Your Consent To This
              Privacy Policy Followed By Your Submission Of Such Information
              Represents Your Agreement To That Transfer. The Company Will Take
              All Steps Reasonably Necessary To Ensure That Your Data Is Treated
              Securely And In Accordance With This Privacy Policy And No
              Transfer Of Your Personal Data Will Take Place To An Organization
              Or A Country Unless There Are Adequate Controls In Place Including
              The Security Of Your Data And Other Personal Information. r
              Original Account.
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>Business Transactions</h2>
            <br />
            <p>
              If The Company Is Involved In A Merger, Acquisition Or Asset Sale,
              Your Personal Data May Be Transferred. We Will Provide Notice
              Before Your Personal Data Is Transferred And Becomes Subject To A
              Different Privacy Policy.
              <br />
            </p>
          </div>{" "}
          <div className="contentn-bets-terms-and-condition">
            <h2>Law Enforcement</h2>
            <br />
            <p>
              Under Certain Circumstances, The Company May Be Required To
              Disclose Your Personal Data If Required To Do So By Law Or In
              Response To Valid Requests By Public Authorities (E.G. A Court Or
              A Government Agency).
              <br />
            </p>
          </div>{" "}
          <div className="contentn-bets-terms-and-condition">
            <h2>Other Legal Requirements</h2>
            <br />
            <p>
              The Company May Disclose Your Personal Data In The Good Faith
              Belief That Such Action Is Necessary To: Comply With A Legal
              Obligation Protect And Defend The Rights Or Property Of The
              Company Prevent Or Investigate Possible Wrongdoing In Connection
              With The Service Protect The Personal Safety Of Users Of The
              Service Or The Public Protect Against Legal Liability
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>Security Of Your Personal Data</h2>
            <br />
            <p>
              The Security Of Your Personal Data Is Important To Us, But
              Remember That No Method Of Transmission Over The Internet, Or
              Method Of Electronic Storage Is 100% Secure. While We Strive To
              Use Commercially Acceptable Means To Protect Your Personal Data,
              We Cannot Guarantee Its Absolute Security.
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>Children's Privacy</h2>
            <br />
            <p>
              YOur Service Does Not Address Anyone Under The Age Of 18. We Do
              Not Knowingly Collect Personally Identifiable Information From
              Anyone Under The Age Of 18. If You Are A Parent Or Guardian And
              You Are Aware That Your Child Has Provided Us With Personal Data,
              Please Contact Us. If We Become Aware That We Have Collected
              Personal Data From Anyone Under The Age Of 18 Without Verification
              Of Parental Consent, We Take Steps To Remove That Information From
              Our Servers. If We Need To Rely On Consent As A Legal Basis For
              Processing Your Information And Your Country Requires Consent From
              A Parent, We May Require Your Parent's Consent Before We Collect
              And Use That Information.
              <br />
            </p>
          </div>{" "}
          <div className="contentn-bets-terms-and-condition">
            <h2>Links To Other Websites</h2>
            <br />
            <p>
              Our Service May Contain Links To Other Websites That Are Not
              Operated By Us. If You Click On A Third-Party Link, You Will Be
              Directed To That Third Party's Site. We Strongly Advise You To
              Review The Privacy Policy Of Every Site You Visit. We Have No
              Control Over And Assume No Responsibility For The Content, Privacy
              Policies Or Practices Of Any Third-Party Sites Or Services.
              <br />
            </p>
          </div>{" "}
          <div className="contentn-bets-terms-and-condition">
            <h2>Changes To This Privacy Policy</h2>
            <br />
            <p>
              We May Update Our Privacy Policy From Time To Time. We Will Notify
              You Of Any Changes By Posting The New Privacy Policy On This Page.
              We Will Let You Know Via Email And/Or A Prominent Notice On Our
              Service, Prior To The Change Becoming Effective And Update The
              "Last Updated" Date At The Top Of This Privacy Policy. You Are
              Advised To Review This Privacy Policy Periodically For Any
              Changes. Changes To This Privacy Policy Are Effective When They
              Are Posted On This Page.
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>Contact Us</h2>
            <br />
            <p>
              If You Have Any Questions About This Privacy Policy, You Can Email
              Us: Support@Betkaro India.Com
              <br />
            </p>
          </div>
        </div>
      </div>
      <Footer />
      <MobileFooter />
    </div>
  );
};

export default PrivacyPolicy;
