export const getBankDetails = '/user/account_details_of_admin'
export const userPayment = '/user/payment_request'
export const walletBalance = '/user/wallet_balance'
export const walletHistory = '/user/wallet_history'
export const depositHistory = '/user/deposit_history'
export const withdrawHistory = '/user/withdraw_history'
export const placeBet = '/user/place_bet'
export const placeFancyBet = '/user/place_bet_fancy'
export const placeBookmakerBet = '/user/place_bet_bookmaker'
export const placeBetAPI = '/user/place_bet'
export const allBetsList = '/user/all_bets'
export const updateBank = '/user/update_bank'
export const getBankDetals = '/user/bank_details'
export const withdrawalRequest = '/user/withdrawal_request'
export const bonusBalance = '/user/bonus_balance'
export const profitLoss = '/user/profit_loss'
export const allWithdraHistoryList = '/user/deposit_withdraw_history'
export const userCasinoStatement = '/user/casino_statement'
export const userCasinoStatementNew = '/user/casino_statementnew'
export const update_Profile = '/user/update_profile'
export const get_Message_List = '/user/messages'
export const update_view_messge = '/user/view-messages'
export const get_User_Profile = '/user'
export const get_turnover = '/user/bonus_turnover'
export const delete_Bank = '/user/delete_bank'
export const get_Promotion = '/user/bonus_turnover'
export const put_send_Otp = '/user/send-otp'
export const put_forget_password = '/user/forget_password'
export const casino_game_list = '/casino/game_list'
export const casino_game_category = '/casino/all_categories'
export const get_net_exposure = "/user/net_exposure"
export const transfer_to_casino = "/user/transferto_casino"
export const transfer_to_main = "/user/transferto_main"














