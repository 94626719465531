import React from 'react';
import { useEffect, useState } from 'react'
import PrimaryNavbar from '../../components/PrimaryNavbar'
import ArrowLeft from "../../assets/arrow_left.svg"
import CleanderIcon from "../../assets/cleander_icon.svg"
import DownArrow from "../../assets/down_arrow.svg"
import { useNavigate } from 'react-router-dom';
import MobileFooter from '../../components/MobileFooter';
import { profitLoss, userCasinoStatement, userCasinoStatementNew } from '../../services/api';
import axios from 'axios';
import baseUrl from '../../services/baseUrl';
import MobileSideBar from '../../components/MobileSideBar/MobileSideBar';

const CasinoStatement = () => {
    const navigate = useNavigate()
    const [casinoStatement, setCasinoStatement] = useState({ data: [], loader: false })
    const [filterStartDate, setFilterStartDate] = useState("")
    const [filterEndDate, setFilterEndDate] = useState("")
   

    const handleStartDate = (e) => {
        let date = e.target.value
        handleStatesSync("filterStDt", date)
    }

    const handleEndDate = (e) => {
        let date = e.target.value
        handleStatesSync("filterEdDt", date)
    }

    const handleStatesSync = (stateName, value) => {
        if (stateName === "filterStDt") { setFilterStartDate(value) }
        if (stateName === "filterEdDt") { setFilterEndDate(value) }
    }

    const createStEnDate = () => {
        let dtStr = "";
        if (filterStartDate.length !== 0 && filterEndDate.length !== 0) {
            dtStr = `&dateFrom=${filterStartDate}&dateTo=${filterEndDate}`;
        } else if (filterStartDate.length !== 0 && filterEndDate.length === 0) {
            dtStr = `&dateFrom=${filterStartDate}`;
        } else if (filterStartDate.length === 0 && filterEndDate.length !== 0) {
            dtStr = `&dateTo=${filterEndDate}`;
        } else {
            dtStr = "";
        }
        return dtStr;
    };


    const handleGetcasinoStatement = async () => {
        setCasinoStatement({ ...casinoStatement, loader: true })
        const user = JSON.parse(localStorage.getItem("betUser"));
        const headers = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": user?.token
            }
        }
        try {
            const dateStr = createStEnDate()
            const responce = await axios.get(baseUrl + userCasinoStatementNew + "?" + dateStr, headers)
            if (responce.status == 200) {
                setCasinoStatement({ ...casinoStatement, data: responce?.data?.data, loader: false })
            }

        } catch (error) {
            setCasinoStatement({ ...casinoStatement, loader: false })
        }
    }
    useEffect(() => {
        handleGetcasinoStatement()
    }, [ filterStartDate, filterEndDate])

    return (
        <div>
            <PrimaryNavbar />
            <div className="position-relative">
                <MobileSideBar />
            </div>
            <div className="container px-0 py-3 accountStatementBG pb_4rem">
                <div className="row px-3 m-0 mb-3 text-white">
                    <div className="col-1 ps-0 cursor-pointer" onClick={() => navigate(-1)}>
                        <img src={ArrowLeft} className="cursor-pointer arrow-icone-size rounded-4" />
                    </div>
                    <div className="col-10">
                        <p className="mb-0 currentbets_title fw_700 text-center">
                            Casino Statement
                        </p>
                    </div>
                    <div className="col-1"></div>
                </div>

                {/* <div className='row mb-2 mx-0 mb-3'>
                    <div className='col-12 d-flex m-0 p-0 px-3  '>
                        <div className="col-4 m-0 p-0 pe-2">
                            <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2 rounded-6'>
                                <div className='date_input d-flex align-items-center me-3 rounded-6'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>{filterStartDate ? (new Date(filterStartDate))?.toLocaleDateString() : "MM/DD/YYYY"}</p>
                                </div>
                                <div className='cleander_dropedown down-arrow-radius'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input onChange={handleStartDate} type="date" name='date' max={new Date().toISOString().split("T")[0]} className="position-absolute w-18 opacity-0" />
                                </div>
                            </div>
                        </div>
                        <div className="col-4 m-0 p-0 pe-2">
                            <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2 rounded-6'>
                                <div className='date_input d-flex align-items-center me-3 rounded-6'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>{filterEndDate ? (new Date(filterEndDate))?.toLocaleDateString() : "MM/DD/YYYY"}</p>
                                </div>
                                <div className='cleander_dropedown down-arrow-radius'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input onChange={handleEndDate} type="date" name="date" max={new Date().toISOString().split("T")[0]} className="position-absolute w-18 opacity-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {
                    casinoStatement?.data?.map(casino => (
                        <div className="row  overflow-hidden mb-2 mx-0 mb-3">
                            <div className='col-12 m-0 p-0'>
                                <div className="px-3">
                                    <div className="rounded-8 accordion-item overflow-hidden">
                                        <h2 className="accordion-header border-0">
                                            <button className="accordion-button profilAndLossAccordian row m-0 py-2 px-3  border-0">
                                                <div className="fs_16 m-0 p-0 col-6 text-white font-weight-bold mb-1">{casino?.gameName}</div>
                                                <div className="fs_14 m-0 p-0 col-6 text-white text-end font-weight-bold mb-1">Placed Time</div>

                                                <div className="fs_10 fw_400 m-0 p-0 col-6 text-white">Ref ID - {casino?._id}</div>
                                                <div className="fs_10 fw_400 m-0 p-0 col-6 text-white text-end">{casino.createdAt}</div>
                                            </button>
                                        </h2>
                                        <div className="row m-0 py-2 align-items-end ">
                                            <div className='col-6 border-right text-white fs_12 fw_400'>
                                                <div className='row mb-2'>
                                                    <div className='col-5'>
                                                        <p className='mb-0'>Provider</p>
                                                    </div>
                                                    <div className='col-7'>
                                                    <p className='mb-0 text-yellow_01 font-weight-bold'>: {casino?.MerchantName}</p>
                                                    </div>
                                                </div>
                                                <div className='row mb-2'>
                                                    <div className='col-5'>
                                                        <p className='mb-0'>Win/Loss</p>
                                                    </div>
                                                    <div className='col-7'>
                                                        {
                                                            casino?.profit
                                                            ?
                                                            <p className='text-green_01 mb-0'>: {casino?.profit}</p>
                                                            :
                                                            casino?.loss
                                                            ?
                                                            <p className='text-red_01 mb-0'>: -{casino?.loss}</p>
                                                            :
                                                            <p className='text-red_01 mb-0'>: </p>
                                                        }
                                                    </div>
                                                </div>
                                                <div className='row mb-2 text-white'>
                                                    <div className='col-5'>
                                                        <p className='mb-0'>Stake</p>
                                                    </div>
                                                    <div className='col-7'>
                                                        <p className='mb-0'> : {casino?.stake}</p>
                                                    </div>
                                                </div>
                                                <div className='row mb-2 text-white'>
                                                    <div className='col-5'>
                                                        <p className='mb-0'>Commission</p>
                                                    </div>
                                                    <div className='col-7'>
                                                        <p className='mb-0'> : 0.00</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6 text-white fs_12 text-end px-4'>
                                                <img className="w-100 rounded-8" src={casino?.ImageFullPath} />
                                            </div>
                                        </div>
                                        <div className="border-succes-5 row m-0 py-2 profitLossBorder align-items-end ">
                                            <div className='col-12 text-yellow_01 fs_12 fw_700 d-flex px-4 pe-0'>
                                                <span className="row profitLossBg p-1 px-2 w-100 rounded-4">
                                                    <span className="col-6 px-0 d-flex justify-content-between"><span>Status</span><span>:</span></span>
                                                    <span className="col-6 px-0 d-flex justify-content-end">Settled</span>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))
                }
                
                        
                {/* <div>
                    {
                        casinoStatement?.data?.length == 0 && casinoStatement?.loader == false
                            ?
                            <div className="bet_card_wrapper mb_4rem rounded-8  ">
                                <div className="row m-0 white_glass py-3 align-items-end d-flex align-items-center justify-content-center py-5 rounded-8">
                                    <span className="text-white fs_12 text-center">No content</span>
                                </div>
                            </div>
                            :
                            ''
                    }
                    {
                        casinoStatement?.loader == true
                        ?
                        <div className='text-center'>
                            <div className="spinner-border text-primary" role="status"></div>
                        </div>
                        :
                        ''
                    }
                </div> */}

            </div >
            <MobileFooter />
        </div >
    );
};

export default CasinoStatement;