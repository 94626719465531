import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import PrimaryNavbar from '../../components/PrimaryNavbar';
import MobileFooter from '../../components/MobileFooter';
import MobileSideBar from '../../components/MobileSideBar/MobileSideBar';
import promotionBanner from "../../assets/promotionBanner.png"
import OwlCarousel from 'react-owl-carousel';
import HomepageCategoryCardS from '../../components/HomepageCategoryCardS/HomepageCategoryCardS';
// import OwlCarouselRTL from 'react-owl-carousel-rtl';
import WelcomeBonusTop from "../../assets/images/WelcomeBonusTop.jpg"
import RefillBonusTop from "../../assets/images/RefillBonusTop.jpg"
import CashbackBonusTop from "../../assets/images/CashbackBonusTop.jpg"

const PromotionInternalPage = () => {
    const location = useLocation()
    const [select, setSelect] = useState(1)
    const [loader, setLoader] = useState(false)

    const setSelectedId = id => {
        setLoader(true)
        setSelect(id)
        setTimeout(() => setLoader(false))
    }

    useEffect(() => {
        setSelectedId(location?.state || 1)
    }, [])

    return (
        <div>
            <PrimaryNavbar />
            <div className="position-relative">
                <MobileSideBar />
            </div>
            <div className="promotionInternalBG">
                {
                    select === 1 || select === 4
                    ?
                    <img className="w-100" src={WelcomeBonusTop} />
                    :
                    ""
                }
                {
                    select === 2
                    ?
                    <img className="w-100" src={CashbackBonusTop} />
                    :
                    ""
                }
                {
                    select === 3
                    ?
                    <img className="w-100" src={RefillBonusTop} />
                    :
                    ""
                }
                
                {/* carousel boxes */}
                {
                    loader
                    ?
                    ""
                    :
                    <div className="overflow-auto d-flex">
                        <OwlCarousel className='owl-theme' loop={false} autoplay={false} dots={false} responsive={{ 0: { items: 3 } }} >
                            <div onClick={() => setSelectedId(1)} className={`text-dark bg-muted p-2 py-3 d-flex align-items-center justify-content-center border-left border-right  text-center text-carousel-homapegeCategory ${select === 1 ? "active" : ""}`} >WELCOME BONUS</div>
                            <div onClick={() => setSelectedId(2)} className={`text-dark bg-muted p-2 py-3 d-flex align-items-center justify-content-center border-left border-right  text-center text-carousel-homapegeCategory ${select === 2 ? "active" : ""}`} >CASHBACK BONUS</div>
                            <div onClick={() => setSelectedId(3)} className={`text-dark bg-muted p-2 py-3 d-flex align-items-center justify-content-center border-left border-right  text-center text-carousel-homapegeCategory ${select === 3 ? "active" : ""}`} >REFILL BONUS</div>
                            <div onClick={() => setSelectedId(4)} className={`text-dark bg-muted p-2 py-3 d-flex align-items-center justify-content-center border-left border-right  text-center text-carousel-homapegeCategory ${select === 4 ? "active" : ""}`} >REFERRAL BONUS</div>
                        </OwlCarousel>
                    </div>

                }
                

                {/* content here */}
                {
                    select === 1
                    ?
                    <div className=' border-white-promotional rounded-8'  >
                        <h4 className='text-warning pb-2 lorem-promotionap-text'>WELCOME BONUS</h4>
                        <p className='text-white pb-4 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p>
                        <h4 className='text-warning pb-2 lorem-promotionap-text'>WHAT IS WELCOME BONUS</h4>
                        <p className='text-white pb-4 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p><h4 className='text-warning pb-2 lorem-promotionap-text'>HOW TO GET THIS BONUS</h4>
                        <p className='text-white pb-4 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p><h4 className='text-warning pb-2 lorem-promotionap-text'>WHAT IS RO REQUIREMENT?</h4>
                        <p className='text-white pb-4 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p><h4 className='text-warning pb-2 lorem-promotionap-text'>TERMS AND CONDITION</h4>
                        <p className='text-white pb-5 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p>
                    </div>
                    :
                    ""
                }
                {
                    select === 2
                    ?
                    <div className=' border-white-promotional rounded-8'  >
                        <h4 className='text-warning pb-2 lorem-promotionap-text'>CASHBACK BONUS</h4>
                        <p className='text-white pb-4 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p>
                        <h4 className='text-warning pb-2 lorem-promotionap-text'>WHAT IS CASHBACK BONUS</h4>
                        <p className='text-white pb-4 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p><h4 className='text-warning pb-2 lorem-promotionap-text'>HOW TO GET THIS BONUS</h4>
                        <p className='text-white pb-4 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p><h4 className='text-warning pb-2 lorem-promotionap-text'>WHAT IS RO REQUIREMENT?</h4>
                        <p className='text-white pb-4 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p><h4 className='text-warning pb-2 lorem-promotionap-text'>TERMS AND CONDITION</h4>
                        <p className='text-white pb-5 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p>
                    </div>
                    :
                    ""
                }
                {
                    select === 3
                    ?
                    <div className=' border-white-promotional rounded-8'  >
                        <h4 className='text-warning pb-2 lorem-promotionap-text'>REFILL BONUS</h4>
                        <p className='text-white pb-4 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p>
                        <h4 className='text-warning pb-2 lorem-promotionap-text'>WHAT IS REFILL BONUS</h4>
                        <p className='text-white pb-4 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p><h4 className='text-warning pb-2 lorem-promotionap-text'>HOW TO GET THIS BONUS</h4>
                        <p className='text-white pb-4 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p><h4 className='text-warning pb-2 lorem-promotionap-text'>WHAT IS RO REQUIREMENT?</h4>
                        <p className='text-white pb-4 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p><h4 className='text-warning pb-2 lorem-promotionap-text'>TERMS AND CONDITION</h4>
                        <p className='text-white pb-5 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p>
                    </div>
                    :
                    ""
                }
                {
                    select === 4
                    ?
                    <div className=' border-white-promotional rounded-8'  >
                        <h4 className='text-warning pb-2 lorem-promotionap-text'>REFERRAL BONUS</h4>
                        <p className='text-white pb-4 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p>
                        <h4 className='text-warning pb-2 lorem-promotionap-text'>WHAT IS REFERRAL BONUS</h4>
                        <p className='text-white pb-4 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p><h4 className='text-warning pb-2 lorem-promotionap-text'>HOW TO GET THIS BONUS</h4>
                        <p className='text-white pb-4 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p><h4 className='text-warning pb-2 lorem-promotionap-text'>WHAT IS RO REQUIREMENT?</h4>
                        <p className='text-white pb-4 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p><h4 className='text-warning pb-2 lorem-promotionap-text'>TERMS AND CONDITION</h4>
                        <p className='text-white pb-5 lorem-promotional-p-text'>Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i premiren lådade mil, plan. Stereofiering don poliga lavis fashionista. Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar, innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
                        </p>
                    </div>
                    :
                    ""
                }
                
            </div>

            <MobileFooter />
        </div >
    );
}

export default PromotionInternalPage
