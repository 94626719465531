import React from 'react';
import { useEffect, useState } from 'react'
import PrimaryNavbar from '../../components/PrimaryNavbar'
import ArrowLeft from "../../assets/arrow_left.svg"
import CleanderIcon from "../../assets/cleander_icon.svg"
import DownArrow from "../../assets/down_arrow.svg"
import { useNavigate } from 'react-router-dom';
import MobileFooter from '../../components/MobileFooter';
import { profitLoss } from '../../services/api';
import axios from 'axios';
import baseUrl from '../../services/baseUrl';
import MobileSideBar from '../../components/MobileSideBar/MobileSideBar';

const ProfitAndLoss = () => {
    const navigate = useNavigate()
    const [profitList, setProfitList] = useState({ data: [], loader: false, betType: 'cricket' })
    const [numbers, setNumbers] = useState([])
    const [eventid, setEventId] = useState(4)
    const [filterStartDate, setFilterStartDate] = useState("")
    const [filterEndDate, setFilterEndDate] = useState("")
   

    const handleStartDate = (e) => {
        let date = e.target.value
        handleStatesSync("filterStDt", date)
    }

    const handleEndDate = (e) => {
        let date = e.target.value
        handleStatesSync("filterEdDt", date)
    }

    const handleStatesSync = (stateName, value) => {
        if (stateName === "filterStDt") { setFilterStartDate(value) }
        if (stateName === "filterEdDt") { setFilterEndDate(value) }
    }

    const createStEnDate = () => {
        let dtStr = "";
        if (filterStartDate.length !== 0 && filterEndDate.length !== 0) {
            dtStr = `&dateFrom=${filterStartDate}&dateTo=${filterEndDate}`;
        } else if (filterStartDate.length !== 0 && filterEndDate.length === 0) {
            dtStr = `&dateFrom=${filterStartDate}`;
        } else if (filterStartDate.length === 0 && filterEndDate.length !== 0) {
            dtStr = `&dateTo=${filterEndDate}`;
        } else {
            dtStr = "";
        }
        return dtStr;
    };


    const handleGetProfitList = async () => {
        setProfitList({ ...profitList, loader: true })
        const user = JSON.parse(localStorage.getItem("betUser"));
        const headers = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": user?.token
            }
        }
        try {
            const dateStr = createStEnDate()
            const responce = await axios.get(baseUrl + profitLoss + "?eventid="+eventid+"&" + dateStr, headers)
            if (responce.status == 200) {
                setProfitList({ ...profitList, data: responce?.data?.data, loader: false, numbers: responce?.data?.data?.[0] })
            }

        } catch (error) {
            setProfitList({ ...profitList, loader: false })
        }
    }

    const handleGetNumbers = async () => {
        const user = JSON.parse(localStorage.getItem("betUser"));
        const headers = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": user?.token
            }
        }
        try {
            const responce = await axios.get(baseUrl+"/user/profit_loss_net", headers)
            if (responce.status == 200) {
                setNumbers(responce?.data?.data)
            }

        } catch (error) {
            console.log(error)
        }
    }

    useEffect(() => {
        handleGetProfitList()
        handleGetNumbers()
    }, [eventid, filterStartDate, filterEndDate])

    const totalBet = numbers?.reduce((acc, el) => acc + el?.netTotal, 0)
    const footballTotalBet = numbers?.reduce((acc, el) => acc + el?.netFootballTotal, 0)
    const cricketTotalBet = numbers?.reduce((acc, el) => acc + el?.netCricketTotal, 0)
    const tennisTotalBet = numbers?.reduce((acc, el) => acc + el?.netTennisTotal, 0)

    return (
        <div>
            <PrimaryNavbar />
            <div className="position-relative">
                <MobileSideBar />
            </div>
            <div className="container px-0 py-3 profitAndLossBG pb_4rem">
                <div className="row mb-3 text-white m-0">
                    <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
                        <img src={ArrowLeft} className="cursor-pointer arrow-icone-size rounded-4" />
                    </div>
                    <div className="col-10">
                        <p className="mb-0 currentbets_title fw_700 text-center">
                            Profit And Loss
                        </p>
                    </div>
                    <div className="col-1"></div>
                </div>

                {/* <div className='row px-3 mb-2 mx-0 mb-3'>
                    <div className='col-12 d-flex m-0 p-0'>
                        <div className="col-4 m-0 p-0 pe-2">
                            <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2'>
                                <div className='date_input d-flex align-items-center me-3'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>{filterStartDate ? (new Date(filterStartDate))?.toLocaleDateString() : "MM/DD/YYYY"}</p>
                                </div>
                                <div className='cleander_dropedown'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input onChange={handleStartDate} type="date" className="position-absolute w-18 opacity-0" />

                                </div>
                            </div>
                        </div>
                        <div className="col-4 m-0 p-0 pe-2">
                            <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2'>
                                <div className='date_input d-flex align-items-center me-3'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>{filterEndDate ? (new Date(filterEndDate))?.toLocaleDateString() : "MM/DD/YYYY"}</p>
                                </div>
                                <div className='cleander_dropedown'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input onChange={handleEndDate} type="date" className="position-absolute w-18 opacity-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}


                <div className='row mb-2 mx-0 mb-3'>
                    <div className='col-12 d-flex m-0 p-0 px-3  '>
                        <div className="col-4 m-0 p-0 pe-2">
                            <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2 rounded-6'>
                                <div className='date_input d-flex align-items-center me-3 rounded-6'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>{filterStartDate ? (new Date(filterStartDate))?.toLocaleDateString() : "MM/DD/YYYY"}</p>
                                </div>
                                <div className='cleander_dropedown down-arrow-radius'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input onChange={handleStartDate} type="date" name='date' max={new Date().toISOString().split("T")[0]} className="position-absolute w-18 opacity-0" />
                                </div>
                            </div>
                        </div>
                        <div className="col-4 m-0 p-0 pe-2">
                            <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2 rounded-6'>
                                <div className='date_input d-flex align-items-center me-3 rounded-6'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>{filterEndDate ? (new Date(filterEndDate))?.toLocaleDateString() : "MM/DD/YYYY"}</p>
                                </div>
                                <div className='cleander_dropedown down-arrow-radius'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input onChange={handleEndDate} type="date" name="date" max={new Date().toISOString().split("T")[0]} className="position-absolute w-18 opacity-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row px-3 mb-2 mx-0 mb-3'>
                    <div className='col-12 d-flex m-0 p-0 fs_12 text-white'>
                        <div className="">Total P&L:
                            <span className="text-yellow_01 ms-2 fw_700">{totalBet > 0 ? "+"+totalBet : totalBet < 0 ? "-"+totalBet : totalBet}</span>
                        </div>
                    </div>
                </div>


                <div className='green_glass py-2 border-0 row px-3 mb-2 mx-0 mb-3  rounded-8'>
                    <div className='col-12 d-flex m-0 p-0'>
                        <div className="col-4 m-0 p-0 pe-2 " onClick={() => setEventId(4)}>
                            <button className={`${eventid === 4 ? "btn w-100 d-flex flex-column align-items-center justify-content-center btn-warning py-0 fw_700 fs_14 lh_15 h-100 rounded-8 shadow-none btn_commn_yellow_1" : "btn w-100 d-flex flex-column align-items-center justify-content-center btn-light py-0 fw_700 fs_14 lh_15 h-100 rounded-8 shadow-none"}`}>
                                <div>Cricket</div>
                                <div>
                                    {
                                        cricketTotalBet > 0 ? <span className="text-success">{"+"+(cricketTotalBet)?.toFixed(2)}</span> :
                                        cricketTotalBet < 0 ? <span className="text-danger">{"-"+(cricketTotalBet)?.toFixed(2)}</span> :
                                        "0.00"
                                    }
                                </div>
                            </button>
                        </div>
                        <div className="col-4 m-0 p-0 pe-2 " onClick={() => setEventId(1)}>
                            <button className={`${eventid === 1 ? "btn w-100 d-flex flex-column align-items-center justify-content-center btn-warning py-0 fw_700 fs_14 lh_15 h-100 rounded-8 shadow-none btn_commn_yellow_1" : "btn w-100 d-flex flex-column align-items-center justify-content-center btn-light py-0 fw_700 fs_14 lh_15 h-100 rounded-8 shadow-none"}`} >
                                <div>Football</div>
                                <div>
                                    {
                                        footballTotalBet > 0 ? <span className="text-success">{"+"+(footballTotalBet)?.toFixed(2)}</span> :
                                        footballTotalBet < 0 ? <span className="text-danger">{"-"+(footballTotalBet)?.toFixed(2)}</span> :
                                        "0.00"
                                    }
                                </div>
                            </button>
                        </div>
                        <div className="col-4 m-0 p-0 pe-2 " onClick={() => setEventId(2)}>
                            <button className={`${eventid === 2 ? "btn w-100 d-flex flex-column align-items-center justify-content-center btn-warning py-0 fw_700 fs_14 lh_15 h-100 rounded-8 shadow-none btn_commn_yellow_1" : "btn w-100 d-flex flex-column align-items-center justify-content-center btn-light py-0 fw_700 fs_14 lh_15 h-100 rounded-8 shadow-none"}`}>
                                <div>Tennis</div>
                                <div>
                                    {
                                        tennisTotalBet > 0 ? <span className="text-success">{"+"+(tennisTotalBet)?.toFixed(2)}</span> :
                                        tennisTotalBet < 0 ? <span className="text-danger">{"-"+(tennisTotalBet)?.toFixed(2)}</span> :
                                        "0.00"
                                    }
                                </div>
                            </button>
                        </div>
                    </div >
                </div >
                
                {
                    profitList?.data?.length != 0 ? profitList?.data?.map((ele, ind) => {
                        console.log(ele,5656)
                        return <div className="row rounded-8 overflow-hidden px-3 mb-2 mx-0 mb-3">
                            <div className='col-12 d-flex m-0 p-1 fw_700 fs_12 black_gradient text-white d-flex justify-content-center'>
                                { ele?.bets?.[0]?.updatedAt.slice(0, 10) ? ele?.bets?.[0]?.updatedAt.slice(0, 10) : 'null' }
                            </div>
                            <div className='col-12 m-0 p-0'>
                                <div className="accordion " id={"accordionExample"+ind}>
                                    <div className="accordion-item border-0 rounded-0 border-0 ">
                                        <h2 className="accordion-header border-0 rounded-0 border-0">
                                            <button className="accordion-button profilAndLossAccordian p-2 px-3 d-flex flex-column border-0 rounded-0 collapsed border-0 " type="button" data-bs-toggle="collapse" data-bs-target={"#collapseOne"+ind} aria-expanded="false" aria-controls={"collapseOne"+ind}>
                                                <div className="fs_8 w-100 text-white">Match Odds</div>
                                                <div className="fs_12 w-100 text-white">
                                                    {
                                                        ele?.bets?.[0]?.matchDetails?.name
                                                    }
                                                </div>
                                            </button>
                                        </h2>
                                        <div className="row m-0 py-2 align-items-end ">
                                            <div className='col-6 border-right text-white fs_12 fw_400'>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <p className='mb-0'>Start Time</p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className='mb-0'>: {ele?.bets?.[0]?.matchDetails?.openDate ? ele?.bets?.[0]?.matchDetails?.openDate?.slice(11, 19) : 'null'}</p>
                                                    </div>
                                                </div>
                                                <div className='row text-white'>
                                                    <div className='col-6'>
                                                        <p className='mb-0'>Settle Time</p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className='mb-0'> :  {ele?.bets?.[0]?.updatedAt ? ele?.bets?.[0]?.updatedAt?.slice(11, 19) : 'null'}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6 text-white fs_12 fw_700 d-flex justify-content-end px-4'>
                                                <span className="row profitLossBg p-1 w-100">
                                                    <span className="col-6 text-yellow_01">Net</span>
                                                    <span className="col-6">: {ele?.netTotal || ele?.netTotal == 0 ? ele?.netTotal : 'null'}</span>
                                                </span>
                                            </div>
                                        </div>
                                        <div id={"collapseOne"+ind} className="accordion-collapse collapse" data-bs-parent={"#accordionExample"+ind}>
                                            <div className="accordion-bodys profitLossBorder p-0">

                                                {
                                                    ele?.bets?.map((ele, ind) => {
                                                        return <div className="row m-0 py-2 align-items-end ">
                                                            <div className='col-6 border-right text-white fs_12 fw_400'>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <p className='mb-0'>Selection Id</p>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <p className='mb-0'>: <span className='fw_700 text-yellow_01'> {ele?.selectionId ? ele?.selectionId : 'null'}</span></p>
                                                                    </div>
                                                                </div>
                                                                <div className='row text-white'>
                                                                    <div className='col-6'>
                                                                        <p className='mb-0'>Type</p>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <p className='mb-0'><span className="badge badge-pill badge-primary rounded-0">{ele?.type ? ele?.type : 'null'}</span></p>
                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className='col-6'>
                                                                        <p className='mb-0'>Profit/Loss</p>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <p className='mb-0'>: {
                                                                            Object.keys(ele).includes('loss')
                                                                                ?
                                                                                <span className='fw_700 text-red_01'>- {ele?.loss || ele?.loss  == 0 ? ele?.loss : 'null'}</span>
                                                                                :
                                                                                <span className='fw_700 text-green_01'>+ {ele?.profit || ele?.profit == 0 ? ele?.profit : 'null'}</span>
                                                                        }
                                                                        </p>
                                                                    </div>
                                                                </div>
                                                                <div className='row text-white'>
                                                                    <div className='col-6'>
                                                                        <p className='mb-0'>Status</p>
                                                                    </div>
                                                                    <div className='col-6'>
                                                                        <p className='mb-0'> : {Object.keys(ele)?.includes('loss') ? 'LOST' : 'WON'}</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-6 text-white fs_12 fw_700 d-flex justify-content-end px-4'>
                                                                <span className="row profitLossBg p-1 w-100">
                                                                    <span className="col-6 text-yellow_01">Status</span>
                                                                    {
                                                                        Object.keys(ele)?.includes('loss')
                                                                            ?
                                                                            <span className="col-6 text-red_01">: LOST</span>
                                                                            :
                                                                            <span className="col-6 text-green_01">: WON</span>
                                                                    }
                                                                </span>
                                                            </div>
                                                        </div>
                                                    })
                                                }


                                                <div className="p-3 py-2 profitLossCardBlue d-flex border-succes-5">
                                                    <span className="text-center textAutoAdjust  text-primary">
                                                        <div className="fs_10"><i>Back Subtotal</i></div>
                                                        <div className="fw_600 fs_12">{ele?.back_total || ele?.back_total == 0 ? ele?.back_total : 'null'}</div>
                                                    </span>
                                                    <span className="text-center w-auto">
                                                        <div className="fs_12">+</div>
                                                    </span>
                                                    <span className="text-center textAutoAdjust  text-red_01">
                                                        <div className="fs_10"><i>Lay Subtotal</i></div>
                                                        <div className="fw_600 fs_12">{ele?.lay_total || ele?.lay_total == 0 ? ele?.lay_total : 'null'}</div>
                                                    </span>
                                                    <span className="text-center">
                                                        <div className="fs_12">+</div>
                                                    </span>
                                                    <span className="text-center textAutoAdjust ">
                                                        <div className="fs_10"><i>Commission</i></div>
                                                        <div className="fw_600 fs_12">{ele?.total_commission || ele?.total_commission == 0 ? ele?.total_commission : 'null'}</div>
                                                    </span>
                                                    <span className="text-center">
                                                        <div className="fs_12">=</div>
                                                    </span>
                                                    <span className="text-center textAutoAdjust text-primary">
                                                        <div className="fs_10"><i>Net Total</i></div>
                                                        <div className="fw_600 fs_12">{ele?.netTotal || ele?.netTotal == 0 ? ele?.netTotal : 'null'}</div>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    })
                        :
                        <div>
                            {
                                profitList?.data?.length == 0 && profitList?.loader == false
                                    ?
                                    <div className="bet_card_wrapper mb_4rem rounded-8  ">
                                    <div className="row m-0 white_glass py-3 align-items-end d-flex align-items-center justify-content-center py-5 rounded-8">
                                        <span className="text-white fs_12 text-center">No content</span>
                                    </div>
                                </div>
                                    :
                                    ''
                            }
                            {
                                profitList?.loader == true
                                    ?
                                    <div className='text-center'>
                                        <div className="spinner-border text-primary" role="status"></div>
                                    </div>
                                    :
                                    ''
                            }
                        </div>
                }

                {/* <div className="row px-3 mb-2 mx-0 mb-3">
                    <div className='col-12 d-flex m-0 p-1 fw_700 fs_12 black_gradient text-white d-flex justify-content-center'>
                        2023-04-02
                    </div>
                    <div className='col-12 m-0 p-0'>
                        <div className="accordion " id="accordionExample">
                            <div className="accordion-item border-0 rounded-0 border-0 ">
                                <h2 className="accordion-header border-0 rounded-0 border-0">
                                    <button className="accordion-button profilAndLossAccordian p-2 px-3 d-flex flex-column border-0 rounded-0 collapsed border-0 " type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                        <div className="fs_8 w-100 text-white">Match Odds</div>
                                        <div className="fs_12 w-100 text-white">India Vs Pakistan</div>
                                    </button>
                                </h2>
                                <div className="row m-0 py-2 align-items-end ">
                                    <div className='col-6 border-right text-white fs_12 fw_400'>
                                        <div className='row'>
                                            <div className='col-6'>
                                                <p className='mb-0'>Start Time</p>
                                            </div>
                                            <div className='col-6'>
                                                <p className='mb-0'>: 05:02:23</p>
                                            </div>
                                        </div>
                                        <div className='row text-white'>
                                            <div className='col-6'>
                                                <p className='mb-0'>Settle Time</p>
                                            </div>
                                            <div className='col-6'>
                                                <p className='mb-0'> : 21:04:98</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-6 text-white fs_12 fw_700 d-flex justify-content-end px-4'>
                                        <span className="row profitLossBg p-1 w-100">
                                            <span className="col-6 text-yellow_01">Net</span>
                                            <span className="col-6 text-green_01">: 130</span>
                                        </span>
                                    </div>
                                </div>
                                <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                    <div className="accordion-bodys profitLossBorder p-0">
                                        <div className="row m-0 py-2 align-items-end ">
                                            <div className='col-6 border-right text-white fs_12 fw_400'>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <p className='mb-0'>Selection</p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className='mb-0'>: <span className='fw_700 text-yellow_01'>Arsenal</span></p>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-12 fs_8'>
                                                        aj21348192hfchdoq3asd
                                                    </div>
                                                </div>
                                                <div className='row text-white'>
                                                    <div className='col-6'>
                                                        <p className='mb-0'>Type</p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className='mb-0'><span className="badge badge-pill badge-primary rounded-0">Primary</span></p>
                                                    </div>
                                                </div>
                                                <div className='row'>
                                                    <div className='col-6'>
                                                        <p className='mb-0'>Profit/Loss</p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className='mb-0'>: <span className='fw_700 text-red_01'>130</span></p>
                                                    </div>
                                                </div>
                                                <div className='row text-white'>
                                                    <div className='col-6'>
                                                        <p className='mb-0'>Status</p>
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className='mb-0'> : Loss</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-6 text-white fs_12 fw_700 d-flex justify-content-end px-4'>
                                                <span className="row profitLossBg p-1 w-100">
                                                    <span className="col-6 text-yellow_01">Status</span>
                                                    <span className="col-6 text-green_01">: Lost</span>
                                                </span>
                                            </div>
                                        </div>


                                        <div className="p-3 py-2 profitLossCardBlue d-flex border-succes-5">
                                            <span className="text-center textAutoAdjust  text-primary">
                                                <div className="fs_10"><i>Back Subtotal</i></div>
                                                <div className="fw_600 fs_12">-130.00</div>
                                            </span>
                                            <span className="text-center w-auto">
                                                <div className="fs_12">+</div>
                                            </span>
                                            <span className="text-center textAutoAdjust  text-red_01">
                                                <div className="fs_10"><i>Lay Subtotal</i></div>
                                                <div className="fw_600 fs_12">0.00</div>
                                            </span>
                                            <span className="text-center">
                                                <div className="fs_12">+</div>
                                            </span>
                                            <span className="text-center textAutoAdjust ">
                                                <div className="fs_10"><i>Commission</i></div>
                                                <div className="fw_600 fs_12">0.00</div>
                                            </span>
                                            <span className="text-center">
                                                <div className="fs_12">=</div>
                                            </span>
                                            <span className="text-center textAutoAdjust text-primary">
                                                <div className="fs_10"><i>Net Total</i></div>
                                                <div className="fw_600 fs_12">-130.00</div>
                                            </span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div> */}

                {
                    // profitList?.loader == false && profitList?.data?.length != 0
                    //     ?
                    //     profitList?.data?.map((ele, ind) => {
                    //         return <div>
                    //             <div className=' px-3 mb-2 mx-0 '>
                    //                 <div className=' fw_700 text-center  m-0 p-0 fs_12 text-white'>
                    //                     <div className="">2023-04-02</div>
                    //                 </div>
                    //             </div>
                    //             <div className="bet_card_wrapper mb-3 border-succes-5">
                    //                 <div className="row m-0 green_glass border-success py-3 align-items-center position_relative">
                    //                     <span className="col-12 fs_9 text-white">Match Odds</span>
                    //                     <span className="col-11 fs_14 text-white">Koepfer v Tirante</span>
                    //                 </div>
                    //                 <div className="row m-0 white_glass py-3 align-items-end green_btm_border">
                    //                     <div className='col-8 border-right text-white fs_12 fw_400'>
                    //                         <div className='row '>
                    //                             <div className='col-6'>
                    //                                 <p className='mb-0'>Start Time</p>
                    //                             </div>
                    //                             <div className='col-6'>
                    //                                 <p className='mb-0'>: 05:01:26</p>
                    //                             </div>
                    //                         </div>
                    //                         <div className='row text-white'>
                    //                             <div className='col-6'>
                    //                                 <p className='mb-0'> Settle Time</p>
                    //                             </div>
                    //                             <div className='col-6'>
                    //                                 <p className='mb-0'>: 05:01:26</p>
                    //                             </div>
                    //                         </div>
                    //                     </div>
                    //                     <div className='col-4 text-white text-end align-items-end fs_10 fw_400'>
                    //                         <p className='text-yellow_01 fw_700 mb-0'>Net : <span className='text-red_01' >-56.69</span></p>
                    //                     </div>
                    //                 </div>
                    //             </div>
                    //         </div>
                    //     })
                    //     :
                    //     <div>
                    //         {
                    //             profitList?.data?.length == 0
                    //             &&
                    //             <div className="bet_card_wrapper mb_4rem">
                    //                 <div className="row m-0 mx-3 white_glass py-3 align-items-end d-flex align-items-center justify-content-center py-5">
                    //                     <span className="text-white fs_12 text-center">No content</span>
                    //                 </div>
                    //             </div>
                    //         }
                    //         {
                    //             profitList?.loader == true
                    //             &&
                    //             <div className='text-center'>
                    //                 <div className="spinner-border text-primary" role="status"></div>
                    //             </div>
                    //         }
                    //     </div>
                }
            </div >
            <MobileFooter />
        </div >
    );
};

export default ProfitAndLoss;