import React from "react";
import football from "./../assets/images/football.png";
import bat from "./../assets/images/bat.png";
import table from "./../assets/images/table.png";
import basket from "./../assets/images/basket.png";
import vollyball from "./../assets/images/vollyball.png";
import tenis from "./../assets/images/tenis.png";
import Carousel from "react-bootstrap/Carousel";
import inplayimg from "./../assets/images/inplayimg.png";
import Slider from "react-slick";
import rightarrow from "../assets/images/rightarrow.png";
import leftarrow from "../assets/images/leftarrow.png";
import downarrow from "../assets/images/downarrow.png";
import bang_flag from "../assets/images/bang_flag.png";
import eng_flag from "../assets/images/eng_flag.png";
import livescore from "../assets/images/livescore.png";
import "../custom.css";
import Switch from "@mui/material/Switch";
import { styled } from "@mui/material/styles";

function OddMainPage() {
  var settingsfootball = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  const AntSwitch = styled(Switch)(({ theme }) => ({
    width: 28,
    height: 16,
    padding: 0,
    display: "flex",
    "&:active": {
      "& .MuiSwitch-thumb": {
        width: 15,
      },
      "& .MuiSwitch-switchBase.Mui-checked": {
        transform: "translateX(9px)",
      },
    },
    "& .MuiSwitch-switchBase": {
      padding: 2,
      "&.Mui-checked": {
        transform: "translateX(12px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          opacity: 1,
          backgroundColor:
            theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
        },
      },
    },
    "& .MuiSwitch-thumb": {
      boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
      width: 12,
      height: 12,
      borderRadius: 6,
      transition: theme.transitions.create(["width"], {
        duration: 200,
      }),
    },
    "& .MuiSwitch-track": {
      borderRadius: 16 / 2,
      opacity: 1,
      backgroundColor:
        theme.palette.mode === "dark"
          ? "rgba(255,255,255,.35)"
          : "rgba(0,0,0,.25)",
      boxSizing: "border-box",
    },
  }));
  return (
    <div className="">
      <div className="PlayLiveGamesdiv text-white">
        <div className="container-fluid px-5 py-3">
          <div className="crickettitle">
            <h2 className="headingcricket">Cricket Betting Odds</h2>
            <p className="paracricket">
              {" "}
              Homepage <img src={rightarrow} />{" "}
              <span className="paracricket2">Cricket Betting Odds </span>
            </p>
          </div>

          <div className="row ">
            <div className="col-md-12">
              <div>
                <Slider {...settingsfootball}>
                  <div className="px-2">
                    <div className="card mx-1  cardfootball">
                      <div className="card-body footballcard">
                        <img src={football} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">
                          Football
                        </h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={bat} className="px-1" />
                        <a href="/Cricket">
                          <h1 className="text-dark Footballtext px-2">
                            Cricket
                          </h1>
                        </a>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={tenis} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">Tennis</h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={vollyball} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">
                          Volleyball
                        </h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={tenis} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">Tennis</h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1  cardfootball">
                      <div className="card-body footballcard">
                        <img src={football} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">
                          Football
                        </h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={bat} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">Cricket</h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={tenis} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">Tennis</h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={vollyball} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">
                          Volleyball
                        </h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={tenis} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">Tennis</h1>
                      </div>
                    </div>{" "}
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="oddbg">
        <div className="row odd-main">
          <div className="col-md-9">
            <p className="oddmain-head">
              <span className="textmatch">In-PLAY</span>
            </p>
          </div>
          <div className="col-md-3 teamnames">
            <div className="odd-main-search">
              <svg
                width="18"
                height="18"
                viewBox="0 0 18 18"
                className="search-icon"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M11.3419 12.4392C8.60017 14.6351 4.58684 14.4623 2.04526 11.9207C-0.681755 9.19366 -0.681755 4.77228 2.04526 2.04527C4.77228 -0.681755 9.19366 -0.681755 11.9207 2.04527C14.4623 4.58684 14.6351 8.60016 12.4392 11.3419L17.7728 16.6755C18.0758 16.9785 18.0758 17.4697 17.7728 17.7727C17.4698 18.0758 16.9785 18.0758 16.6755 17.7727L11.3419 12.4392ZM3.14253 10.8234C1.02152 8.70239 1.02152 5.26355 3.14253 3.14253C5.26355 1.02152 8.70239 1.02152 10.8234 3.14253C12.9429 5.26199 12.9444 8.69735 10.8281 10.8187C10.8265 10.8203 10.8249 10.8218 10.8234 10.8234C10.8218 10.8249 10.8203 10.8265 10.8187 10.8281C8.69735 12.9444 5.26199 12.9429 3.14253 10.8234Z"
                  fill="#999999"
                />
              </svg>
              <input
                type="search"
                placeholder="Search"
                value=""
                className="search-input"
              />
            </div>
          </div>
        </div>
        <div className="cricketdiv">
          <div className="container-fluid px-5 py-3">
            <div className="row sec">
              <div className="col-md-12">
                <div className="odd-matchpoint">
                  <div className="row">
                    <div className="col-md-1">
                      <p className="oddmianborder">
                        <span className="oddmatch">In-Play</span>
                      </p>
                    </div>
                    <div className="col-md-1">
                      <p className="oddmianborder">
                        <span className="oddmatch">Favourites</span>
                      </p>
                    </div>
                    <div className="col-md-2">
                      <p className="oddmianborder">
                        <span className="oddmatch">My Markets</span>
                      </p>
                    </div>
                    <div className="col-md-7"> </div>
                    <div className="col-md-1">
                      <p className="bit">Bet Slip</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="odd-toogle">
                      <div className="col-md-2 odd-toogle-space">
                        <p className="switchtext">
                          <span className="textmatch">Sports</span>
                        </p>
                      </div>
                      <div className="col-md-2">
                        <p className="switchtext">Match</p>
                      </div>
                      <div className="col-md-2">
                        <p className="switchtext">Status</p>
                      </div>
                      <div className="col-md-5 odd-toogle-space2">
                        <p className="oddmain-title">
                          <span className="oddmain-border">
                            Click on Odds to BET
                          </span>
                        </p>
                      </div>
                      <div className="col-md-3">
                        <p className="switchtext">Boost Your Bet</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="tooglebutton">
                        <AntSwitch className="switch" />
                        <p className="switchtext">1-click bet</p>{" "}
                        <AntSwitch className="switch" />
                        <p className="switchtext">Accept all odds for BM</p>
                      </div>
                    </div>
                  </div>
                  <div className="row odd-teamtrack">
                    <div className="col-md-1 "></div>
                    <div className="col-md-1">
                      <p className="odd-teamntrackname">Teams</p>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-1 odd-space">
                      <p className="odd-teamntrackname">1</p>
                    </div>
                    <div className="col-md-1 odd-space">
                      <p className="odd-teamntrackname">X</p>
                    </div>
                    <div className="col-md-1">
                      <p className="odd-teamntrackname">2</p>
                    </div>
                  </div>

                  <div className="odd-pointdetails">
                    <div className="col-md-1">
                      <img src={inplayimg} />
                    </div>
                    <div className="col-md-2">
                      <p className="pointdetailsname">1 Bangladesh</p>
                      <p className="pointdetailsname">2 England</p>
                    </div>
                    <div className="col-md-2">
                      <p className="pointdetailsname">27-02-23 </p>
                      <p className="pointdetailsname">09:30 PM</p>
                    </div>
                    <div className="col-md-5 odd-table-points">
                      <div className="pointback odd-pointback">
                        <p className="odd-pointnumber">01.2</p>
                        <p className="odd-pointnumber">01.2</p>
                        <p className="odd-pointempty">01.2</p>
                        <p className="odd-pointempty">01.2</p>
                        <p className="odd-pointnumberlay">01.2</p>
                        <p className="odd-pointnumberlay">01.2</p>
                      </div>
                    </div>
                    <div className="col-md-1 odd-boost">
                      <p className="odd-pointnumber">01.2</p>
                      <p className="odd-pointnumberlay">01.2</p>
                    </div>
                    <p className="odd-toss">ENG Will Win The Toss</p>
                    <div className="col-md-1 odd-morediv">
                      <p className="odd-more">More +</p>
                    </div>
                  </div>
                  <div className="odd-pointdetails">
                    <div className="col-md-1">
                      <img src={inplayimg} />
                    </div>
                    <div className="col-md-2">
                      <p className="pointdetailsname">1 Bangladesh</p>
                      <p className="pointdetailsname">2 England</p>
                    </div>
                    <div className="col-md-2">
                      <p className="pointdetailsname">27-02-23 </p>
                      <p className="pointdetailsname">09:30 PM</p>
                    </div>
                    <div className="col-md-5 odd-table-points">
                      <div className="pointback odd-pointback">
                        <p className="odd-pointnumber">01.2</p>
                        <p className="odd-pointnumber">01.2</p>
                        <p className="odd-pointempty">01.2</p>
                        <p className="odd-pointempty">01.2</p>
                        <p className="odd-pointnumberlay">01.2</p>
                        <p className="odd-pointnumberlay">01.2</p>
                      </div>
                    </div>
                    <div className="col-md-1 odd-boost">
                      <p className="odd-pointnumber">01.2</p>
                      <p className="odd-pointnumberlay">01.2</p>
                    </div>
                    <p className="odd-toss">ENG Will Win The Toss</p>
                    <div className="col-md-1 odd-morediv">
                      <p className="odd-more">More +</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row odd-main2">
          <div className="col-md-9">
            <p className="oddmain-head">
              <span className="textmatch">Popular</span>
            </p>
          </div>
        </div>
        <div className="cricketdiv">
          <div className="container-fluid px-5 py-3">
            <div className="row sec">
              <div className="col-md-12">
                <div className="odd-matchpoint">
                  <div className="row">
                    <div className="col-md-1">
                      <p className="oddmianborder">
                        <span className="oddmatch">Today</span>
                      </p>
                    </div>
                    <div className="col-md-1">
                      <p className="oddmianborder">
                        <span className="oddmatch">Tomorrow</span>
                      </p>
                    </div>
                    <div className="col-md-1">
                      <p className="oddmianborder">
                        <span className="oddmatch">Upcoming</span>
                      </p>
                    </div>
                    <div className="col-md-8"> </div>
                    <div className="col-md-1">
                      <p className="bit">Bet Slip</p>
                    </div>
                  </div>
                  <div className="row">
                    <div className="odd-toogle">
                      <div className="col-md-2 odd-toogle-space">
                        <p className="switchtext">
                          <span className="textmatch">Sports</span>
                        </p>
                      </div>
                      <div className="col-md-2">
                        <p className="switchtext">Match</p>
                      </div>
                      <div className="col-md-2">
                        <p className="switchtext">Status</p>
                      </div>
                      <div className="col-md-5 odd-toogle-space2">
                        <p className="oddmain-title">
                          <span className="oddmain-border">
                            Click on Odds to BET
                          </span>
                        </p>
                      </div>
                      <div className="col-md-3">
                        <p className="switchtext">Boost Your Bet</p>
                      </div>
                    </div>
                    <div className="col-md-3">
                      <div className="tooglebutton">
                        <AntSwitch className="switch" />
                        <p className="switchtext">1-click bet</p>{" "}
                        <AntSwitch className="switch" />
                        <p className="switchtext">Accept all odds for BM</p>
                      </div>
                    </div>
                  </div>
                  <div className="row odd-teamtrack">
                    <div className="col-md-1"></div>
                    <div className="col-md-1">
                      <p className="odd-teamntrackname">Teams</p>
                    </div>
                    <div className="col-md-2"></div>
                    <div className="col-md-1 odd-space">
                      <p className="odd-teamntrackname">1</p>
                    </div>
                    <div className="col-md-1 odd-space">
                      <p className="odd-teamntrackname">X</p>
                    </div>
                    <div className="col-md-1">
                      <p className="odd-teamntrackname">2</p>
                    </div>
                  </div>

                  <div className="odd-pointdetails">
                    <div className="col-md-1">
                      <img src={inplayimg} />
                    </div>
                    <div className="col-md-2">
                      <p className="pointdetailsname">1 Bangladesh</p>
                      <p className="pointdetailsname">2 England</p>
                    </div>
                    <div className="col-md-2">
                      <p className="pointdetailsname">27-02-23 </p>
                      <p className="pointdetailsname">09:30 PM</p>
                    </div>
                    <div className="col-md-5 odd-table-points">
                      <div className="pointback odd-pointback">
                        <p className="odd-pointnumber">01.2</p>
                        <p className="odd-pointnumber">01.2</p>
                        <p className="odd-pointempty">01.2</p>
                        <p className="odd-pointempty">01.2</p>
                        <p className="odd-pointnumberlay">01.2</p>
                        <p className="odd-pointnumberlay">01.2</p>
                      </div>
                    </div>
                    <div className="col-md-1 odd-boost">
                      <p className="odd-pointnumber">01.2</p>
                      <p className="odd-pointnumberlay">01.2</p>
                    </div>
                    <p className="odd-toss">ENG Will Win The Toss</p>
                    <div className="col-md-1 odd-morediv">
                      <p className="odd-more">More +</p>
                    </div>
                  </div>
                  <div className="odd-pointdetails">
                    <div className="col-md-1">
                      <img src={inplayimg} />
                    </div>
                    <div className="col-md-2">
                      <p className="pointdetailsname">1 Bangladesh</p>
                      <p className="pointdetailsname">2 England</p>
                    </div>
                    <div className="col-md-2">
                      <p className="pointdetailsname">27-02-23 </p>
                      <p className="pointdetailsname">09:30 PM</p>
                    </div>
                    <div className="col-md-5 odd-table-points">
                      <div className="pointback odd-pointback">
                        <p className="odd-pointnumber">01.2</p>
                        <p className="odd-pointnumber">01.2</p>
                        <p className="odd-pointempty">01.2</p>
                        <p className="odd-pointempty">01.2</p>
                        <p className="odd-pointnumberlay">01.2</p>
                        <p className="odd-pointnumberlay">01.2</p>
                      </div>
                    </div>
                    <div className="col-md-1 odd-boost">
                      <p className="odd-pointnumber">01.2</p>
                      <p className="odd-pointnumberlay">01.2</p>
                    </div>
                    <p className="odd-toss">ENG Will Win The Toss</p>
                    <div className="col-md-1 odd-morediv">
                      <p className="odd-more">More +</p>
                    </div>
                  </div>
                  <div className="odd-pointdetails">
                    <div className="col-md-1">
                      <img src={inplayimg} />
                    </div>
                    <div className="col-md-2">
                      <p className="pointdetailsname">1 Bangladesh</p>
                      <p className="pointdetailsname">2 England</p>
                    </div>
                    <div className="col-md-2">
                      <p className="pointdetailsname">27-02-23 </p>
                      <p className="pointdetailsname">09:30 PM</p>
                    </div>
                    <div className="col-md-5 odd-table-points">
                      <div className="pointback odd-pointback">
                        <p className="odd-pointnumber">01.2</p>
                        <p className="odd-pointnumber">01.2</p>
                        <p className="odd-pointempty">01.2</p>
                        <p className="odd-pointempty">01.2</p>
                        <p className="odd-pointnumberlay">01.2</p>
                        <p className="odd-pointnumberlay">01.2</p>
                      </div>
                    </div>
                    <div className="col-md-1 odd-boost">
                      <p className="odd-pointnumber">01.2</p>
                      <p className="odd-pointnumberlay">01.2</p>
                    </div>
                    <p className="odd-toss">ENG Will Win The Toss</p>
                    <div className="col-md-1 odd-morediv">
                      <p className="odd-more">More +</p>
                    </div>
                  </div>
                  <div className="odd-pointdetails">
                    <div className="col-md-1">
                      <img src={inplayimg} />
                    </div>
                    <div className="col-md-2">
                      <p className="pointdetailsname">1 Bangladesh</p>
                      <p className="pointdetailsname">2 England</p>
                    </div>
                    <div className="col-md-2">
                      <p className="pointdetailsname">27-02-23 </p>
                      <p className="pointdetailsname">09:30 PM</p>
                    </div>
                    <div className="col-md-5 odd-table-points">
                      <div className="pointback odd-pointback">
                        <p className="odd-pointnumber">01.2</p>
                        <p className="odd-pointnumber">01.2</p>
                        <p className="odd-pointempty">01.2</p>
                        <p className="odd-pointempty">01.2</p>
                        <p className="odd-pointnumberlay">01.2</p>
                        <p className="odd-pointnumberlay">01.2</p>
                      </div>
                    </div>
                    <div className="col-md-1 odd-boost">
                      <p className="odd-pointnumber">01.2</p>
                      <p className="odd-pointnumberlay">01.2</p>
                    </div>
                    <p className="odd-toss">ENG Will Win The Toss</p>
                    <div className="col-md-1 odd-morediv">
                      <p className="odd-more">More +</p>
                    </div>
                  </div>
                  <div className="odd-pointdetails">
                    <div className="col-md-1">
                      <img src={inplayimg} />
                    </div>
                    <div className="col-md-2">
                      <p className="pointdetailsname">1 Bangladesh</p>
                      <p className="pointdetailsname">2 England</p>
                    </div>
                    <div className="col-md-2">
                      <p className="pointdetailsname">27-02-23 </p>
                      <p className="pointdetailsname">09:30 PM</p>
                    </div>
                    <div className="col-md-5 odd-table-points">
                      <div className="pointback odd-pointback">
                        <p className="odd-pointnumber">01.2</p>
                        <p className="odd-pointnumber">01.2</p>
                        <p className="odd-pointempty">01.2</p>
                        <p className="odd-pointempty">01.2</p>
                        <p className="odd-pointnumberlay">01.2</p>
                        <p className="odd-pointnumberlay">01.2</p>
                      </div>
                    </div>
                    <div className="col-md-1 odd-boost">
                      <p className="odd-pointnumber">01.2</p>
                      <p className="odd-pointnumberlay">01.2</p>
                    </div>
                    <p className="odd-toss">ENG Will Win The Toss</p>
                    <div className="col-md-1 odd-morediv">
                      <p className="odd-more">More +</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default OddMainPage;
