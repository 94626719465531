import React, { useEffect, useRef, useState } from "react";
// import Icon from "../assets/images/Icon.png";
import Prince from "../assets/images/Prince-raj.png";
import Symbols from "../assets/images/symbols.png";
import vector from "../assets/images/Vector.png";
import football from "../assets/images/gamefootball.png";
import IconLower from "../assets/images/IconLower.png";
import Tennis from "../assets/images/Tennis.png";
import Hockey from "../assets/images/Hockey.png";
import icon from "../assets/images/Icon.png";
import ProfileDashboard from "../components/Profile/ProfileDashboard";
import DepositDashboard from "../components/Finance/Deposit";
import PrimaryNavbar from "../components/PrimaryNavbar";
import Footer from "../components/Footer";
import profileUser from "../assets/images/profileUser.png";
import BetDetails from "../components/BetDetails/BetDetails";
import rupeesIcon from "../assets/images/rupeesIcon.png";
import betIcon from "../assets/images/betIcon.png";
import Logout from "../assets/images/LogoutIcon.png";
import History from "../components/History/History";
import Password from "../components/Password/Password";
import Withdrawals from "../components/Withdrawal/Withdrawal";
import BankDetail from "../components/BankDetail/BankDetail";
import ProfitAndLoss from "../components/ProfitAndLoss/ProfitAndLoss";
import AccountStatement from "../components/AccountStatement/AccountStatement";
import MobileFooter from "../components/MobileFooter";
// import axios from "axios";

function AccountsInfo() {
  const toggleSidebar = () => {
    const sidebar = document.getElementById("mobile_sidebar");
    sidebar?.classList.toggle("d-none");
  };

  const ref1 = useRef(null)
  const ref2 = useRef(null)
  const [activeMenu, setActiveMenu] = useState("");
  const [pageId, setPageId] = useState("1");

  const [logindata, setLoginData] = useState();
  useEffect(() => {
    const userLoginData = JSON.parse(localStorage.getItem("betUser"));
    setLoginData(userLoginData);
  }, []);

  return (
    <div>
      <PrimaryNavbar />
      <div className="PlayLiveGamesdiv text-white">
        <div className="container-fluid px-5 py-3">
          <h2 className="headingAccounts">Accounts Info</h2>
          <div className="AccountsUser">
            <p>Accounts -My Dashboard -Profile -Accounts Info</p>

            <div className="input-groups">
              <div className="input-search-box">
                <img className="searchimage-icon" src={icon} />
              </div>
              <input className="form-controls" placeholder="Search" />
            </div>
          </div>

          <div className="account-dashboard-flex row">
            {/* dashboard section */}
            <div className="pe-2 col-md-3 mb-3" ref={ref2}>
              <div className="my-dashboard w-100">
                <div className="symbols-dashboard">
                  <img src={Symbols} alt="Symbols" />
                  <p className="dashboard-p">My dashboard</p>
                </div>
                <div className="image-user-dashboard">
                  <img src={Prince} alt="Prince-raj" />
                  <h4 className="name-author">{logindata?.name || "-"} </h4>
                  <p className="blue-tier">Blue Tier</p>
                </div>
                <div className="container-accordian">
                  <div className="accordian-dropdoen-parent">
                    <div className="accordian-dropdoen-child">
                      <div id="MainMenu">
                        <div className="list-group-panel">
                          <a
                            href="#demo1"
                            className={`color-muted list-group-item-list-group-item-success ${activeMenu === "profile" ? "selectedMenu" : ""
                              }`}
                            data-toggle="collapse"
                            data-parent="#MainMenu"
                            onClick={() => { setActiveMenu("profile") }}
                          >
                            <div className="dashboard-user-left">
                              <div className="images-dashboard-user-profile">
                                {/* <img src={UserIcon} /> Profile{" "} */}
                                <img src={profileUser} alt="" /> Profile{" "}
                              </div>
                              <div>
                                <img src={IconLower} alt="IconLower" />
                              </div>
                            </div>
                          </a>
                          <div className="collapse" id="demo1">
                            <a
                              href="javascript:;"
                              className="list-group-item"
                              onClick={() => {
                                setPageId("1");
                                { setActiveMenu("profile"); ref1?.current?.scrollIntoView({ behaviour: "smooth" }) };
                              }}
                            >
                              Account Info
                            </a>
                            <a href="javascript:;" className="list-group-item" onClick={() => {
                              setPageId("5");
                              setActiveMenu("profile"); ref1?.current?.scrollIntoView({ behaviour: "smooth" });
                            }}>
                              Password
                            </a>
                            <a href="javascript:;" className="list-group-item" onClick={() => {
                              setPageId("6");
                              setActiveMenu("profile"); ref1?.current?.scrollIntoView({ behaviour: "smooth" })
                            }}>
                              Bank Details
                            </a>
                            {/* <a className="kyc-verification-hide" href="javascript:;" className="list-group-item">
                              KYC Verification
                            </a> */}
                          </div>
                          {/*  */}
                          <a
                            href="#demo2"
                            className={`list-group-item-list-group-item-success ${activeMenu === "finance" ? "selectedMenu" : ""
                              }`}
                            data-toggle="collapse"
                            data-parent="#MainMenu"
                            onClick={() => { setActiveMenu("finance") }}
                          >
                            <div className="dashboard-user-left">
                              <div className="images-dashboard-user-profile">
                                <img src={rupeesIcon} alt="rupeesIcon" />{" "}
                                Finance{" "}
                              </div>
                              <div>
                                {" "}
                                <img src={IconLower} alt="IconLower" />
                              </div>
                            </div>
                          </a>
                          <div className="collapse" id="demo2">
                            <a
                              href="javascript:;"
                              className="list-group-item"
                              onClick={() => {
                                setPageId("2");
                                { setActiveMenu("finance"); ref1?.current?.scrollIntoView({ behaviour: "smooth" }) };
                              }}
                            >
                              Deposit
                            </a>
                            <a
                              href="javascript:;"
                              className="list-group-item"
                              onClick={() => {
                                setPageId("22");
                                { setActiveMenu("finance"); ref1?.current?.scrollIntoView({ behaviour: "smooth" }) };
                              }}
                            >
                              Withdraw
                            </a>
                            {/* <a href="javascript:;" className="list-group-item">
                              Wallet Transfer
                            </a> */}
                            <a
                              href="javascript:;"
                              className="list-group-item"
                              onClick={() => {
                                setPageId("4");
                                { setActiveMenu("finance"); ref1?.current?.scrollIntoView({ behaviour: "smooth" }) };
                              }}
                            >
                              History
                            </a>
                          </div>
                          {/*  */}
                          <a
                            href="#demo3"
                            className={`list-group-item-list-group-item-success ${activeMenu === "BetDetails" ? "selectedMenu" : ""
                              }`}
                            data-toggle="collapse"
                            data-parent="#MainMenu"
                            onClick={() => { setActiveMenu("BetDetails") }}
                          >
                            <div className="dashboard-user-left">
                              <div className="images-dashboard-user-profile">
                                <img src={betIcon} alt="betIcon" /> Bet Details{" "}
                              </div>
                              <div>
                                <img src={IconLower} alt="IconLower" />
                              </div>{" "}
                            </div>
                          </a>
                          <div className="collapse" id="demo3">
                            <a
                              href="javascript:;"
                              className="list-group-item"
                              onClick={() => {
                                setPageId("3");

                                { setActiveMenu("BetDetails"); ref1?.current?.scrollIntoView({ behaviour: "smooth" }) };
                              }}
                            >
                              My Bets
                            </a>
                            <a href="javascript:;"
                              className="list-group-item"
                              onClick={() => {
                                setPageId("7");
                                { setActiveMenu("ProfitAndLoss"); ref1?.current?.scrollIntoView({ behaviour: "smooth" }) };
                              }}>
                              Profit & Loss
                            </a>
                            {/* <a href="javascript:;" className="list-group-item">
                              Casino statement
                            </a> */}
                            {/* <a href="javascript:;" className="list-group-item">
                              Sportsbook Statement
                            </a> */}
                            <a href="javascript:;"
                              className="list-group-item"
                              onClick={() => {
                                setPageId("8");
                                { setActiveMenu("AccountStatement"); ref1?.current?.scrollIntoView({ behaviour: "smooth" }) };
                              }}>
                              Account Statement
                            </a>
                            {/* <a href="javascript:;" className="list-group-item">
                              Virtual Sports Statement
                            </a> */}
                            <a href="javascript:;" className="list-group-item">
                              Turnover Statement
                            </a>
                          </div>
                          {/* <a
                            href="#demo4"
                            className={`list-group-item-list-group-item-success ${activeMenu === "Promotions" ? "selectedMenu" : ""
                              }`}
                            data-toggle="collapse"
                            data-parent="#MainMenu"
                            onClick={() => { setActiveMenu("Promotions") }}
                          >
                            <div className="dashboard-user-left">
                              <div className="images-dashboard-user-profile">
                                {" "}
                                <img src={promotionIcon} /> Promotions{" "}
                              </div>
                              <div>
                                <img src={IconLower} alt="IconLower" />
                              </div>{" "}
                            </div>
                          </a> */}
                          <div className="collapse" id="demo4">
                            <a href="javascript:;" className="list-group-item">
                              Account Info
                            </a>
                            <a href="javascript:;" className="list-group-item">
                              Password
                            </a>
                            <a href="javascript:;" className="list-group-item">
                              Bank Details
                            </a>
                            <a href="javascript:;" className="list-group-item">
                              KYC Verification
                            </a>
                          </div>{" "}
                          {/* <a
                            href="#demo5"
                            className={`list-group-item-list-group-item-success ${activeMenu === "Messages" ? "selectedMenu" : ""
                              }`}
                            data-toggle="collapse"
                            data-parent="#MainMenu"
                            onClick={() => { setActiveMenu("Messages") }}
                          >
                            <div className="dashboard-user-left">
                              <div className="images-dashboard-user-profile">
                                {" "}
                                <img src={messageIcon} /> Messages{" "}
                              </div>
                              <div>
                                <img src={IconLower} alt="IconLower" />
                              </div>{" "}
                            </div>
                          </a> */}
                          <div className="collapse" id="demo5">
                            <a href="javascript:;" className="list-group-item">
                              Account Info
                            </a>
                            <a href="javascript:;" className="list-group-item">
                              Password
                            </a>
                            <a href="javascript:;" className="list-group-item">
                              Bank Details
                            </a>
                            <a href="javascript:;" className="list-group-item">
                              KYC Verification
                            </a>
                          </div>{" "}
                          {/* <a
                            href="#demo6"
                            className={`list-group-item-list-group-item-success ${activeMenu === "Faq" ? "selectedMenu" : ""
                              }`}
                            data-toggle="collapse"
                            data-parent="#MainMenu"
                            onClick={() => { setActiveMenu("Faq") }}
                          >
                            <div className="dashboard-user-left">
                              <div className="images-dashboard-user-profile">
                                <img src={FAQIcon} /> FAQ{" "}
                              </div>
                            </div>
                          </a> */}
                          <a
                            href="#demo6"
                            className={`list-group-item-list-group-item ${activeMenu === "logout" ? "selectedMenu" : ""
                              }`}
                            data-toggle="collapse"
                            data-parent="#MainMenu"
                            onClick={() => { setActiveMenu("logout") }}
                          >
                            <div className="dashboard-user-left">
                              <div className="images-dashboard-user-profile">
                                <img src={Logout} /> Log Out{" "}
                              </div>
                            </div>
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* account-section-middle */}
            <div ref={ref1} className="col-md-6 m-0 p-0">
              {pageId === "1" ? <ProfileDashboard ref2={ref2} /> : ""}
              {pageId === "2" ? <DepositDashboard ref2={ref2} /> : ""}
              {pageId === "3" ? <BetDetails ref2={ref2} /> : ""}
              {pageId === "4" ? <History ref2={ref2} /> : ""}
              {pageId === "22" ? <Withdrawals ref2={ref2} /> : ""}
              {pageId === "5" ? <Password ref2={ref2} /> : ""}
              {pageId === "6" ? <BankDetail ref2={ref2} /> : ""}
              {pageId === "7" ? <ProfitAndLoss ref2={ref2} /> : ""}
              {pageId === "8" ? <AccountStatement ref2={ref2} /> : ""}
            </div>
            {/* <div ref={ref1} className="col-md-6 m-0 p-0">
              {pageId === "1" ? <ProfileDashboard ref2={ref2}/> : ""}
              {pageId === "2" ? <DepositDashboard  /> : ""}
              {pageId === "3" ? <BetDetails  /> : ""}
              {pageId === "4" ? <History  /> : ""}
              {pageId === "22" ? <Withdrawals  /> : ""}
            </div> */}

            {/* bonus-section-right */}
            <div className="bonus-accounts col-md-3">
              <div className="welcome-bonus-one">
                <p className="dashboard-p1">Bonus</p>
                <div className="bonus">
                  <div className="vector-bonus">
                    <img src={vector} alt="vectr" />
                    <div className="vector-texts">
                      <p className="welcome-bonus">Welcome Bonus</p>
                      <p className="welcome-bonus-two">
                        Get 60% OFF on first time deposit
                      </p>
                    </div>
                  </div>
                  <div className="vector-bonus">
                    <img src={vector} alt="vectr" />
                    <div className="vector-texts">
                      <p className="welcome-bonus">Refill Bonus</p>
                      <p className="welcome-bonus-two">
                        Get 20% OFF on first time deposit
                      </p>
                    </div>
                  </div>
                  <div className="vector-bonus">
                    <img src={vector} alt="vectr" />
                    <div className="vector-texts">
                      <p className="welcome-bonus">Cashback Bonus</p>
                      <p className="welcome-bonus-two">
                        Get 4% OFF on first time deposit
                      </p>
                    </div>
                  </div>
                  <div className="vector-bonus">
                    <img src={vector} alt="vectr" />
                    <div className="vector-texts">
                      <p className="welcome-bonus">Referral Bonus</p>
                      <p className="welcome-bonus-two">
                        Get 5% OFF on first time deposit
                      </p>
                    </div>
                  </div>
                  <p className="blue-tier-action">Check your bonus</p>
                </div>
              </div>
              <div className="games-bonus">
                <p className="dashboard-p">Popular Games</p>
                <div className="games-athlete">
                  <div className="images-games">
                    <img src={football} alt="football" />
                  </div>
                  <div className="images-games">
                    <img src={Tennis} alt="football" />
                  </div>
                  <div className="images-games">
                    <img src={Hockey} alt="football" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />

      <MobileFooter />
    </div >
  );
}

export default AccountsInfo;
