import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import login from "./../assets/images/login.png";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrimaryNavbar from "../components/PrimaryNavbar";
import ArrowLeft from "../assets/images/profileDashboard/arrowLeft.svg"
import { fontSize } from "@mui/system";
import baseUrl from "../services/baseUrl";
import { checkPassword } from "../services/helper";

function LoginPage() {
  const navigate = useNavigate();

  const [loginForm, setLoginForm] = useState({
    phoneNumber: "",
    password: "",
    loader: false,
  });
  const [loginFormError, setLoginFormError] = useState({
    phoneNumber: "",
    password: "",
  });

  const handleChange = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
    setLoginFormError({ ...loginFormError, [e.target.name]: null });
  };

  const handleValidation = () => {
    const { phoneNumber, password } = loginForm;
    var rphoneNumberReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const newErrors = {};
    if (!phoneNumber) {
      newErrors.phoneNumber = "Phone Number Required";
    } else if (phoneNumber && phoneNumber.length > 10) {
      newErrors.phoneNumber = "Phone Number should be below 10 characters";
    } else if (phoneNumber && phoneNumber.length < 10) {
      newErrors.phoneNumber = "Phone Number should be  10 characters";
    }
    if (!password) {
      newErrors.password = "Please Enter Password";
    }
    if(!checkPassword(password)){
      newErrors.password = "Min 8 letter password, with at least a symbol, upper and lower case letters and a number"
    }
    return newErrors;
  };

  let [userData, setUserData] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const handleValidationObject = handleValidation();
    if (Object.keys(handleValidationObject).length > 0) {
      setLoginFormError(handleValidationObject);
    } else {
      setLoginForm({ ...loginForm, loader: true });
      try {
        let payLoad = {
          phone: loginForm.phoneNumber,
          password: loginForm.password,
        };

        let data = await axios.post(
          `${baseUrl}/user/login`,
          payLoad
        );
        if (data?.data?.data) {
          setLoginForm({ ...loginForm, loader: false });
          setUserData(data?.data?.data);
          toast.success("login sucess", {
            position: "top-center",
            autoClose: 2000,
          });
          localStorage.setItem("betUser", JSON.stringify(data?.data?.data));
          localStorage.setItem("UserName", JSON.stringify(data?.data?.data?.name));
          setTimeout(() => {
            navigate("/");
          }, 1000);
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, {
          position: "top-center",
          autoClose: 2000,
        });
        setLoginForm({ ...loginForm, loader: false });
      }
    }
  };

  return (
    <div className="loginBg">
      <PrimaryNavbar></PrimaryNavbar>
      <div className="container-fluid g-0 text-white p-4">
        <ToastContainer />
        <div className="pt-5">
          <div className="black_glass border-light_01 position-relative  rounded-8">
          <i className="bi bi-x-lg position-absolute d-flex justify-content-end cursor-pointer" onClick={() => navigate("/")} style={{right: "10px" , top: "10px", fontSize: "25px"}}></i>
            <Form className="px-4 py-5" onSubmit={handleSubmit}>
              <Form.Group className="mb-3">
              <div className='row mb-3 text-white text-start'>
                <div className='col-1'>
                  {/* <img src={ArrowLeft} className="" />  */}
                </div>
                <div className='col-10'>
                  <h2 className="text-center  text-white">Welcome back!</h2>
                </div>
                <div className='col-1'></div>
              </div>
                
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicphoneNumber">
                <Form.Label className=" text-white ">Phone Number</Form.Label>
                <Form.Control
                  type="number"
                  name="phoneNumber"
                  className={`br0  rounded-6 ${loginFormError.phoneNumber ? "border border-danger  " : ""
                    }`}
                  onChange={handleChange}
                  placeholder="Enter Phone Number"
                  maxLength={10}
                  autoComplete='off'
                  onInput={(e) => {
                    if (e.target.value > e.target.maxLength) {
                      e.target.value = e.target.value.slice(0, e.target.maxLength)
                    }
                  }}
                />
                <span className="text-danger">
                  {loginFormError && loginFormError?.phoneNumber}
                </span>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicPassword">
                <Form.Label>Password</Form.Label>
                <Form.Control
                  type="password"
                  name="password"
                  autoComplete='off'
                  className={`br0   rounded-6${loginFormError.password ? "border border-danger" : ""
                    }`}
                  onChange={handleChange}
                  placeholder="Password"
                />
                <span className="text-danger">
                  {loginFormError && loginFormError?.password}
                </span>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicCheckbox">
              <Link  to='/send-otp' className="text-decoration-none" >  <Form.Text className="text-white text-end" >Forgot Password?</Form.Text></Link>
              </Form.Group>

              <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Button
                  variant="primary"
                  disabled={loginForm?.loader}
                  type="submit"
                  className="br0 w-100  text-white  rounded-6"
                >
                  Login{" "}
                  {loginForm?.loader && (
                    <div
                      className="spinner-border spinner-border-sm "
                      role="status"
                    ></div>
                  )}
                </Button>
              </Form.Group>

              <Form.Group className="mb-3">
                <p className="text-center">
                  Do not have an Account?{" "}
                  <Link
                    to="/registration-page"
                    className="text-decoration-none  text-warning mx-2"
                  >
                    <b>SIGN UP</b>
                  </Link>
                </p>
              </Form.Group>
            </Form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default LoginPage;
