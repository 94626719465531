import React from "react";
import Footer from "../components/Footer";
import MobileFooter from "../components/MobileFooter";
import MobileSideBar from "../components/MobileSideBar/MobileSideBar";
import PrimaryNavbar from "../components/PrimaryNavbar";
import ArrowLeft from "../assets/images/profileDashboard/arrowLeft.svg";
import { useNavigate } from "react-router-dom";

const KYCPolicy = () => {
  const navigate = useNavigate();

  return (
    <div>
      <PrimaryNavbar></PrimaryNavbar>
      <div className="position-relative">
        <MobileSideBar />
      </div>
      <div className="terms-and-conditions">
        <div className="main-content-tac">
          <div className="row position-relative" onClick={() => navigate(-1)}>
          <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
              <img src={ArrowLeft} className="cursor-pointer arrow-icone-size rounded-4" />
            </div>
          </div>
          <h2 className="terms-and-cond-heading mt-2">
            KYC Policy Of Betkaro India
          </h2>
          <p>Last Updated: 09/06/2022</p>

          <p>
            This Privacy Policy Describes Our Policies And Procedures On The
            Collection, Use, And Disclosure Of Your Information When You Use The
            Service And Tells You About Your Privacy Rights And How The Law
            Protects You. We Use Your Personal Data To Provide And Improve The
            Service. By Using The Service, You Agree To The Collection And Use
            Of Information In Accordance With This Privacy Policy. This Privacy
            Policy Has Been Created With The Help Of The Privacy Policy
            Template.
          </p>
          <h2 className="terms-and-cond-heading">
            Interpretation And Definitions
          </h2>

          <p>
            When A User Makes An Aggregate Lifetime Total Of Deposits Exceeding
            INR 200,000 Or Requests A Withdrawal Of Any Amount Inside The
            www.Betkaro India.com Platform, Then It Is Compulsory For Them To
            Perform A Full KYC Process. During This Process, The User Will Have
            To Input Some Basic Details About Themselves And Then Upload A Copy
            Of Government Issued Photo ID (In Some Cases Front And Back
            Depending On The Doc) A Selfie Of Themselves Holding The ID Doc. A
            Bank Statement/Utility Bill. Once Uploaded, The User Will Get A
            “Temporarily Approved” Status And The Documents Will Now Be On Our
            Side, And The “KYC Team” Will Have 24hrs To Go Over Them And Email
            The User About The Outcome: Approval Rejection More Information
            Needed – No Change In Status When The User Is On “Temporarily
            Approved” Status Then They Can Use The Platform Normally They Cannot
            Deposit More Than INR 50,000 In Aggregate Total They Cannot Complete
            Any Withdrawal.
          </p>
          <div className="contentn-bets-terms-and-condition">
            <h2>Guideline For The “KYC Process”</h2>

            <p>
              1) Proof Of ID Signature Is There Country Is Not A Restricted
              Country: United States Of America And Its Territories, France And
              Its Territories, Netherlands And Its Territories And Countries
              That Form The Kingdom Of Netherlands Including Bonaire, Sint
              Eustatius, Saba, Aruba, Curacao And Sint Maarten,Australia And Its
              Territories, United Kingdom Of Great Britain, Northern Ireland,
              Spain. Full Name Matches Client’s Name Document Does Not Expire In
              The Next 3 Months Owner Is Over 18 Years Of Age 2) Proof Of
              Residence Bank Statement Or Utility Bill Country Is Not A
              Restricted Country: United States Of America And Its Territories,
              France And Its Territories, Netherlands And Its Territories And
              Countries That Form The Kingdom Of Netherlands Including Bonaire,
              Sint Eustatius, Saba, Aruba, Curacao And Sint Maarten, Australia
              And Its Territories, United Kingdom Of Great Britain, Northern
              Ireland, Spain, And Cyprus Full Name Matches Client’s Name And Is
              Same As In Proof Of ID. Date Of Issue: In The Last 3 Months 3)
              Selfie With ID Holder Is The Same As In The ID Document Above ID
              Document Is The Same As In “1”. Make Sure Photo/ID Number Is The
              Same
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>Notes On The “KYC Process”</h2>

            <p>
              Holder Is The Same As In The ID Document Above ID Document Is The
              Same As In “1”. Make Sure Photo/ID Number Is The Same
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>“Other AML Measures”</h2>
            <p>
              If A User Has Not Passed Full KYC Then They Cannot Make Additional
              Deposits Or Withdrawals Of Any Amount. If A User Has Passed The
              KYC Process Successfully Then There Is A Deposit Limit Per
              Transaction (Max INR 200,000) Prior To Any Withdrawal There Is A
              Detailed Algorithmic And Manual Check On The Activity And Balance
              Of The User To See If The Amount Withdrawn Is A Result Of Proper
              Activity In The Platform. Under No Circumstances May A User
              Transfer Funds Directly To Another User.
            </p>
            <br />
          </div>
        </div>
      </div>
      <Footer />
      <MobileFooter />
    </div>
  );
};

export default KYCPolicy;
