import React from "react";
import PrimaryNavbar from "../../components/PrimaryNavbar";
import ArrowLeft from "../../assets/images/profileDashboard/arrowLeft.svg";
import edit from "../../assets/images/profileDashboard/edit.svg";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import MobileFooter from "../../components/MobileFooter";
import MobileSideBar from "../../components/MobileSideBar/MobileSideBar";
import baseUrl from "../../services/baseUrl";
import { checkPassword } from "../../services/helper";

function ChangePassword() {
  const navigate = useNavigate();
  const [pageId, setPageID] = useState("1");
  const [Responce, setResponce] = useState("");
  const [passwordForm, setPasswordForm] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
    loader: false,
  });
  const [passwordFormError, setPasswordFormError] = useState({
    currentPassword: "",
    newPassword: "",
    confirmNewPassword: "",
  });

  const handleSubmit = (e) => {
    e.preventDefault();

    // validate form
    let errors = {};
    let isValid = true;

    if (!passwordForm.currentPassword) {
      errors.currentPassword = "Current password is required";
      isValid = false;
    }

    if (!passwordForm.newPassword) {
      errors.newPassword = "New password is required";
      isValid = false;
    } else if (!checkPassword(passwordForm.newPassword)) {
      errors.newPassword = "Min 8 letter password, with at least a symbol, upper and lower case letters and a number";
      isValid = false;
    }

    if (!passwordForm.confirmNewPassword) {
      errors.confirmNewPassword = "Confirm new password is required";
      isValid = false;
    } else if (passwordForm.newPassword !== passwordForm.confirmNewPassword) {
      errors.confirmNewPassword = "Passwords doesn't match";
      isValid = false;
    }

    setPasswordFormError(errors);
    if (isValid) {
      const token = JSON.parse(localStorage.getItem("betUser"))?.token;
      const headers = {
        headers: {
          "x-access-token": token,
        },
      };
      setPasswordForm({ ...passwordForm, loader: true });
      axios
        .put(
          `${baseUrl}/user/change_password`,
          {
            old_password: passwordForm.currentPassword,
            new_password: passwordForm.newPassword,
          },
          headers
        )
        .then((response) => {
          if (response.data.error) {
            if (response.data.message === "Invalid password") {
              toast.error("Invalid password. Please try again.");
            } else {
              toast.error(response.data.message, {
                position: "top-right",
                theme: "dark",
                autoclose: 2000,
              });
            }
          } else {
            toast.success("Updated Successfully..", {
              position: "top-right",
              theme: "dark",
              autoclose: 2000,
            });
            handleReset();
          }
        })
        .catch((error) => {
          if (
            error.response &&
            error.response.data &&
            error.response.data.message === "Invalid password"
          ) {
            toast.error(error.response.data.message);
          } else {
            toast.error(error.message);
          }
          setPasswordForm({ ...passwordForm, loader: false });
        });
    }
  };
  const handleChange = (e) => {
    setPasswordForm({ ...passwordForm, [e.target.name]: e.target.value });
    setPasswordFormError({ ...passwordFormError, [e.target.name]: null });
  };
  const handleReset = (e) => {
    setPasswordForm({
      currentPassword: "",
      newPassword: "",
      confirmNewPassword: "",
      loader: false,
    });
  };
  return (
    <div>
      <PrimaryNavbar />
      <div className="position-relative">
        <MobileSideBar />
      </div>
      <div className="container py-3 changePasswordBG">
        <div className="row mb-3 text-white">
          <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
            <img src={ArrowLeft} className="cursor-pointer arrow-icone-size rounded-4" />
          </div>
          <div className="col-10">
            <p className="mb-0 currentbets_title fw_700 text-center">
              Change Password
            </p>
          </div>
          <div className="col-1"></div>
        </div>

        <div className="container green_glass py-3 mb-3 border-success rounded-8">
          <div className="row m-0 p-0 align-items-center">
            <span className="col-auto m-0 p-0 d-flex square_green"></span>
            <span className="col-11 t-14 text-white">Account Password</span>
          </div>
          <div className="row m-0 p-0 mt-2 ">
            <input
              type="password"
              placeholder="Current Account password*"
              className={`fs_14 h-42p py-2 rounded-6 ${passwordFormError.currentPassword ? "border border-2 border-danger " : ""
                }`}
              id="currentPassword"
              name="currentPassword"
              value={passwordForm.currentPassword}
              onChange={handleChange}
              required
            />
            {passwordFormError.currentPassword && (
              <div className="text-danger m-0 p-0 font-weight-bold">
                {passwordFormError.currentPassword}
              </div>
            )}
          </div>
          <div className="row m-0 p-0 mt-2">
            <input
              type="password"
              placeholder="New Account password*"
              className={`fs_14 h-42p py-2 rounded-6 ${passwordFormError.newPassword ? "border border-2 border-danger" : ""
                }`}
              id="newPassword"
              name="newPassword"
              value={passwordForm.newPassword}
              onChange={handleChange}
              required
            />
            {passwordFormError.newPassword && (
              <div className="text-danger m-0 p-0 font-weight-bold">
                {passwordFormError.newPassword}
              </div>
            )}
          </div>
          <div className="row m-0 p-0 mt-2">
            <input
              type="password"
              placeholder="Confirm New Account password*"
              className={`fs_14 h-42p py-2 rounded-6 ${passwordFormError.confirmNewPassword ? "border border-2 border-danger" : ""
                }`}
              id="confirmNewPassword"
              name="confirmNewPassword"
              value={passwordForm.confirmNewPassword}
              onChange={handleChange}
              required
            />
            {passwordFormError.confirmNewPassword && (
              <div className="text-danger m-0 p-0 font-weight-bold">
                {passwordFormError.confirmNewPassword}
              </div>
            )}
          </div>
          <div className="row mt-2 mb-3">
            <div className="col-12 d-flex">
              <button
                onClick={handleSubmit}
                disabled={passwordForm.loader}
                className="btn rounded-6  btn-warning rounded-0 shadow-none btn_commn_yellow_1 px-4 me-2"
              >
                {passwordForm.loader ? (
                  <span className="px-3">
                    <span
                      className="spinner-border spinner-border-sm mr-2"
                      role="status"
                      aria-hidden="true"
                    ></span>
                  </span>
                ) : (
                  "Submit"
                )}
              </button>
              <button
                onClick={handleReset}
                className="btn btn-light rounded-6 px-4 shadow-none"
              >
                Reset
              </button>
            </div>
          </div>
        </div>
      </div>

      <MobileFooter />
    </div>
  );
}

export default ChangePassword;
