import React from "react";
import PrimaryNavbar from "../../components/PrimaryNavbar";
import availableBalance from "../../assets/images/profileDashboard/availableBalance.svg"
import overviewgraph from "../../assets/images/profileDashboard/overviewgraph.svg"
import accountInfo from "../../assets/images/profileDashboard/accountInfo.svg"
import walletIcon from "../../assets/images/walletIcon.svg"
import accountStatement from "../../assets/images/profileDashboard/accountStatement.svg"
import casinoStatement from "../../assets/images/profileDashboard/casinoStatement.svg"
import bankDetail from "../../assets/images/profileDashboard/bankDetail.svg"
import bonusWon from "../../assets/images/profileDashboard/bonusWon.svg"
import changePassword from "../../assets/images/profileDashboard/changePassword.svg"
import deposit from "../../assets/images/profileDashboard/deposit.svg"
import history from "../../assets/images/profileDashboard/history.svg"
import logout from "../../assets/images/profileDashboard/logout.svg"
import messages from "../../assets/images/profileDashboard/messages.svg"
import myBets from "../../assets/images/profileDashboard/myBets.svg"
import profitAndloss from "../../assets/images/profileDashboard/profit&loss.svg"
import promotions from "../../assets/images/profileDashboard/promotions.svg"
import turnaroundStatement from "../../assets/images/profileDashboard/turnaroundStatement.svg"
import withdraw from "../../assets/images/profileDashboard/withdraw.svg"
import { useState } from "react";
import axios from "axios";
import baseUrl from "../../services/baseUrl";
import { useEffect } from "react";
import { bonusBalance, update_Profile, get_turnover, walletBalance, get_net_exposure } from "../../services/api";
import { Link, useNavigate } from "react-router-dom";
import MobileFooter from "../../components/MobileFooter";
import MobileSideBar from "../../components/MobileSideBar/MobileSideBar";


function UserProfile() {
    const navigate = useNavigate()
    const [balanceData, setBalanceData] = useState({ balance: '0.00', loader: false });
    const [balanceBonus, setBalanceBonus] = useState({ bonus: '0.00', loader: false });
    const [balanceTurnover, setBalanceTurnover] = useState({ turnover: '0.00', loader: false });


    const handleWalletBalance = async () => {
        try {
            setBalanceData({ ...balanceData, loader: false })
            const Token = JSON.parse(localStorage.getItem("betUser"));
            const headers = {
                headers: {
                    // "Content-Type": "application/json",
                    "x-access-token": Token?.token,
                },
            };
            const responce = await axios.get(baseUrl + walletBalance, headers);
            if (responce.status == 200) {
                setBalanceData({ ...balanceData, balance: responce?.data?.data?.[0]?.wallet_amount || "0.00", loader: false })
            }
        } catch (error) {
            setBalanceData({ ...balanceData, loader: false })
        }
    };


    const handelLogout = () => {
        localStorage.clear();
        navigate("/")
    }


    const getBonusBalance = async () => {
        try {
            setBalanceBonus({ ...balanceBonus, loader: true });
            const Token = JSON.parse(localStorage.getItem("betUser"));
            const headers = {
                headers: {
                    // "Content-Type": "application/json",
                    "x-access-token": Token?.token,
                },
            };
            const responce = await axios.get(baseUrl + bonusBalance, headers);
            if (responce.status == 200) {
                setBalanceBonus({ ...balanceBonus, bonus: responce?.data?.data, loader: false });
            }
        } catch (error) {
            setBalanceBonus({ ...balanceBonus, loader: false });
        }
    };

    const getTurnover = async () => {
        try {
            setBalanceTurnover({ ...balanceTurnover, loader: true });
            const Token = JSON.parse(localStorage.getItem("betUser"));
            const headers = {
                headers: {
                    // "Content-Type": "application/json",
                    "x-access-token": Token?.token,
                },
            };
            const responce = await axios.get(baseUrl + get_net_exposure, headers);
            if (responce.status == 200) {
                setBalanceTurnover({ ...balanceTurnover, turnover: responce?.data?.data, loader: false });
            }
        } catch (error) {
            setBalanceTurnover({ ...balanceTurnover, loader: false });
        }
    }



    useEffect(() => {
        handleWalletBalance();
        getBonusBalance()
        getTurnover()
    }, []);

    return (
        <div>
            <PrimaryNavbar />
            <div className="position-relative">
                <MobileSideBar />
            </div>
            <div className="container py-3 mobileBg1">
                <div className="container green_glass py-3 mb-3 border-success rounded-8">
                    <div className="row m-0 p-0 align-items-center ">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">Overview</span>
                    </div>
                    <div className="row m-0 p-0 mt-2">
                        <div className="col-6 p-0 pe-2 m-0">
                            <div className="bg-white pt-2 px-3 py-3 rounded-6">
                                <img className="rounded-4" src={availableBalance} />
                                <div className="mt-2 fs_14">Available <br />Balance</div>
                                <div className="mt-4 font-weight-bold">
                                    {
                                        balanceData.loader
                                            ?
                                            <span className="">
                                                <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" ></span>
                                            </span>
                                            :
                                            "₹ " + balanceData?.balance ? " ₹ " + balanceData?.balance : " ₹ " + 0.00
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-6 d-flex flex-column p-0 pe-2 m-0">
                            <div className="h-50 bg-white p-3 py-2 mb-2 rounded-6">
                                <div className="d-flex justify-content-between ">
                                    <img className="rounded-4 "  src={overviewgraph} />
                                    <span className="text-danger font-weight-bold">
                                        {
                                            balanceTurnover?.loader
                                                ?
                                                <span className="">
                                                    <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" ></span>
                                                </span>
                                                :
                                                balanceTurnover?.turnover?.sum ? "- ₹ " + balanceTurnover?.turnover?.sum : "- ₹ " + "0.00"
                                        }
                                    </span>
                                </div>
                                <div className="mt-2 fs_14">Net Exposure</div>
                            </div>
                            <div className="h-50 bg-white p-3 py-2 rounded-6">
                                <div className="d-flex justify-content-between">
                                    <img className="rounded-4" src={bonusWon} />
                                    {
                                        balanceBonus.loader == true
                                            ?
                                            <span className="">
                                                <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" ></span>
                                            </span>
                                            :
                                            <span className="text-success font-weight-bold">₹ {balanceBonus?.bonus ? balanceBonus?.bonus : "0.00"}</span>

                                    }
                                </div>
                                <div className="mt-2 fs_14">Bonus Won</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container white_glass py-3 mb-3 border-white border-succes-5 rounded-6">
                    <div className="row m-0 p-0 align-items-center ">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">Profile</span>
                    </div>
                    <div className="row m-0 p-0 mt-2">
                        <div className="col-4 p-0 m-0 h-100 ">
                            <Link to="/accounts-mobile" className="text-dark td-none">
                                <div className="bg-white py-3 mb-2 me-2 rounded-6">
                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <img className="w-25 mb-2 rounded-4" src={accountInfo} />
                                        <span className="fs_10">Account Info</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-4 p-0 m-0 h-100">
                            <Link to="/change-password" className="text-dark td-none">
                                <div className="bg-white py-3 mb-2 me-2 rounded-6">
                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <img className="w-25 mb-2 rounded-4" src={changePassword} />
                                        <span className="fs_10">Change Password</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-4 p-0 m-0 h-100">
                            <Link to="/bank-cards" className="text-dark td-none">
                                <div className="bg-white py-3 mb-2 me-2 rounded-6">
                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <img className="w-25 mb-2 rounded-4" src={bankDetail} />
                                        <span className="fs_10">Bank Details</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="container white_glass py-3 mb-3 border-white border-succes-5 rounded-8">
                    <div className="row m-0 p-0 align-items-center ">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">Finance</span>
                    </div>
                    <div className="row m-0 p-0 mt-2">
                        <div className="col-4 p-0 m-0 h-100">
                            <Link to="/deposit" className="text-dark td-none">
                                <div className="bg-white py-3 mb-2 me-2 rounded-6">
                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <img className="w-25 mb-2 rounded-4" src={deposit} />
                                        <span className="fs_10">Deposit</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-4 p-0 m-0 h-100">
                            <Link to="/withdrawl" className="text-dark td-none">
                                <div className="bg-white py-3 mb-2 me-2 rounded-6">
                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <img className="w-25 mb-2 rounded-4" src={withdraw} />
                                        <span className="fs_10">Withdraw</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-4 p-0 m-0 h-100">
                            <Link to="/bets-history" className="text-dark td-none">
                                <div className="bg-white py-3 mb-2 me-2 rounded-6">
                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <img className="w-25 mb-2 rounded-4" src={history} />
                                        <span className="fs_10">History</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="col-4 p-0 m-0 h-100">
                            <Link to="/wallet-transfer" className="text-dark td-none">
                                <div className="bg-white py-3 mb-2 me-2 rounded-6">
                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <img className="w-25 mb-2 rounded-4" src={walletIcon} />
                                        <span className="fs_10">Wallet Transfer</span>
                                    </div>
                                </div>
                            </Link>
                        </div> */}
                    </div>
                </div>

                <div className="container white_glass py-3 mb-3 border-white border-succes-5 rounded-8">
                    <div className="row m-0 p-0 align-items-center">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">Bet Details</span>
                    </div>
                    <div className="row m-0 p-0 mt-2">
                        <div className="col-4 p-0 m-0 h-100">
                            <Link to="/my-bets" className="text-dark td-none">
                                <div className="bg-white py-3 mb-2 me-2 rounded-6">
                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <img className="w-25 mb-2 rounded-4" src={myBets} />
                                        <span className="fs_10">My Bets</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-4 p-0 m-0 h-100">
                            <Link to="/profit-and-loss" className="text-dark td-none">
                                <div className="bg-white py-3 mb-2 me-2 rounded-6">
                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <img className="w-25 mb-2 rounded-4" src={profitAndloss} />
                                        <span className="fs_10">Profit & Loss</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-4 p-0 m-0 h-100">
                            <Link to="/account-statement" className="text-dark td-none">
                                <div className="bg-white py-3 mb-2 me-2 rounded-6">
                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <img className="w-25 mb-2 rounded-4" src={accountStatement} />
                                        <span className="fs_10">Account Statement</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        {/* <div className="col-4 p-0 m-0 h-100">
                            <Link to="/turnover-statement" className="text-dark td-none">
                                <div className="bg-white py-3 mb-2 me-2">
                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <img className="w-25 mb-2" src={turnaroundStatement} />
                                        <span className="fs_10">Turnover Statement</span>
                                    </div>
                                </div>
                            </Link>
                        </div> */}
                        <div className="col-4 p-0 m-0 h-100">
                            <Link to="/casino-statement" className="text-dark td-none">
                                <div className="bg-white py-3 mb-2 me-2 rounded-6">
                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <img className="w-25 mb-2 rounded-4" src={casinoStatement} />
                                        <span className="fs_10">Casino Statement</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="container white_glass py-3 mb-3 border-white border-succes-5 rounded-8">
                    <div className="row m-0 p-0 align-items-center">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">Promotions</span>
                    </div>
                    <div className="row m-0 p-0 mt-2">
                        <div className="col-4 p-0 m-0 h-100">
                            <Link to="/promotion-page" className="text-dark td-none">
                                <div className="bg-white py-3 mb-2 me-2 rounded-6">
                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <img className="w-25 mb-2 rounded-4" src={promotions} />
                                        <span className="fs_10">Promotions</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-4 p-0 m-0 h-100">
                            <Link to="/messages" className="text-dark td-none">
                                <div className="bg-white py-3 mb-2 me-2 rounded-6">
                                    <div className="d-flex flex-column align-items-center justify-content-between">
                                        <img className="w-25 mb-2 rounded-4" src={messages} />
                                        <span className="fs_10">Message</span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>

                <div className="container m-0 p-0 mb_4rem ">
                    <button className="btn btn-light w-100 border-0 rounded-6 py-2" onClick={handelLogout}>
                        <img className=" me-3 rounded-4 " src={logout} />
                    </button>
                </div>
            </div>
            <MobileFooter />
        </div >
    );
}

export default UserProfile;
