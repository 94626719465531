import React from 'react'

const Suspended = ({gameStatus}) => {
  return (
    <div className="position-absolute h-100 d-flex align-items-center justify-content-center bg-light text-danger fw_600 suspended">
      {gameStatus}
    </div>
  )
}

export default Suspended
