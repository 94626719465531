import React, { useState } from "react";
import leftarrow from "../../assets/images/leftarrow.png";
import Calender from "../../assets/images/Calendar.png";
import PLStatement from "../PLStatement/PLStatement";

const AccountStatement = ({ ref2 }) => {
    const [pageId, setPageID] = useState("1");
    return (
        <>
            <div className="dashboard-account mb-3">
                <div className="symbols-dashboard-back">
                    <img className="back-arrow" src={leftarrow} alt="Symbols" onClick={() => ref2?.current?.scrollIntoView({ behaviour: "smooth" })} />
                    <p className="dashboard-pl">Account Statement</p>
                </div>
                <div className="my-bat-dates-section">
                    <img src={Calender} />
                    <input className="dates-my-bets" type="date" />
                    <input className="dates-my-bets" type="date" />
                </div>
                Total P&L 0.00
                <div className="bats-past-current">
                    <div
                        className={`${pageId === "1" ? "border-bottom-blue" : ""}`}
                        onClick={() => setPageID("1")}
                    >
                        All
                    </div>
                    <div
                        className={`${pageId === "2" ? "border-bottom-blue" : ""}`}
                        onClick={() => setPageID("2")}
                    >
                        Betting P&L
                    </div>
                    <div
                        className={`${pageId === "3" ? "border-bottom-blue" : ""} `}
                        onClick={() => setPageID("3")}
                    >
                        Settling Statement
                    </div>
                    {/* <select>
                        <option>MATCHED</option>
                    </select> */}
                </div>
                <PLStatement />

                {/* <div>HELLO CHANGE HERE BETS STATUS</div> */}
            </div>
        </>
    );
};

export default AccountStatement;
