import React, { useState, useRef } from "react";
import leftarrow from "../../assets/images/leftarrow.png";
import upi from "../../assets/images/upi.png";
import creditcard from "../../assets/images/creditcard.png";
import paymentWallet from "../../assets/images/paymentWallet.png";
import netBanking from "../../assets/images/netBanking.png";
import bhim from "../../assets/images/bhim.png";
import mastercard from "../../assets/images/bhim.png";
import paypal from "../../assets/images/paypal.png";
import rupay from "../../assets/images/rupay.png";
import netbankingCard from "../../assets/images/netBankingCard.png";
import visa from "../../assets/images/visa.png";
import rightarr from "../../assets/images/rightarr.png"
import downarr from "../../assets/images/downarr.png"
import americanCard from "../../assets/images/americanCard.png";
import PhonePay from "../PhonePay/PhonePay";
import NetBanking from "../NetBanking/NetBanking";
import FastUpi from "../FastUpi/FastUpi";
import '../../components/Finance/Deposit.css'
import { Button, Col, Form, Row } from "react-bootstrap";
import axios from "axios";
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css';
import baseUrl from "../../services/baseUrl";
import { userPayment } from "../../services/api";



const DepositDashboard = ({ ref2 }) => {
  const [activeMenu, setActiveMenu] = useState("");
  const [pageId, setPageId] = useState("1");
  const [loginForm, setLoginForm] = useState({ amount: "", utr: "", loader: false, image: '' });
  const [loginFormError, setLoginFormError] = useState({
    amount: "",
    utr: "",
    image: ''
  });
  const [uploadSlip, setUploadSlip] = useState('')


  const handleChange = (e) => {
    setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
    setLoginFormError({ ...loginFormError, [e.target.name]: null });
  };

  const handleSelectFiled = (ele) => {
    document.getElementById(ele).click();
  };

  const [file, setFile] = useState(null);
  const fileInputRef = useRef(null);

  const handleFileInputChange = (event) => {
    const imageFile = event.target.files[0];
    setFile(imageFile);
  };

  const handleUploadButtonClick = () => {
    fileInputRef.current.click();
  };

  const handleValidation = () => {
    const { amount, utr, image } = loginForm;
    var ramountReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
    const newErrors = {};
    if (!amount) {
      newErrors.amount = "enter amonut";
    }
    if (!utr) {
      newErrors.utr = "enter utr number";
    }
    if (!image) {
      newErrors.image = "please upload";
    }
    return newErrors;
  };

  let [userData, setUserData] = useState();

  const handleSubmit = async (e) => {
    e.preventDefault();
    const handleValidationObject = handleValidation();
    if (Object.keys(handleValidationObject).length > 0) {
      setLoginFormError(handleValidationObject);
    } else {
      setLoginForm({ ...loginForm, loader: true })
      try {
        const formData = new FormData()
        formData.append('amount', loginForm.amount)
        formData.append('utr', loginForm.utr)
        formData.append('image', loginForm.image)
        const Token = JSON.parse(localStorage.getItem('betUser'))
        const headers = {
          headers: {
            // "Content-Type": "application/json",
            "x-access-token": Token?.token
          }
        }
        let data = await axios.post(baseUrl + userPayment, formData, headers);
        if (data.status == 200) {
          setLoginForm({ ...loginForm, loader: false, image: '', amount: "", utr: "", })
          toast.success('Uploaded success', { position: "top-center", autoClose: 2000 });
        }
      } catch (error) {
        toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
        setLoginForm({ ...loginForm, loader: false, image: '', amount: "", utr: "", })
      }
    }
  };

  const handleReset = () => {
    setLoginForm({ ...loginForm, loader: false, image: '', amount: "", utr: "", })
  }

  return (
    <div className="dashboard-account mobile-dashboard-account">
      <ToastContainer />
      <div className="symbols-dashboard-back">
        <img className="back-arrow" src={leftarrow} alt="Symbols" onClick={() => ref2?.current?.scrollIntoView({ behaviour: "smooth" })} />
        <p className="dashboard-p">Deposit</p>
      </div>
      <p className="deposite-method">Choose deposit Method</p>

      {/* <div></div> */}
      <div className="deposit-payment-gateway">
        <div className="payment-options">
          {/* " */}
          {/* <div
            className={`banks-logos-left mobile-payment ${activeMenu === "phonePay" ? "selectedMenu-1" : ""
              }`}
            onClick={() => {
              setPageId("1");
              setActiveMenu("phonePay");
            }}
          >
            <div className="banks-logos-left-basics">
              <img src={upi} />
            </div>
            <div>PhonePe/Google Pay/ BHIM UPI</div>
          </div> */}
          <div
            className={`banks-logos-left ${activeMenu === "FastUPI" ? "selectedMenu-1" : ""
              }`}
            onClick={() => {
              setPageId("2");
              setActiveMenu("FastUPI");
            }}
          >
            <div className="banks-logos-left-basics">
              <img src={upi} />
            </div>
            <div>Fast UPI</div>
            <div>
            {activeMenu === "FastUPI" ? <img src={downarr} className="payment-arrow"/> : <img src={rightarr} className="payment-arrow"/>}
              
              </div>
          </div>
          {/* <div
            className={`banks-logos-left mobile-payment ${activeMenu === "netBanking" ? "selectedMenu-1" : ""
              }`}
            onClick={() => {
              setPageId("3");
              setActiveMenu("netBanking");
            }}
          >
            <div className="banks-logos-left-basics">
              <img src={netBanking} />
            </div>
            <div>Net Banking</div>
          </div> */}
          {/* <div
            className={`banks-logos-left mobile-payment ${activeMenu === "creditCard" ? "selectedMenu-1" : ""
              }`}
            onClick={() => setActiveMenu("creditCard")}
          >
            <div className="banks-logos-left-basics">
              <img src={creditcard} />
            </div>
            <div>Credit/ Debit Card</div>
          </div> */}
          {/* <div
            className={`banks-logos-left mobile-payment ${activeMenu === "paytmWallet" ? "selectedMenu-1" : ""
              }`}
            onClick={() => setActiveMenu("paytmWallet")}
          >
            <div className="banks-logos-left-basics">
              <img src={paymentWallet} />
            </div>

            <div>Paytm/Wallet</div>
          </div> */}
        </div>
        {pageId === "1" ? <PhonePay /> : ""}
        {pageId === "2" ? <FastUpi /> : ""}
        {pageId === "3" ? <NetBanking /> : ""}
        {/*  */}
      </div>
      {
        pageId === '2' &&
        < div className="card-payment-scaner" >
          <div className="d-flex mobile-upload" >
            <div className="col-6 card mr-2 mobile-screenshot" >
              <b>Upload payment receipt</b>
              {
                // loginForm.image == "" ? (
                //   <Row className="mt-2 bg-danger" >
                //     <Form.Group className="mb-3" controlId="Pan Card">
                //       <div
                //         className="form-group py-2 position-relative mb-3 "
                //         onClick={() => handleSelectFiled("profile")}
                //       >
                //         <input
                //           type="file"
                //           name="profile"
                //           id="profile"
                //           onChange={(event) => setLoginForm({ ...loginForm, image: event.target.files[0] })}
                //           isInvalid={loginFormError?.image}
                //           className="opacity0 position-absolute w-75 placeholder-light form-control py-2 imgheight px-3"
                //         />
                //         <div
                //           className={`${loginFormError?.image ? "border-danger" : " "} profile-box text-center`}
                //         >
                //           <div style={{ marginTop: "80px" }}>
                //             <img
                //             // src={placeholder}
                //             />{" "}
                //             <span style={{ color: "#A3A3A3" }}>
                //               Upload Photo
                //             </span>
                //           </div>
                //         </div>
                //         <span className="text-danger">
                //           {/* {franchiseDataError.profile} */}
                //         </span>
                //       </div>
                //     </Form.Group>
                //   </Row>
                // ) : (
                //   <Row className="mt-2" >
                //     <div
                //       className="profile-box mb-3 g-0"
                //       onClick={() => handleSelectFiled("profile")}
                //     >
                //       <img
                //         src={URL.createObjectURL(loginForm.image)}
                //         width="100%" height="100%" />
                //     </div>
                //     <input
                //       type="file"
                //       name="profile"
                //       onChange={(event) => setLoginForm({ ...loginForm, image: event.target.files[0] })}
                //       className="opacity0"
                //       id="profile"
                //     />
                //   </Row>
                // )
              }
              <div className="image-uploader">
                {
                  loginForm.image == "" &&
                  <div
                    style={{
                      width: '150px',
                      height: '150px',
                    }}
                    className={`${loginFormError?.image ? "border-danger" : " "} profile-box text-center card  m-auto`}
                    onClick={handleUploadButtonClick} >
                    <input
                      placeholder="Click to upload or drag and drop"
                      className="upload-image"
                      type="file"
                      accept="image/*"
                      onChange={(event) => setLoginForm({ ...loginForm, image: event.target.files[0] })}
                      ref={fileInputRef}
                      style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}
                    />
                  </div>
                }
                {loginForm.image != "" && (
                  <div className='card m-auto'
                    style={{
                      width: '150px',
                      height: '150px',
                    }}
                    onClick={handleUploadButtonClick}>
                    <input
                      type="file"
                      accept="image/*"
                      onChange={(event) => setLoginForm({ ...loginForm, image: event.target.files[0] })}
                      ref={fileInputRef}
                      style={{ position: 'absolute', top: '-9999px', left: '-9999px' }}
                    />
                    <img
                      // src={URL.createObjectURL(file)}
                      src={URL.createObjectURL(loginForm.image)}
                      alt="Uploaded image preview"
                      style={{ objectFit: 'fill', width: "100%", height: "100%" }}
                    />
                  </div>
                )}
              </div>
              {/* <div className="col-md-3"></div> */}
              {/* </div> */}
            </div>
            <div className="col-6 card p-3 mobile-screenshot" >
              <b>Verify Pament Details</b>
              <Form className="mt-2" onSubmit={handleSubmit}>
                <Form.Group
                  className="mb-3  m-auto"
                  controlId="formBasicamount"
                >
                  {/* <Form.Label>Phone Number address</Form.Label> */}
                  <Form.Control
                    type="number"
                    name="amount"
                    autoComplete="off"
                    className={`${loginFormError.amount ? "border border-danger" : ""}`}
                    onChange={handleChange}
                    value={loginForm.amount}
                    placeholder="Enter Deposit Amount"
                  />
                  <span className="text-danger">
                    {loginFormError && loginFormError?.amount}
                  </span>
                </Form.Group>
                <Form.Group
                  className="mb-3  m-auto"
                  controlId="formBasicPassword"
                >
                  {/* <Form.Label>Password</Form.Label> */}
                  <Form.Control
                    type="number"
                    name="utr"
                    autoComplete="off"
                    className={`${loginFormError.utr ? "border border-danger" : ""}`}
                    onChange={handleChange}
                    placeholder="UTR Number"
                    value={loginForm.utr}
                  />
                  <span className="text-danger"> {loginFormError && loginFormError?.utr} </span>
                </Form.Group>
                <div className="row justify-content-between" >
                  <div className="col-6">
                    <Button variant="primary" type="reset" className="w-100" onClick={handleReset} >Reset</Button>
                  </div>
                  <div className="col-6" >
                    <Button variant="primary" type="submit" className="w-100">
                      Submit {" "}{" "}
                      {
                        loginForm.loader && <div className="spinner-border spinner-border-sm" role="status"></div>
                      }
                    </Button>
                  </div>

                </div>
              </Form>{" "}
            </div>
          </div>
        </div>
      }
      <div className="">
        <div className="card-images-payment-balance">
          <img src={mastercard} />
          <img src={visa} />
          <img src={paypal} />
          <img src={americanCard} />
          <img src={rupay} />
          <img src={netbankingCard} />
          <img src={bhim} />
        </div>
        <div className="contact-us-action">Contact Us</div>
      </div>
    </div >
  );
};

export default DepositDashboard;
