import axios from "axios";
import React, { useEffect, useState } from "react";
import Copy from "../../assets/images/Copy.png";
import qrid from "../../assets/images/qrid.png";
import upiid from "../../assets/images/upiid.png";
import { getBankDetails } from "../../services/api";
import baseUrl from "../../services/baseUrl";

const FastUpi = () => {
  const [selectedBank, setSelectedBank] = useState("");
  const [accountDetails, setAccountDetails] = useState([])
  const [qrData, setQrData] = useState({ data: '', booling: false, loader: false })

  const handleGetAccountDetails = async () => {
    try {
      setQrData({ ...qrData, loader: true })
      const responce = await axios.get(baseUrl + getBankDetails)
      if (responce.status == 200) {
        setAccountDetails(responce.data.data)
        setQrData({ ...qrData, loader: false })
      }
    } catch (error) {
      setQrData({ ...qrData, loader: false })
    }
  }
  const handleClickQrCode = (ele) => {
    setQrData({
      ...qrData,
      data: ele,
      booling: !qrData.booling
    })
  }
  useEffect(() => {
    handleGetAccountDetails()
  }, [])



  return (
    <div className="" style={{ width: '100%' }}>
      <div className="card desposit-mobile-card" >
        <h4 className="desposit-mobile-card-head">PAYMENT UPI DETAILS</h4>
        <div
          className=""
          onClick={() => setSelectedBank("FAST-UPI-INPUT-ONE")}
        >
          <div className="radio-upi" >
            <div className="col-1" >
              <input
                type="radio"
                id="javascript"
                name="fav_language"
                value="JavaScript"
                checked={selectedBank === "FAST-UPI-INPUT-ONE" ? true : false}
              />
            </div>
            {
              qrData.loader == false && accountDetails && accountDetails?.map((ele, ind) => {
                return (
                  <div className="col-11 card" >
                    <div className="d-flex" >
                      <div className="col-11" >
                        <div>{ele?.account_holder}-@{ele?.bank_name}</div>
                      </div>
                      <div className="col-1" >
                        <img src={Copy} className="copy-icon copy-icone-size" />
                      </div>
                    </div>
                    <div className="row justify-content-between mt-3 mb-3" >
                      <div className="col-6" >
                        <img src={upiid} />
                      </div>
                      <div className="col-6" onClick={() => handleClickQrCode(ele)} >
                        <img src={qrid} className="qr-icon"/>
                      </div>
                    </div>
                  </div>
                )
              })
            }
            <div className="text-center m-auto" >
              {
                qrData.loader && <button className="btn btn-primary" type="button" disabled>
                  <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                  Loading...
                </button>
              }
            </div>

            <div>
            </div>
          </div>
          {
            qrData.booling &&
            <div>
              <img src={qrData?.data?.qr_image} width='200px' height='200px' />
            </div>
          }
        </div>
      </div>
    </div>
  );
};

export default FastUpi;
