import React, { useState } from 'react'
import leftarrow from "../../assets/images/leftarrow.png";
import DepositHistory from './DepositHistory';
import WalletHostoy from './WalletHostoy';
import WithdraHistory from './WithdraHistory';

function History({ ref2 }) {
    const [activeTabCard2, setActiveTabCard2] = useState('Live');
    const [activeTabCard3, setActiveTabCard3] = useState('BettingSlip');
    const handleClick2 = (tab) => {
        setActiveTabCard2(tab);
    };

    const handleClick3 = (tab) => {
        setActiveTabCard3(tab);
    };
    return (
        <div className="dashboard-account mb-3" >
            <div className="symbols-dashboard-back">
                <img className="back-arrow" src={leftarrow} alt="Symbols" onClick={() => ref2?.current?.scrollIntoView({ behaviour: "smooth" })} />
                <p className="dashboard-p">History</p>
            </div>
            <div>
                <div className='col-sm-11 justify-content-start history-mobile'>
                    <nav className='navtabe' >
                        <ul className='navul' >
                            <li id='navli' className={activeTabCard2 === 'Live' ? 'active' : ''} onClick={() => handleClick2('Live')}>
                                Wallet History
                            </li>
                            <li id='navli' className={activeTabCard2 === 'Upcomming' ? 'active' : ''} onClick={() => handleClick2('Upcomming')}>
                                Deposit
                            </li>
                            <li id='navli' className={activeTabCard2 === 'Highlights' ? 'active' : ''} onClick={() => handleClick2('Highlights')}>
                                Withdraw
                            </li>
                            <div className="underline"></div>
                        </ul>
                    </nav>
                </div>
            </div>
            <div>
                {
                    activeTabCard2 === 'Live' && <WalletHostoy></WalletHostoy>
                }
                {
                    activeTabCard2 === 'Upcomming' && <DepositHistory></DepositHistory>
                }
                {
                    activeTabCard2 === 'Highlights' && <WithdraHistory></WithdraHistory>
                }
            </div>
        </div>
    )
}

export default History