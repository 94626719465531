import React, { useState } from "react";
import leftarrow from "../../assets/images/leftarrow.png";
import Calender from "../../assets/images/Calendar.png";
import CurrentBets from "../CurrentBets/CurrentBets";
import PastBets from "../PastBets/PastBets";

const BetDetails = ({ ref2 }) => {
  const [pageId, setPageID] = useState("1");
  return (
    <>
      <div className="dashboard-account   mb-3">
        <div className="symbols-dashboard-back">
          <img className="back-arrow" src={leftarrow} alt="Symbols" onClick={() => ref2?.current?.scrollIntoView({ behaviour: "smooth" })} />
          <p className="dashboard-p">My Bets</p>
        </div>
        <div className="my-bat-dates-section">
          <img src={Calender} />
          <input className="dates-my-bets" type="date" />
          <input className="dates-my-bets" type="date" />
        </div>
        <div className="bats-past-current">
          <div
            className={`${pageId === "1" ? "border-bottom-blue" : ""}`}
            onClick={() => setPageID("1")}
          >
            Current Bet
          </div>
          <div
            className={`${pageId === "2" ? "border-bottom-blue" : ""}`}
            onClick={() => setPageID("2")}
          >
            Past Bet
          </div>

          <select>
            <option>MATCHED</option>
          </select>
        </div>
        {pageId === "1" ? <CurrentBets /> : ""}
        {pageId === "2" ? <PastBets /> : ""}

        {/* <div>HELLO CHANGE HERE BETS STATUS</div> */}
      </div>
    </>
  );
};

export default BetDetails;
