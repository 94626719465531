import { type } from '@testing-library/user-event/dist/type';
import axios from 'axios';
import React from 'react'
import { useEffect, useState } from 'react'
import { walletHistory } from '../../services/api';
import baseUrl from '../../services/baseUrl';
import ReactPaginate from 'react-paginate';

function WalletHostoy() {
    let [data, setData] = useState();
    const [walletData, setWalletData] = useState([])
    const [loader, setLoader] = useState(false)

    const handleGetWalletHistory = async () => {
        try {
            setLoader(true)
            const Token = JSON.parse(localStorage.getItem('betUser'))
            const headers = {
                headers: {
                    // "Content-Type": "application/json",
                    "x-access-token": Token?.token
                }
            }
            const responce = await axios.get(baseUrl + walletHistory, headers)
            if (responce.status == 200) {
                setLoader(false)
                setWalletData(responce.data.data)
            }
        } catch (error) {
            setLoader(false)
        }
    }

    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 10
    const endOffset = itemOffset + itemsPerPage;
    const pageCount = Math.ceil(walletData.length / itemsPerPage);
    const currentItems = walletData.slice(itemOffset, endOffset);

    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % walletData.length;
        setItemOffset(newOffset);
    };

    useEffect(() => {
        handleGetWalletHistory()
    }, [])
    return (
        <div>
            <div className='card p-3 m-3' >
                {
                    loader == false &&
                    <div>
                        <div  className=''  style={{ overflowX: 'scroll'}} >
                            <table className="table table-striped">
                                <thead>
                                    <tr>
                                        <th scope="col">Sl.No</th>
                                        <th scope="col">Time And Date</th>
                                        <th scope="col">Type</th>
                                        <th scope="col">Ammount</th>
                                        <th scope="col">Status</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        currentItems && currentItems.map((ele, ind) => {
                                            return <tr>
                                                <td scope="row">{ind + 1}</td>
                                                <td>{ele.createdAt.slice(0, 10)}</td>
                                                <td>{ele.type}</td>
                                                <td>{ele.debit_credit}</td>
                                                <td>{ele.status}</td>
                                            </tr>
                                        })
                                    }
                                </tbody>
                            </table>
                        </div>

                        <div className="m-2 d-flex justify-content-center align-items-center">
                            <div className="justify-content-center" >
                                <ReactPaginate
                                    breakLabel="..."
                                    nextLabel=">"
                                    onPageChange={handlePageClick}
                                    pageRangeDisplayed={3}
                                    pageCount={pageCount}
                                    previousLabel="<"
                                    renderOnZeroPageCount={null}
                                    pageClassName="page-item mb-0"
                                    subContainerClassName="pages "
                                    pageLinkClassName="page-link"
                                    previousClassName="page-item"
                                    previousLinkClassName="page-link"
                                    nextClassName="page-item"
                                    nextLinkClassName="page-link"
                                    breakClassName="page-item"
                                    breakLinkClassName="page-link"
                                    marginPagesDisplayed={2}
                                    containerClassName="pagination"
                                    activeClassName="active"
                                />
                            </div>
                        </div>
                    </div>
                }
                {
                    loader == true && <div>
                        <button className="btn btn-primary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            Loading...
                        </button>
                    </div>
                }
            </div>
        </div>
    )
}

export default WalletHostoy