import React, { useEffect, useState } from 'react'
import Footer from '../components/Footer'
import PrimaryNavbar from '../components/PrimaryNavbar'
import cashback from '../assets/images/cashback.png'
import "../custom.css";
import WelcomeBonus from '../components/WelcomeBonus/WelcomeBonus';
import CashBackBonus from '../components/CashBackBonus/WelcomeBonus';


function Cashback() {
    const [activeMenu, setActiveMenu] = useState("");
    const [pageId, setPageId] = useState("1");

    return (
        <div>
            <PrimaryNavbar></PrimaryNavbar>
            <div className='cashback' >
                <div className='cashback-banner' >
                    <img src={cashback} className='cashback-banner-image'/>
                    <div className='cashback-nav'>
                        <div className='nav-items'>
                            {pageId === "1" ? 
                                <div className='nav-first'onClick={() => {setPageId("1");}}> 
                                    <p className='cashback-nav-items'>WELCOME BONUS</p>
                                </div> : 
                                <div className='nav-sec2'onClick={() => {setPageId("1");}}> 
                                    <p className='cashback-nav-items'>WELCOME BONUS</p>
                                </div>
                            }
                            {pageId === "2" ? 
                                <div className='nav-sec'onClick={() => {setPageId("2");}}> 
                                    <p className='cashback-nav-items'>CASHBACK BONUS</p>
                                </div> : 
                                <div className='nav-sec3'onClick={() => {setPageId("2");}}> 
                                    <p className='cashback-nav-items'>CASHBACK BONUS</p>
                                </div>
                            }
                        </div>
                    </div>

                    {pageId === "1" ? <WelcomeBonus /> : ""}
                    {pageId === "2" ? <CashBackBonus /> : ""}
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default Cashback