import React, { useState } from 'react'
import Footer from '../../components/Footer'
import PrimaryNavbar from '../../components/PrimaryNavbar'
import '../SportsBook/SportsBook.css'
import inplayimg from '../../assets/images/inplayimg.png'
import vectorCircle from '../../assets/images/VectoreCicle.png'
import fire from '../../assets/images/fire.png'
import arrow from '../../assets/images/arrow.png'
import vollyball from "../../assets/images/vollyball.png";
import tenis from "../../assets/images/tenis.png";
import Slider from "react-slick";
import footer from "../../assets/images/vollyball.png";
import bat from "../../assets/images/bat.png";
import football from "../../assets/images/football.png";
import basket from "../../assets/images/basket.png";






function SportsBook() {
    const [activeTabCard2, setActiveTabCard2] = useState('Live');
    const [activeTabCard3, setActiveTabCard3] = useState('BettingSlip');
    const [cardsData, setCardsData] = useState([
        {
            color: '#5bc0de'
        },
        {
            color: '#f0ad4e'
        }, {
            color: '#f0ad4e'
        }, {
            color: '#d9534f'
        }, {
            color: '#d9534f'
        }, {
            color: '#007bff'
        }])


    const handleClick2 = (tab) => {
        setActiveTabCard2(tab);
    };

    const handleClick3 = (tab) => {
        setActiveTabCard3(tab);
    };
    var settingsfootball = {
        dots: false,
        infinite: false,
        speed: 500,
        slidesToShow: 5,
        slidesToScroll: 5,
        initialSlide: 0,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true,
                },
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    initialSlide: 2,
                },
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };

    return (
        <div>
            <PrimaryNavbar />
            <div className='center-block main-image' >
                <div className='head-block' >
                    <div className='font-weight-bold' >
                        <h5 className='heding-text' >SportsBook</h5>
                        <div className='sub-text' >
                            <span className='t-12 mr-2' >Homepage</span>{" > "} <span className='ml-2' >SportsBook</span>
                        </div>
                    </div>
                </div>
                <div className='message-block ' >
                    <div className='row text-center'>
                        <div className='col message-text pt-1' >Bet Karo India - Beyond Games, Your Bonuses, Auto activated - Explore your entertainment partner now !!</div>
                    </div>
                </div>

                <div className="row justify-content-around mt-3">
                    <div className="col-3">
                        <div className='card-one' >
                            <div>
                                <div className='' >
                                    <div className='d-flex  py-2' >
                                        <div className='col-2' >
                                            <img src={fire} width='50px' height='50px' className='vectore-class'></img>
                                        </div>
                                        <div className='col-10 mt-2' >
                                            <p className='text-white' >Featured League</p>
                                        </div>
                                    </div>
                                    <div className='d-flex' >
                                        <div className='col-2 mt-3' >
                                            <img width='50px' height='50px' src={vectorCircle} className='vectore-class'></img>
                                        </div>
                                        <div className='col-10' >
                                            <p style={{ color: 'rgba(243, 243, 243, 0.58)' }} >Soccer</p>
                                            <p className='text-white' >England - Premier league</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="container-accordian" >
                                    <div className="accordian-dropdoen-parent">
                                        <div className="accordian-dropdoen-child" >
                                            <div id="MainMenu">
                                                <div className="">
                                                    {/*  */}
                                                    <a
                                                        href="#demo2"
                                                        className="list-group-item-list-group-item-success text-white text-decoration-none"
                                                        data-toggle="collapse"
                                                        data-parent="#MainMenu"
                                                    >
                                                        Cricket
                                                        <i className="color-info bi bi-caret-down-fill"></i>
                                                    </a>
                                                    <div className="collapse text-start ml-5" id="demo2" style={{ color: '#F3F3F3', textDecoration: 'none' }}>
                                                        <a href="javascript:;" className="list-group-item">
                                                            India
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            International
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            Australia
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            Oman
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            Pakistan
                                                        </a>
                                                    </div>
                                                    {/*  */}
                                                    <a
                                                        href="#demo3"
                                                        className="list-group-item-list-group-item-success text-white text-decoration-none"
                                                        data-toggle="collapse"
                                                        data-parent="#MainMenu"
                                                    >
                                                        Foot Ball
                                                        <i className="color-info bi bi-caret-down-fill"></i>
                                                    </a>
                                                    <div className="collapse ml-5" id="demo3" style={{ color: '#F3F3F3', textDecoration: 'none' }}>
                                                        <a href="javascript:;" className="list-group-item">
                                                            game 1
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            game 2
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            game 3
                                                        </a>
                                                    </div>
                                                    {/*  */}
                                                    <a
                                                        href="#demo4"
                                                        className="list-group-item-list-group-item-success text-white text-decoration-none"
                                                        data-toggle="collapse"
                                                        data-parent="#MainMenu"
                                                    >
                                                        Basket Ball
                                                        <i className="color-info bi bi-caret-down-fill"></i>
                                                    </a>
                                                    <div className="collapse ml-5" id="demo4" style={{ color: '#F3F3F3', textDecoration: 'none' }}>
                                                        <a href="javascript:;" className="list-group-item">
                                                            Game 1
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            game 2
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            game 3
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            game 4
                                                        </a>
                                                    </div>
                                                    {/*  */}
                                                    <a
                                                        href="#demo5"
                                                        className="list-group-item-list-group-item-success text-white text-decoration-none"
                                                        data-toggle="collapse"
                                                        data-parent="#MainMenu"
                                                    >
                                                        E-Sport
                                                        <i className="color-info bi bi-caret-down-fill"></i>
                                                    </a>
                                                    <div className="collapse ml-5" id="demo5" style={{ color: '#F3F3F3', textDecoration: 'none' }}>
                                                        <a href="javascript:;" className="list-group-item">
                                                            Game 1
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            game 2
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            game 3
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            game 4
                                                        </a>
                                                    </div>
                                                    {/*  */}
                                                    <a
                                                        href="#demo6"
                                                        className="list-group-item-list-group-item-success text-white text-decoration-none"
                                                        data-toggle="collapse"
                                                        data-parent="#MainMenu"
                                                    >
                                                        Tennis
                                                        <i className="color-info bi bi-caret-down-fill"></i>
                                                    </a>
                                                    <div className="collapse ml-5" id="demo6" style={{ color: '#F3F3F3', textDecoration: 'none' }}>
                                                        <a href="javascript:;" className="list-group-item">
                                                            Game 1
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            game 2
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            game 3
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            game 4
                                                        </a>
                                                    </div>
                                                    {/*  */}
                                                    <a
                                                        href="#demo7"
                                                        className="list-group-item-list-group-item-success text-white text-decoration-none"
                                                        data-toggle="collapse"
                                                        data-parent="#MainMenu"
                                                    >
                                                        Baseball
                                                        <i className="color-info bi bi-caret-down-fill"></i>
                                                    </a>
                                                    <div className="collapse ml-5" id="demo7" style={{ color: '#F3F3F3', textDecoration: 'none' }}>
                                                        <a href="javascript:;" className="list-group-item">
                                                            Game 1
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            game 2
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            game 3
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            game 4
                                                        </a>
                                                    </div>
                                                    {/*  */}
                                                    <a
                                                        href="#demo8"
                                                        className="list-group-item-list-group-item-success text-white text-decoration-none"
                                                        data-toggle="collapse"
                                                        data-parent="#MainMenu"
                                                    >
                                                        Volleyball
                                                        <i className="color-info bi bi-caret-down-fill"></i>
                                                    </a>
                                                    <div className="collapse ml-5" id="demo8" style={{ color: '#F3F3F3', textDecoration: 'none' }}>
                                                        <a href="javascript:;" className="list-group-item">
                                                            Game 1
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            game 2
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            game 3
                                                        </a>
                                                        <a href="javascript:;" className="list-group-item">
                                                            game 4
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="col-6">
                        <div className='card-two card' >
                            <div className='ml-4 mr-4 row justify-content-center mt-3' >
                                <div className='col-sm-1' >
                                    <div className='mt-2' width='100px' height='100px' >
                                        <div className='forward-arrow text-center' >
                                            <img src={arrow} width='15px' className='mt-2' ></img>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-sm-11 justify-content-start'>
                                    <nav className='navtabe' >
                                        <ul className='navul' >
                                            <li id='navli' className={activeTabCard2 === 'Live' ? 'active' : ''} onClick={() => handleClick2('Live')}>
                                                Live(66)
                                            </li>
                                            <li id='navli' className={activeTabCard2 === 'Upcomming' ? 'active' : ''} onClick={() => handleClick2('Upcomming')}>
                                                Upcomming
                                            </li>
                                            <li id='navli' className={activeTabCard2 === 'Highlights' ? 'active' : ''} onClick={() => handleClick2('Highlights')}>
                                                Highlights
                                            </li>
                                            <div className="underline"></div>
                                        </ul>
                                    </nav>
                                </div>
                            </div>
                            <div className='ml-4 mr-4' >
                                <div className="" >
                                    <Slider {...settingsfootball}>
                                        <div className="">
                                            <div className="card px-2 py-2 card-hover-color mr-3">
                                                <div className='m-auto' >
                                                    <img src={bat} width='20px' height='20px' className="" />
                                                </div>
                                                <div className='text-center mt-2' >
                                                    <a
                                                    // href="/Cricket" className="linkpage"
                                                    >
                                                        <p className="text-white">
                                                            Cricket
                                                        </p>
                                                    </a>
                                                </div>
                                            </div>{" "}
                                        </div>
                                        <div className="">
                                            <div className="card px-2 py-2 card-hover-color  mr-3">
                                                <div className='m-auto' >
                                                    <img src={football} width='20px' height='20px' className="" />
                                                </div>
                                                <div className='text-center mt-2' >
                                                    <a
                                                    // href="/Cricket" className="linkpage"
                                                    >
                                                        <p className="text-white">
                                                            Football
                                                        </p>
                                                    </a>
                                                </div>
                                            </div>{" "}
                                        </div>
                                        <div className="">
                                            <div className="card px-2 py-2 card-hover-color mr-3">
                                                <div className='m-auto' >
                                                    <img src={tenis} width='20px' height='20px' className="" />
                                                </div>
                                                <div className='text-center mt-2' >
                                                    <a
                                                    // href="/Cricket" className="linkpage"
                                                    >
                                                        <p className="text-white">
                                                            Tennis
                                                        </p>
                                                    </a>
                                                </div>
                                            </div>{" "}
                                        </div>
                                        <div className="">
                                            <div className="card px-2 py-2 card-hover-color  mr-3">
                                                <div className='m-auto' >
                                                    <img src={vollyball} width='20px' height='20px' className="" />
                                                </div>
                                                <div className='text-center mt-2' >
                                                    <a
                                                    // href="/Cricket" className="linkpage"
                                                    >
                                                        <p className="text-white">
                                                            Volleyball
                                                        </p>
                                                    </a>
                                                </div>
                                            </div>{" "}
                                        </div>
                                        <div className="">
                                            <div className="card px-2 py-2 card-hover-color  mr-3">
                                                <div className='m-auto' >
                                                    <img src={basket} width='20px' height='20px' className="" />
                                                </div>
                                                <div className='text-center mt-2' >
                                                    <a
                                                    // href="/Cricket" className="linkpage"
                                                    >
                                                        <p className="text-white">
                                                            Basketball
                                                        </p>
                                                    </a>
                                                </div>
                                            </div>{" "}
                                        </div>
                                        <div className="">
                                            <div className="card px-2 py-2 card-hover-color mr-3">
                                                <div className='m-auto' >
                                                    <img src={bat} width='20px' height='20px' className="" />
                                                </div>
                                                <div className='text-center mt-2' >
                                                    <a
                                                    // href="/Cricket" className="linkpage"
                                                    >
                                                        <p className="text-white">
                                                            Cricket
                                                        </p>
                                                    </a>
                                                </div>
                                            </div>{" "}
                                        </div>
                                        <div className="">
                                            <div className="card px-2 py-2 card-hover-color  mr-3">
                                                <div className='m-auto' >
                                                    <img src={football} width='20px' height='20px' className="" />
                                                </div>
                                                <div className='text-center mt-2' >
                                                    <a
                                                    // href="/Cricket" className="linkpage"
                                                    >
                                                        <p className="text-white">
                                                            Football
                                                        </p>
                                                    </a>
                                                </div>
                                            </div>{" "}
                                        </div>
                                    </Slider>
                                </div>
                            </div>
                            <div className='p-4' >
                                <div>
                                    <p className='live-match-first-line' >Live Cricket Matches : 8</p>
                                    <p className='live-match-second-line' >Live Cricket Matches : 8</p>
                                </div>
                                {
                                    cardsData?.map((ele, ind) => {

                                        return (
                                            <div>
                                                <div>
                                                    <div className='card py-2 mt-2 bd-callout' style={{ borderLeftColor: ele.color }} >
                                                        <div className='d-flex text-center' >
                                                            <div className='col-md-1' >
                                                                <img src={inplayimg} className="pt-3" />
                                                            </div>
                                                            <div className='col-md-2' >
                                                                First Inninbgs
                                                            </div>
                                                            <div className='col-md-2' >
                                                                Bangladesh England
                                                            </div>
                                                            <div className='col-md-2' >
                                                                <p> 246/10</p>
                                                                <p>83/3</p>
                                                            </div>
                                                            <div className='col-md-3' >
                                                                <div className='card' style={{ backgroundColor: '#E8E8E8' }} >
                                                                    <p>Bangladesh</p>
                                                                    <p>2.72</p>
                                                                </div>
                                                            </div>
                                                            <div className='col-md-2' >
                                                                <div className='card' style={{ backgroundColor: '#E8E8E8' }} >
                                                                    <p>England</p>
                                                                    <p>1.46</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        )
                                    })
                                }

                            </div>
                        </div>
                    </div>
                    <div className="col-3">
                        <div className='card-three card' >
                            <nav className='navtabe' >
                                <ul className='navul' >
                                    <li id='navli' className={activeTabCard3 === 'BettingSlip' ? 'active' : ''} onClick={() => handleClick3('BettingSlip')}>
                                        Betting Slip
                                    </li>
                                    <li id='navli' className={activeTabCard3 === 'MyBets' ? 'active' : ''} onClick={() => handleClick3('MyBets')}>
                                        My Bets(0)
                                    </li>
                                    <div className="underline"></div>
                                </ul>
                            </nav>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    )
}

export default SportsBook