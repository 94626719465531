import React from "react";
import PrimaryNavbar from "../../components/PrimaryNavbar";
import ArrowLeft from "../../assets/images/profileDashboard/arrowLeft.svg";
import edit from "../../assets/images/profileDashboard/edit.svg";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import MobileFooter from "../../components/MobileFooter";
import MobileSideBar from "../../components/MobileSideBar/MobileSideBar";
import walletIcon from "../../assets/images/walletIcon.svg"
import CasinoLogo from "../../assets/CasinoLogo.svg"
import TeenpattiLogo from "../../assets/TeenpattiLogo.svg"

import refreshIcon from "../../assets/refreshIcon.svg"
import baseUrl from "../../services/baseUrl";
import { walletBalance } from "../../services/api";

function WalletTransfer() {
  const navigate = useNavigate();
  const [balanceData, setBalanceData] = useState("0.00");
  const [loader, setLoader] = useState(false);




  const handleWalletBalance = async () => {
    try {
      setLoader(true);
      const Token = JSON.parse(localStorage.getItem("betUser"));
      const headers = {
        headers: {
          // "Content-Type": "application/json",
          "x-access-token": Token?.token,
        },
      };
      const responce = await axios.get(baseUrl + walletBalance, headers);
      if (responce.status == 200) {
        setLoader(false);
        setBalanceData(responce?.data?.data[0]);
      }
    } catch (error) {
      setLoader(false);
    }
  };

  useEffect(() => {
    handleWalletBalance();
  }, []);

  return (
    <div>
      <PrimaryNavbar />
      <div className="position-relative">
        <MobileSideBar />
      </div>
      <div className="container py-3 bankDetailsBG fs_14">
        <div className="row mb-3 text-white">
          <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
            <img src={ArrowLeft} className="cursor-pointer arrow-icone-size rounded-4 " />
          </div>
          <div className="col-10">
            <p className="mb-0 currentbets_title fw_700 text-center">
              Wallet Transfer
            </p>
          </div>
          <div className="col-1"></div>
        </div>

        <div className="container green_glass py-3 mb-3 border-success rounded-8">
          <div className="row m-0 p-0 align-items-center p-2 bg-white rounded-6">
            <div className="col-6 p-0 d-flex align-items-center ">
              <img src={walletIcon} className="me-2 fw_600" />
              <span className="fw_600 fs_14" >Main Wallet</span>
            </div>
            <div className="col-6 p-0 d-flex align-items-center justify-content-end">
              {
                loader
                  ?
                  <div className="spinner-border spinner-border-sm" role="status" ></div>
                  :
                  <span className="text-green_02 fs_12 fw_700">{balanceData?.wallet_amount ? '₹' + balanceData?.wallet_amount : '₹' + '0:00'}</span>
              }
            </div>
          </div>
        </div>

        <div className="container white_glass py-3 mb-3 border-success border-succes-5 rounded-8">
          <div className="row m-0 p-0 align-items-center p-2 bg-white mb-3 rounded-6">
            <div className="col-6 p-0 d-flex align-items-center ">
              <img src={CasinoLogo} className="me-2 fw_600" />
              <span className="fw_600 fs_14" >Casino</span>
            </div>
            <div className="col-6 p-0 d-flex align-items-center justify-content-end ">
              <span className="text-green_02 fs_12 fw_700 d-flex flex-column justify-content-center align-items-center">
                <span className="">₹ 0.00</span>
                {/* <span className="text-dark">Balance</span> */}

              </span>
            </div>
          </div>

          <div className="row m-0 p-0 align-items-center p-2 bg-white rounded-4">
            <div className="col-6 p-0 d-flex align-items-center ">
              <img src={TeenpattiLogo} className="me-2 fw_600" />
              <span className="fw_600 fs_14" >Teenpatti</span>
            </div>
            <div className="col-6 p-0 d-flex align-items-center justify-content-end">
              <span className="text-green_02 fs_12 fw_700 d-flex flex-column justify-content-center align-items-center">
                <span className="">₹ 0.00</span>
                {/* <span className="text-dark">Balance</span> */}
              </span>
            </div>
          </div>
        </div>

        <div className='row mt-2 mb-3'>
          <div className='col-12 d-flex'>
            <button className='btn w-50 btn-warning rounded-0 shadow-none btn_commn_yellow_1 px-0 fs_12 fw_700 me-3 rounded-6' >Transfer All To Main Wallet</button>
            <button className='btn btn-light rounded-0 shadow-none rounded-4'>
              <img  src={refreshIcon}></img>
            </button>
          </div>
        </div>

      </div>

      <MobileFooter />
    </div>
  );
}

export default WalletTransfer;
