const NotificationIcn = () => {
  return (
    <>
      <svg
        width="18"
        height="24"
        viewBox="0 0 18 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          id="Icon"
          fill-rule="evenodd"
          clip-rule="evenodd"
          d="M10.1539 1.88924C10.1539 1.26006 9.63727 0.75 9.00001 0.75C8.36274 0.75 7.84614 1.26006 7.84614 1.88924V2.74367H7.20295C4.63551 2.74367 2.51055 4.71457 2.35028 7.24452L2.09527 11.27C1.99805 12.8048 1.47616 14.2838 0.586462 15.546C-0.217417 16.6864 0.498236 18.2615 1.89549 18.4271L5.82687 18.8928V20.1171C5.82687 21.8473 7.24753 23.25 9.00001 23.25C10.7525 23.25 12.1731 21.8473 12.1731 20.1171V18.8928L16.1045 18.4271C17.5018 18.2615 18.2174 16.6864 17.4135 15.546C16.5238 14.2838 16.002 12.8048 15.9047 11.27L15.6497 7.24452C15.4894 4.71457 13.3645 2.74367 10.797 2.74367H10.1539V1.88924ZM7.55767 20.1171C7.55767 20.9036 8.20343 21.5411 9.00001 21.5411C9.79659 21.5411 10.4423 20.9036 10.4423 20.1171V19.2627H7.55767V20.1171Z"
          fill="white"
        />
      </svg>
    </>
  );
};

export default NotificationIcn;
