import React, { useEffect, useState } from "react";
import PrimaryNavbar from "../../components/PrimaryNavbar";
import ArrowLeft from "../../assets/images/profileDashboard/arrowLeft.svg"
import edit from "../../assets/images/profileDashboard/edit.svg"
import { Link, useNavigate } from "react-router-dom";
import MobileFooter from "../../components/MobileFooter";
import baseUrl from "../../services/baseUrl";
import { getBankDetals, updateBank } from "../../services/api";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MobileSideBar from "../../components/MobileSideBar/MobileSideBar";

function UserBankDetails() {
    const navigate = useNavigate()
    const [bankAccounts, setBankAccounts] = useState([])

    const getBanks = async () => {
        try {
            const Token = JSON.parse(localStorage.getItem("betUser"));
            const headers = {
                headers: {
                    // "Content-Type": "application/json",
                    "x-access-token": Token?.token,
                },
            };
            const responce = await axios.get(baseUrl + '/user', headers);
            if (responce.status == 200) {
                setBankAccounts(responce?.data?.data?.bankDetails);
            }
        } catch (error) {
        }
    };

    useEffect(() => {
        getBanks()
    }, [])

    console.log(bankAccounts, 'bankAccountsbankAccounts');

    return (
        <div>
            <PrimaryNavbar />
            <div className="position-relative">
                <MobileSideBar />
            </div>
            <ToastContainer />
            <div className="container py-3 bankDetailsBG">
                <div className="row mb-3 text-white">
                    <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
                        <img src={ArrowLeft} className="cursor-pointer arrow-icone-size" />
                    </div>
                    <div className="col-10">
                        <p className="mb-0 currentbets_title fw_700 text-center">
                            Bank Details
                        </p>
                    </div>
                    <div className="col-1"></div>
                </div>

                <div className="container green_glass py-3 mb-3 border-success">
                    <div className="row m-0 p-0 align-items-center">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">Add new Bank Details</span>
                    </div>
                    <div className="row m-0 p-0 mt-2">
                        {
                            bankAccounts.map((ele, ind) => {
                                return <div className="card px-2 py-2" >
                                    <b>{ele?.bankname}</b>
                                    <b>{ele?.accountNumber}</b>
                                    <b>{ele?.ifsc}</b>
                                    <b>{ele?.branch}</b>
                                    <b>{ele?.address}</b>
                                </div>
                            })
                        }
                    </div>

                    <Link to='/bank-details' >  <button type="button"   >Add New Bank</button></Link>
                </div>
            </div>

            <MobileFooter />
        </div >
    );
}

export default UserBankDetails;
