import "bootstrap/dist/css/bootstrap.min.css";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./custom.css";
import "./utilites.css";
import ScreenRoute from "./routers/ScreenRoute";
import { ToastContainer } from "react-toastify";
function App() {
  return (
    <div>
      <ScreenRoute />
      <ToastContainer/>
    </div>
  );
}

export default App;
