import React, { useEffect, useState, useCallback } from "react";
import PrimaryNavbar from "../../components/PrimaryNavbar";
import MobileFooter from "../../components/MobileFooter";
import MobileSideBar from "../../components/MobileSideBar/MobileSideBar";
import Crown from "../../assets/crown.svg";
import EzugiLogo from "../../assets/ezugiLogoNew.png"
import BTILogo from "../../assets/BTILogo.png"
import BetGamesLogo from "../../assets/betGamesLogo.png.png"
import BlackJack from "../../assets/blackjack.svg";
import Dragon from "../../assets/dragon.svg";
import Teenpatti from "../../assets/teenpatti.svg";
import Roulette from "../../assets/Roulette.png";
import CasinoCard1 from "../../assets/casinoCard1.png";
import CasinoCard2 from "../../assets/casinoCard2.png";
import CasinoCard3 from "../../assets/casinoCard3.png";
import CasinoCard4 from "../../assets/casinoCard4.png";
import Evoplay from "../../assets/Evoplay.png";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { casino_game_category, casino_game_list } from "../../services/api";
import baseUrl from "../../services/baseUrl";
import axios from "axios";
import "./OnlineCasinoGames.css"
import { useLocation, useNavigate } from "react-router-dom";
import { accessDenied, checkAccess, debounce } from "../../services/helper";
import { ACCESS_TYPE } from "../../services/constants";

function OnlineCasinoGames() {
    const user = JSON.parse(localStorage.getItem("betUser")) ? JSON.parse(localStorage.getItem("betUser")) : {username: "BETKARODEMO123", password: "DEMOUSER12@"}
    const [page, setPage] = useState(1)

    const navigate = useNavigate()
    const location = useLocation()

    const [menuTab, setMenuTab] = useState(location?.state?.menuTab ? location?.state?.menuTab : { ID: 0 })
    const [gamesList, setGamesLIst] = useState([])
    const [iframeData, setIframeData] = useState({})
    const [search, setSearch] = useState("")

    var settings = {
        dots: false,
        infinite: false,
        speed: 300,
        slidesToShow: 2,
        variableWidth: true,
        arrows: false,
        overflow: "hidden"
    };


    const handleGetGamesList = async (page, search, menuTab, gamesList) => {
        try {
            const Token = JSON.parse(localStorage.getItem('betUser'))
            const headers = { headers: { "x-access-token": Token?.token } }
            const responce = await axios.get(baseUrl + casino_game_list + `?page=${page}&${menuTab?.ID ? `System=${menuTab?.ID}&` : ""}${search ? `search=${search}` : ""}`, headers)
            if (responce.status == 200) {
                if(page == 1){
                    setGamesLIst(responce?.data?.data)
                } else {
                    const newGamesList = [...gamesList, ...responce?.data?.data]
                    setGamesLIst(() => newGamesList)
                }
            }
        } catch (error) {
            console.log(error);
        }
    }

    const handleCategoryChange = async (Id) => {
        try {
            setSearch("")
            const Token = JSON.parse(localStorage.getItem('betUser'))
            const headers = { headers: { "x-access-token": Token?.token } }
            const responce = await axios.get(baseUrl + casino_game_list + `?page=1&${Id ? `System=${Id}` : ""}${search ? `&search=${search}` : ""}`, headers)
            if (responce.status == 200) {
                setPage(1)
                setGamesLIst(responce?.data?.data)
            }
        } catch (error) {
            console.log(error);
        }
    }

    const debounced = useCallback(
        debounce((page, search, menuTab, gamesList) => {
            handleGetGamesList(page, search, menuTab, gamesList)  
        }, 300),
        []
    )

    const getIFrameData = async data => {
        const res = await axios.get(`${baseUrl}/casino/game_preview?username=${user?.username}&password=${user?.casinopassword}&system=${iframeData?.System}&page=${iframeData?.PageCode}&Timezone=2023-05-09T17:20:41Z`)
        navigate("/iframe-game", { state: { iFrame: "<iframe " + (res?.data?.data).split("<iframe").pop() } })
    }

    useEffect(() => {        
        debounced(page, search, menuTab, gamesList)
    }, [page, search, menuTab])

    useEffect(() => {
        if(menuTab?.ID){
            setPage(1)
            setSearch("")
            // handleGetGamesList(menuTab?.ID)
        }
    }, [menuTab])

    const handleScroll = (e) => {
        const bottom = e.target.scrollHeight - e.target.scrollTop - 1 <= e.target.clientHeight;
        if (bottom) { setPage(page + 1) }
    }

    useEffect(() => {
        if (iframeData?.System && iframeData?.PageCode) {
            getIFrameData(iframeData)
        }
    }, [iframeData, user])

    useEffect(() => {
        if(user?.access?.length){
          if(!checkAccess(ACCESS_TYPE?.CASINO)){
            accessDenied()
            navigate("/")
          }
        }
    }, [user])

    return (
        <div className="casinoBg">
            <PrimaryNavbar />
            <div className="position-relative">
                <MobileSideBar />
            </div>
            <div className="casino-top-section">
                <div className="py-2">
                    <div className="">
                        <div className="row px-2rem d-flex align-items-center">
                            <div className="col-1 d-flex p-0">
                                <img src={Crown} />
                            </div>

                            <div className="col-5 d-flex p-0 ps-2">
                                <p className='mb-0 text-dark currentbets_title'>Providers</p>
                            </div>
                            <div className="input-all-games-search">
                                <input className="width-input-box-algames" type="text " placeholder="Search Games"  value={search} onChange={e => {setPage(1); setSearch(e.target.value)}}/>
                            </div>
                            {/* <div className="col-6 p-0">
                                <div className=" rounded-8 border-dark border d-flex w-100 p-3 py-1 align-items-center">
                                    <i className="bi bi-search fs_14"></i>
                                    <input type="text" value={search} onChange={e => setSearch(e.target.value)} placeholder="Search" className="text-dark fs_14 w-90 px-2 online-casino-search" style={{background: "transparent"}} />
                                </div>
                            </div> */}

                        </div>
                    </div>
                </div>
                <div className="pb-2 overflow-auto d-flex all-games-tabs-container">
                    {/* <Slider {...settings}> */}
                    <div className="d-flex align-items-center justify-content-center mx-1" onClick={() => setMenuTab({ ID: 0 })}>
                        <div className={`py-1 all-games-tabs2 px-2 rounded-6  ${menuTab?.ID === 0 ? "casino-tabs-active" : "bg-black"} text-white lh_33`}>All</div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mx-1" onClick={() => setMenuTab({ ID: 998 })}>
                        <div className={`py-2 all-games-tabs2 px-2 rounded-6  ${menuTab?.ID === 998 ? "casino-tabs-active" : "bg-black"} text-white lh_33`}>
                            <img width={"100%"} src="https://opt-v3-files.raksahb.com/static/media/evolutionGamesNew.png" />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mx-1" onClick={() => setMenuTab({ ID: 983 })}>
                        <div className={`py-2 all-games-tabs2 px-2 rounded-6  ${menuTab?.ID === 983 ? "casino-tabs-active" : "bg-black"} text-white lh_33`}>
                            <img width={"100%"} src="https://opt-v3-files.raksahb.com/static/media/ezugiLogoNew.png" />
                        </div>
                    </div>
                    <div className="d-flex align-items-center justify-content-center mx-1" onClick={() => setMenuTab({ ID: 940 })}>
                        <div className={`py-2 all-games-tabs2 px-2 rounded-6  ${menuTab?.ID === 940 ? "casino-tabs-active" : "bg-black"} text-white lh_33`}>
                            <img width={"100%"} src={Evoplay} />
                        </div>
                    </div>
                    
                    {/* </Slider> */}
                </div>
                {/* {
                    categories?.length
                    ?
                    <div className="pb-2">
                        <Slider {...settings}>
                            <div className="d-flex align-items-center justify-content-center mx-1" onClick={() => setMenuTab({ID:0})}>
                                <div className={`py-2 px-4 rounded-6  ${menuTab?.ID === 0 ? "casino-tabs-active" : "bg-black"} text-white lh_33`}>All</div>
                            </div>
                            {
                                categories?.map(el => (
                                    <div className="d-flex align-items-center justify-content-center mx-1" onClick={() => setMenuTab(el)}>
                                        <div className={`py-2 px-4 rounded-6  ${menuTab?.ID === el?.ID ? "casino-tabs-active" : "bg-black"} text-white lh_33`}>{el?.Name?.en}</div>
                                    </div>
                                ))
                            }
                        </Slider>
                    </div>
                    :
                    ""
                } */}
                {/* <div className="pb-3">
                    <Slider {...settings}>
                        <div className="d-flex align-items-center justify-content-center mx-1" onClick={() => setMenuTab({...menuTab, type: 0})}>
                            <div className={`py-2 px-3 rounded-6 text-white casino-tabs fs_14 ${menuTab?.type === 0 ? "casino-tabs-active" : "bg-black"}`}>
                                <span className="fs_14">All</span>
                            </div>
                        </div>
                        <div className="h-100 d-flex align-items-center justify-content-center mx-1" onClick={() => setMenuTab({...menuTab, type: 1})}>
                            <div className={`p-1 py-2 rounded-6 text-white casino-tabs d-flex justify-content-between align-items-center ${menuTab?.type === 1 ? "casino-tabs-active" : "bg-black"}`}>
                                <img className="mx-2" src={Teenpatti} height="20" />
                                <span className="me-2 fs_14">BLACK JACK</span>
                            </div>
                        </div>
                        <div className="h-100 d-flex align-items-center justify-content-center mx-1" onClick={() => setMenuTab({...menuTab, type: 2})}>
                            <div className={`p-1 py-2 rounded-6 text-white casino-tabs d-flex justify-content-between align-items-center ${menuTab?.type === 2 ? "casino-tabs-active" : "bg-black"}`}>
                                <img className="mx-2" src={BlackJack} height="20" />
                                <span className="me-2 fs_14">TEENPATTI</span>
                            </div>
                        </div>
                        <div className="h-100 d-flex align-items-center justify-content-center mx-1" onClick={() => setMenuTab({...menuTab, type: 3})}>
                            <div className={`p-1 py-2 rounded-6 text-white casino-tabs d-flex justify-content-between align-items-center ${menuTab?.type === 3 ? "casino-tabs-active" : "bg-black"}`}>
                                <img className="mx-2" src={Teenpatti} height="20" />
                                <span className="me-2 fs_14">ROULETTE</span>
                            </div>
                        </div>
                        <div className="h-100 d-flex align-items-center justify-content-center mx-1" onClick={() => setMenuTab({...menuTab, type: 4})}>
                            <div className={`p-1 py-2 rounded-6 text-white casino-tabs d-flex justify-content-between align-items-center ${menuTab?.type === 4 ? "casino-tabs-active" : "bg-black"}`}>
                                <img className="mx-2" src={Dragon} height="20" />
                                <span className="me-2 fs_14">DRAGON TIGER</span>
                            </div>
                        </div>
                    </Slider>
                </div> */}
            </div>

            <div>
                <div className="mb_5rem">
                    {
                        // loader || filterLoader
                        // ?
                        // <div className='text-center mt-4'>
                        //     <div className="spinner-border text-primary" role="status"></div>
                        // </div>
                        // :
                        gamesList?.length
                            ?
                            <>
                                <div className="g-0 m-0 p-0 py-1 d-flex align-content-around flex-wrap justify-content-center " onScroll={handleScroll} style={{}}>
                                    {
                                        gamesList?.map((ele, ind) => (
                                            <div onClick={() => setIframeData(ele)} className="onlineGamesCard w-50 d-flex justify-content-center">
                                                <img src={ele.ImageFullPath} className='border-rounded p-1 w-100' style={{ borderRadius: '11px' }} />
                                            </div>
                                        ))
                                    }
                                    <button className="btn btn-success w-75 m-auto mx-3 mt-3" onClick={() => setPage(page + 1)}>Load More</button>
                                </div>

                            </>
                            :
                            "No data Found"
                    }

                </div>
            </div>

            {/* <div className="row m-0 p-0 px-2 mb_4rem">
                <div className="col-6 p-2 ">
                    <img src={CasinoCard1} className="w-100 h-100" />
                </div>
                <div className="col-6 p-2 ">
                    <img src={CasinoCard2} className="w-100 h-100" />
                </div>
                <div className="col-6 p-2 ">
                    <img src={CasinoCard3} className="w-100 h-100" />
                </div>
                <div className="col-6 p-2 ">
                    <img src={CasinoCard4} className="w-100 h-100" />
                </div>

                <div className="col-6 p-2 ">
                    <img src={CasinoCard1} className="w-100 h-100" />
                </div>
                <div className="col-6 p-2 ">
                    <img src={CasinoCard2} className="w-100 h-100" />
                </div>
                <div className="col-6 p-2 ">
                    <img src={CasinoCard3} className="w-100 h-100" />
                </div>
                <div className="col-6 p-2 ">
                    <img src={CasinoCard4} className="w-100 h-100" />
                </div>
            </div> */}
            <MobileFooter />
        </div >
    );
}

export default OnlineCasinoGames;
