import React from "react";
import footerimg from "./../assets/images/footer-img.png";
import fb from "./../assets/images/fb.png";
import telegram from "./../assets/images/telegram.png";
import insta from "./../assets/images/insta.png";
import twiter from "./../assets/images/twiter.png";
import logo from "./../assets/images/logo.png";
import { Link, useNavigate } from "react-router-dom";
import ezuki from "../assets/ezugi.svg"
import evolPlay from "../assets/evoPlay.svg"
import evolution from "../assets/evolutionGaming.svg"
import "./footer.css"


function Footer() {

  const navigate = useNavigate()
  return (
    <div className="footerbg">
      <div className="container-fluid footer">
        <div className="row">
          <div className="col-md-2">
            <p className="footer-heading pt-5">Quick Links </p>
            <ul className="ul-footer p-0">
              <li>Sports</li>
              <li>Teenpati</li>
              <li>Casino</li>
              <li>Virtual Sports</li>
              <li>Promotions</li>
            </ul>
          </div>
          <div className="col-md-2">
            <p className="footer-heading pt-5">Legal </p>
            <ul className="ul-footer p-0">
              <Link className="tersm-li-tag" to="/terms-and-condition">
                <li>Term & Conditions</li>
              </Link>
              <Link className="tersm-li-tag" to="/privacy-policy">
                <li>Privacy Policy</li>
              </Link>
              <Link className="tersm-li-tag" to="/responsible-gambling">
                <li>Responsible Gambling</li>
              </Link>
              <Link className="tersm-li-tag" to="/kyc-policy">
                <li>KYC Policy</li>
              </Link>
              <Link className="tersm-li-tag" to="/exclusive-policy">
                <li>Self Exclusion Policy</li>
              </Link>
            </ul>{" "}
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginBottom: "20px", marginTop: "20px" }}>
              <img style={{ width: "147px" }} src="https://opt-v3-files.raksahb.com/static/cashsite/brand/6/icon/Gaming-Curacao-Logo.png" />
          </div>
          <div className="col-md-6 w-100 mb-3">
            <p className="footer-heading pt-md-5 mb-2 text-center mt-2">Gaming Partners </p>
          </div>
          <div className="scroll-hide d-flex overflow-auto hideScrollbar px-2 py-3">
            <img  onClick={() => navigate("/online-casino-games", { state: {menuTab: { ID: 998 }} })} className="me-3" style={{height: "20px", objectFit: "cover"}} src="https://opt-v3-files.raksahb.com/static/media/evolutionGamesNew.png" alt="evolutionGamesNew" />
            <img onClick={() => navigate("/online-casino-games", { state: {menuTab: { ID: 983 }} })} className="me-3" style={{height: "20px", objectFit: "cover"}} src="https://opt-v3-files.raksahb.com/static/media/ezugiLogoNew.png" alt="ezugiLogoNew" />
            <img className="me-3" style={{height: "20px", objectFit: "cover"}} src="https://opt-v3-files.raksahb.com/static/media/aGLogoNew.png" alt="aGLogoNew" />
            <img className="me-3" style={{height: "20px", objectFit: "cover"}} src="https://opt-v3-files.raksahb.com/static/media/betGamesLogoNew.png" alt="betGamesLogoNew" />
            <img className="me-3" style={{height: "20px", objectFit: "cover"}} src="https://opt-v3-files.raksahb.com/static/media/netentLogoNew.png" alt="netentLogoNew" />
            <img className="me-3" style={{height: "20px", objectFit: "cover"}} src="https://opt-v3-files.raksahb.com/static/media/tvbetLogoNew.png" alt="tvbetLogoNew" />
            <img className="me-3" style={{height: "20px", objectFit: "cover"}} src="https://opt-v3-files.raksahb.com/static/media/superSpadeLogoNew.png" alt="superSpadeLogoNew" />
            <img className="me-3" style={{height: "20px", objectFit: "cover"}} src="https://opt-v3-files.raksahb.com/static/media/supernowaLogoNew.png" alt="supernowaLogoNew" />
            <img className="me-3" style={{height: "20px", objectFit: "cover"}} src="https://opt-v3-files.raksahb.com/static/media/BTILogoNew.png" alt="BTILogoNew" />
            <img className="me-3" style={{height: "20px", objectFit: "cover"}} src="https://opt-v3-files.raksahb.com/static/media/7mojosIcon.png" alt="7mojosIcon" />
            <img className="me-3" style={{height: "20px", objectFit: "cover"}} src="https://opt-v3-files.raksahb.com/static/media/spribe_logo_footer.png" alt="spribe_logo_footer" />
          </div>
        </div>
        <div className="row mt-4">
          <div className="col-md-12 text-center my-3">
            <img src={logo} className="" />
          </div>
          <div className="footer-main-burger col-md-12 text-center text-white my-3 mb_4rem">
            <p className="footer-p">
              Anti-Money Laundering Program | About Us |FAQ |Affiliate| Blog|
              News
            </p>
            <p className="copyright">
              Copyright 2021-2023. All rights reserved
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Footer;
