import React, { useEffect, useState } from "react";
import leftarrow from "../../assets/images/leftarrow.png";
import withdrawOne from "../../assets/images/withdraw-bank-one.png";
import withdrawTwo from "../../assets/images/withdraw-bank-two.png";
import withdrawThree from "../../assets/images/withdraw-bank-three.png";
import QuickWithdraw from "../QuickWithdraw/QuickWithdraw";
import NormalWithdraw from "../NormalWithdraw/NormalWithdraw";
import axios from 'axios';
import { walletBalance } from "../../services/api";
import baseUrl from "../../services/baseUrl";


// import CurrentBets from "../CurrentBets/CurrentBets";
// import PastBets from "../PastBets/PastBets";

const Withdrawals = ({ ref2 }) => {
  const [pageId, setPageID] = useState("1");
  const [balanceData, setBalanceData] = useState();

  const getWalletBalance = async () => {
    try {
      // setLoader(true);
      const Token = JSON.parse(localStorage.getItem("betUser"));
      const headers = {
        headers: {
          // "Content-Type": "application/json",
          "x-access-token": Token?.token,
        },
      };
      const responce = await axios.get(baseUrl + walletBalance, headers);
      if (responce.status == 200) {
        // setLoader(false);
        console.log(responce.data.data[0].wallet_amount, "responce");
        setBalanceData(responce.data.data[0].wallet_amount);
      }
    } catch (error) {
      // setLoader(false);
    }
  };


  useEffect(() => {
    getWalletBalance()
  }, [])
  return (
    <>
      <div className="dashboard-account mb-3">
        <div className="symbols-dashboard-back">
          <img className="back-arrow" src={leftarrow} alt="Symbols" onClick={() => ref2?.current?.scrollIntoView({ behaviour: "smooth" })} />
          <p className="dashboard-p">Withdrawal</p>
        </div>
        <div className="withdraw-banks-div-flex">
          <div className="withdraw-under-inner-div">
            <div className="image-withdraw-sides">
              <img src={withdrawOne} />
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="withdraw-p-tag">
                {" "}
                <p>Available withdrawal</p>
              </div>

              <div className="withdraw-class-p3 text-center align-bottom">
                <p className="text-center">{balanceData}</p>
              </div>
            </div>
          </div>
          <div className="withdraw-under-inner-div">
            <div className="image-withdraw-sides">
              <img src={withdrawTwo} />
            </div>
            <div className="d-flex flex-column align-items-center">
              <div className="withdraw-p-tag">
                <p>Withdrawal blocked due to bonus</p>
              </div>

              <div className="withdraw-class-p2 text-center align-self-end">
                <p className="text-center">0.00</p>
              </div>
            </div>
          </div>
          <div className="withdraw-under-inner-div">
            <div className="image-withdraw-sides">
              <img src={withdrawThree} />
            </div>
            <div className="withdraw-p-tag">
              {" "}
              <p>Withdrawal blocked due to pending turnover</p>
            </div>

            <div className="withdraw-class-p2 text-center align-bottom">
              <p className="">0.00</p>
            </div>
          </div>
        </div>
        {/* <div className="withdrawal-option-cjoode">
          <p>Choose Withdrawal Method</p>
        </div> */}
        {/* <div className="bats-past-current-1">
          <div
            className={`${pageId === "1" ? "border-bottom-blue" : ""}`}
            onClick={() => setPageID("1")}
          >
            <p>Quick Withdrawal</p>
          </div>
          <div
            className={`${pageId === "2" ? "border-bottom-blue" : ""}`}
            onClick={() => setPageID("2")}
          >
            <p> Normal Withdrawal</p>
          </div>
        </div> */}
        {/* {pageId === "1" ?  */}
        <QuickWithdraw />
        {/* {pageId === "2" ? <NormalWithdraw /> : ""} */}
      </div>
    </>
  );
};

export default Withdrawals;
