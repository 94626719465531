import React, { useState } from "react";

const WelcomeBonus = () => {
  return (
    <>
      <div className="cashback-content">
        <h3 className="cashback-content-head">WELCOME BONUS</h3>
        <p className="cashback-content-para">
          Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i
          premiren lådade mil, plan. Stereofiering don poliga lavis fashionista.
          Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar,
          innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska
          utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
        </p>
        <h3 className="cashback-content-head">WHAT IS WELCOME BONUS</h3>
        <p className="cashback-content-para">
          Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i
          premiren lådade mil, plan. Stereofiering don poliga lavis fashionista.
          Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar,
          innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska
          utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
        </p>
        <h3 className="cashback-content-head">HOW TO GET THIS BONUS</h3>
        <p className="cashback-content-para">
          Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i
          premiren lådade mil, plan. Stereofiering don poliga lavis fashionista.
          Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar,
          innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska
          utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
        </p>
        <h3 className="cashback-content-head">WHAT IS RO REQUIREMENT?</h3>
        <p className="cashback-content-para">
          Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i
          premiren lådade mil, plan. Stereofiering don poliga lavis fashionista.
          Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar,
          innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska
          utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
        </p>
        <h3 className="cashback-content-head">TERMS AND CONDITION</h3>
        <p className="cashback-content-para">
          Lörem ipsum förortare are ang jag reinfeldtare sev. Dode prode i
          premiren lådade mil, plan. Stereofiering don poliga lavis fashionista.
          Bektiga göning och ser viligt i ovalig. Diabuskap planing dojyngar,
          innan doda. Yoloa yr löck res lasamma dyren sätegt. Oska
          utpressningsprogram, av sovis. Sävad viryvis anteligt såväl.
        </p>
      </div>
    </>
  );
};

export default WelcomeBonus;
