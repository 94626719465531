import axios from "axios";
import React, { useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import { Link, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PrimaryNavbar from "../../components/PrimaryNavbar";
import { put_send_Otp } from "../../services/api";
import baseUrl from "../../services/baseUrl";
import ArrowLeft from "../../assets/images/profileDashboard/arrowLeft.svg";

function SendOTP() {
    const navigate = useNavigate();

    const [loginForm, setLoginForm] = useState({
        phoneNumber: "",
        password: "",
        loader: false,
    });
    const [loginFormError, setLoginFormError] = useState({
        phoneNumber: "",
        password: "",
    });

    const handleChange = (e) => {
        setLoginForm({ ...loginForm, [e.target.name]: e.target.value });
        setLoginFormError({ ...loginFormError, [e.target.name]: null });
    };

    const handleValidation = () => {
        const { phoneNumber, password } = loginForm;
        var rphoneNumberReg = /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/;
        const newErrors = {};
        if (!phoneNumber) {
            newErrors.phoneNumber = "Please Enter Phone Number";
        } else if (phoneNumber && phoneNumber.length > 10) {
            newErrors.phoneNumber = "Phone Number should be below 10 characters";
        } else if (phoneNumber && phoneNumber.length < 10) {
            newErrors.phoneNumber = "Phone Number should be  10 characters";
        }
        // if (!password) {
        //   newErrors.password = "Please enter password";
        // }
        return newErrors;
    };

    let [userData, setUserData] = useState();

    const handleSubmit = async (e) => {
        e.preventDefault();
        const handleValidationObject = handleValidation();
        if (Object.keys(handleValidationObject).length > 0) {
            setLoginFormError(handleValidationObject);
        } else {
            setLoginForm({ ...loginForm, loader: true });
            try {
                let payLoad = {
                    phone: loginForm.phoneNumber,
                    // password: loginForm.password,
                };

                let data = await axios.put(baseUrl + put_send_Otp + '?' +
                    `phone=${loginForm.phoneNumber}`
                    , payLoad);
                if (data?.data?.data) {
                    setLoginForm({ ...loginForm, loader: false });
                    toast.success(data?.data?.data.otp, { position: "top-center", autoClose: 2000 });
                    setTimeout(() => {
                        if (data?.data?.data.otp) {
                            navigate('/otp', { state: data?.data?.data })
                            const otpDataStringify = JSON.stringify(data?.data?.data)
                            const otpData = localStorage.setItem('otpdata', otpDataStringify)
                        }
                    }, 1000);
                }
            } catch (error) {
                toast.error(error?.response?.data?.message, {
                    position: "top-center",
                    autoClose: 2000,
                });
                setLoginForm({ ...loginForm, loader: false });
            }
        }
    };

    return (
        <div className="loginBg">
            <PrimaryNavbar></PrimaryNavbar>
            <div className="container-fluid g-0 text-white p-4">
                <ToastContainer />
                <div className="pt-5">
                    <div className="black_glass border-light_01 position-relative rounded-8">
                        <i className="bi bi-x-lg position-absolute d-flex justify-content-end cursor-pointer" onClick={() => navigate("/")} style={{ right: "10px", top: "10px", fontSize: "25px" }}></i>
                        <Form className="px-4 py-5" onSubmit={handleSubmit}>
                            <Form.Group className="mb-3">
                                <div className='row mb-3 text-white text-start'>
                                    <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
                                        <img src={ArrowLeft} className="cursor-pointer arrow-icone-size" />
                                    </div>
                                    <div className='col-10'>
                                        <h2 className="text-center  text-white">Forgot Password</h2>
                                    </div>
                                    <div className='col-1'></div>
                                </div>

                            </Form.Group>

                            <Form.Group className="mb-3" controlId="formBasicphoneNumber">
                                <Form.Label className=" text-white">Phone Number</Form.Label>
                                <Form.Control
                                    type="number"
                                    name="phoneNumber"
                                    className={`br0 ${loginFormError.phoneNumber ? "border border-danger " : ""
                                        } rounded-6`}
                                    onChange={handleChange}
                                    placeholder="Enter Phone Number"
                                    maxLength={10}
                                    autoComplete='off'
                                    onInput={(e) => {
                                        if (e.target.value > e.target.maxLength) {
                                            e.target.value = e.target.value.slice(0, e.target.maxLength)
                                        }
                                    }}
                                />
                                <span className="text-danger">
                                    {loginFormError && loginFormError?.phoneNumber}
                                </span>
                            </Form.Group>
                            {/* <Form.Group className="mb-3" controlId="formBasicCheckbox">
                <Form.Text className="text-white text-end ">Forgot Password?</Form.Text>
              </Form.Group> */}

                            <Form.Group className="mb-3" controlId="formBasicCheckbox">
                                <Button
                                    variant="primary"
                                    type="submit"
                                    className="br0 w-100  text-white rounded-6"
                                >
                                    Send OTP{" "}
                                    {loginForm.loader && (
                                        <div
                                            className="spinner-border spinner-border-sm"
                                            role="status"
                                        ></div>
                                    )}
                                </Button>
                            </Form.Group>
                            {/* <Form.Group className="mb-3">
                <p className="text-center">
                  Do not have an Account?{" "}
                  <Link
                    to="/registration-page"
                    className="text-decoration-none  text-warning mx-2"
                  >
                    <b>SIGN UP</b>
                  </Link>
                </p>
              </Form.Group> */}
                        </Form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SendOTP;
