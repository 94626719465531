import React from "react";
import PrimaryNavbar from "../../components/PrimaryNavbar";
import QRCode from "react-qr-code";
import name from "../../assets/images/profileDashboard/name.svg"
import username from "../../assets/images/profileDashboard/username.svg"
import phone from "../../assets/images/profileDashboard/phone.svg"
import email from "../../assets/images/profileDashboard/email.svg"
import referralCode from "../../assets/images/profileDashboard/referralCode.svg"
import referralLink from "../../assets/images/profileDashboard/referralLink.svg"
import copy from "../../assets/images/profileDashboard/copy.svg"
import ShareIcon from "../../assets/ShareIcon.svg"
import TelegramSvg from '../../assets/TelegramSvg.svg'
import WhatsappIcon from '../../assets/WhatsappIcon.svg'


import qrcode from "../../assets/images/profileDashboard/qrcode.svg"
import ArrowLeft from "../../assets/images/profileDashboard/arrowLeft.svg"
import edit from "../../assets/images/profileDashboard/edit.svg"
import MobileFooter from "../../components/MobileFooter"
import { useNavigate } from "react-router-dom"
import MobileSideBar from "../../components/MobileSideBar/MobileSideBar";
import Modal from 'react-modal';
import { Form } from 'react-bootstrap';
import { toast, ToastContainer } from 'react-toastify'
import { useState, useEffect } from 'react'
import 'react-toastify/dist/ReactToastify.css';
import baseUrl, { frontEndUrl } from "../../services/baseUrl";
import { get_User_Profile, update_Profile } from "../../services/api";
import UnionCross from "../../assets/images/Union.svg"
import axios from "axios";
import UsernameContext from "../../context/UserContext";
import '../AcconutsMobile/AcconutsMobile.css'
import { Tooltip } from 'react-tooltip'
import 'react-tooltip/dist/react-tooltip.css'

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        padding: 0,
        border: 'none',
        outline: 'none',
        borderRadius: 0,
        overflowY: 'hidden',
        borderRadius:"8px"
    },
    overlay: {
        background: "rgba(0, 0, 0, 0.5)",
        zIndex: 9
    }
};

function AcconutsMobile() {
    const navigate = useNavigate()
    const [user, setUser] = useState('')
    let subtitle;
    const [modalIsOpen, setIsOpen] = React.useState(false);
    const [userData, setUserData] = useState({ name: '', userName: '', email: '', contactNumber: '', loader: false })
    const [userDataError, setUserDataError] = useState({ name: '', userName: '', email: '', contactNumber: '', loader: false })
    const [messageList, setMessageList] = useState([])
    const [profileDetails, setProfileDetails] = useState({ data: [], loader: false })
    const handleUsernameUpdate = React.useContext(UsernameContext);
    const [shareOpen, setShareOpen] = useState(false)

    function afterOpenModal() {
        if (subtitle && subtitle?.style) {
            subtitle.style.color = '#f00';
        }
    }

    function openModal() {
        setIsOpen(true);
        setUserData({ ...userData, name: profileDetails?.data?.name, email: profileDetails?.data?.email })
    }

    function closeModal() {
        setIsOpen(false);
    }


    const handleChange = (event) => {
        setUserData({ ...userData, [event.target.name]: event.target.value })
        setUserDataError({ ...userDataError, [event.target.name]: null })
    }

    const handleValidation = () => {
        const newErrrors = {}
        if (userData.name == '') {
            newErrrors.name = 'please enter name'
        }
        if (userData.userName == '') {
            newErrrors.userName = 'please enter userName'
        }

        if (userData.email == '') {
            newErrrors.email = 'please enter email'
        }

        if (userData.contactNumber == '') {
            newErrrors.time = 'please enter contactNumber'
        }
        return newErrrors
    }

    const handleUpdateInfomation = async (event) => {
        event.preventDefault()
        // const handleValidationObject = handleValidation()
        // if (Object.keys(handleValidationObject).length > 0) {
        //     setUserDataError(handleValidationObject)
        // } else {
        try {
            setUserData({ ...userData, loader: true })
            const payload = {
                name: userData?.name,
                email: userData?.email,
                // phone: userData?.contactNumber,
            }
            const Token = localStorage.getItem("betUser");
            const parseToken = JSON.parse(Token)
            const headers = {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": parseToken?.token,
                },
            };

            const responec = await axios.put(baseUrl + update_Profile, payload, headers)
            if (responec.status == 200) {
                toast.success('Updated profile', { position: "top-center", autoClose: 2000 });
                // handleUsernameUpdate(userData?.name);
                handleCleare()
                closeModal()
                getProfileDetails()
            } else {
                handleCleare()
                closeModal()
                getProfileDetails()
            }
        } catch (error) {
            toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
            handleCleare()
            getProfileDetails()
        }
        // }
    }

    const handleCleare = () => {
        setUserData({ ...userData, name: "", email: "", loader: false })
    }

    const getProfileDetails = async () => {
        try {
            setProfileDetails({ ...profileDetails, loader: true });
            const Token = JSON.parse(localStorage.getItem("betUser"));
            const headers = {
                headers: {
                    // "Content-Type": "application/json",
                    "x-access-token": Token?.token,
                },
            };
            const responce = await axios.get(baseUrl + get_User_Profile, headers);
            if (responce.status == 200) {
                setProfileDetails({ ...profileDetails, data: responce?.data?.data, loader: false });
                localStorage.setItem("UserName", JSON.stringify(responce?.data?.data?.name));

            }
        } catch (error) {
            setProfileDetails({ ...profileDetails, loader: false });
        }
    };


    useEffect(() => {
        getProfileDetails()
    }, [])

    const handleCopy = (mes) => {
        navigator.clipboard.writeText(mes);
        toast.success('Text Copied', { position: "top-center", autoClose: 2000, });
    }



    return (
        <div>
            <ToastContainer />
            <PrimaryNavbar profileDetails={profileDetails} />
            <div className="position-relative">
                <MobileSideBar />
            </div>
            <div className="container py-3 accountInfoBg">
                <div className='row mb-3 text-white text-start'>
                    <div className='col-2 ' onClick={() => navigate(-1)}>
                        <img src={ArrowLeft} className="arrow-icone-size rounded-4" />
                    </div>
                    <div className='col-8 text-center'>
                        <p className='mb-0 currentbets_title fw_700'>Account Info</p>
                    </div>
                    <div className='col-2 text-end m-0 p-0 pe-2 cursor-pointer' onClick={openModal}>
                        <img src={edit} className="pb-1 me-1" />
                        <span className="text-"  >Edit</span>
                    </div>

                </div>

                <div className="container green_glass py-3 mb-3 border-success rounded-8">
                    <div className="row m-0 p-0 align-items-center">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">General Info</span>
                    </div>
                    <div className="row m-0 p-0 mt-2">
                        <div className="col-6 p-0 pe-2 m-0 rounded-8">
                            <div className="bg-white p-3 rounded-6">
                                <div className=""><img className="rounded-4" src={name} /></div>
                                <div className="mt-2 fs_14 font-weight-bold">Name</div>
                                <div className="mt-2 fs_14 font-weight-bold">
                                    {
                                        profileDetails?.loader
                                            ?
                                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" ></span>
                                            :
                                            profileDetails?.data?.name
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-6 p-0 pe-2 m-0 ">
                            <div className="bg-white p-3 rounded-6">
                                <div className=""><img className="rounded-4" src={username} /></div>
                                <div className="mt-2 fs_14 font-weight-bold">User name</div>
                                <div className="mt-2 fs_14">{
                                    profileDetails?.loader
                                        ?
                                        <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" ></span>
                                        :
                                        profileDetails?.data?.username
                                }</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container white_glass py-3 mb-3 border-white border-succes-5 rounded-8">
                    <div className="row m-0 p-0 align-items-center">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">Contact Info</span>
                    </div>
                    <div className="row m-0 p-0 mt-2 ">
                        <div className="col-6 p-0 m-0 h-100">
                            <div className="bg-white p-3 mb-2 me-2 rounded-6">
                                <div className="d-flex align-items-center">
                                    <img className="w-16 me-2 rounded-4" src={phone} />
                                    <span className="fs_10">{
                                        profileDetails?.loader
                                            ?
                                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" ></span>
                                            :
                                            profileDetails?.data?.phone
                                    }</span>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 p-0 m-0 h-100 ">
                            <div className="bg-white p-3 mb-2 me-2 rounded-6">
                                <div className="d-flex align-items-center">
                                    <img className="w-16 me-2 rounded-4" src={email} />
                                    <span className="fs_10">{
                                        profileDetails?.loader
                                            ?
                                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" ></span>
                                            :
                                            profileDetails?.data?.email
                                    }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="container white_glass py-3 border-white border-succes-5 mb_4rem rounded-8">
                    <div className="row m-0 p-0 align-items-center">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">Referrals</span>
                    </div>

                    <div className="row m-0 p-0 mt-2">
                        <div className="col-6 p-0 pe-2 m-0 ">
                            <div className="bg-white p-3 rounded-6">
                                <div className=""><img className="rounded-4" src={referralCode} /></div>
                                <div className="mt-2 fs_14 font-weight-bold">Referral Code</div>
                                <div className="mt-1 fs_14 lh_12">
                                    <span id="referral_code">
                                        {profileDetails?.data?.referral_code ? profileDetails?.data?.referral_code : "XXXXXXXXX"}
                                    </span>
                                    <img src={copy} onClick={() => handleCopy(profileDetails?.data?.referral_code)} data-clipboard-action="copy" data-clipboard-target="#referral_code" className="ms-1 pb-1 copy-icone-size" />
                                </div>
                            </div>
                        </div>
                        <div className="col-6 p-0 pe-2 m-0 " >
                            <Tooltip
                                anchorSelect="referralLinkId"
                                place="bottom"
                                content={profileDetails?.data?.referral_code ? (frontEndUrl + `/registration-page?referral=${profileDetails?.data?.referral_code}`)?.slice(0,10)+"..." : ""}
                            />
                            <div className="bg-white p-3 rounded-6" id="referralLinkId">
                                
                                <div className=""><img className="rounded-4" src={referralLink} /></div>
                                <div className="mt-2 fs_14 font-weight-bold">Referral Link</div>
                                <div className="mt-1 fs_14 lh_12">
                                    <span id="referral_link">
                                        {profileDetails?.data?.referral_code ? (frontEndUrl + `/registration-page?referral=${profileDetails?.data?.referral_code}`)?.slice(0,10)+"..." : ""}
                                    </span>
                                    <img  src={copy} onClick={() => handleCopy(frontEndUrl + `/registration-page?referral=${profileDetails?.data?.referral_code}`)} data-clipboard-action="copy" data-clipboard-target="#referral_link" className="ms-1 pb-1 copy-icone-size" />
                                    <span>
                                        <img src={ShareIcon} onClick={() => setShareOpen(true)} data-clipboard-action="copy" data-clipboard-target="#referral_link" className="ms-1 pb-1 " />
                                        {
                                            shareOpen
                                                ?
                                                <div className="share-card position-fixed"  >
                                                    <div className="p-0 d-flex align-items-center">
                                                        <div className="white_glass d-flex flex-column w-100 h-36 justify-content-center">
                                                            <span onClick={() => setShareOpen(false)} className="share-card-close text-white text-end mr-1" >x</span>
                                                            <a href="https://telegram.me/share/url?url=<URL>&text=<TEXT>" className="d-flex justify-content-center my-2" target="_blank" rel="noopener noreferrer" >
                                                                <img src={TelegramSvg} className="w-50" />
                                                            </a>
                                                            <a href="//api.whatsapp.com/send?phone=919625402718&text=Hi, I want Betkaro Id." className="d-flex justify-content-center my-2" target="_blank" rel="noopener noreferrer" >
                                                                <img src={WhatsappIcon} className="w-50" />
                                                            </a>
                                                            <div className="d-flex justify-content-center my-2" >
                                                                <img onClick={() => handleCopy('dummy url')} src={copy} className="" width='23px' />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                :
                                                ''
                                        }
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row m-0 p-0 mt-4 d-flex justify-content-center">
                        <div className="col-auto p-0 m-0">
                            <div className="">
                                <div className="d-flex justify-content-center rounded-6">
                                    {
                                        profileDetails?.data?.referral_code ? <QRCode size={200} value={frontEndUrl + `/registration-page?referral=${profileDetails?.data?.referral_code}`} /> : ""
                                    }
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="row m-0 p-0 mt-2 fs_10 text-white d-flex justify-content-center">
                        Scan Qr Code
                    </div>

                </div>
            </div>

            {/* Message Modal */}
            <Modal
                isOpen={modalIsOpen}
                onAfterOpen={afterOpenModal}
                onRequestClose={closeModal}
                style={customStyles}
                contentLabel="Example Modal"
            >
                <div className="d-flex bg-success justify-content-end">
                    <button onClick={closeModal} className="crossbutton-cancel mb-2 m-1 ">
                        {/* <i className="bi bi-x"></i> */}
                        <img className="bg-success pt-1 px-1" src={UnionCross} />
                        {/* <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" className="bi bi-x" viewBox="0 0 16 16">
                            <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                        </svg> */}
                    </button>
                </div>

                <div className="grid-margin stretch-card">
                    <div className="p-4 bg-success">
                        <div className="card-body">
                            <form className="forms-sample" onSubmit={handleUpdateInfomation}>
                                <Form.Group>
                                    <Form.Control placeholder="Name" autoComplete="off" type="text" id="exampleInputUsername1" value={userData?.name} name='name' onChange={handleChange} className={` ${userDataError?.date && 'border border-danger '}  rounded-6`} size="lg" />
                                    <span className='text-danger' >{userDataError.name}</span>
                                </Form.Group>
                                <br />
                                <Form.Group>
                                    <Form.Control autoComplete="off" type="email" id="exampleInputPassword1" value={userData?.email} name='email' onChange={handleChange} className={` ${userDataError?.email && 'border border-danger'} rounded-6 form-control`} placeholder="Email" />
                                    <span className='text-danger' >{userDataError.email}</span>
                                </Form.Group>
                                <br />
                                {/* <Form.Group>
                                    <label htmlFor="exampleInputConfirmPassword1">Contact Number</label>
                                    <Form.Control type="number" id="exampleInputConfirmPassword1" value={userData.contactNumber} name='contactNumber' onChange={handleChange} className={` ${userDataError?.contactNumber && 'border border-danger'} form-control`} placeholder="Contact Number"
                                        maxLength={10}

                                        onInput={(e) => {
                                            if (e.target.value > e.target.maxLength) {
                                                e.target.value = e.target.value.slice(0, e.target.maxLength)
                                            }
                                        }}
                                    />
                                    <span className='text-danger' >{userDataError.contactNumber}</span>
                                </Form.Group> */}
                                <button type="submit" className="btn btn-warning px-5 mr-4 rounded-6" >Submit{" "}
                                    {
                                        userData?.loader
                                        &&
                                        <div className="spinner-border spinner-border-sm " role="status"></div>
                                    }
                                </button>
                                <button className="btn bg-white text-dark px-5 rounded-6" type='reset' onClick={handleCleare}>Clear</button>
                            </form>
                        </div>
                    </div>
                </div>
            </Modal >

            <MobileFooter />
        </div >
    );
}

export default AcconutsMobile;
