import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom';
import PrimaryNavbar from '../../components/PrimaryNavbar';
import ArrowLeft from "../../assets/arrow_left.svg"
import CleanderIcon from "../../assets/cleander_icon.svg"
import DownArrow from "../../assets/down_arrow.svg"
import MobileFooter from '../../components/MobileFooter';
import MobileSideBar from '../../components/MobileSideBar/MobileSideBar';
import welcomebonusPromo from "../../assets/images/welcomebonusPromo.svg"
import { Link } from 'react-router-dom';
import IconLower from "../../assets/images/IconLower.png";
import { Tooltip as ReactTooltip } from "react-tooltip";
import "react-tooltip/dist/react-tooltip.css";
import axios from 'axios';
import baseUrl from '../../services/baseUrl';
import { get_Promotion } from '../../services/api';



const PromotionPage = () => {

    const [filterStartDate, setFilterStartDate] = useState("")
    const [filterEndDate, setFilterEndDate] = useState("")
    const navigate = useNavigate()
    const [sliderAmount, setSliderAmount] = useState("")
    const [promotionData, setPromotionData] = useState({ data: '', loader: false })

    const handleEndDate = (e) => {
        let date = e.target.value
        handleStatesSync("filterEdDt", date)
    }

    const handleStartDate = (e) => {
        let date = e.target.value
        handleStatesSync("filterStDt", date)
    }

    const handleStatesSync = (stateName, value) => {
        if (stateName === "filterStDt") { setFilterStartDate(value) }
        if (stateName === "filterEdDt") { setFilterEndDate(value) }
    }

    const createStEnDate = () => {
        let dtStr = "";
        if (filterStartDate.length !== 0 && filterEndDate.length !== 0) {
            dtStr = `&dateFrom=${filterStartDate}&dateTo=${filterEndDate}`;
        } else if (filterStartDate.length !== 0 && filterEndDate.length === 0) {
            dtStr = `&dateFrom=${filterStartDate}`;
        } else if (filterStartDate.length === 0 && filterEndDate.length !== 0) {
            dtStr = `&dateTo=${filterEndDate}`;
        } else {
            dtStr = "";
        }
        return dtStr;
    };

    const handlePrice = (e) => {
        setSliderAmount(e.target.value)
        // console.log(e.target.value)
    }

    const handleGetPromotion = async () => {
        try {
            const dateStr = createStEnDate()
            const user = JSON.parse(localStorage.getItem("betUser"));
            const headers = {
                headers: {
                    "Content-Type": "application/json",
                    "x-access-token": user?.token
                }
            }

            const responce = await axios.get(baseUrl + get_Promotion + dateStr, headers)
            if (responce.status == 200) {
                setPromotionData({ ...promotionData, data: responce.data.data, loader: false })
            }
        } catch (error) {
            setPromotionData({ ...promotionData, loader: false })
        }
    }

    useEffect(() => {
        handleGetPromotion()
    }, [filterStartDate, filterEndDate])

    return (
        <div>
            <PrimaryNavbar />
            <div className="position-relative">
                <MobileSideBar />
            </div>
            <div className="container px-0 py-3 mobileBgPromo">
                <div className="row px-3 mb-3 text-white">
                    <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
                        <img src={ArrowLeft} className="cursor-pointer arrow-icone-size rounded-4" />
                    </div>
                    <div className="col-10">
                        <p className="mb-0 currentbets_title fw_700 text-center">
                            Promotion
                        </p>
                    </div>
                    <div className="col-1"></div>
                </div>

                <div className='row px-3 mb-2 mx-0 mb-3'>

                </div>

                <div className='row px-3 mb-2 mx-0 mb-3'>
                    <div className='col-12 d-flex m-0 p-0'>
                        <div className="col-4 m-0 p-0 pe-2">
                            <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2 rounded-6'>
                                <div className='date_input d-flex align-items-center me-3 rounded-6'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>{filterStartDate ? (new Date(filterStartDate))?.toLocaleDateString() : "MM/DD/YYYY"}</p>
                                </div>
                                <div className='cleander_dropedown down-arrow-radius'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input onChange={handleStartDate} type="date" name='date' max={new Date().toISOString().split("T")[0]} className="position-absolute w-18 opacity-0" />
                                </div>
                            </div>
                        </div>
                        <div className="col-4 m-0 p-0 pe-2">
                            <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2 rounded-6'>
                                <div className='date_input d-flex align-items-center me-3 rounded-6'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>{filterEndDate ? (new Date(filterEndDate))?.toLocaleDateString() : "MM/DD/YYYY"}</p>
                                </div>
                                <div className='cleander_dropedown down-arrow-radius'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input onChange={handleEndDate} type="date" name='date' max={new Date().toISOString().split("T")[0]} className="position-absolute w-18 opacity-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                {/*  */}
                <div className="row text-white px-3  welcome-bonus-promotion-page-css ">
                    <p className='rounded-6'>You have won 4 Rs bonus so far !</p>
                </div>
                {/*  */}

                <div className="welcome-prom-con">
                    <img className="w-100 " src={welcomebonusPromo} />
                </div>

                <div className="row mb-3 px-3 mb_4rem ">
                    <div className="accordion " id="accordionExample">
                        <div className="accordion-item accordion-bottom-radius border-0 rounded-0 border-0 mb-3 ">
                            <h2 className="accordion-header border-0 rounded-0 border-0 ">
                                <button className="accordion-button accordionButton border-0 rounded-0 border-0  background-promotion-page " type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne ">
                                    <div>
                                        <p className="mb-1 text-dark">Details</p>

                                    </div>
                                    <span data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">  </span>
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className='m-3'>
                                    <div className="accordion-body fs_12 p-4 inside-accordian-flex green_glass rounded-6">
                                        <div className='d-flex justify-content-between align-item-center lh_0'>
                                            <p>Bonus Amount {promotionData?.data?.bonus ? promotionData?.data?.bonus : '0.00'}</p>
                                            <p className='text-warning'>Turnover Needed</p>
                                        </div>
                                        <div id="app-title" className='d-flex justify-content-between align-item-center'>
                                            <span className='d-flex px-2'>0</span>
                                            <input className='w-100 ' min={0}
                                                max={10000} type='range' value={promotionData?.data?.sum ? promotionData?.data?.sum : 0}
                                            // onChange={handlePrice}
                                            />
                                            <span className='px-2'>{promotionData?.data?.balance ? promotionData?.data?.balance : '0.00'}</span>
                                        </div>
                                        <ReactTooltip
                                            anchorId="app-title"
                                            place="bottom"
                                            content={"Rs" + " " + promotionData?.data?.sum ? promotionData?.data?.sum : 0 + "  /-"}
                                        // className='tooltip-box-divs'
                                        />


                                    </div>
                                </div>

                                <div className="d-flex row pb-2 text-white mx-4 px-2 justify-content-center">
                                    <span className="col-3 text-center d-flex m-0 p-0 px-2 fs_12 justify-content-center">Bonus Amount</span>
                                    <span className="col-3 text-center d-flex m-0 p-0 px-2 fs_12 justify-content-center">Request Date</span>
                                    <span className="col-3 text-center d-flex m-0 p-0 px-2 fs_12 justify-content-center">Expire Date</span>
                                    <span className="col-3 text-center d-flex m-0 p-0 px-2 fs_12 justify-content-center">Status</span>
                                </div>
                                <div className="d-flex row profitLossBorder pt-2 pb-4 text-white mx-4 justify-content-center">
                                    <span className="col-3 text-center d-flex m-0 p-0 fs_10 justify-content-center">{promotionData?.data?.bonus ? promotionData?.data?.bonus : '0.00'}</span>
                                    <span className="col-3 text-center d-flex m-0 p-0 fs_10 justify-content-center">null</span>
                                    <span className="col-3 text-center d-flex m-0 p-0 fs_10 justify-content-center">null</span>
                                    <span className="col-3 text-center d-flex m-0 p-0 fs_10 px-2 justify-content-center fw_500 text-green_01">IN PROGRESS </span>
                                </div>

                                <div className=' mb-3  mt-4 p-1 text-white w-20 text-center m-auto bg-dark rounded-6'>
                                    <Link className='links-promotional-page rounded-6' to="/promotion-internal"><span>Terms</span></Link>

                                </div>

                                <div className="bg-white p-1 d-flex fs_12 text-center d-flex justify-content-center" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">Close</div>
                            </div>
                        </div>



                    </div>
                </div>


            </div>
            <MobileFooter />
        </div >
    );
}

export default PromotionPage
