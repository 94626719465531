import { toast } from "react-toastify"

export const toogleMobileSidebar = () => {
    document.getElementById("mobile_sidebar")?.classList?.toggle("d-none")
}

export const showMobileSidebar = () => {
    document.getElementById("mobile_sidebar")?.classList?.remove("d-none")
}

export const hideMobileSidebar = () => {
    document.getElementById("mobile_sidebar")?.classList?.add("d-none")
}

export const debounce = (callback, wait) => {
    let timeoutId = null;
    return (...args) => {
      window.clearTimeout(timeoutId);
      timeoutId = window.setTimeout(() => {
        callback.apply(null, args);
      }, wait);
    };
}

export const isValidIFSC = (ifscCode) => {
  // Regular expression for IFSC code pattern
  var ifscPattern = /^[A-Za-z]{4}\d{7}$/;

  // Check if the provided code matches the pattern
  return ifscPattern.test(ifscCode) ? true : false
}

export const isValid_Bank_Acc_Number = bank_account_number => {
  const regex = new RegExp(/^[0-9]{9,18}$/);
  return regex.test(bank_account_number)
}

export const checkPassword = (str) => {
  // following script check -  min 8 letter password, with at least a symbol, upper and lower case letters and a number
  var re = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(str);
}

export const checkAccess = (type) => {
  const user = JSON.parse(localStorage.getItem("betUser"))
  if(user){
    if(user?.access?.includes(type)){
      return true
    } else {
      return false
    }
  } else {
    return true
  }
}


export const accessDenied = () => toast.error("Access Denied, Contact Agent.", { position: "top-center", autoClose: 2000 })
