import React, { useEffect, useState } from "react";
import {
  Button,
  Container,
  Dropdown,
  Form,
  Nav,
  Navbar,
} from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import logo from "./../assets/logo-main.svg";
// import mobilelo from "./../assets/mobilelo.png";
import deposite from "./../assets/deposite.svg";
import profile from "./../assets/images/profile.png";
import sportone from "../assets/images/sportone.png";
import sporttwo from "../assets/images/sporttwo.png";
import axios from "axios";
import baseUrl from "../services/baseUrl";
import {
  get_Message_List,
  transfer_to_main,
  walletBalance,
} from "../services/api";
import UsernameContext from "../context/UserContext";
import NotificationIcn from "../assets/NotificationIcn";

function PrimaryNavbar(props) {
  const user = JSON.parse(localStorage.getItem('betUser'))
  const navigate = useNavigate();
  let [data, setData] = useState();
  const [balanceData, setBalanceData] = useState("0.00");
  const [loader, setLoader] = useState(false);

  const [userName, setUserName] = useState("");
  const username = React.useContext(UsernameContext);
  const [notiLength, setNotiLength] = useState(0)

  const getMessageList = async () => {
    try {
      
      const headers = {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": user?.token,
        },
      };
      const res = await axios.get(baseUrl + get_Message_List, headers);
      if (res?.status == 200) {
        setNotiLength(res?.data?.unreadCount)
      } else {
        setNotiLength(0)
      }
    } catch (error) {
      // setMessageList({ ...messageList, loader: false })
      setNotiLength(0)
      // console.log(error)
    }
  };

  useEffect(() => {
    getMessageList()
  }, [])

  // const [userName, setUserName] = useState('')
  // const username = React.useContext(UsernameContext);


  const handleWalletBalance = async () => {
    try {
      const user = JSON.parse(localStorage.getItem("betUser"));
      if (user?._id) {
        setLoader(true);
        const Token = JSON.parse(localStorage.getItem("betUser"));
        const headers = {
          headers: {
            // "Content-Type": "application/json",
            "x-access-token": Token?.token,
          },
        };
        const responce = await axios.get(baseUrl + walletBalance, headers);
        if (responce.status == 200) {
          setLoader(false);
          setBalanceData(responce?.data?.data?.[0] || "0.00");
        }
      }
    } catch (error) {
      setLoader(false);
      if (error?.response?.data?.jwtExpired === true) {
        localStorage.removeItem("betUser");
        navigate("/login-page");
      }
    }
  };

  const transferToMain = async () => {
    if (window.location.pathname !== "/iframe-game") {
      const Token = JSON.parse(localStorage.getItem("betUser"));
      const headers = { headers: { "x-access-token": Token?.token } };
      await axios.post(baseUrl + transfer_to_main, {}, headers);
    }
  };

  useEffect(() => {
    const userData = JSON.parse(localStorage.getItem("betUser"));
    const userName = JSON.parse(localStorage.getItem("UserName"));
    handleWalletBalance();
    transferToMain();
    setData(userData);
    setUserName(userName);
    window?.scrollTo({ top: 0, behavior: "smooth" });
    // console.log(userData, "userData ==>");
  }, []);

  const handelLogout = () => {
    localStorage.removeItem("betUser");
    navigate("/");
  };

  useEffect(() => {
    if(window.location.pathname === "/iframe-game"){
      setTimeout(() => {
        handleWalletBalance()
        
      }, 2000)
    }
  }, [window.location.pathname])
  

  return (
    <>
      <div className="header-desktop">
        <Navbar expand="lg" className="text-nav Navbar p-0">
          <Container fluid>
            <Navbar.Brand href="/">
              {/* <Link to="/"> */}
              <img src={logo} className="mx-5 w-100" />
              {/* </Link> */}
            </Navbar.Brand>
            <Navbar.Toggle aria-controls="navbarScroll" />
            <Navbar.Collapse id="navbarScroll">
              <Nav
                className="me-auto my-2 my-lg-0 text-nav mx-5"
                style={{ maxHeight: "100px" }}
                navbarScroll
              >
                <Nav.Link href="#" className="text-nav text-white">
                  <li className="dropdown">
                    <div className="d-flex align-items-center">
                      Sports <i className="bi bi-chevron-down mx-2"></i>
                    </div>
                    <ul className="child-dropdown ps-0">
                      <div className="imagediv2 text-white">
                        <li className="info">
                          <p>
                            {" "}
                            <Link to="/odd-internal" className="link">
                              Cricket
                            </Link>
                          </p>
                          <p>
                            {" "}
                            <Link to="/odd-internal" className="link">
                              Football
                            </Link>
                          </p>
                          <p>
                            {" "}
                            <Link to="/odd-internal" className="link">
                              Volleyball
                            </Link>
                          </p>
                          <p>
                            {" "}
                            <Link to="/odd-internal" className="link">
                              Tennis
                            </Link>
                          </p>
                          <p>
                            {" "}
                            <Link to="/odd-internal" className="link">
                              Boxing
                            </Link>
                          </p>
                          <p>
                            <Link to="/odd-internal" className="link">
                              Hockey
                            </Link>
                          </p>
                        </li>
                        <li>
                          {" "}
                          <Link to="/sports-book">
                            <img src={sportone} alt="" />
                            <div className="under-image">SportsBook</div>
                          </Link>
                        </li>
                        <li>
                          <img className="imagess" src={sporttwo} alt="" />
                          <Link className="under-image" to="/exchange-page">
                            <div>Exchange</div>
                          </Link>
                        </li>
                      </div>
                    </ul>
                  </li>
                </Nav.Link>
                <Nav.Link href="#action2" className="text-nav mx-2">
                  <li className="dropdown">
                    <div className="d-flex align-items-center">
                      Teenpati <i className="bi bi-chevron-down mx-2"></i>
                    </div>
                    <ul className="child-dropdown ps-0">
                      <div className="imagediv2">
                        <li className="info">
                          <p>Cricket</p>
                          <p>Football</p>
                          <p>Volleyball</p>
                          <p>Tennis</p>
                          <p>Boxing</p>
                          <p>Hockey</p>
                        </li>
                        <li>
                          <img src={sportone} alt="" />
                          <div className="under-image">SportsBook</div>
                        </li>
                        <li>
                          <img className="imagess" src={sporttwo} alt="" />
                          <Link className="under-image" to="/exchange-page">
                            <div>Exchange</div>
                          </Link>
                        </li>
                      </div>
                    </ul>
                  </li>
                </Nav.Link>

                <Nav.Link href="#" className="text-nav mx-2">
                  <li className="dropdown">
                    <div className="d-flex align-items-center">
                      Casino <i className="bi bi-chevron-down mx-2"></i>
                    </div>
                    <ul className="child-dropdown-casino ps-0">
                      <div className="imagediv2">
                        <li className="info">
                          <p>Cricket</p>
                          <p>Football</p>
                          <p>Volleyball</p>
                          <p>Tennis</p>
                          <p>Boxing</p>
                          <p>Hockey</p>
                        </li>
                        <li>
                          <img src={sportone} alt="" />
                          <div className="under-image">SportsBook</div>
                        </li>
                        <li>
                          <img className="imagess" src={sporttwo} alt="" />
                          <Link className="under-image" to="/exchange-page">
                            <div>Exchange</div>
                          </Link>
                        </li>
                      </div>
                    </ul>
                  </li>
                </Nav.Link>
                <Nav.Link href="#" className="text-nav mx-2">
                  <li className="dropdown">
                    <div className="d-flex align-items-center">
                      Virtual Sports <i className="bi bi-chevron-down mx-2"></i>
                    </div>
                    <ul className="child-dropdown-casino ps-0">
                      <div className="imagediv2">
                        <li className="info">
                          <p>Cricket</p>
                          <p>Football</p>
                          <p>Volleyball</p>
                          <p>Tennis</p>
                          <p>Boxing</p>
                          <p>Hockey</p>
                        </li>
                        <li>
                          <img src={sportone} alt="" />
                          <div className="under-image">SportsBook</div>
                        </li>
                        <li>
                          <img className="imagess" src={sporttwo} alt="" />
                          <Link className="under-image" to="/exchange-page">
                            <div>Exchange</div>
                          </Link>
                        </li>
                      </div>
                    </ul>
                  </li>
                </Nav.Link>
                <Nav.Link href="#" className="text-nav mx-2">
                  <li className="dropdown">
                    <div className="d-flex align-items-center">
                      Promotions <i className="bi bi-chevron-down mx-2"></i>
                    </div>
                    <ul className="child-dropdown-promotions ps-0">
                      <div className="imagediv2">
                        <li className="info">
                          <p>Cricket</p>
                          <p>Football</p>
                          <p>Volleyball</p>
                          <p>Tennis</p>
                          <p>Boxing</p>
                          <p>Hockey</p>
                        </li>
                        <li>
                          <img src={sportone} alt="" />
                          <div className="under-image">SportsBook</div>
                        </li>
                        <li className="li-tag-exchange-css">
                          <img className="imagess" src={sporttwo} alt="" />
                          <Link className="under-image" to="/exchange-page">
                            <div>Exchange</div>
                          </Link>
                        </li>
                      </div>
                    </ul>
                  </li>
                </Nav.Link>
              </Nav>
              <Form className="d-flex mx-2">
                {data ? (
                  <>
                    <Button
                      className="rounded"
                      style={{
                        backgroundColor: "#009E26",
                      }}
                    >
                      <Link
                        to="/accounts"
                        className="text-white text-decoration-none mx-4"
                      >
                        {" "}
                        Deposit{" "}
                      </Link>
                    </Button>
                    <div
                      className="d-flex align-items-center mx-4 m-0 py-0 bg-dark rounded px-4"
                      style={{ color: "#FEC348" }}
                    >
                      <b className="me-2">Bal:</b>
                      <span>
                        {loader ? (
                          <div
                            className="spinner-border spinner-border-sm"
                            role="status"
                          ></div>
                        ) : (
                          <>
                            {balanceData?.wallet_amount
                              ? balanceData?.wallet_amount
                              : "0.00"}
                          </>
                        )}
                      </span>
                    </div>

                    <div>
                      <Dropdown className="m-0 p-0">
                        <Dropdown.Toggle
                          // id="dropdown-button-dark-example1"
                          variant=""
                          className="p-0 m-0 me-5 "
                        >
                          <img
                            src={profile}
                            className="w-100 rounded m-0 p-0 "
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark">
                          <Link
                            to="/accounts"
                            className="dropdown-items-navabar"
                          >
                            Profile
                          </Link>
                          <Dropdown.Item>
                            Balance: {balanceData.wallet_amount}
                          </Dropdown.Item>
                          <Dropdown.Item onClick={handelLogout}>
                            Log Out
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </>
                ) : (
                  <>
                    <Link to="/login-page">
                      {" "}
                      <Button variant="outline-primary" className="mx-5">
                        Log in
                      </Button>
                    </Link>
                    <Link to="/registration-page">
                      {" "}
                      <Button variant="primary">Sign Up</Button>
                    </Link>
                  </>
                )}
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>

      <div className="header-mobile">
        <Navbar expand="lg" className="text-nav Navbar p-0 ">
          <Container fluid className="d-flex">
            <Navbar.Brand href="/" className="me-0" style={{ flex: "1" }}>
              <img src={logo} className="mx-3 " style={{ width: "79%" }} />
            </Navbar.Brand>
           
            {data?._id ? (
              <>
               <div
              onClick={() => navigate("/messages")}
              style={{ position: "relative", marginRight: "10px" }}
            >
              <div
                style={{
                  width: "13px",
                  height: "13px",
                  backgroundColor: "red",
                  borderRadius: "50%",
                  position: "absolute",
                  right: 0,
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "center",
                  color: "white",
                  fontWeight: 600,
                  fontSize: "11px"
                }}
              >{notiLength}</div>
              <NotificationIcn />
            </div>
                <span className="d-flex mx-2">
                  <Link to="/deposit">
                    <img src={deposite} className="desposite-mobile" />
                  </Link>
                </span>

                <Link to="/user-profile">
                  <Button
                    variant="success"
                    className="ms-0 header-button pl-2 pb-1 pt-1 pr-0"
                    style={{ borderRadius: "0", height: "70px" }}
                  >
                    <div className="mobile-header-profile d-flex">
                      <div>
                        <p className="mobile-header-name text-start">
                          {props?.profileDetails?.data?.name
                            ? props?.profileDetails?.data?.name
                            : userName?.length <= 9
                              ? userName
                              : userName?.slice(0, 10) + "..."}
                        </p>
                        <p className="mt-2 mobile-bal">
                          <span className="font-weight-bold fs_14">Bal:</span>
                          {loader ? (
                            <span>
                              {" "}
                              <div
                                className="spinner-border spinner-border-sm"
                                role="status"
                              ></div>
                            </span>
                          ) : (
                            <span className="mr-2 font-weight-bold fs_14">
                              {
                                balanceData?.wallet_amount
                                ? 
                                parseFloat(balanceData?.wallet_amount).toFixed(2)
                                : 
                                "0.00"
                              }
                            </span>
                          )}
                        </p>
                      </div>
                      <Form className="d-flex ">
                        <>
                          <div className="position-relative">
                            <Dropdown className="m-0 p-0 ">
                              <Dropdown.Toggle
                                variant="dark"
                                className="heder-dropdown d-flex align-items-center justify-content-center rounded-circle mb-3  "
                                style={{ height: "25px", width: "15px" }}
                              ></Dropdown.Toggle>
                              {/* 
            {
              data?._id
                ?
                <>
                  <span className="d-flex mx-2">
                    <Link to="/deposit"><img src={deposite} className="desposite-mobile" /></Link>
                  </span>
                  <Link to='/user-profile' >
                    <Button variant="success" className="ms-0 header-button pl-2 pb-1 pt-1 pr-0" style={{ borderRadius: "0", height: "70px" }}>
                      <div className="mobile-header-profile d-flex">
                        <div>
                          <p className="mobile-header-name text-start">{props?.profileDetails?.data?.name ? props?.profileDetails?.data?.name : (userName)?.length <= 9 ? userName : userName?.slice(0, 10) + "..."}</p>
                          <p className="mt-2 mobile-bal">
                            <span className="font-weight-bold">Bal:</span>
                            {loader ? (
                              <span>
                                {" "}
                                <div className="spinner-border spinner-border-sm" role="status"></div>
                              </span>
                            ) : (
                              <span className="mr-2 font-weight-bold"> {balanceData?.wallet_amount ? balanceData?.wallet_amount : '0.00'}</span>
                            )}
                          </p>
                        </div>
                        <Form className="d-flex ">
                          <>
                            <div className="position-relative">
                              <Dropdown className="m-0 p-0 ">
                                <Dropdown.Toggle
                                  variant="dark"
                                  className="heder-dropdown d-flex align-items-center justify-content-center rounded-circle mb-3  "
                                  style={{ height: "25px", width: "15px" }}
                                >
                                </Dropdown.Toggle>
                                {/* 
                                <Dropdown.Menu variant="" className="" style={{ borderRadius: "0", top: "250%" }}>
                                  <Dropdown.Item 
                                  onClick={() => navigate("/user-profile")}
                                  >
                                    Profile
                                  </Dropdown.Item>
                                  <Dropdown.Item onClick={handelLogout}>
                                    Log Out
                                  </Dropdown.Item>
                                </Dropdown.Menu> */}
                            </Dropdown>
                          </div>
                        </>
                      </Form>
                    </div>
                  </Button>
                </Link>
              </>
            ) : (
              <span className="mx-3">
                <Link className="mx-1" to="/login-page">
                  <button
                    type="button"
                    className="  login-signup-content p-1 px-2 rounded-6 bg-warning"
                  >
                    LOGIN{" "}
                  </button>
                </Link>
                <Link className="mx-1" to="/registration-page">
                  <button
                    type="button"
                    className="  login-signup-content p-1 px-2 rounded-6 bg-success "
                  >
                    SIGNUP
                  </button>
                </Link>
                {/* <button className="btn btn-primary">Login</button>
              <button className="btn btn-primary">SignUp</button> */}
              </span>
            )}

            {/* <Navbar.Toggle aria-controls="navbarScroll" /> */}

            <Navbar.Collapse id="navbarScroll">
              {/* <Nav
            className="me-auto my-2 my-lg-0 text-nav mx-5"
            style={{ maxHeight: "100px" }}
            navbarScroll
          >
            <Nav.Link href="#" className="text-nav text-white">
              <li className="dropdown">
                <div className="nav-heading-main d-flex align-items-center">
                  Sports <i className="bi bi-chevron-down mx-2"></i>
                </div>
                <ul className="child-dropdown">
                  <div className="imagediv2 text-white">
                    <li className="info">
                      <p>
                        {" "}
                        <Link to="/odd-internal" className="link">
                          Cricket
                        </Link>
                      </p>
                      <p>
                        {" "}
                        <Link to="/odd-internal" className="link">
                          Football
                        </Link>
                      </p>
                      <p>
                        {" "}
                        <Link to="/odd-internal" className="link">
                          Volleyball
                        </Link>
                      </p>
                      <p>
                        {" "}
                        <Link to="/odd-internal" className="link">
                          Tennis
                        </Link>
                      </p>
                      <p>
                        {" "}
                        <Link to="/odd-internal" className="link">
                          Boxing
                        </Link>
                      </p>
                      <p>
                        {" "}
                        <Link to="/odd-internal" className="link">
                          Hockey
                        </Link>
                      </p>
                    </li>
                    <li>
                      {" "}
                      <Link to="/sports-book">
                        <img src={sportone} alt="" />
                        <div className="under-image">SportsBook</div>
                      </Link>
                    </li>
                    <li>
                      <img className="imagess" src={sporttwo} alt="" />
                      <div className="under-image">Exchange</div>
                    </li>
                  </div>
                </ul>
              </li>
            </Nav.Link>
            <Nav.Link href="#action2" className="text-nav mx-2">
              <li className="dropdown">
                <div className="nav-heading-main d-flex align-items-center">
                  Teenpati <i className="bi bi-chevron-down mx-2"></i>
                </div>
                <ul className="child-dropdown">
                  <div className="imagediv2">
                    <li className="info">
                      <p>Cricket</p>
                      <p>Football</p>
                      <p>Volleyball</p>
                      <p>Tennis</p>
                      <p>Boxing</p>
                      <p>Hockey</p>
                    </li>
                    <li>
                      <img src={sportone} alt="" />
                      <div className="under-image">SportsBook</div>
                    </li>
                    <li>
                      <img className="imagess" src={sporttwo} alt="" />
                      <div className="under-image">Exchange</div>
                    </li>
                  </div>
                </ul>
              </li>
            </Nav.Link>

            <Nav.Link href="#" className="text-nav mx-2">
              <li className="dropdown">
                <div className="nav-heading-main d-flex align-items-center">
                  Casino <i className="bi bi-chevron-down mx-2"></i>
                </div>
                <ul className="child-dropdown-casino">
                  <div className="imagediv2">
                    <li className="info">
                      <p>Cricket</p>
                      <p>Football</p>
                      <p>Volleyball</p>
                      <p>Tennis</p>
                      <p>Boxing</p>
                      <p>Hockey</p>
                    </li>
                    <li>
                      <img src={sportone} alt="" />
                      <div className="under-image">SportsBook</div>
                    </li>
                    <li>
                      <img className="imagess" src={sporttwo} alt="" />
                      <div className="under-image">Exchange</div>
                    </li>
                  </div>
                </ul>
              </li>
            </Nav.Link>
            <Nav.Link href="#" className="text-nav mx-2">
              <li className="dropdown">
                <div className="nav-heading-main d-flex align-items-center">
                  Virtual Sports <i className="bi bi-chevron-down mx-2"></i>
                </div>
                <ul className="child-dropdown-casino">
                  <div className="imagediv2">
                    <li className="info">
                      <p>Cricket</p>
                      <p>Football</p>
                      <p>Volleyball</p>
                      <p>Tennis</p>
                      <p>Boxing</p>
                      <p>Hockey</p>
                    </li>
                    <li>
                      <img src={sportone} alt="" />
                      <div className="under-image">SportsBook</div>
                    </li>
                    <li>
                      <img className="imagess" src={sporttwo} alt="" />
                      <div className="under-image">Exchange</div>
                    </li>
                  </div>
                </ul>
              </li>
            </Nav.Link>
            <Nav.Link href="#" className="text-nav mx-2">
              <li className="dropdown">
                <div className="nav-heading-main d-flex align-items-center">
                  Promotions <i className="bi bi-chevron-down mx-2"></i>
                </div>
                <ul className="child-dropdown-promotions">
                  <div className="imagediv2">
                    <li className="info">
                      <p>Cricket</p>
                      <p>Football</p>
                      <p>Volleyball</p>
                      <p>Tennis</p>
                      <p>Boxing</p>
                      <p>Hockey</p>
                    </li>
                    <li>
                      <img src={sportone} alt="" />
                      <div className="under-image">SportsBook</div>
                    </li>
                    <li>
                      <img className="imagess" src={sporttwo} alt="" />
                      <div className="under-image">Exchange</div>
                    </li>
                  </div>
                </ul>
              </li>
            </Nav.Link>
          </Nav> */}
              <Form className="d-flex mx-2">
                {data ? (
                  <>
                    {/* <Button variant="success" className="mx-5 m-0 py-0">
                  <Link
                    to="/accounts"
                    className="text-white text-decoration-none"
                  >
                    {" "}
                    Deposit{" "}
                  </Link>
                </Button>
                <Button variant="success" className="mx-5 m-0 py-0">
                  <b>
                    Balance:
                    {loader ? (
                      <span>
                        {" "}
                        <div
                          className="spinner-border spinner-border-sm"
                          role="status"
                        ></div>
                      </span>
                    ) : (
                      <span> {balanceData.wallet_amount}</span>
                    )}
                  </b>
                </Button> */}

                    <div>
                      <Dropdown className="m-0 p-0">
                        <Dropdown.Toggle
                          // id="dropdown-button-dark-example1"
                          variant=""
                          className="p-0 m-0"
                        >
                          <img
                            src={profile}
                            className="w-100 rounded m-0 p-0"
                          />
                        </Dropdown.Toggle>

                        <Dropdown.Menu variant="dark">
                          <Link to="/accounts">
                            <Dropdown.Item active>Profile</Dropdown.Item>
                          </Link>
                          <Dropdown.Item>
                            Banalnce: {balanceData.wallet_amount}
                          </Dropdown.Item>
                          <Dropdown.Item onClick={handelLogout}>
                            Log Out
                          </Dropdown.Item>
                        </Dropdown.Menu>
                      </Dropdown>
                    </div>
                  </>
                ) : (
                  <>
                    <Link to="/login-page">
                      {" "}
                      <Button variant="outline-primary" className="mx-5">
                        Log in
                      </Button>
                    </Link>
                    <Link to="/registration-page">
                      {" "}
                      <Button variant="primary">Sign Up</Button>
                    </Link>
                  </>
                )}
              </Form>
            </Navbar.Collapse>
          </Container>
        </Navbar>
      </div>
    </>
  );
}

export default PrimaryNavbar;
