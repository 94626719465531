import React, { useEffect } from 'react';
import { useState } from 'react'
import PrimaryNavbar from '../../components/PrimaryNavbar';
import overviewhome from "../../assets/overview-home.svg"
import overviewgraph from "../../assets/overviewgraph.svg"
import ArrowLeft from "../../assets/arrow_left.svg"
import CleanderIcon from "../../assets/cleander_icon.svg"
import DownArrow from "../../assets/down_arrow.svg"
import { useNavigate } from 'react-router-dom';
import MobileFooter from '../../components/MobileFooter';
import axios from 'axios';
import { allBetsList } from '../../services/api';
import baseUrl from '../../services/baseUrl';
import MobileSideBar from '../../components/MobileSideBar/MobileSideBar';
import ReactPaginate from 'react-paginate';

const MyBetsMobile = () => {
    const [betsList, setBetsList] = useState({ data: [], loader: false, betType: 'current' })
    const navigate = useNavigate()
    const [filterStartDate, setFilterStartDate] = useState("")
    const [filterEndDate, setFilterEndDate] = useState("")
    const [filterType, setFilterType] = useState("matched")
    // console.log(betsList)
    const handleStartDate = (e) => {
        let date = e.target.value
        handleStatesSync("filterStDt", date)
    }

    const handleEndDate = (e) => {
        let date = e.target.value
        handleStatesSync("filterEdDt", date)
    }

    const handleStatesSync = (stateName, value) => {
        if (stateName === "filterStDt") { setFilterStartDate(value) }
        if (stateName === "filterEdDt") { setFilterEndDate(value) }
    }

    const createStEnDate = () => {
        let dtStr = "";
        if (filterStartDate.length !== 0 && filterEndDate.length !== 0) {
            dtStr = `?dateFrom=${filterStartDate}&dateTo=${filterEndDate}`;
        } else if (filterStartDate.length !== 0 && filterEndDate.length === 0) {
            dtStr = `?dateFrom=${filterStartDate}`;
        } else if (filterStartDate.length === 0 && filterEndDate.length !== 0) {
            dtStr = `&dateTo=${filterEndDate}`;
        } else {
            dtStr = "";
        }
        return dtStr;
    };

    const handleType = (e) => {
        let value = e.target.value;
        setFilterType(value)
    }

    const createTypeStr = () => {
        let typeStr = "";
        if (filterType === "matched") {
            typeStr = "&type=matched"
        } else if (filterType === "unmatched") {
            typeStr = "&type=unmatched"
        } else {
            typeStr = "&type=both"
        }
        return typeStr;
    }

    const handleGetBetsList = async () => {
        setBetsList({ ...betsList, loader: true })
        const user = JSON.parse(localStorage.getItem("betUser"));
        const headers = {
            headers: {
                "Content-Type": "application/json",
                "x-access-token": user?.token
            }
        }
        try {
            if (betsList.betType == 'current') {
                const dateStr = createStEnDate()
                const typeStr = createTypeStr()
                const responce = await axios.get(baseUrl + allBetsList + `?live=true` + dateStr + typeStr, headers)
                if (responce.status == 200) {
                    setBetsList({ ...betsList, data: responce?.data?.data?.reverse(), loader: false })
                }
            } else {
                const dateStr = createStEnDate()
                const typeStr = createTypeStr()
                const responce = await axios.get(baseUrl + allBetsList + dateStr, headers)
                if (responce.status == 200) {
                    setBetsList({ ...betsList, data: responce?.data?.data?.reverse(), loader: false })
                }
            }
        } catch (error) {
            setBetsList({ ...betsList, loader: false })
        }
    }


    const [itemOffset, setItemOffset] = useState(0);
    const itemsPerPage = 3
    const endOffset = itemOffset + itemsPerPage;
    const currentItems = betsList?.data?.slice(itemOffset, endOffset);
    const pageCount = Math.ceil(betsList?.data?.length / itemsPerPage);
    const handlePageClick = (event) => {
        const newOffset = (event.selected * itemsPerPage) % betsList?.data?.length;
        setItemOffset(newOffset);
    };

    useEffect(() => {
        handleGetBetsList()
    }, [betsList?.betType, filterEndDate, filterStartDate, filterType])

    return (
        <div>
            <PrimaryNavbar />
            <div className="position-relative">
                <MobileSideBar />
            </div>
            <div className="container py-3 myBetsBG">
                <div className="row mb-3 text-white">
                    <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
                        <img src={ArrowLeft} className="cursor-pointer arrow-icone-size rounded-4" />
                    </div>
                    <div className="col-10">
                        <p className="mb-0 currentbets_title fw_700 text-center">
                            My Bets
                        </p>
                    </div>
                    <div className="col-1"></div>
                </div>
                <div className='row mb-2 mx-0 mb-3'>
                    <div className='col-12 d-flex m-0 p-0'>
                        <div className="col-4 m-0 p-0 pe-2" onClick={() => setBetsList({ ...betsList, betType: 'current' })} >
                            <button className={`${betsList.betType == 'current' ? "btn w-100 h-100 py-0 fw_700 fs_14 lh_14 btn-warning   shadow-none btn_commn_yellow_1 rounded-6 " : "w-100 h-100 btn btn-light rounded-0 px-4 fw_700 fs_14 lh_14 shadow-none rounded-6"}`}>Current</button>
                        </div>
                        <div className="col-4 m-0 p-0 pe-2" onClick={() => setBetsList({ ...betsList, betType: 'past' })}>
                            <button className={`${betsList.betType == 'past' ? "btn w-100 h-100 py-0 fw_700 fs_14 lh_14 btn-warning rounded-0 shadow-none rounded-6 btn_commn_yellow_1 rounded-6" : "w-100 h-100 btn btn-light rounded-0 px-4 fw_700 fs_14 lh_14 shadow-none rounded-4"}`}>Past</button>
                        </div>
                    </div>
                </div>
                <div className='row mb-2 mx-0 mb-3'>
                    <div className='col-12 d-flex m-0 p-0'>
                        <div className="col-4 m-0 p-0 pe-2">
                            <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2 rounded-6'>
                                <div className='date_input d-flex align-items-center me-3 rounded-6'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>{filterStartDate ? (new Date(filterStartDate))?.toLocaleDateString() : "MM/DD/YYYY"}</p>
                                </div>
                                <div className='cleander_dropedown down-arrow-radius'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input onChange={handleStartDate} type="date" name='date' max={new Date().toISOString().split("T")[0]} className="position-absolute w-18 opacity-0" />
                                </div>
                            </div>
                        </div>
                        <div className="col-4 m-0 p-0 pe-2">
                            <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2 rounded-6'>
                                <div className='date_input d-flex align-items-center me-3 rounded-6'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>{filterEndDate ? (new Date(filterEndDate))?.toLocaleDateString() : "MM/DD/YYYY"}</p>
                                </div>
                                <div className='cleander_dropedown down-arrow-radius'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input onChange={handleEndDate} type="date" name='date' max={new Date().toISOString().split("T")[0]} className="position-absolute w-18 opacity-0" />
                                </div>


                            </div>
                        </div>
                        <div className="col-4 m-0 p-0">
                            <div className='start_date_Wrapper d-flex align-items-center rounded-6'>
                                {/* <div className='date_input d-flex align-items-center me-3'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>01/03/2023</p>
                                </div>
                                <div className='cleander_dropedown'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                </div> */}
                                {
                                    betsList.betType == 'current'
                                    &&
                                    <select onChange={handleType} className="fs_14 w-100 p-1 outline-0 shadow-0 h-32 rounded-6">
                                        <option value="matched">Matched</option>
                                        <option value="unmatched">Unmatched</option>
                                        <option value="both">Both</option>
                                    </select>
                                }
                            </div>
                        </div>
                    </div>
                </div>

                {/* <div className="bet_card_wrapper mb-3">
                    <div className="row m-0 green_glass border-success py-3 align-items-center position_relative">
                        <span className="col-11 fs_14 text-white">Koepfer v Tirante</span>
                        <span className="col-12 fs_9 text-white">932834894893390</span>
                        <span className="col-12 fs_9 text-white">32323432432</span>
                        <span className="badge badge-pill badge-blue fs_13 fw_400">Back</span>
                    </div>
                    <div className="row m-0 white_glass py-3 align-items-end green_btm_border">
                        <div className='col-8 border-right text-white fs_12 fw_400'>
                            <div className='row '>
                                <div className='col-6'>
                                    <p className='mb-0'>Odds</p>
                                </div>
                                <div className='col-6'>
                                    <p className='mb-0'>: 1.42</p>
                                </div>
                            </div>
                            <div className='row text-white'>
                                <div className='col-6'>
                                    <p className='mb-0'>Stake</p>
                                </div>
                                <div className='col-6'>
                                    <p className='mb-0'> : 250.00</p>
                                </div>
                            </div>
                            <div className='row text-white'>
                                <div className='col-6'>
                                    <p className='mb-0'> Liability</p>
                                </div>
                                <div className='col-6'>
                                    <p className='mb-0'>: -</p>
                                </div>
                            </div>
                            <div className='row text-white'>
                                <div className='col-6'>
                                    <p className='mb-0'>Potential Profit</p>
                                </div>
                                <div className='col-6'>
                                    <p className='mb-0'>: +105.00</p>
                                </div>
                            </div>
                            <div className='row text-green_01'>
                                <div className='col-6'>
                                    <p className='mb-0'>Potential Profit</p>
                                </div>
                                <div className='col-6'>
                                    <p className='mb-0'>: +105.00</p>
                                </div>
                            </div>
                        </div>
                        <div className='col-4 text-white fs_10 fw_400'>
                            <p className='mb-1'>Bet Placed</p>
                            <p className='mb-1'>02/04/2023 04:09:10</p>
                        </div>
                    </div>
                </div> */}

                {
                    betsList?.loader == false && betsList?.data?.length != 0
                        ?
                        currentItems?.map((ele, ind) => {
                            return (
                                <div className={`bet_card_wrapper ${ind + 1 === betsList?.data?.length ? "mb_4rem" : "mb-3"} `}>
                                    <div className="row m-0 green_glass border-success py-3 align-items-center position_relative">
                                        <span className="col-11 fs_14 text-white">
                                            {
                                                ele?.oddsBackup?.[0]?.marketType?.[0]?.runners?.[0]?.runnerName && ele?.oddsBackup?.[0]?.marketType?.[0]?.runners?.[1]?.runnerName
                                                    ?
                                                    ele?.oddsBackup?.[0]?.marketType?.[0]?.runners?.[0]?.runnerName + " V " + ele?.oddsBackup?.[0]?.marketType?.[0]?.runners?.[1]?.runnerName
                                                    :
                                                    ""
                                            }
                                        </span>
                                        <span className="col-12 fs_9 text-white">Bet Id: {ele?._id}</span>
                                        <span className="col-12 fs_9 text-white">Match Id: {ele?.match_id}</span>
                                        <span className={`${ele?.type == 'Back' ? "badge-blue" : "badge-red"} badge badge-pill fw_400 fs_13 rounded-left-4`}>{ele?.type}</span>
                                    </div>
                                    <div className="row m-0 white_glass py-3 align-items-end green_btm_border">
                                        <div className='col-8 border-right text-white fs_12 fw_400'>
                                            <div className='row '>
                                                <div className='col-6'>
                                                    <p className='mb-0'>Odds</p>
                                                </div>
                                                <div className='col-6'>
                                                    <p className='mb-0'>: {ele?.odds}</p>
                                                </div>
                                            </div>
                                            <div className='row text-white'>
                                                <div className='col-6'>
                                                    <p className='mb-0'>Stake</p>
                                                </div>
                                                <div className='col-6'>
                                                    <p className='mb-0'> : {ele?.stake}</p>
                                                </div>
                                            </div>
                                            {
                                                ele.type == 'Lay'
                                                &&
                                                <div className='row text-white'>
                                                    <div className='col-6'>
                                                        <p className='mb-0 text-'> Liability</p>
                                                    </div>
                                                    <div className='col-6'>:
                                                        <p className='mb-0 fw_800 text-red_01 d-inline-block ms-1'>{" -"}
                                                            {
                                                                ele?.type == 'Lay' && " "+parseFloat(ele?.liabilities).toFixed(2)
                                                            }
                                                        </p>
                                                    </div>
                                                </div>
                                            }
                                            
                                            {
                                                betsList?.betType === "past" 
                                                ?
                                                <div className='row '>
                                                    <div className='col-6'>
                                                        {
                                                            ele?.profit
                                                            ?
                                                            <p className='mb-0'>Potential Profit</p>
                                                            :
                                                            ""
                                                        }
                                                    </div>
                                                    <div className='col-6' >
                                                    {
                                                        ele?.profit
                                                        ?
                                                        <p className='mb-0 fw_800'>:
                                                        {
                                                            ele?.type == 'Back'
                                                            ?
                                                            <span className='text-green_01' >{" "}+{" "}{parseFloat(ele?.odds * ele?.stake - ele?.stake).toFixed(2)}</span>
                                                            :
                                                            <span className='text-green_01' >{" "}+{" "}{parseFloat(ele?.odds * ele?.stake - ele?.stake).toFixed(2)}</span>
                                                        }
                                                        </p>
                                                        :
                                                        ""
                                                    }
                                                    </div>
                                                    <div className='col-6'>
                                                        <p className='mb-0'>Status</p>
                                                    </div>
                                                    <div className='col-6' >
                                                    {
                                                        ele?.profit
                                                        ?
                                                        <p className='mb-0 fw_800'>:
                                                            <span className='text-green_01' >{" "}+{" "}WON</span>
                                                        </p>
                                                        :
                                                        <p className='mb-0 fw_800'>:
                                                            <span className='text-red_01' >{" "}-{" "}LOSS</span>
                                                        </p>
                                                    }
                                                    </div>
                                                </div> 
                                                :
                                                <div className='row '>
                                                    <div className='col-6'>
                                                        <p className='mb-0'>Potential Profit</p>
                                                    </div>
                                                    <div className='col-6' >
                                                        <p className='mb-0 fw_800'>:
                                                            {
                                                                ele?.type == 'Back'
                                                                    ?
                                                                    <span className='text-green_01' >{" "}+{" "}{parseFloat(ele?.odds * ele?.stake - ele?.stake)?.toFixed(2)}</span>
                                                                    :
                                                                    <span className='text-green_01' >{" "}+{" "}{parseFloat(ele?.odds * ele?.stake - ele?.stake)?.toFixed(2)}</span>
                                                            }</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                        <div className='col-4 text-white fs_10 fw_400'>
                                            <p className='mb-1'>Bet Placed</p>
                                            <p className='mb-1'>{ele?.createdAt?.slice(0, 10)} {ele?.createdAt?.slice(11, 19)}</p>
                                        </div>
                                    </div>
                                </div>
                            )
                        })
                        :
                        <div>
                            {
                                betsList?.data?.length == 0
                                &&
                                <div className="bet_card_wrapper mb_4rem rounded-8">
                                    <div className="row m-0 white_glass py-3 align-items-end d-flex align-items-center justify-content-center py-5 rounded-8">
                                        <span className="text-white fs_12 text-center">No content</span>
                                    </div>
                                </div>
                            }
                            {
                                betsList?.loader == true
                                &&
                                <div className='text-center'>
                                    <div className="spinner-border text-primary" role="status"></div>
                                </div>
                            }
                        </div>
                }

                <ReactPaginate
                    breakLabel="..."
                    nextLabel=">"
                    onPageChange={handlePageClick}
                    pageRangeDisplayed={1}
                    pageCount={pageCount}
                    previousLabel="<"
                    renderOnZeroPageCount={null}
                    pageClassName="page-item me-1"
                    subContainerClassName="pages"
                    pageLinkClassName="page-link"
                    previousClassName="page-item me-1"
                    previousLinkClassName="page-link"
                    nextClassName="page-item me-1"
                    nextLinkClassName="page-link"
                    breakClassName="page-item me-1"
                    breakLinkClassName="page-link"
                    marginPagesDisplayed={1}
                    containerClassName="pagination"
                    activeClassName="active"
                />
            </div>
            <MobileFooter />
        </div >
    );
};

export default MyBetsMobile;