import React, { useState } from 'react'
import { useEffect } from 'react'

const BetSlipNumberMobile = ({className, style, onClick, children, size, sizeClass=""}) => {

    const [changed, setChanged] = useState(true)

    useEffect(() => {
        setChanged(true)
        setTimeout(() => setChanged(false), 300 )
    }, [children])

  return (
    <span className={className} style={{...style, opacity: changed? "0.4": "1"}} onClick={onClick} >
        {children}
        {
          size && children && children > 0
          ?
          <small className={sizeClass} style={{bottom: "3px"}}>{size}</small>
          :
          ""
        }
        
    </span>
  )
}

export default BetSlipNumberMobile