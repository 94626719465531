import React from "react";
import football from "./../assets/images/football.png";
import bat from "./../assets/images/bat.png";
import table from "./../assets/images/table.png";
import basket from "./../assets/images/basket.png";
import vollyball from "./../assets/images/vollyball.png";
import tenis from "./../assets/images/tenis.png";
import Carousel from "react-bootstrap/Carousel";
import inplayimg from "./../assets/images/inplayimg.png";
import Slider from "react-slick";
function OddInternal() {
  var settingsfootball = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 6,
    slidesToScroll: 6,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };
  return (
    <div>
      <div className="PlayLiveGamesdiv text-white">
        <div className="container-fluid px-5 py-3">
          <h2 className="headinghome">Top Games</h2>
          <div className="row ">
            <div className="col-md-12">
              <div>
                <Slider {...settingsfootball}>
                  <div className="px-2">
                    <div className="card mx-1  cardfootball">
                      <div className="card-body footballcard">
                        <img src={football} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">
                          Football
                        </h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={bat} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">Cricket</h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={tenis} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">Tennis</h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={vollyball} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">
                          Volleyball
                        </h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={tenis} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">Tennis</h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1  cardfootball">
                      <div className="card-body footballcard">
                        <img src={football} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">
                          Football
                        </h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={bat} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">Cricket</h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={tenis} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">Tennis</h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={vollyball} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">
                          Volleyball
                        </h1>
                      </div>
                    </div>{" "}
                  </div>
                  <div className="px-2">
                    <div className="card mx-1 cardfootball">
                      <div className="card-body footballcard">
                        <img src={tenis} className="px-1" />
                        <h1 className="text-dark Footballtext px-2">Tennis</h1>
                      </div>
                    </div>{" "}
                  </div>
                </Slider>
              </div>
            </div>
          </div>
        </div>
      </div>{" "}
    </div>
  );
}

export default OddInternal;
