import React from 'react';
import PrimaryNavbar from '../../components/PrimaryNavbar'
import  ArrowLeft from "../../assets/arrow_left.svg"
import  CleanderIcon from "../../assets/cleander_icon.svg"
import  DownArrow from "../../assets/down_arrow.svg"
import { useNavigate } from 'react-router-dom';
import MobileFooter from '../../components/MobileFooter';

const TurnAroundStatement = () => {
    const navigate = useNavigate()
    return (
        <div>
            <PrimaryNavbar />
            <div className="container px-0 py-3 turnoverBG">
                <div className="row px-3 mb-3 text-white">
                    <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
                        <img src={ArrowLeft} className="cursor-pointer arrow-icone-size" />
                    </div>
                    <div className="col-10">
                        <p className="mb-0 currentbets_title fw_700 text-center">
                            Turnover Statement
                        </p>
                    </div>
                    <div className="col-1"></div>
                </div>
                
                <div className='row px-3 mb-2 mx-0 mb-3'>
                    <div className='col-12 d-flex m-0 p-0'>
                        <div className="col-4 m-0 p-0 pe-2">
                            <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2'>
                                <div className='date_input d-flex align-items-center me-3'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>01/03/2023</p>
                                </div>
                                <div className='cleander_dropedown'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input type="date" className="position-absolute w-18 opacity-0" />
                                </div>
                            </div>
                        </div>
                        <div className="col-4 m-0 p-0 pe-2">
                            <div className='mx-auto start_date_Wrapper d-flex align-items-center ps-2'>
                                <div className='date_input d-flex align-items-center me-3'>
                                    <img src={CleanderIcon} className="me-2 img-fluid" />
                                    <p className='mb-0 lh_14 fs_10'>01/03/2023</p>
                                </div>
                                <div className='cleander_dropedown'>
                                    <div className='down_arrowicon'>
                                        <img src={DownArrow} className="img-fluid" />
                                    </div>
                                    <input type="date" className="position-absolute w-18 opacity-0" />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='row px-3 mb-2 mx-0 mb-3'>
                    <div className='col-12 d-flex m-0 p-0 fs_12 text-white'>
                        <div className="">Total turnover: 0.00</div>
                    </div>
                </div>
                
                <div className="px-3 bet_card_wrapper mb_4rem">
                    <div className="row m-0 white_glass py-3 align-items-end d-flex align-items-center justify-content-center py-5">
                        <span className="text-white fs_12 text-center">No content</span>
                    </div>
                </div>

            </div>
            <MobileFooter />
        </div>
    );
};

export default TurnAroundStatement;