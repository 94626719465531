import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import logo from "../../assets/images/logo.png"

import IconLower from "./../../assets/images/IconLower.png";
import { toogleMobileSidebar } from '../../services/helper';



const MobileSideBar = () => {
    // const [activeMenu, setActiveMenu] = useState("");
    // const [pageId, setPageId] = useState("1");
    return (
        <div className="d-flex d-none flex-column flex-shrink-0 p-4 text-white" id="mobile_sidebar" >
            {/* <a
                href="/"
                className="d-flex align-items-center mb-3 mb-md-0 me-md-auto text-white text-decoration-none"
            >
                <svg className="bi me-2" width={40} height={32}>
                    <use xlinkHref="#bootstrap" />
                </svg>
                <span className="fs-4">Sidebar</span>
            </a> */}
            <div className="d-flex justify-content-between align-items-start">
                <Link to={"/"}><img src={logo} /></Link>
                <i className="bi bi-x h1 fs_40" onClick={toogleMobileSidebar}></i>
            </div>

            <ul className="nav nav-pills flex-column mb-auto">
                <div className="container-accordian">

                    <div className="accordian-dropdoen-parent">
                        <div className="accordian-dropdoen-child">
                            <div id="MainMenu">

                                <div className="list-group-panel">
                                    <a href="#demo1" className="color-muted list-group-item-list-group-item-success td-none" data-toggle="collapse" data-parent="#MainMenu">
                                        <div className="dashboard-user-left">
                                            <div className="images-dashboard-user-profile"> Legal </div> 
                                            <div> <img src={IconLower} alt="IconLower" /> </div>
                                        </div>
                                    </a>
                                    <div className='mobile-header-tags collapse' id="demo1">
                                        <Link className="border-footer-tags td-none" to="/terms-and-condition">
                                            <h6 className="list-group-item p-0 py-2 text-white">Term & Conditions</h6>
                                        </Link>
                                        <Link className="border-footer-tags td-none" to="/privacy-policy">
                                            <h6 className="list-group-item p-0 py-2 text-white"> Privacy Policy </h6>
                                        </Link>
                                        <Link className="border-footer-tags td-none" to="/responsible-gambling">
                                            <h6 className="list-group-item p-0 py-2 text-white"> Responsible Gambling </h6>
                                        </Link>
                                        <Link className="border-footer-tags td-none" to="/kyc-policy">
                                            <h6 className="list-group-item p-0 py-2 text-white"> KYC Policy </h6>
                                        </Link>
                                        <Link className="border-footer-tags td-none" to="/exclusive-policy">
                                            <h6 className="list-group-item p-0 py-2 text-white"> Self Exclusion Policy </h6>
                                        </Link>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='faq-bar-dropdown'>
                        <Link className='border-footer-tags-FAQ td-none' to={"/FAQ-page"}>
                            <div className='border-bottom-FAQ td-none' >
                                <h6 className="text-white td-none">FAQ</h6>
                            </div>
                        </Link>
                    </div>
                </div>
            </ul >
        </div >
    )
}

export default MobileSideBar
