import React, { useEffect, useState } from "react";
import PrimaryNavbar from "../../components/PrimaryNavbar";
import ArrowLeft from "../../assets/images/profileDashboard/arrowLeft.svg"
import edit from "../../assets/images/profileDashboard/edit.svg"
import { useNavigate } from "react-router-dom";
import MobileFooter from "../../components/MobileFooter";
import baseUrl from "../../services/baseUrl";
import { getBankDetals, updateBank } from "../../services/api";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import MobileSideBar from "../../components/MobileSideBar/MobileSideBar";
import { styled } from "@mui/material/styles";
import Switch from "@mui/material/Switch";
import { isValidIFSC, isValid_Bank_Acc_Number } from "../../services/helper";



function BankDetails() {
    const navigate = useNavigate()
    const [details, setDeatails] = useState([])
    const [loader, setLoder] = useState(false)
    const [bankDetails, setBankDetails] = useState({ bankName: '', accountHoldeName: '', accountNumber: '', ifsc: '', branch: '', address: '', remarks: '', default: false, loader: false })
    const [bankDetailsError, setBankDetailsError] = useState({ bankName: '', accountHoldeName: '', accountNumber: '', ifsc: '', branch: '', address: '', remarks: '', default: false, loader: false })
    const [banksList, setBankList] = useState([
        { value: '', name: 'Select' },
        { value: 'sbi', name: 'SBI' },
        { value: 'icici', name: 'ICICI' },
        { value: 'indian', name: 'Indian' },
        { value: 'city bank', name: 'City Bank' }
    ]
    )

    const AntSwitch = styled(Switch)(({ theme }) => ({
        width: 32,
        height: 18,
        padding: 0,
        display: "flex",
        "&:active": {
            "& .MuiSwitch-thumb": {
                width: 15,
            },
            "& .MuiSwitch-switchBase.Mui-checked": {
                transform: "translateX(9px)",
            },
        },
        "& .MuiSwitch-switchBase": {
            padding: 2,
            "&.Mui-checked": {
                transform: "translateX(12px)",
                color: "#fff",
                "& + .MuiSwitch-track": {
                    opacity: 1,
                    backgroundColor:
                        theme.palette.mode === "dark" ? "#177ddc" : "#1890ff",
                },
            },
        },
        "& .MuiSwitch-thumb": {
            boxShadow: "0 2px 4px 0 rgb(0 35 11 / 20%)",
            width: 14,
            height: 14,
            borderRadius: 6,
            transition: theme.transitions.create(["width"], {
                duration: 200,
            }),
        },
        "& .MuiSwitch-track": {
            borderRadius: 16 / 2,
            opacity: 1,
            backgroundColor:
                theme.palette.mode === "dark"
                    ? "rgba(255,255,255,.35)"
                    : "rgba(0,0,0,.25)",
            boxSizing: "border-box",
        },
    }));

    //     < option value = '' > Select</option>
    // <option value='sbi' >SBI</option>
    // <option value='icici' >ICICI</option>
    // <option value='indian' >Indian</option>
    // <option value='city bank'>City Bank</option>

    const handleChange = (e) => {
        setBankDetails({ ...bankDetails, [e.target.name]: e.target.value })
        setBankDetailsError({ ...bankDetailsError, [e.target.name]: null })

    }

    const handleValidation = () => {
        const newErrors = {}
        if (bankDetails.bankName == '') {
            newErrors.bankName = 'please selct bank name'
        }
        if (bankDetails.accountHoldeName == '') {
            newErrors.accountHoldeName = 'please enter account holder name'
        }
        if (bankDetails.accountNumber == '') {
            newErrors.accountNumber = 'please enter account number '
        } else if(!isValid_Bank_Acc_Number(bankDetails.accountNumber)){
            newErrors.accountNumber = 'Invalid account number'
        }
        if (bankDetails.ifsc == '') {
            newErrors.ifsc = 'please enter ifsc code'
        }
        if(!isValidIFSC(bankDetails.ifsc)){
            newErrors.ifsc = 'Invalid IFSC'
          }
        if (bankDetails.branch == '') {
            newErrors.branch = 'please enter branch name'
        }
        if (bankDetails.bankName == '') {
            newErrors.bankDetails = 'please selct branch name'
        }
        if (bankDetails.address == '') {
            newErrors.address = 'please enter address'
        }
        if (bankDetails.remarks == '') {
            newErrors.remarks = 'please enter any remarks'
        }
        return newErrors
    }

    const handleSubmit = async (e) => {
        // e.preventDefault()
        const handleValidationObject = handleValidation()
        if (Object.keys(handleValidationObject).length > 0) {
            setBankDetailsError(handleValidationObject)
        } else {
            try {
                setBankDetails({ ...bankDetails, loader: true })
                const payload = {
                    bankDetails: {
                        bankname: bankDetails.bankName,
                        accountHolderName: bankDetails.accountHoldeName,
                        accountNumber: bankDetails.accountNumber,
                        ifsc: bankDetails.ifsc,
                        branch: bankDetails.branch,
                        address: bankDetails.address,
                        remarks: bankDetails.remarks
                    }
                }
                // if (bankDetails.default == true) {
                //     payload.bankDetails['default'] = true
                // }
                if (bankDetails.default == true) {
                    payload.bankDetails.default = true
                }

                const Token = JSON.parse(localStorage.getItem('betUser'))
                const headers = {
                    headers: {
                        "x-access-token": Token?.token
                    }
                }
                const responce = await axios.put(baseUrl + updateBank, payload, headers)
                if (responce.status == 200) {
                    handleReset()
                    toast.success('Bank Details Added', { position: "top-center", autoClose: 2000 });
                    navigate('/bank-cards')
                }
            } catch (error) {
                handleReset()
                toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
                navigate('/bank-cards')
            }
        }
    }

    const handleGetBankDetails = async () => {
        try {
            setLoder(true)
            const Token = JSON.parse(localStorage.getItem('betUser'))
            const headers = {
                headers: {
                    "x-access-token": Token?.token
                }
            }
            const responce = await axios.get(baseUrl + getBankDetals, headers)
            if (responce.status == 200) {
                setLoder(false)
                setDeatails(responce?.data?.data[0].bankDetails)
            }

        } catch (error) {
            // setLoder(false)
        }
    }


    useEffect(() => {
        handleGetBankDetails()
    }, [])

    const handleReset = () => {
        setBankDetails({ ...bankDetails, bankName: '', accountHoldeName: '', accountNumber: '', ifsc: '', branch: '', address: '', remarks: '', loader: false })
        setBankList([{ value: '', name: 'Select' }])
        setTimeout(() => {
            setBankList(banksList)
        }, [1000])
    }

    return (
        <div>
            <PrimaryNavbar />
            <div className="position-relative">
                <MobileSideBar />
            </div>
            <ToastContainer />
            <div className="container py-3 bankDetailsBG">
                <div className="row mb-3 text-white">
                    <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
                        <img src={ArrowLeft} className="cursor-pointer arrow-icone-size rounded-4" />
                    </div>
                    <div className="col-10">
                        <p className="mb-0 currentbets_title fw_700 text-center">
                            Bank Details
                        </p>
                    </div>
                    <div className="col-1"></div>
                </div>

                <div className="container green_glass py-3 mb-3 border-success rounded-8">
                    <div className="row m-0 p-0 align-items-center ">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">Add new Bank Details</span>
                    </div>
                    <div className="row m-0 p-0 mt-2 rounded-6">
                        <select className={`${bankDetailsError.bankName ? 'border border-2 border-danger' : ''} h-42p fs_14 py-2 px-2 outline-0 shadow-0 rounded-6`} name="bankName" onChange={handleChange} >
                            {
                                banksList?.map((ele, ind) => {
                                    return <option value={ele?.value} >{ele.name}</option>
                                })
                            }
                        </select>
                    </div>
                    <div className="row m-0 p-0 mt-2">
                        <input type="text" placeholder="Name" autoComplete="off" value={bankDetails.accountHoldeName} name="accountHoldeName" onChange={handleChange} className={`${bankDetailsError.accountHoldeName ? 'border border-2 border-danger' : ''} fs_14 h-42p py-2 rounded-6`} />
                    </div>
                    <div className="row m-0 p-0 mt-2">
                        <input type="number" placeholder="Account Number" maxLength={18} onInput={(e) => { if (e.target.value > e.target.maxLength) { e.target.value = e.target.value.slice(0, e.target.maxLength) } }} autoComplete="off" value={bankDetails.accountNumber} name="accountNumber" onChange={handleChange} className={`${bankDetailsError.accountNumber ? 'border border-2 border-danger' : ''} fs_14 h-42p py-2 rounded-6`} />
                    </div>
                    <div className="row m-0 p-0 mt-2">
                        <input type="text" placeholder="IFSC Code" maxLength={11} onInput={(e) => { if (e.target.value > e.target.maxLength) { e.target.value = e.target.value.slice(0, e.target.maxLength) } }} autoComplete="off" value={bankDetails.ifsc} name="ifsc" onChange={handleChange} className={`${bankDetailsError.ifsc ? 'border border-2 border-danger' : ''} fs_14 h-42p py-2 rounded-6`} />
                    </div>
                    <div className="row m-0 p-0 mt-2">
                        <input type="text" placeholder="Bank Branch" autoComplete="off" value={bankDetails.branch} name="branch" onChange={handleChange} className={`${bankDetailsError.branch ? 'border border-2 border-danger' : ''} fs_14 h-42p py-2 rounded-6`} />
                    </div>
                    <div className="row m-0 p-0 mt-2">
                        <input type="text" placeholder="Bank Address" autoComplete="off" value={bankDetails.address} name="address" onChange={handleChange} className={`${bankDetailsError.address ? 'border border-2 border-danger' : ''} fs_14 h-42p py-2 rounded-6`} />
                    </div>
                    <div className="row m-0 p-0 mt-2">
                        <input type="text" placeholder="Remarks" autoComplete="off" value={bankDetails.remarks} name="remarks" onChange={handleChange} className={`${bankDetailsError.remarks ? 'border border-2 border-danger' : ''} fs_14 h-42p py-2 rounded-6`} />
                    </div>
                    <div className="row m-0 p-0 mt-2 d-flex">
                        {/* <div className="col-1 p-0" > */}
                        {/* <input type="checkbox" placeholder="" autoComplete="off"
                                name="defalut"
                                onClick={() => setBankDetails({ ...bankDetails, default: !bankDetails.default })}
                            /> */}
                        {/* </div> */}
                        <div className="col-1 p-0 d-flex justify-content-start align-items-center" >
                            <AntSwitch className="switch bg-muted"
                                //  onClick={handleClickOne} checked={clickState === "oneClickBet"}
                                onClick={() => setBankDetails({ ...bankDetails, default: !bankDetails.default })}
                                checked={bankDetails.default}
                            />
                        </div>
                        <div className="col-1 p-0" >  </div>
                        <div className="col-10 p-0 m-0 d-flex justify-content-start align-items-center">
                            <p className="text-white p-0 m-0" >Set Default Account</p>
                        </div>
                    </div>
                    <div className='row mt-3 mb-3'>
                        <div className='col-12 d-flex'>
                            <button className='btn  btn-warning rounded-0 shadow-none btn_commn_yellow_1 px-4 me-2 rounded-6' onClick={handleSubmit} >Submit{" "}
                                {
                                    bankDetails.loader && (<div className="spinner-border spinner-border-sm" role="status" ></div>)
                                }
                            </button>
                            <button className='btn btn-light rounded-0 px-4 shadow-none rounded-6' onClick={handleReset} >Cancel</button>
                        </div>
                    </div>
                </div>
            </div>
            <MobileFooter />
        </div >
    );
}

export default BankDetails;
