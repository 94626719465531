import React from 'react'
import PrimaryNavbar from '../../components/PrimaryNavbar';
import ArrowLeft from "../../assets/images/profileDashboard/arrowLeft.svg";

import { useNavigate } from 'react-router-dom';
import MobileFooter from '../../components/MobileFooter';
import MobileSideBar from '../../components/MobileSideBar/MobileSideBar';

const FAQMobilePage = () => {
    const navigate = useNavigate();
    return (
        <div>
            <PrimaryNavbar />
            <div className="position-relative">
                <MobileSideBar />
            </div>

            <div className="container py-3 mobilepromotionBg1">
                <div className="row mb-3 text-white">
                    <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
                    <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
              <img src={ArrowLeft} className="cursor-pointer arrow-icone-size rounded-4" />
            </div>
                    </div>
                    <div className="col-10">
                        <p className="mb-0 currentbets_title fw_700 text-center">
                            FAQ
                        </p>
                    </div>
                    <div className="col-1"></div>
                </div>

                <div className="row mb-3 pt-3 ">


                    <span className="col-11 t-14 my-account-color">My Account</span>


                </div>
                <div className="row mb-3">
                    <div className="accordion " id="accordionExample">
                        <div className="accordion-item border-0 rounded-0 border-0 ">
                            <h2 className="accordion-header border-0 rounded-0 border-0">
                                <button className="accordion-button accordionButton accordionButton border-0 rounded-0 border-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="true" aria-controls="collapseOne">
                                    Why BetKaro India?
                                </button>
                            </h2>
                            <div id="collapseOne" className="accordion-collapse collapse show" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, dolor ut ullamcorper pulvinar, turpis nulla malesuada ante, id bibendum est velit ut turpis. Sed ac nunc ut ex finibus vehicula. Sed imperdiet diam ut risus accumsan, non euismod lectus commodo. Suspendisse commodo, lectus in viverra scelerisque, quam ex fermentum odio, eget posuere ante est vel est. Nulla facilisi. Nam quis tristique velit, ac suscipit risus. Vivamus ac quam non nisl ullamcorper imperdiet ac at augue. Suspendisse dignissim eget magna auctor bibendum. Suspendisse ut arcu eget enim aliquet lacinia vel a sapien. Quisque sed ex eros. Donec sollicitudin velit ut purus scelerisque, sed cursus orci euismod. Sed in aliquet libero, id congue lectus. Donec euismod mauris ac nibh ullamcorper fringilla.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item border-0">
                            <h2 className="accordion-header border-0">
                                <button className="accordion-button accordionButton border-0 collapsed mt-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                    How to reset my password
                                </button>
                            </h2>
                            <div id="collapseTwo" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, dolor ut ullamcorper pulvinar, turpis nulla malesuada ante, id bibendum est velit ut turpis. Sed ac nunc ut ex finibus vehicula. Sed imperdiet diam ut risus accumsan, non euismod lectus commodo. Suspendisse commodo, lectus in viverra scelerisque, quam ex fermentum odio, eget posuere ante est vel est. Nulla facilisi. Nam quis tristique velit, ac suscipit risus. Vivamus ac quam non nisl ullamcorper imperdiet ac at augue. Suspendisse dignissim eget magna auctor bibendum. Suspendisse ut arcu eget enim aliquet lacinia vel a sapien. Quisque sed ex eros. Donec sollicitudin velit ut purus scelerisque, sed cursus orci euismod. Sed in aliquet libero, id congue lectus. Donec euismod mauris ac nibh ullamcorper fringilla.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item border-0">
                            <h2 className="accordion-header border-0">
                                <button className="accordion-button accordionButton border-0 collapsed mt-2 " type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                    Where can i see my betting history?
                                </button>
                            </h2>
                            <div id="collapseThree" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, dolor ut ullamcorper pulvinar, turpis nulla malesuada ante, id bibendum est velit ut turpis. Sed ac nunc ut ex finibus vehicula. Sed imperdiet diam ut risus accumsan, non euismod lectus commodo. Suspendisse commodo, lectus in viverra scelerisque, quam ex fermentum odio, eget posuere ante est vel est. Nulla facilisi. Nam quis tristique velit, ac suscipit risus. Vivamus ac quam non nisl ullamcorper imperdiet ac at augue. Suspendisse dignissim eget magna auctor bibendum. Suspendisse ut arcu eget enim aliquet lacinia vel a sapien. Quisque sed ex eros. Donec sollicitudin velit ut purus scelerisque, sed cursus orci euismod. Sed in aliquet libero, id congue lectus. Donec euismod mauris ac nibh ullamcorper fringilla.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item border-0">
                            <h2 className="accordion-header border-0">
                                <button className="accordion-button accordionButton border-0 collapsed mt-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFour" aria-expanded="false" aria-controls="collapseFour">
                                    Why i am not able to see my balance?
                                </button>
                            </h2>
                            <div id="collapseFour" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, dolor ut ullamcorper pulvinar, turpis nulla malesuada ante, id bibendum est velit ut turpis. Sed ac nunc ut ex finibus vehicula. Sed imperdiet diam ut risus accumsan, non euismod lectus commodo. Suspendisse commodo, lectus in viverra scelerisque, quam ex fermentum odio, eget posuere ante est vel est. Nulla facilisi. Nam quis tristique velit, ac suscipit risus. Vivamus ac quam non nisl ullamcorper imperdiet ac at augue. Suspendisse dignissim eget magna auctor bibendum. Suspendisse ut arcu eget enim aliquet lacinia vel a sapien. Quisque sed ex eros. Donec sollicitudin velit ut purus scelerisque, sed cursus orci euismod. Sed in aliquet libero, id congue lectus. Donec euismod mauris ac nibh ullamcorper fringilla.
                                </div>
                            </div>
                        </div> <div className="accordion-item border-0">
                            <h2 className="accordion-header  border-0">
                                <button className="accordion-button accordionButton border-0 collapsed mt-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseFive" aria-expanded="false" aria-controls="collapseFive">
                                    What if i loose a bet?
                                </button>
                            </h2>
                            <div id="collapseFive" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, dolor ut ullamcorper pulvinar, turpis nulla malesuada ante, id bibendum est velit ut turpis. Sed ac nunc ut ex finibus vehicula. Sed imperdiet diam ut risus accumsan, non euismod lectus commodo. Suspendisse commodo, lectus in viverra scelerisque, quam ex fermentum odio, eget posuere ante est vel est. Nulla facilisi. Nam quis tristique velit, ac suscipit risus. Vivamus ac quam non nisl ullamcorper imperdiet ac at augue. Suspendisse dignissim eget magna auctor bibendum. Suspendisse ut arcu eget enim aliquet lacinia vel a sapien. Quisque sed ex eros. Donec sollicitudin velit ut purus scelerisque, sed cursus orci euismod. Sed in aliquet libero, id congue lectus. Donec euismod mauris ac nibh ullamcorper fringilla.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item border-0 border-0">
                            <h2 className="accordion-header border-0 border-0">
                                <button className="accordion-button accordionButton border-0 shadow-0 outline-0 border-0 collapsed mt-2" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSix" aria-expanded="false" aria-controls="collapseSix">
                                    Where can i see my wallet?
                                </button>
                            </h2>
                            <div id="collapseSix" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, dolor ut ullamcorper pulvinar, turpis nulla malesuada ante, id bibendum est velit ut turpis. Sed ac nunc ut ex finibus vehicula. Sed imperdiet diam ut risus accumsan, non euismod lectus commodo. Suspendisse commodo, lectus in viverra scelerisque, quam ex fermentum odio, eget posuere ante est vel est. Nulla facilisi. Nam quis tristique velit, ac suscipit risus. Vivamus ac quam non nisl ullamcorper imperdiet ac at augue. Suspendisse dignissim eget magna auctor bibendum. Suspendisse ut arcu eget enim aliquet lacinia vel a sapien. Quisque sed ex eros. Donec sollicitudin velit ut purus scelerisque, sed cursus orci euismod. Sed in aliquet libero, id congue lectus. Donec euismod mauris ac nibh ullamcorper fringilla.
                                </div>
                            </div>
                        </div>
                        <div className="accordion-item border-0  rounded-0 mb_4rem">
                            <h2 className="accordion-header border-0 rounded-0">
                                <button className="accordion-button accordionButton  border-0 collapsed mt-2 rounded-0" type="button" data-bs-toggle="collapse" data-bs-target="#collapseSeven" aria-expanded="false" aria-controls="collapseSeven">
                                    how to add bank?
                                </button>
                            </h2>
                            <div id="collapseSeven" className="accordion-collapse collapse" data-bs-parent="#accordionExample">
                                <div className="accordion-body pb-5">
                                    Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam euismod, dolor ut ullamcorper pulvinar, turpis nulla malesuada ante, id bibendum est velit ut turpis. Sed ac nunc ut ex finibus vehicula. Sed imperdiet diam ut risus accumsan, non euismod lectus commodo. Suspendisse commodo, lectus in viverra scelerisque, quam ex fermentum odio, eget posuere ante est vel est. Nulla facilisi. Nam quis tristique velit, ac suscipit risus. Vivamus ac quam non nisl ullamcorper imperdiet ac at augue. Suspendisse dignissim eget magna auctor bibendum. Suspendisse ut arcu eget enim aliquet lacinia vel a sapien. Quisque sed ex eros. Donec sollicitudin velit ut purus scelerisque, sed cursus orci euismod. Sed in aliquet libero, id congue lectus. Donec euismod mauris ac nibh ullamcorper fringilla.
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <MobileFooter />
        </div>
    );
}

export default FAQMobilePage

