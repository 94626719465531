import axios from "axios";
import React, { useEffect, useState } from "react";
import { allBetsList } from "../../services/api";
import baseUrl from "../../services/baseUrl";
import ReactPaginate from 'react-paginate';


const PastBets = () => {
  const [allBetsListData, setAllBetsListdata] = useState([])
  const [loader, setLoader] = useState(false)
  const handleBetList = async () => {
    setLoader(true)
    const user = JSON.parse(localStorage.getItem("betUser"));
    const headers = {
      headers: {
        "Content-Type": "application/json",
        "x-access-token": user?.token
      }
    }
    try {
      const responce = await axios.get(baseUrl + allBetsList, headers)
      if (responce.status == 200) {
        setLoader(false)
        setAllBetsListdata(responce?.data?.data)
      }
    } catch (error) {
      setLoader(false)
    }
  }


  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 10
  const endOffset = itemOffset + itemsPerPage;
  const pageCount = Math.ceil(allBetsListData.length / itemsPerPage);
  const currentItems = allBetsListData.slice(itemOffset, endOffset);

  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % allBetsListData.length;
    setItemOffset(newOffset);
  };





  useEffect(() => {
    handleBetList()
  }, [])

  return (
    <div>
      {/* <div className="bets-description-data">
        <div className="my-bets-desc">Description</div>
        <div className="bets-desc-tabs">
          <div>Placed</div>
          <div>Type</div>
          <div>Odds</div>
          <div>Stake</div>
          <div>Liability</div>
          <div>Potential Profit</div>
        </div>
      </div>
      <div className="current-no-bets">
        <p>No Current Bets </p>
      </div> */}

      <div className='card p-3 m-3' >
        {
          loader == false &&
          <div style={{ overflowX: 'scroll' }} >
            <table className="table table-striped table-bordered">
              <thead>
                <tr>
                  <th >Sl.No</th>
                  <th >Odds</th>
                  <th >Stake</th>
                  <th >Type</th>
                  <th >Status</th>
                </tr>
              </thead>
              <tbody>
                {
                  currentItems && currentItems?.map((ele, ind) => {
                    return <tr>
                      <td >{ind + 1}</td>
                      <td>{ele.odds}</td>
                      <td>{ele.stake}</td>
                      <td>{ele.type}</td>
                      <td>{ele.status}</td>
                    </tr>

                  })
                }
              </tbody>
            </table>
          </div>
        }
        <div className="m-2 d-flex justify-content-center align-items-center">
          <div className="justify-content-center" >
            <ReactPaginate
              breakLabel="..."
              nextLabel=">"
              onPageChange={handlePageClick}
              pageRangeDisplayed={3}
              pageCount={pageCount}
              previousLabel="<"
              renderOnZeroPageCount={null}
              pageClassName="page-item mb-0"
              subContainerClassName="pages "
              pageLinkClassName="page-link"
              previousClassName="page-item"
              previousLinkClassName="page-link"
              nextClassName="page-item"
              nextLinkClassName="page-link"
              breakClassName="page-item"
              breakLinkClassName="page-link"
              marginPagesDisplayed={2}
              containerClassName="pagination"
              activeClassName="active"
            />
          </div>
        </div>

        {
          loader == true && <div>
            <button className="btn btn-primary" type="button" disabled>
              <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
              Loading...
            </button>
          </div>
        }
      </div>

    </div>
  );
};

export default PastBets;
