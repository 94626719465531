import React, { useState } from 'react';
import '../Whatsapp/Whatsapp.css'
import { useEffect } from 'react';
import axios from 'axios';
import baseUrl from '../../services/baseUrl';

const Whatsapp = () => {
    const [closePopup, setClosePopup] = useState(false)
    const [number, setNumber] = useState("")

    const getSiteSetting = async () => {
        try {
          const Token = localStorage.getItem('token')
          const headers = {
              headers: {
                "x-access-token": Token
              }
          }
          const res = await axios.get(baseUrl + '/site-setting', headers)
          if (res?.status == 200  && res?.data?.data?.whatsAppNumber) {
            setNumber(res?.data?.data?.whatsAppNumber || `+91 8586862674`)
          }
        } catch (error) { }
    }

    useEffect(() => {
        getSiteSetting()
    }, [])

    return (
        <div>
            {
                closePopup
                ?
                <div className='rounded position-fixed text-white d-block d-sm-none p-2 whatsappImage-box border border-success' style={{ backgroundColor: '#212529' }} >
                    <div className='d-flex position-relative' >
                        <i className="bi bi-x  position-absolute" style={{top: "-5px", right: "-5px"}} onClick={() => setClosePopup(false)}></i>
                        <a href={`//api.whatsapp.com/send?phone=${number}&text=Hi, I want Betkaro Id.`} className='text-white text-decoration-none' >
                            <p className='main-text' >Get An ID Instantly On Whatsapp</p>
                            <i className='inner-text text-decoration-underline' >Click Here Now</i>
                        </a>
                    </div>
                </div>
                :
                ''
            }
            <div className='box-2'>
                <a href={`//api.whatsapp.com/send?phone=${number}&text=Hi, I want Betkaro Id.`}>
                    <lottie-player class="position-fixed d-block d-sm-none whatsappImage" src="https://assets3.lottiefiles.com/private_files/lf30_lghsciar.json" background="transparent" speed="1" loop autoplay></lottie-player>
                </a>
            </div>
        </div >
    );
};

export default Whatsapp;