import React from "react";
import Footer from "../components/Footer";
import MobileFooter from "../components/MobileFooter";
import MobileSideBar from "../components/MobileSideBar/MobileSideBar";
import PrimaryNavbar from "../components/PrimaryNavbar";
import ArrowLeft from "../assets/images/profileDashboard/arrowLeft.svg";
import { useNavigate } from "react-router-dom";

const ExclusivePolicy = () => {
  const navigate = useNavigate();

  return (
    <div>
      <PrimaryNavbar></PrimaryNavbar>
      <div className="position-relative">
        <MobileSideBar />
      </div>

      <div className="terms-and-conditions">
        <div className="main-content-tac">
          <div className="row position-relative" onClick={() => navigate(-1)}>
          <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
              <img src={ArrowLeft} className="cursor-pointer arrow-icone-size rounded-4" />
            </div>
          </div>
          <h2 className="terms-and-cond-heading mt-2">SELF EXCLUSION</h2>

          <div className="contentn-bets-terms-and-condition">
            <h2>What Is A Self-Exclusion?</h2>

            <p>
              Self-Exclusion Is A Facility That The Site Offers To Help Those
              Customers Who Feel That Their Gambling Is Out Of Control And Want
              Us To Help Them Stop. By Entering Into A Self-Exclusion Agreement
              With The Site, You Will Be Prevented From Using Your Account (As
              Defined In The Terms And Conditions) For A Specific Period, As
              Determined By You, Of Between 6 Months And 5 Years.
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>How To Self-Exclude From The Site?</h2>

            <p>
              Please Inform Us Of The Period For Which You Wish To Self-Exclude.
              The Minimum Is 6 Months And The Maximum Is 5 Years. If You Request
              Self-Exclusion But Do Not Specify A Period, We Will Exclude You
              For The Minimum Period Of Six Months ("Minimum Period").
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>What Happens If I Self-Exclude?</h2>
            <p>
              We Will Make All Reasonable Efforts To: Prevent Any Marketing
              Material Being Forwarded To You. Remove You From Any Marketing
              Databases Operated By Us. Suspend Your Activity By Cancelling Your
              Ability To Access The Site For The Period Requested By You, Or If
              No Period Is Requested By You, For The Minimum Period. Permanently
              Close Your Customer Account If Instructed To Do So By You, And
              Return All Funds Owed To You To Your Nominated Bank Account.
            </p>
            <br />
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>
              Can I Re-Activate My Account Or Open A New Account During The
              Self-Exclusion Period?
            </h2>
            <p>
              {" "}
              Accounts That Have Been Self-Excluded Cannot Be Reactivated Under
              Any Circumstances Until The Expiry Of The Self-Exclusion Period.
              During The Period Of Your Exclusion, You Must Not Attempt To
              Re-Open Any Existing Account(S), Seek To Open Any New Accounts Or
              Seek To Place Bets Through Any Other Customer’s Account.
            </p>
            <br />
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>
              If I Would Like To Re-Activate My Account, Is This Possible?
            </h2>
            <p>
              {" "}
              Accounts That Have Been Self-Excluded Cannot Be Reactivated Under
              Any Circumstances Until The Expiry Of The Self-Exclusion Period.
              At The End Of The Self-Exclusion Period, You Must Contact Us In
              Person And Confirm Such Intention In Writing. If It Is Decided (In
              The Site’s Absolute Discretion) To Permit You To Re-Open Your
              Account/Open A New Account, You Should Be Aware That A 24-Hour
              Waiting Period Will Be Imposed Prior To The Account Being
              Available For Use.
            </p>
            <br />
          </div>
        </div>
      </div>
      <Footer />
      <MobileFooter />
    </div>
  );
};

export default ExclusivePolicy;
