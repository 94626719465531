import React from "react";
import Footer from "../components/Footer";
import MobileFooter from "../components/MobileFooter";
import MobileSideBar from "../components/MobileSideBar/MobileSideBar";
import PrimaryNavbar from "../components/PrimaryNavbar";
import { useNavigate } from "react-router-dom";
import ArrowLeft from "../assets/images/profileDashboard/arrowLeft.svg";

const TermsAndCondition = () => {
  const navigate = useNavigate();

  return (
    <div>
      <PrimaryNavbar></PrimaryNavbar>
      <div className="position-relative">
        <MobileSideBar />
      </div>
      <div className="terms-and-conditions">
        <div className="main-content-tac">
          <div className="row position-relative" onClick={() => navigate(-1)}>
            <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
              <img src={ArrowLeft} className="cursor-pointer arrow-icone-size rounded-4" />
            </div>
          </div>

          <h2 className="terms-and-cond-heading mt-2">Terms And Conditions</h2>
          <p>Last Updated: 09/06/2022</p>
          <div className="contentn-bets-terms-and-condition">
            <h2>1. Introduction</h2>
            <p>
              These Terms And Conditions And The Documents Referred To Below
              (The "Terms") Apply To The Use Of The Current Website (The
              "Website") And Its Related Or Connected Services (Collectively,
              The "Service"). You Should Carefully Review These Terms As They
              Contain Important Information Concerning Your Rights And
              Obligations Concerning The Use Of The Website And Form A Binding
              Legal Agreement Between You - Our Customer (The "Customer"), And
              Us. By Using This Website And/Or Accessing The Service, You,
              Whether You Are A Guest Or A Registered User With An Account
              (“Account”), Agree To Be Bound By These Terms, Together With Any
              Amendments, Which May Be Published From Time To Time. If You Do
              Not Accept These Terms, You Should Refrain From Accessing The
              Service And Using The Website.
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>2. General Terms</h2>
            <p>
              We Reserve The Right To Revise And Amend The Terms (Including Any
              Documents Referred To And Linked To Below) At Any Time. You Should
              Visit This Page Periodically To Review The Terms And Conditions.
              Amendments Will Be Binding And Effective Immediately Upon
              Publication On This Website. If You Object To Any Such Changes,
              You Must Immediately Stop Using The Service. Your Continued Use Of
              The Website Following Such Publication Will Indicate Your
              Agreement To Be Bound By The Terms As Amended. Any Bets Not
              Settled Prior To The Changed Terms Taking Effect Will Be Subject
              To The Pre-Existing Terms
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>3. Your Obligations</h2>
            <p>
              You Acknowledge That At All Times When Accessing The Website And
              Using The Service:
              <br />
              3.1. You Are Is Over 18, Or The Legal Age At Which Gambling, Or
              Gaming Activities Are Allowed Under The Law Or Jurisdiction That
              Applies To You. We Reserve The Right To Request Proof Of Age
              Documents From You At Any Time.
              <br />
              3.2. You Are Of Legal Capacity And Can Enter Into A Binding Legal
              Agreement With Us. You Must Not Access The Website Or Utilize The
              Service If You Are Not Of Legal Capacity.
              <br />
              3.3. You Are A Resident In A Jurisdiction That Allows Gambling.
              You Are Not A Resident Of Any Country In Which Access To Online
              Gambling To Its Residents Or To Any Person Within Such Country Is
              Prohibited. It Is Your Sole Responsibility To Ensure That Your Use
              Of The Service Is Legal.
              <br />
              3.4. You May Not Use A VPN, Proxy Or Similar Services Or Devices
              That Mask Or Manipulate The Identification Of Your Real Location.
              <br />
              3.5. You Are The Authorized User Of The Payment Method You Use.
              <br />
              3.6. You Must Make All Payments To Us In Good Faith And Not
              Attempt To Reverse A Payment Made Or Take Any Action Which Will
              Cause Such Payment To Be Reversed By A Third Party.
              <br />
              3.7. When Placing Bets You May Lose Some Or All Of Your Money
              Deposited To The Service In Accordance With These Terms And You
              Will Be Fully Responsible For That Loss.
              <br />
              3.8. When Placing Bets You Must Not Use Any Information Obtained
              In Breach Of Any Legislation In Force In The Country In Which You
              Were When The Bet Was Placed.
              <br />
              3.9. You Are Not Acting On Behalf Of Another Party Or For Any
              Commercial Purposes, But Solely On Your Own Behalf As A Private
              Individual In A Personal Capacity.
              <br />
              3.10. You Must Not Either Attempt To Manipulate Any Market Or
              Element Within The Service In Bad Faith Nor In A Manner That
              Adversely Affects The Integrity Of The Service Or Us.
              <br />
              3.11. You Must Generally Act In Good Faith In Relation To Us Of
              The Service At All Times And For All Bets Made Using The Service.
              <br />
              3.12. You, Or, If Applicable, Your Employees, Employers, Agents,
              Or Family Members, Are Not Registered As An Affiliate In Our
              Affiliate Program.
            </p>
          </div>

          <div className="contentn-bets-terms-and-condition">
            <h2>4. Restricted Use</h2>
            <p>
              4.1. You Must Not Use The Service: 4.1.1. If You Are Under The Age
              Of 18 Years (Or Below The Age Of Majority As Stipulated In The
              Laws Of The Jurisdiction Applicable To You) Or If You Are Not
              Legally Able To Enter Into A Binding Legal Agreement With Us Or
              You Acting As An Agent For, Or Otherwise On Behalf, Of A Person
              Under 18 Years (Or Below The Age Of Majority As Stipulated In The
              Laws Of The Jurisdiction Applicable To You);
              <br />
              4.1.2. If You Reside In A Country In Which Access To Online
              Gambling To Its Residents Or To Any Person Within Such Country Is
              Prohibited.
              <br />
              4.1.3. If You Are A Resident Of One Of The Following Countries, Or
              Accessing The Website From One Of The Following Countries: United
              States Of America And Its Territories, France And Its Territories,
              Netherlands And Its Territories And Countries That Form The
              Kingdom Of Netherlands, Including Bonaire, Sint Eustatius, Saba,
              Aruba, Curaçao And Sint Maarten, Australia And Its Territories,
              United Kingdom Of Great Britain And Northern Ireland, Spain
              Cyprus,
              <br />
              4.1.4. To Collect Nicknames, E-Mail Addresses And/Or Other
              Information Of Other Customers By Any Means (For Example, By
              Sending Spam, Other Types Of Unsolicited Emails Or The
              Unauthorised Framing Of, Or Linking To, The Service);
              <br />
              4.1.5. To Disrupt Or Unduly Affect Or Influence The Activities Of
              Other Customers Or The Operation Of The Service Generally;
              <br />
              4.1.6. To Promote Unsolicited Commercial Advertisements, Affiliate
              Links, And Other Forms Of Solicitation Which May Be Removed From
              The Service Without Notice;
              <br />
              4.1.7. In Any Way Which, In Our Reasonable Opinion, Could Be
              Considered As An Attempt To: (I) Cheat The Service Or Another
              Customer Using The Service; Or (Ii) Collude With Any Other
              Customer Using The Service In Order To Obtain A Dishonest
              Advantage;
              <br />
              4.1.8. To Scrape Our Odds Or Violate Any Of Our Intellectual
              Property Rights; Or
              <br />
              4.1.9. For Any Unlawful Activity Whatsoever.
              <br />
              4.2. You Cannot Sell Or Transfer Your Account To Third Parties,
              Nor Can You Acquire A Player Account From A Third Party.
              <br />
              4.3. You May Not, In Any Manner, Transfer Funds Between Player
              Accounts.
              <br />
              4.4. We May Immediately Terminate Your Account Upon Written Notice
              To You If You Use The Service For Unauthorised Purposes. We May
              Also Take Legal Action Against You For Doing So In Certain
              Circumstances.
              <br />
              4.5. Employees Of Company, Its Licensees, Distributors,
              Wholesalers, Subsidiaries, Advertising, Promotional Or Other
              Agencies, Media Partners, Contractors, Retailers And Members Of
              The Immediate Families Of Each Are NOT Allowed To Use The Service
              For Real Money Without Prior Consent From The Company Director Or
              CEO. Should Such Activity Be Discovered, The Account(S) Will Be
              Immediately Terminated And All Bonuses/Winnings Will Be Forfeited.
              <br />
              4.6. Only One Bonus Is Allowed Per Customer, Family, Address,
              Shared Computer, Shared IP Address, Any Identical Account Details
              Including E-Mail Address, Bank Account Details, Credit Card
              Infomation And Payment System Account. Any Misuse Of This Bonus
              Offer Will Lead To An Account Being Close.
            </p>
            <br />
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>5.Registration</h2>
            <p>
              You Agree That At All Times When Using The Service: 5.1. We
              Reserve The Right To Refuse To Accept A Registration Application
              From Any Applicant At Our Sole Discretion And Without Any
              Obligation To Communicate A Specific Reason.
              <br />
              5.2. Before Using The Service, You Must Personally Complete The
              Registration Form And Read And Accept These Terms. In Order To
              Start Betting On The Service Or Withdraw Your Winnings, We May
              Require You To Become A Verified Customer Which Includes Passing
              Certain Checks. You May Be Required To Provide A Valid Proof Of
              Identification And Any Other Document As It May Be Deemed
              Necessary. This Includes But Is Not Limited To, A Picture ID (Copy
              Of Passport, Driver's Licence Or National ID Card) And A Recent
              Utility Bill Listing Your Name And Address As Proof Of Residence.
              We Reserve The Right To Suspend Wagering Or Restrict Account
              Options On Any Account Until The Required Information Is Received.
              This Procedure Is Done In Accordance With The Applicable Gaming
              Regulation And The Anti-Money Laundering Legal Requirements.
              Additionally, You Will Need To Fund Your Service Account Using The
              Payment Methods Set Out On The Payment Section Of Our Website.
              <br />
              5.3. You Have To Provide Accurate Contact Information, Inclusive
              Of A Valid Email Address (“Registered Email Address”), And Update
              Such Information In The Future To Keep It Accurate. It Is Your
              Responsibility To Keep Your Contact Details Up To Date On Your
              Account. Failure To Do So May Result In You Failing To Receive
              Important Account Related Notifications And Information From Us,
              Including Changes We Make To These Terms. We Identify And
              Communicate With Our Customers Via Their Registered Email Address.
              It Is The Responsibility Of The Customer To Maintain An Active And
              Unique Email Account, To Provide Us With The Correct Email Address
              And To Advise Company Of Any Changes In Their Email Address. Each
              Customer Is Wholly Responsible For Maintaining The Security Of His
              Registered Email Address To Prevent The Use Of His Registered
              Email Address By Any Third Party. Company Shall Not Be Responsible
              For Any Damages Or Losses Deemed Or Alleged To Have Resulted From
              Communications Between Company And The Customer Using The
              Registered Email Address. Any Customer Not Having An Email Address
              Reachable By Company Will Have His Account Suspended Until Such An
              Address Is Provided To Us. We Will Immediately Suspend Your
              Account Upon Written Notice To You To This Effect If You
              Intentionally Provide False Or Inaccurate Personal Information. We
              May Also Take Legal Action Against You For Doing So In Certain
              Circumstances And/Or Contact The Relevant Authorities Who May Also
              Take Action Against You.
              <br />
              5.4. You Are Only Allowed To Register One Account With The
              Service. Accounts Are Subject To Immediate Closure If It Is Found
              That You Have Multiple Accounts Registered With Us. This Includes
              The Use Of Representatives, Relatives, Associates, Affiliates,
              Related Parties, Connected Persons And/Or Third Parties Operating
              On Your Behalf.
              <br />
              5.5. In Order To Ensure Your Financial Worthiness And To Confirm
              Your Identity, We May Ask You To Provide Us With Additional
              Personal Information, Such As Your Name And Surname, Or Use Any
              Third-Party Information Providers We Consider Necessary. Should
              Any Additional Personal Information Be Obtained Via Third-Party
              Sources, We Will Inform You About The Data Obtained.
              <br />
              5.6. You Must Keep Your Password For The Service Confidential.
              Provided That The Account Information Requested Has Been Correctly
              Supplied, We Are Entitled To Assume That Bets, Deposits And
              Withdrawals Have Been Made By You. We Advise You To Change Your
              Password On A Regular Basis And Never Disclose It To Any Third
              Party. It Is Your Responsibility To Protect Your Password And Any
              Failure To Do So Shall Be At Your Sole Risk And Expense. You May
              Log Out Of The Service At The End Of Each Session. If You Believe
              Any Of Your Account Information Is Being Misused By A Third Party,
              Or Your Account Has Been Hacked Into, Or Your Password Has Been
              Discovered By A Third Party, You Must Notify Us Immediately. You
              Must Notify Us If Your Registered Email Address Has Been Hacked
              Into, We May, However, Require You To Provide Additional
              Information/ Documentation So That We Can Verify Your Identity. We
              Will Immediately Suspend Your Account Once We Are Aware Of Such An
              Incident. In The Meantime You Are Responsible For All Activity On
              Your Account Including Third Party Access, Regardless Of Whether
              Or Not Their Access Was Authorised By You.
              <br />
              5.7. You Must Not At Any Time Transmit Any Content Or Other
              Information On The Service To Another Customer Or Any Other Party
              By Way Of A Screen Capture (Or Other Similar Method), Nor Display
              Any Such Information Or Content In A Frame Or In Any Other Manner
              That Is Different From How It Would Appear If Such Customer Or
              Third Party Had Typed The URL For The Service Into The Browser
              Line.
              <br />
              5.8. When Registering, You Will Receive Possibility To Use All
              Currencies Available On The Website. Those Will Be The Currencies
              Of Your Deposits, Withdrawals And Bets Placed And Matched Into The
              Service As Set Out In These Terms. Some Payment Methods Do Not
              Process In All Currencies. In Such Cases A Processing Currency
              Will Be Displayed, Along With A Conversion Calculator Available On
              The Page.
              <br />
              5.9. We Are Under No Obligation To Open An Account For You And Our
              Website Sign-Up Page Is Merely An Invitation To Treat. It Is
              Entirely Within Our Sole Discretion Whether Or Not To Proceed With
              The Opening Of An Account For You And, Should We Refuse To Open An
              Account For You, We Are Under No Obligation To Provide You With A
              Reason For The Refusal.
              <br />
              5.10. Upon Receipt Of Your Application, We May Be In Touch To
              Request Further Information And/ Or Documentation From You In
              Order For Us To Comply With Our Regulatory And Legal Obligations.
            </p>
            <br />
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>6. Your Account</h2>
            <p>
              6.1. Accounts Could Use Several Currencies, In This Case All
              Account Balances And Transactions Appear In The Currency Used For
              The Transaction.
              <br />
              6.2. We Do Not Give Credit For The Use Of The Service.
              <br />
              6.3. We May Close Or Suspend An Account If You Are Not Or We
              Reasonably Believe That You Are Not Complying With These Terms, Or
              To Ensure The Integrity Or Fairness Of The Service Or If We Have
              Other Reasonable Grounds To Do So. We May Not Always Be Able To
              Give You Prior Notice. If We Close Or Suspend Your Account Due To
              You Not Complying With These Terms, We May Cancel And/Or Void Any
              Of Your Bets And Withhold Any Money In Your Account (Including The
              Deposit).
              <br />
              6.4. We Reserve The Right To Close Or Suspend Any Account Without
              Prior Notice And Return All Funds. Contractual Obligations Already
              Matured Will However Be Honoured.
              <br />
              6.5. We Reserve The Right To Refuse, Restrict, Cancel Or Limit Any
              Wager At Any Time For Whatever Reason, Including Any Bet Perceived
              To Be Placed In A Fraudulent Manner In Order To Circumvent Our
              Betting Limits And/ Or Our System Regulations.
              <br />
              6.6. If Any Amount Is Mistakenly Credited To Your Account It
              Remains Our Property And When We Become Aware Of Any Such Mistake,
              We Shall Notify You And The Amount Will Be Withdrawn From Your
              Account.
              <br />
              6.7. If, For Any Reason, Your Account Goes Overdrawn, You Shall Be
              In Debt To Us For The Amount Overdrawn.
              <br />
              6.8. You Must Inform Us As Soon As You Become Aware Of Any Errors
              With Respect To Your Account.
              <br />
              6.9. Please Remember That Betting Is Purely For Entertainment And
              Pleasure And You Should Stop As Soon As It Stops Being Fun.
              Absolutely Do Not Bet Anything You Can’t Afford To Lose. If You
              Feel That You May Have Lost Control Of Your Gambling, We Offer A
              Self-Exclusion Option. Just Send A Message To Our Customer Support
              Department Using Your Registered Email Address That You Wish To
              SELF-EXCLUDE And This Request Will Take Effect Within 24 Hours
              From The Moment Of Its Receipt. In This Case Your Account Will Be
              Disabled Until Your Further Notice, And You Won’t Be Able To Login
              To It.
              <br />
              6.10. You Cannot Transfer, Sell, Or Pledge Your Account To Another
              Person. This Prohibition Includes The Transfer Of Any Assets Of
              Value Of Any Kind, Including But Not Limited To Ownership Of
              Accounts, Winnings, Deposits, Bets, Rights And/Or Claims In
              Connection With These Assets, Legal, Commercial Or Otherwise. The
              Prohibition On Said Transfers Also Includes However Is Not Limited
              To The Encumbrance, Pledging, Assigning, Usufruct, Trading,
              Brokering, Hypothecation And/Or Gifting In Cooperation With A
              Fiduciary Or Any Other Third Party, Company, Natural Or Legal
              Individual, Foundation And/Or Association In Any Way Shape Or Form
              <br />
              6.11. Should You Wish To Close Your Account With Us, Please Send
              An Email From Your Registered Email Address To Our Customer
              Support Department Via The Links On The Website.
            </p>
            <br />
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>7. Inactive Accounts</h2>
            <br />
            <p>
              7.1. We Will Charge You A Fee Of INR 500 (Or Currency Equivalent)
              Per Calendar Month (Inactive Account Fee) If: You Have Not Logged
              Into Your Account Or Logged Out From Your Account, Or Used The
              Service, Or Made Any Bet In Any Section Of The Service For Twelve
              Consecutive Months Or More (Account Activity) And Your Account Is
              In Credit.
              <br />
              7.2. You Will Be Notified That We Will Start Charging You The Fees
              On The Eleventh Month Of Inactivity And You Will Always Have The
              Option To Log In And Withdraw Your Funds.
              <br />
              7.3. The Inactive Account Fee Will Be Withdrawn From Your Account
              On A Monthly Basis To The Extent That Your Account Remains In
              Credit And Only For The Period During Which There Remains No
              Account Activity After The Initial Twelve Month Period Has Passed.
              If The Inactive Account Fee Is Due To Be Withdrawn From Your
              Account And The Balance Of Your Account Is Less Than INR 500 (Or
              Equivalent Currency), The Inactive Account Fee Shall Comprise The
              Remaining Funds In Your Account.
            </p>
            <br />
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>8. Deposit Of Funds</h2>
            <br />
            <p>
              8.1. All Deposits Should Be Made From An Account Or Payment System
              Or Credit Card That Is Registered In Your Own Name, And Any
              Deposits Made In Any Other Currency Will Be Converted Using The
              Daily Exchange Rate Obtained From Oanda.Com, Or At Our Own Bank's
              Or Our Payment Processor’s Prevailing Rate Of Exchange Following
              Which Your Account Will Be Deposited Accordingly. Note That Some
              Payment Systems May Apply Additional Currency Exchange Fees Which
              Will Be Deducted From The Sum Of Your Deposit.
              <br />
              8.2. Fees And Charges May Apply To Customer Deposits And
              Withdrawals, Which Can Be Found On The Website. In Most Cases We
              Absorb Transaction Fees For Deposits To Your www.Betkaro India.com
              Account. You Are Responsible For Your Own Bank Charges That You
              May Incur Due To Depositing Funds With Us.
              <br />
              8.3. Company Is Not A Financial Institution And Uses A Third Party
              Electronic Payment Processors To Process Credit And Debit Card
              Deposits; They Are Not Processed Directly By Us. If You Deposit
              Funds By Either A Credit Card Or A Debit Card, Your Account Will
              Only Be Credited If We Receive An Approval And Authorisation Code
              From The Payment Issuing Institution. If Your Card Issuer Gives No
              Such Authorisation, Your Account Will Not Be Credited With Those
              Funds.
              <br />
              8.4. You Agree To Fully Pay Any And All Payments And Charges Due
              To Us Or To Payment Providers In Connection With Your Use Of The
              Service. You Further Agree Not To Make Any Charge-Backs Or
              Renounce Or Cancel Or Otherwise Reverse Any Of Your Deposits, And
              In Any Such Event You Will Refund And Compensate Us For Such
              Unpaid Deposits Including Any Expenses Incurred By Us In The
              Process Of Collecting Your Deposit, And You Agree That Any
              Winnings From Wagers Utilising Those Charged Back Funds Will Be
              Forfeited. You Acknowledge And Agree That Your Player Account Is
              Not A Bank Account And Is Therefore Not Guaranteed, Insured Or
              Otherwise Protected By Any Deposit Or Banking Insurance System Or
              By Any Other Similar Insurance System Of Any Other Jurisdiction,
              Including But Not Limited To Your Local Jurisdiction. Furthermore,
              The Player Account Does Not Bear Interest On Any Of The Funds Held
              In It.
              <br />
              8.5. If You Decide To Accept Any Of Our Promotional Or Bonus Offer
              By Entering A Bonus Code During Deposit, You Agree To The Terms Of
              Bonuses And Terms Of Each Specific Bonus.
              <br />
              8.6. Funds Originating From Criminal And/Or Illegal And/Or
              Unauthorized Activities Must Not Be Deposited With Us.
              <br />
              8.7. If You Deposit Using Your Credit Card, It Is Recommended That
              You Retain A Copy Of Transaction Records And A Copy Of These
              Terms.
              <br />
              8.8. Internet Gambling May Be Illegal In The Jurisdiction In Which
              You Are Located; If So, You Are Not Authorized To Use Your Payment
              Card To Deposit On This Site. It Is Your Responsibility To Know
              The Laws Concerning Online Gambling In Your Country Of Domicile.
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>9. Withdrawal Of Funds</h2>
            <br />
            <p>
              9.1. You May Withdraw Any Unutilized And Cleared Funds Held In
              Your Player Account By Submitting A Withdrawal Request In
              Accordance With Our Withdrawal Conditions. The Minimum Withdrawal
              Amount Per Transaction Is INR 1000 (Or Equivalent In Other
              Currency) With The Exception Of An Account Closure In Which Case
              You May Withdraw The Full Balance.
              <br />
              9.2. There Are No Withdrawal Commissions If You Roll Over (Wager)
              The Deposit At Least 1 Time. Otherwise We Are Entitled To Deduct A
              10% Fee With Minimum Sum Of 400 INR (Or Equivalent In Your Account
              Currency) In Order To Combat Money Laundering.
              <br />
              9.3. We Reserve The Right To Request Photo ID, Address
              Confirmation Or Perform Additional Verification Procedures
              (Request Your Selfie, Arrange A Verification Call Etc.) For The
              Purpose Of Identity Verification Prior To Granting Any Withdrawals
              From Your Account. We Also Reserve Our Rights To Perform Identity
              Verification At Any Time During The Lifetime Of Your Relationship
              With Us.
              <br />
              9.4. All Withdrawals Must Be Made To The Original Debit, Credit
              Card, Bank Account, Method Of Payment Used To Make The Payment To
              Your Account. We May, And Always At Our Own Discretion, Allow You
              To Withdraw To A Payment Method From Which Your Original Deposit
              Did Not Originate. This Will Always Be Subject To Additional
              Security Checks.
              <br />
              9.5. Should You Wish To Withdraw Funds But Your Account Is Either
              Inaccessible, Dormant, Locked Or Closed, Please Contact Our
              Customer Service Department.
              <br />
              9.6. In Cases When Your Balance Is At Least 10 Times Larger Than
              The Total Sum Of Your Deposits, You Will Be Limited To INR 500,000
              (Or Currency Equivalent) For Withdrawal Per Month. In Other Cases
              The Maximum Withdrawal Amount Per Month Is INR 10,00,000.
              <br />
              9.7. Please Note That We Cannot Guarantee Successful Processing Of
              Withdrawals Or Refunds In The Event If You Breach The Restricted{" "}
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>10. Payment Transactions And Processors</h2>
            <br />
            <p>
              10.1. You Are Fully Responsible For Paying All Monies Owed To Us.
              You Must Make All Payments To Us In Good Faith And Not Attempt To
              Reverse A Payment Made Or Take Any Action Which Will Cause Such
              Payment To Be Reversed By A Third Party In Order To Avoid A
              Liability Legitimately Incurred. You Will Reimburse Us For Any
              Charge-Backs, Denial Or Reversal Of Payment You Make And Any Loss
              Suffered By Us As A Consequence Thereof. We Reserve The Right To
              Also Impose An Administration Fee Of INR 5000, Or Currency
              Equivalent Per Charge-Back, Denial Or Reversal Of Payment You
              Make.
              <br />
              10.2. We Reserve The Right To Use Third Party Electronic Payment
              Processors And Or Merchant Banks To Process Payments Made By You
              And You Agree To Be Bound By Their Terms And Conditions Providing
              They Are Made Aware To You And Those Terms Do Not Conflict With
              These T Erms.
              <br />
              10.3. All Transactions Made On Our Site Might Be Checked To
              Prevent Money Laundering Or Terrorism Financing Activity.
              Suspicious Transactions Will Be Reported To The Relevant
              Authority.
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>11. Errors</h2>
            <br />
            <p>
              11.1. In The Event Of An Error Or Malfunction Of Our System Or
              Processes, All Bets Are Rendered Void. You Are Under An Obligation
              To Inform Us Immediately As Soon As You Become Aware Of Any Error
              With The Service. In The Event Of Communication Or System Errors
              Or Bugs Or Viruses Occurring In Connection With The Service And/Or
              Payments Made To You As A Result Of A Defect Or Error In The
              Service, We Will Not Be Liable To You Or To Any Third Party For
              Any Direct Or Indirect Costs, Expenses, Losses Or Claims Arising
              Or Resulting From Such Errors, And We Reserve The Right To Void
              All Games/Bets In Question And Take Any Other Action To Correct
              Such Errors.
              <br />
              11.2. We Make Every Effort To Ensure That We Do Not Make Errors In
              Posting Bookmaker Lines. However, If As A Result Of Human Error Or
              System Problems A Bet Is Accepted At An Odd That Is: Materially
              Different From Those Available In The General Market At The Time
              The Bet Was Made; Or Clearly Incorrect Given The Chance Of The
              Event Occurring At The Time The Bet Was Made Then We Reserve The
              Right To Cancel Or Void That Wager, Or To Cancel Or Void A Wager
              Made After An Event Has Started.
              <br />
              11.3. We Have The Right To Recover From You Any Amount Overpaid
              And To Adjust Your Account To Rectify Any Mistake. An Example Of
              Such A Mistake Might Be Where A Price Is Incorrect Or Where We
              Enter A Result Of An Event Incorrectly. If There Are Insufficient
              Funds In Your Account, We May Demand That You Pay Us The Relevant
              Outstanding Amount Relating To Any Erroneous Bets Or Wagers.
              Accordingly, We Reserve The Right To Cancel, Reduce Or Delete Any
              Pending Plays, Whether Placed With Funds Resulting From The Error
              Or Not.
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>
              12. Rules Of Play, Refunds And Cancellations12. Rules Of Play,
              Refunds And Cancellations
            </h2>
            <br />
            <p>
              12.1. The Winner Of An Event Will Be Determined On The Date Of The
              Event's Settlement, And We Will Not Recognize Protested Or
              Overturned Decisions For Wagering Purposes.
              <br />
              12.2. All Results Posted Shall Be Final After 72 Hours And No
              Queries Will Be Entertained After That Period Of Time. Within 72
              Hours After Results Are Posted, We Will Only Reset/Correct The
              Results Due To Human Error, System Error Or Mistakes Made By The
              Referring Results Source.
              <br />
              12.3. If A Match Result Is Overturned For Any Reason By The
              Governing Body Of The Match Within The Payout Period Then All
              Money Will Be Refunded.
              <br />
              12.4. If A Draw Occurs In A Game Where A Draw Option Is Offered
              All Stakes On A Team Win Or Lose Will Be Lost. If A Draw Option Is
              Not Offered Then Everyone Receives A Refund In The Outcome Of A
              Draw On The Match. And If A Draw Option Has Not Been Made
              Available, Then Extra Time Will Count, If Played.
              <br />
              12.5. If A Result Cannot Be Validated By Us, For Instance If The
              Feed Broadcasting The Event Is Interrupted (And Cannot Be Verified
              By Another Source) Then At Our Election, The Wagers On That Event
              Will Be Deemed Invalid And Wagers Refunded.
              <br />
              12.6. Minimum And Maximum Wager Amounts On All Events Will Be
              Determined By Us And Are Subject To Change Without Prior Written
              Notice. We Also Reserve The Right To Adjust Limits On Individual
              Accounts As Well.
              <br />
              12.7. Customers Are Solely Responsible For Their Own Account
              Transactions. Once A Transaction Is Complete, It Cannot Be
              Changed. We Do Not Take Responsibility For Missing Or Duplicate
              Wagers Made By The Customer And Will Not Entertain Discrepancy
              Requests Because A Play Is Missing Or Duplicated. Customers May
              Review Their Transactions In The My Account Section Of The Site
              After Each Session To Ensure All Requested Wagers Were Accepted.
              <br />
              12.8. A Matchup Will Have Action As Long As The Two Teams Are
              Correct, And Regardless Of The League Header In Which It Is Placed
              On Our Website.
              <br />
              12.9. The Start Dates And Times Displayed On The Website For
              ESport Matches Are An Indication Only And Are Not Guaranteed To Be
              Correct. If A Match Is Suspended Or Postponed, And Not Resumed
              Within 72 Hours From The Actual Scheduled Start Time, The Match
              Will Have No Action And Wagers Will Be Refunded. The Exception
              Being Any Wager On Whether A Team/Player Advances In A Tournament,
              Or Wins The Tournament, Will Have Action Regardless Of A Suspended
              Or Postponed Match.
              <br />
              12.10. If An Event Is Posted By Us With An Incorrect Date, All
              Wagers Have Action Based On The Date Announced By The Governing
              Body.
              <br />
              12.11. If A Team Is Using Stand-Ins, The Result Is Still Valid As
              It Was The Team's Choice To Use The Stand-Ins.
              <br />
              12.12. Company Reserves The Right To Remove Events, Markets And
              Any Other Products From The Website.
              <br />
              12.13. In-Depth Explanation Of Our Sports Betting Rules Is On The
              Separate Page: SPORTS BETTING RULES
              <br />
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>13. Communications And Notices</h2>
            <br />
            <p>
              13.1. All Communications And Notices To Be Given Under These Terms
              By You To Us Shall Be Sent Using A Customer Support Form On The
              Website.
              <br />
              13.2. All Communications And Notices To Be Given Under These Terms
              By Us To You Shall, Unless Otherwise Specified In These Terms, Be
              Either Posted On The Website And/Or Sent To The Registered Email
              Address We Hold On Our System For The Relevant Customer. The
              Method Of Such Communication Shall Be In Our Sole And Exclusive
              Discretion.
              <br />
              13.3. All Communications And Notices To Be Given Under These Terms
              By Either You Or Us Shall Be In Writing In The English Language
              And Must Be Given To And From The Registered Email Address In Your
              Account.
              <br />
              13.4. From Time To Time, We May Contact You By Email For The
              Purpose Of Offering You Information About Betting, Unique
              Promotional Offerings, And Other Information From www.Betkaro
              India.com Website. You Agree To Receive Such Emails When You Agree
              To These Terms When Registering At The Website. You Can Choose To
              Opt Out Of Receiving Such Promotional Offerings From Us At Any
              Time By Submitting A Request To The Customer Support.
              <br />
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>14. Matters Beyond Our Control</h2>
            <br />
            <p>
              We Cannot Be Held Liable For Any Failure Or Delay In Providing The
              Service Due To An Event Of Force Majeure Which Could Reasonably Be
              Considered To Be Outside Our Control Despite Our Execution Of
              Reasonable Preventative Measures Such As: An Act Of God; Trade Or
              Labour Dispute; Power Cut; Act, Failure Or Omission Of Any
              Government Or Authority; Obstruction Or Failure Of
              Telecommunication Services; Or Any Other Delay Or Failure Caused
              By A Third Party, And We Will Not Be Liable For Any Resulting Loss
              Or Damage That You May Suffer. In Such An Event, We Reserve The
              Right To Cancel Or Suspend The Service Without Incurring Any
              Liability.
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>15. Liability</h2>
            <br />
            <p>
              15.1. TO THE EXTENT PERMITTED BY APPLICABLE LAW, WE WILL NOT
              COMPENSATE YOU FOR ANY REASONABLY FORESEEABLE LOSS OR DAMAGE
              (EITHER DIRECT OR INDIRECT) YOU MAY SUFFER IF WE FAIL TO CARRY OUT
              OUR OBLIGATIONS UNDER THESE TERMS UNLESS WE BREACH ANY DUTIES
              IMPOSED ON US BY LAW (INCLUDING IF WE CAUSE DEATH OR PERSONAL
              INJURY BY OUR NEGLIGENCE) IN WHICH CASE WE SHALL NOT BE LIABLE TO
              YOU IF THAT FAILURE IS ATTRIBUTED TO: (I) YOUR OWN FAULT; (II) A
              THIRD PARTY UNCONNECTED WITH OUR PERFORMANCE OF THESE TERMS (FOR
              INSTANCE PROBLEMS DUE TO COMMUNICATIONS NETWORK PERFORMANCE,
              CONGESTION, AND CONNECTIVITY OR THE PERFORMANCE OF YOUR COMPUTER
              EQUIPMENT); OR (III) ANY OTHER EVENTS WHICH NEITHER WE NOR OUR
              SUPPLIERS COULD HAVE FORESEEN OR FORESTALLED EVEN IF WE OR THEY
              HAD TAKEN REASONABLE CARE. AS THIS SERVICE IS FOR CONSUMER USE
              ONLY WE WILL NOT BE LIABLE FOR ANY BUSINESS LOSSES OF ANY KIND.
              <br />
              15.2. IN THE EVENT THAT WE ARE HELD LIABLE FOR ANY EVENT UNDER
              THESE TERMS, OUR TOTAL AGGREGATE LIABILITY TO YOU UNDER OR IN
              CONNECTION WITH THESE TERMS SHALL NOT EXCEED (A) THE VALUE OF THE
              BETS AND OR WAGERS YOU PLACED VIA YOUR ACCOUNT IN RESPECT OF THE
              RELEVANT BET/WAGER OR PRODUCT THAT GAVE RISE TO THE RELEVANT
              LIABILITY, OR (B) EUR €500 IN AGGREGATE, WHICHEVER IS LOWER.
              <br />
              15.3. WE STRONGLY RECOMMEND THAT YOU (I) TAKE CARE TO VERIFY THE
              SUITABILITY AND COMPATIBILITY OF THE SERVICE WITH YOUR OWN
              COMPUTER EQUIPMENT PRIOR TO USE; AND (II) TAKE REASONABLE
              PRECAUTIONS TO PROTECT YOURSELF AGAINST HARMFUL PROGRAMS OR
              DEVICES INCLUDING THROUGH INSTALLATION OF ANTI-VIRUS SOFTWARE.
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>16. Gambling By Those Under Age</h2>
            <br />
            <p>
              16.1. If We Suspect That You Are Or Receive Notification That You
              Are Currently Under 18 Years Or Were Under 18 Years (Or Below The
              Age Of Majority As Stipulated In The Laws Of The Jurisdiction
              Applicable To You) When You Placed Any Bets Through The Service
              Your Account Will Be Suspended (Locked) To Prevent You Placing Any
              Further Bets Or Making Any Withdrawals From Your Account. We Will
              Then Investigate The Matter, Including Whether You Have Been
              Betting As An Agent For, Or Otherwise On Behalf, Of A Person Under
              18 Years (Or Below The Age Of Majority As Stipulated In The Laws
              Of The Jurisdiction Applicable To You). If Having Found That You:
              (A) Are Currently; (B) Were Under 18 Years Or Below The Majority
              Age Which Applies To You At The Relevant Time; Or (C) Have Been
              Betting As An Agent For Or At The Behest Of A Person Under 18
              Years Or Below The Majority Age Which Applies: All Winnings
              Currently Or Due To Be Credited To Your Account Will Be Retained;
              All Winnings Gained From Betting Through The Service Whilst Under
              Age Must Be Paid To Us On Demand (If You Fail To Comply With This
              Provision We Will Seek To Recover All Costs Associated With
              Recovery Of Such Sums); And/Or Any Monies Deposited In Your
              Account Which Are Not Winnings Will Be Returned To You OR Retained
              Until You Turn 18 Years Old At Our Sole Discretion. We Reserve The
              Right To Deduct Payment Transaction Fees From The Amount To
              Return, Including Transaction Fees For Deposits To Your
              www.Betkaro India.com Account Which We Covered.
              <br />
              16.2. This Condition Also Applies To You If You Are Over The Age
              Of 18 Years But You Are Placing Your Bets Within A Jurisdiction
              Which Specifies A Higher Age Than 18 Years For Legal Betting And
              You Are Below That Legal Minimum Age In That Jurisdiction.
              <br />
              16.3. In The Event We Suspect You Are In Breach Of The Provisions
              Of This Clause Or Are Attempting To Rely On Them For A Fraudulent
              Purpose, We Reserve The Right To Take Any Action Necessary In
              Order To Investigate The Matter, Including Informing The Relevant
              Law Enforcement Agencies.
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>17. Fraud</h2>
            <br />
            <p>
              We Will Seek Criminal And Contractual Sanctions Against Any
              Customer Involved In Fraud, Dishonesty Or Criminal Acts. We Will
              Withhold Payment To Any Customer Where Any Of These Are Suspected.
              The Customer Shall Indemnify And Shall Be Liable To Pay To Us On
              Demand All Costs, Charges Or Losses Sustained Or Incurred By Us
              (Including Any Direct, Indirect Or Consequential Losses, Loss Of
              Profit, Loss Of Business And Loss Of Reputation) Arising Directly
              Or Indirectly From The Customer’s Fraud, Dishonesty Or Criminal
              Act.
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>18. Intellectual Property</h2>
            <br />
            <p>
              18.1. Any Unauthorised Use Of Our Name And Logo May Result In
              Legal Action Being Taken Against You. 18.2. As Between Us And You,
              We Are The Sole Owners Of The Rights In And To The Service, Our
              Technology, Software And Business Systems (The "Systems") As Well
              As Our Odds. You Must Not Use Your Personal Profile For Your Own
              Commercial Gain (Such As Selling Your Status Update To An
              Advertiser); And When Selecting A Nickname For Your Account We
              Reserve The Right To Remove Or Reclaim It If We Believe It
              Appropriate.
              <br />
              18.3. You May Not Use Our URL, Trademarks, Trade Names And/Or
              Trade Dress, Logos ("Marks") And/Or Our Odds In Connection With
              Any Product Or Service That Is Not Ours, That In Any Manner Is
              Likely To Cause Confusion Among Customers Or In The Public Or That
              In Any Manner Disparages Us.
              <br />
              18.4. Except As Expressly Provided In These Terms, We And Our
              Licensors Do Not Grant You Any Express Or Implied Rights, License,
              Title Or Interest In Or To The Systems Or The Marks And All Such
              Rights, License, Title And Interest Specifically Retained By Us
              And Our Licensors. You Agree Not To Use Any Automatic Or Manual
              Device To Monitor Or Copy Web Pages Or Content Within The Service.
              Any Unauthorized Use Or Reproduction May Result In Legal Action
              Being Taken Against You.
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>19. Your License</h2>
            <br />
            <p>
              19.1. Subject To These Terms And Your Compliance With Them, We
              Grant To You A Non-Exclusive, Limited, Non Transferable And Non
              Sub-Licensable License To Access And Use The Service For Your
              Personal Non-Commercial Purposes Only. Our License To You
              Terminates If Our Agreement With You Under These Terms Ends.
              <br />
              19.2. Save In Respect Of Your Own Content, You May Not Under Any
              Circumstances Modify, Publish, Transmit, Transfer, Sell,
              Reproduce, Upload, Post, Distribute, Perform, Display, Create
              Derivative Works From, Or In Any Other Manner Exploit, The Service
              And/Or Any Of The Content Thereon Or The Software Contained
              Therein, Except As We Expressly Permit In These Terms Or Otherwise
              On The Website. No Information Or Content On The Service Or Made
              Available To You In Connection With The Service May Be Modified Or
              Altered, Merged With Other Data Or Published In Any Form Including
              For Example Screen Or Database Scraping And Any Other Activity
              Intended To Collect, Store, Reorganise Or Manipulate Such
              Information Or Content.
              <br />
              19.3. Any Non-Compliance By You With This Clause May Also Be A
              Violation Of Our Or Third Parties' Intellectual Property And Other
              Proprietary Rights Which May Subject You To Civil Liability And/Or
              Criminal Prosecution.
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>20. Your Conduct And Safety</h2>
            <br />
            <p>
              The Service May Contain Links To Third Party Websites That Are Not
              Maintained By, Or Related To, Us, And Over Which We Have No
              Control. Links To Such Websites Are Provided Solely As A
              Convenience To Customers, And Are In No Way Investigated,
              Monitored Or Checked For Accuracy Or Completeness By Us. Links To
              Such Websites Do Not Imply Any Endorsement By Us Of, And/Or Any
              Affiliation With, The Linked Websites Or Their Content Or Their
              Owner(S). We Have No Control Over Or Responsibility For The
              Availability Nor Their Accuracy, Completeness, Accessibility And
              Usefulness. Accordingly When Accessing Such Websites We Recommend
              That You Should Take The Usual Precautions When Visiting A New
              Website Including Reviewing Their Privacy Policy And Terms Of Use.
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>21. Links To Other Websites</h2>
            <br />
            <p>
              The Service May Contain Links To Third Party Websites That Are Not
              Maintained By, Or Related To, Us, And Over Which We Have No
              Control. Links To Such Websites Are Provided Solely As A
              Convenience To Customers, And Are In No Way Investigated,
              Monitored Or Checked For Accuracy Or Completeness By Us. Links To
              Such Websites Do Not Imply Any Endorsement By Us Of, And/Or Any
              Affiliation With, The Linked Websites Or Their Content Or Their
              Owner(S). We Have No Control Over Or Responsibility For The
              Availability Nor Their Accuracy, Completeness, Accessibility And
              Usefulness. Accordingly When Accessing Such Websites We Recommend
              That You Should Take The Usual Precautions When Visiting A New
              Website Including Reviewing Their Privacy Policy And Terms Of Use.
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>22. Complaints</h2>
            <br />
            <p>
              22.1. If You Have Any Concerns Or Questions Regarding These Terms
              You Should Contact Our Customer Service Department Via The Links
              On The Website And Use Your Registered Email Address In All
              Communication With Us.
              <br />
              22.2. NOTWITHSTANDING THE FOREGOING, WE TAKE NO LIABILITY
              WHATSOEVER TO YOU OR TO ANY THIRD PARTY WHEN RESPONDING TO ANY
              COMPLAINT THAT WE RECEIVED OR TOOK ACTION IN CONNECTION THEREWITH.
              <br />
              22.3. If A Customer Is Not Satisfied With How A Bet Has Been
              Settled Then The Customer Should Provide Details Of Their
              Grievance To Our Customer Service Department. We Shall Use Our
              Reasonable Endeavours To Respond To Queries Of This Nature Within
              A Few Days (And In Any Event We Intend To Respond To All Such
              Queries Within 28 Days Of Receipt).
              <br />
              22.4. Disputes Must Be Lodged Within Three (3) Days From The Date
              The Wager In Question Has Been Decided. No Claims Will Be Honoured
              After This Period. The Customer Is Solely Responsible For Their
              Account Transactions.
              <br />
              22.5. In The Event Of A Dispute Arising Between You And Us Our
              Customer Service Department Will Attempt To Reach An Agreed
              Solution. Should Our Customer Service Department Be Unable To
              Reach An Agreed Solution With You, The Matter Will Be Escalated To
              Our Management.
              <br />
              22.6. Should All Efforts To Resolve A Dispute To The Customer's
              Satisfaction Have Failed, The Customer Has The Right To Lodge A
              Complaint With Our Licensing Body Gaming Services Provider N.V.
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>23. Assignment</h2>
            <br />
            <p>
              Neither These Terms Nor Any Of The Rights Or Obligations Hereunder
              May Be Assigned By You Without The Prior Written Consent Of Us,
              Which Consent Will Not Be Unreasonably Withheld. We May, Without
              Your Consent, Assign All Or Any Portion Of Our Rights And
              Obligations Hereunder To Any Third Party Provided Such Third Party
              Is Able To Provide A Service Of Substantially Similar Quality To
              The Service By Posting Written Notice To This Effect On The
              Service.s
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>24. Severability</h2>
            <br />
            <p>
              In The Event That Any Provision Of These Terms Is Deemed By Any
              Competent Authority To Be Unenforceable Or Invalid, The Relevant
              Provision Shall Be Modified To Allow It To Be Enforced In Line
              With The Intention Of The Original Text To The Fullest Extent
              Permitted By Applicable Law. The Validity And Enforceability Of
              The Remaining Provisions Of These Terms Shall Not Be Affected.
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>25. Breach Of These Terms</h2>
            <br />
            <p>
              Without Limiting Our Other Remedies, We May Suspend Or Terminate
              Your Account And Refuse To Continue To Provide You With The
              Service, In Either Case Without Giving You Prior Notice, If, In
              Our Reasonable Opinion, You Breach Any Material Term Of These
              Terms. Notice Of Any Such Action Taken Will, However, Be Promptly
              Provided To You.
              <br />
            </p>
          </div>
          <div className="contentn-bets-terms-and-condition">
            <h2>26. General Provisions</h2>
            <br />
            <p>
              26.1. Term Of Agreement. These Terms Shall Remain In Full Force
              And Effect While You Access Or Use The Service Or Are A Customer
              Or Visitor Of The Website. These Terms Will Survive The
              Termination Of Your Account For Any Reason.
              <br />
              26.2. Gender. Words Importing The Singular Number Shall Include
              The Plural And Vice Versa, Words Importing The Masculine Gender
              Shall Include The Feminine And Neuter Genders And Vice Versa And
              Words Importing Persons Shall Include Individuals, Partnerships,
              Associations, Trusts, Unincorporated Organisations And
              Corporations.
              <br />
              26.3. Waiver. No Waiver By Us, Whether By Conduct Or Otherwise, Of
              A Breach Or Threatened Breach By You Of Any Term Or Condition Of
              These Terms Shall Be Effective Against, Or Binding Upon, Us Unless
              Made In Writing And Duly Signed By Us, And, Unless Otherwise
              Provided In The Written Waiver, Shall Be Limited To The Specific
              Breach Waived. The Failure Of Us To Enforce At Any Time Any Term
              Or Condition Of These Terms Shall Not Be Construed To Be A Waiver
              Of Such Provision Or Of The Right Of Us To Enforce Such Provision
              At Any Other Time.
              <br />
              26.4. Acknowledgement. By Hereafter Accessing Or Using The
              Service, You Acknowledge Having Read, Understood And Agreed To
              Each And Every Paragraph Of These Terms. As A Result, You Hereby
              Irrevocably Waive Any Future Argument, Claim, Demand Or Proceeding
              To The Contrary Of Anything Contained In These Terms.
              <br />
              26.5. Language. In The Event Of There Being A Discrepancy Between
              The English Language Version Of These Rules And Any Other Language
              Version, The English Language Version Will Be Deemed To Be
              Correct.
              <br />
              26.6. Governing Law. These Terms Are Exclusively Governed By The
              Law In Force In Curaçao.
              <br />
              26.7. Entire Agreement. These Terms Constitute The Entire
              Agreement Between You And Us With Respect To Your Access To And
              Use Of The Service, And Supersedes All Other Prior Agreements And
              Communications, Whether Oral Or Written With Respect To The
              Subject Matter Hereof.
              <br />
              26.8 You Cannot Transfer, Sell, Or Pledge Your Account To Another
              Person. This Prohibition Includes The Transfer Of Any Assets Of
              Value Of Any Kind, Including But Not Limited To Ownership Of
              Accounts, Winnings, Deposits, Bets, Rights And/Or Claims In
              Connection With These Assets, Legal, Commercial Or Otherwise. The
              Prohibition On Said Transfers Also Includes However Is Not Limited
              To The Encumbrance, Pledging, Assigning, Usufruct, Trading,
              Brokering, Hypothecation And/Or Gifting In Cooperation With A
              Fiduciary Or Any Other Third Party, Company, Natural Or Legal
              Individual, Foundation And/Or Association In Any Way Shape Or Form
              <br />
            </p>
          </div>
        </div>
      </div>
      <Footer />
      <MobileFooter />
    </div>
  );
};

export default TermsAndCondition;
