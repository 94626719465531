import React, { useEffect } from "react";
import PrimaryNavbar from "../../components/PrimaryNavbar";
import ArrowLeft from "../../assets/images/profileDashboard/arrowLeft.svg";
import edit from "../../assets/images/profileDashboard/edit.svg";
import { useNavigate, Link } from "react-router-dom";
import { useState } from "react";
import axios from "axios";
import MobileFooter from "../../components/MobileFooter";
import MobileSideBar from "../../components/MobileSideBar/MobileSideBar";
import editBankCards from "../../assets/editBankCard.svg"
import bankIcon from "../../assets/bankIcon.svg"
import deleteBankCards from "../../assets/deleteBankCard.svg"
import refreshIcon from "../../assets/refreshIcon.svg"
import baseUrl from "../../services/baseUrl";
import Modal from 'react-modal';
import { Form } from 'react-bootstrap';
import UnionCross from "../../assets/images/Union.svg"
import { delete_Bank } from "../../services/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactPaginate from "react-paginate";


const customStyles = {
  content: {
    top: '50%',
    left: '50%',
    right: 'auto',
    bottom: 'auto',
    marginRight: '-50%',
    transform: 'translate(-50%, -50%)',
    padding: 0,
    border: 'none',
    outline: 'none',
    borderRadius: 0,
    overflowY: 'hidden',
  },
  overlay: {
    background: "rgba(0, 0, 0, 0.5)",
    zIndex: 9
  }
};

function BankCards() {
  const navigate = useNavigate();
  let subtitle;
  const [bankAccounts, setBankAccounts] = useState({ data: [], loader: false })
  const [deleteBank, setDeleteBank] = useState({ data: '', loader: false, show: false })

  function afterOpenModal() {
    if (subtitle && subtitle?.style) {
      subtitle.style.color = '#f00';
    }
  }

  const getBanks = async () => {
    try {
      setBankAccounts({ ...bankAccounts, loader: true });
      const Token = JSON.parse(localStorage.getItem("betUser"));
      const headers = {
        headers: {
          // "Content-Type": "application/json",
          "x-access-token": Token?.token,
        },
      };
      const responce = await axios.get(baseUrl + '/user', headers);
      if (responce.status == 200) {
        setBankAccounts({ data: responce?.data?.data?.bankDetails?.reverse(), loader: false });
      }
    } catch (error) {
      setBankAccounts({ ...bankAccounts, loader: false });
    }
  };

  /** delete bank **/
  const handleSubmitDelete = async () => {
    try {
      setDeleteBank({ ...deleteBank, loader: true })
      const userData = localStorage.getItem("betUser");
      const parsingUserData = JSON.parse(userData)

      const headers = {
        headers: {
          "Content-Type": "application/json",
          "x-access-token": parsingUserData?.token,
        },
      };

      const responce = await axios.put(baseUrl + delete_Bank + `/${deleteBank?.data?.accountNumber}`, {}, headers)
      if (responce.status == 200) {
        setDeleteBank({ ...deleteBank, loader: false, show: false })
        toast.success('deleted bank', { position: "top-center", autoClose: 2000 });
        getBanks()
      }
    } catch (error) {
      getBanks()
      toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
      setDeleteBank({ ...deleteBank, loader: false, show: false })
    }
  }

  const [itemOffset, setItemOffset] = useState(0);
  const itemsPerPage = 3
  const endOffset = itemOffset + itemsPerPage;
  const currentItems = bankAccounts?.data?.slice(itemOffset, endOffset);
  const pageCount = Math.ceil(bankAccounts?.data?.length / itemsPerPage);
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % bankAccounts?.data?.length;
    setItemOffset(newOffset);
  };

  useEffect(() => {
    getBanks()
  }, [])

  return (
    <div>
      <ToastContainer />
      <PrimaryNavbar />
      <div className="position-relative">
        <MobileSideBar />
      </div>
      <div className="container py-3 bankDetailsBG fs_14">

        <div className="row mb-3 text-white">
          <div className="col-1 cursor-pointer " onClick={() => navigate(-1)}>
            <img src={ArrowLeft} className="cursor-pointer arrow-icone-size rounded-4" />
          </div>
          <div className="col-10">
            <p className="mb-0 currentbets_title fw_700 text-center">
              Bank Details
            </p>
          </div>
          <div className="col-1"></div>
        </div>

        {
          bankAccounts?.loader == false && currentItems?.map((ele, ind) => {
            return <div className="container position-relative white_glass m-0 p-0 border-success mb-3 rounded-6">
             {
               ele.default == true ?  <div className="p-2  px-4 position-absolute bg-green_01 text-white fw_600" style={{ top: 0, left: 0, borderBottomRightRadius: "6px" }}>Default Account</div> : ''
             }
              <div className="row m-0 p-0 d-flex flex-row justify-content-end text-white fs_10 p-2 rounded-6">
                <span className="col-auto p-0 d-flex flex-column align-items-center justify-content-end me-2" onClick={() => { navigate('/update-bank-details', { state: { ele } }) }} >
                  <img src={editBankCards} />
                  <span>Edit</span>
                </span>
                <span className="col-auto p-0 d-flex flex-column align-items-center justify-content-end" onClick={() => setDeleteBank({ ...deleteBank, show: true, data: ele })} >
                  <img src={deleteBankCards} />
                  <span>Delete</span>
                </span>
              </div>

              <div className="row m-0 p-0 d-flex justify-content-start text-white text-start fs_10 p-2">
                <span><img className="ms-0 mb-3" src={bankIcon} height="30" width="30" /></span>
                <h4 className="mb-2">{ele?.accountHolderName}</h4>
                <p className="fs_14 mb-2 lh_14">{ele?.bankname}</p>
                <p className="fs_14 mb-2 lh_14">{ele?.accountNumber}</p>
                <p className="fs_14 mb-2 lh_14">{ele?.ifsc}</p>
                <p className="fs_14 mb-2 lh_14">{ele?.branch}</p>
                <p className="fs_14 mb-2 lh_14">{ele?.address}</p>
              </div>

              <div className="row m-0 p-0 d-flex justify-content-start text-start bg-dark fs_10 p-2 refrences-card-history-2">
                <p className="fs_14 mb-0 text-yellow_01">{ele?.remarks} </p>
              </div>
            </div>
          })
        }

        <div>
          {
            bankAccounts?.data?.length == 0 && bankAccounts?.loader == false
            &&
            <div className="bet_card_wrapper mb_4rem">
              <div className="row m-0 white_glass py-3 align-items-end d-flex align-items-center justify-content-center py-5 rounded-8">
                <span className="text-white fs_12 text-center">No content</span>
              </div>
            </div>
          }
          {
            bankAccounts?.loader == true
            &&
            <div className='text-center'>
              <div className="spinner-border text-primary" role="status"></div>
            </div>
          }
        </div>

        {/* <div className='row mt-2 mb-3'> */}


        {/* <div className="container position-relative white_glass m-0 p-0 border-success mb-3 ">
          <div className="row m-0 p-0 d-flex flex-row justify-content-end text-white fs_10 p-2">
            <span className="col-auto p-0 d-flex flex-column align-items-center justify-content-end me-2">
              <img src={editBankCards} />
              <span>Edit</span>
            </span>
            <span className="col-auto p-0 d-flex flex-column align-items-center justify-content-end">  
            <img src={deleteBankCards} />
              <span>Delete</span>
            </span>
          </div>

          <div className="row m-0 p-0 d-flex justify-content-start text-white text-start fs_10 p-2">
              <span><img className="ms-0 mb-3" src={bankIcon} height="30" width="30"/></span>
              <h4 className="mb-2">Prince Raj</h4>
              <p className="fs_14 mb-2 lh_14">SBIN</p>
              <p className="fs_14 mb-2 lh_14">111122223333</p>
              <p className="fs_14 mb-2 lh_14">SBI11223344</p>
              <p className="fs_14 mb-2 lh_14">Udyog vihar, phase II, Gurgaon, HR</p>
          </div>

          <div className="row m-0 p-0 d-flex justify-content-start text-start bg-dark fs_10 p-2">
              <p className="fs_14 mb-0 text-yellow_01">Remarks: </p>
          </div>
        </div> */}

        <div className='row mt-2 mb-3'>
          <div className='col-12 d-flex'>
            <Link to="/bank-details" className='btn d-flex justify-content-center align-items-center td-none w-50 btn-warning  shadow-none btn_commn_yellow_1 px-0 fs_12 fw_700 me-3 rounded-6' >+ Add New Bank Account</Link>
          </div>
        </div>

        <ReactPaginate
          breakLabel="..."
          nextLabel=">"
          onPageChange={handlePageClick}
          pageRangeDisplayed={1}
          pageCount={pageCount}
          previousLabel="<"
          renderOnZeroPageCount={null}
          pageClassName="page-item me-1"
          subContainerClassName="pages"
          pageLinkClassName="page-link"
          previousClassName="page-item me-1"
          previousLinkClassName="page-link"
          nextClassName="page-item me-1"
          nextLinkClassName="page-link"
          breakClassName="page-item me-1"
          breakLinkClassName="page-link"
          marginPagesDisplayed={1}
          containerClassName="pagination"
          activeClassName="active"
        />

        {/* Message Modal */}
        <Modal
          isOpen={deleteBank.show}
          onAfterOpen={afterOpenModal}
          onRequestClose={() => setDeleteBank({ ...deleteBank, show: false })}
          style={customStyles}
          contentLabel="Example Modal"
        >
          <div className="d-flex bg-success justify-content-end">
            <button onClick={() => setDeleteBank({ ...deleteBank, show: false })} className="crossbutton-cancel mb-2 m-1 ">
              <img className="bg-success pt-1 px-1" src={UnionCross} />
            </button>
          </div>

          <div className="grid-margin stretch-card">
            <div className="p-4 bg-success">
              <div className="card-body">
                <h5 className="text-danger text-center" >are you sure to delete Bank  ?</h5>
                <h5 className="text-white text-center text-uppercase" >{deleteBank?.data?.bankname ? deleteBank?.data?.bankname : 'null'}</h5>
                <br />
                <button type="submit" className="btn btn-warning px-5 mr-4 rounded-0" onClick={handleSubmitDelete} >Yes{" "}
                  {
                    deleteBank?.loader
                    &&
                    <div className="spinner-border spinner-border-sm " role="status"></div>
                  }
                </button>
                <button className="btn bg-white text-dark px-5 rounded-0" type='reset' onClick={() => setDeleteBank({ ...deleteBank, show: false })}>No</button>
              </div>
            </div>
          </div>
        </Modal >

      </div>
      <MobileFooter />
    </div>
  );
}

export default BankCards;
