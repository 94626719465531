import React, { useEffect, useState } from "react";
import "../Withdrawl/Withdrawl.css"
import WithdrawBanner from "../../assets/images/Withdarawal banner.png"
import PrimaryNavbar from "../../components/PrimaryNavbar";
import AddBank from "../../assets/add_bank.svg"
import ViewBank from "../../assets/view_bank.svg"
import DropdownDownArrow from "../../assets/dropedown_arrow.svg"
import ArrowLeft from "../../assets/arrow_left.svg"
import axios from "axios";
import baseUrl from "../../services/baseUrl";
import { bonusBalance, get_turnover, walletBalance, withdrawalRequest } from "../../services/api";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate } from "react-router-dom";
import MobileFooter from "../../components/MobileFooter";
import MobileSideBar from "../../components/MobileSideBar/MobileSideBar";
import "react-tooltip/dist/react-tooltip.css";
import { Tooltip as ReactTooltip } from "react-tooltip";
import FactrorialRed from "../../assets/FactrorialRed.svg"

function WithDrawl({ asd = 12 }) {
    const [withdrawData, setWithdraData] = useState({ accountName: "", amount: "", selectedBank: "", loader: false })
    const [withdrawDataError, setWithdraDataError] = useState({ accountName: "", amount: "", selectedBank: "", loader: false })
    const [bankAccounts, setBankAccounts] = useState('')
    const [balanceWithdraw, setBalanceWithdraw] = useState({ availableBalance: '0.00', loader: false });
    const [balanceBonus, setBalanceBonus] = useState({ bonus: '0.00', loader: false });
    const [balanceTurnover, setBalanceTurnover] = useState({ turnover: '0.00', loader: false });


    const navigate = useNavigate()
    const handleChange = (e) => {
        setWithdraData({ ...withdrawData, [e.target.name]: e.target.value })
        setWithdraDataError({ ...withdrawDataError, [e.target.name]: null })
    }

    const handleValidation = () => {
        const newError = {}
        if (withdrawData.selectedBank == '' || withdrawData.selectedBank == undefined) {
            newError.selectedBank = 'please select bank'
        }
        if (withdrawData.amount == '') {
            newError.amount = 'please enter ammount'
        }
        return newError
    }

    const handleSubmit = async () => {
        const handleValidationObject = handleValidation()
        if (Object.keys(handleValidationObject).length > 0) {
            setWithdraDataError(handleValidationObject)
        } else {
            try {
                setWithdraData({ ...withdrawData, loader: true })
                const payload = {
                    bankDetails: {
                        bankname: withdrawData?.selectedBank?.bankname,
                        accountHolderName: withdrawData?.selectedBank?.accountHolderName,
                        accountNumber: withdrawData?.selectedBank?.accountNumber,
                        ifsc: withdrawData?.selectedBank?.ifsc,
                        branch: withdrawData?.selectedBank?.branch,
                        remarks: withdrawData?.selectedBank?.remarks
                    },
                    amount: withdrawData.amount
                }
                const Token = JSON.parse(localStorage.getItem('betUser'))
                const headers = {
                    headers: {
                        // "Content-Type": "application/json",
                        "x-access-token": Token?.token
                    }
                }
                const responce = await axios.post(baseUrl + withdrawalRequest, payload, headers,)
                if (responce.status == 200) {
                    handleClere()
                    toast.success('Request Sent', { position: "top-center", autoClose: 2000 });
                }
            } catch (error) {
                handleClere()
                toast.error(error?.response?.data?.message, { position: "top-center", autoClose: 2000 });
            }
        }
    }

    const getWalletBalance = async () => {
        try {
            setBalanceWithdraw({ ...balanceWithdraw, loader: true });
            const Token = JSON.parse(localStorage.getItem("betUser"));
            const headers = {
                headers: {
                    // "Content-Type": "application/json",
                    "x-access-token": Token?.token,
                },
            };
            const responce = await axios.get(baseUrl + walletBalance, headers);
            if (responce.status == 200) {
                setBalanceWithdraw({ ...balanceWithdraw, availableBalance: responce.data.data[0].wallet_amount, loader: false });
            }
        } catch (error) {
            setBalanceWithdraw({ ...balanceWithdraw, loader: false });
        }
    };

    const getBanks = async () => {
        try {
            const Token = JSON.parse(localStorage.getItem("betUser"));
            const headers = {
                headers: {
                    // "Content-Type": "application/json",
                    "x-access-token": Token?.token,
                },
            };
            const responce = await axios.get(baseUrl + '/user', headers);
            if (responce.status == 200) {
                setBankAccounts(responce?.data?.data?.bankDetails);
            }
        } catch (error) {
        }
    };

    const getBonusBalance = async () => {
        try {
            setBalanceBonus({ ...balanceBonus, loader: true });
            const Token = JSON.parse(localStorage.getItem("betUser"));
            const headers = {
                headers: {
                    // "Content-Type": "application/json",
                    "x-access-token": Token?.token,
                },
            };
            const responce = await axios.get(baseUrl + bonusBalance, headers);
            if (responce.status == 200) {
                setBalanceBonus({ ...balanceBonus, bonus: responce?.data?.data[0]?.bonus, loader: false });
            }
        } catch (error) {
            setBalanceBonus({ ...balanceBonus, loader: false });
        }
    };

    const getTurnover = async () => {
        try {
            setBalanceTurnover({ ...balanceTurnover, loader: true });
            const Token = JSON.parse(localStorage.getItem("betUser"));
            const headers = {
                headers: {
                    // "Content-Type": "application/json",
                    "x-access-token": Token?.token,
                },
            };
            const responce = await axios.get(baseUrl + get_turnover, headers);
            if (responce.status == 200) {
                setBalanceTurnover({ ...balanceTurnover, turnover: responce?.data?.data, loader: false });
            }
        } catch (error) {
            setBalanceTurnover({ ...balanceTurnover, loader: false });
        }
    }

    useEffect(() => {
        getTurnover()
        getWalletBalance()
        getBanks()
        getBonusBalance()
    }, [])

    const handleClere = () => {
        setWithdraData({ ...withdrawData, accountName: "", amount: "", selectedBank: "", loader: false })
        setBankAccounts([{ value: '', name: 'Select' }])
        setTimeout(() => {
            setBankAccounts(bankAccounts)
        }, [1000])
    }
    const handleSelect = (e) => {
        const foundBank = bankAccounts?.find(el => el?.bankname === e.target.value)
        setWithdraData({ ...withdrawData, selectedBank: foundBank })
    }




    return (
        <div>
            <ReactTooltip
                anchorId="bonus"
                place="bottom-end"
                content="The Winnings earned from the bonus can be withdrawn, but the bonus itself is for playing purposes only and cannot be withdrawn."
                style={{ zIndex: '9999', width: '200px', fontSize: '12px' }}
            />
            <ReactTooltip
                anchorId="turnover"
                place="bottom-end"
                content="This RO requirment has to completed to withdraw full balance"
                style={{ zIndex: '9999', width: '200px', fontSize: '12px' }}
            />
            <PrimaryNavbar />
            <div className="position-relative">
                <MobileSideBar />
            </div>
            <ToastContainer />
            <div className="container py-3 withdrawBG">
                <div className="row mb-3 text-white">
                    <div className="col-1 cursor-pointer" onClick={() => navigate(-1)}>
                        <img src={ArrowLeft} className="cursor-pointer arrow-icone-size rounded-4" />
                    </div>
                    <div className="col-10">
                        <p className="mb-0 currentbets_title fw_700 text-center">
                            Withdrawal
                        </p>
                    </div>
                    <div className="col-1"></div>
                </div>
                {/* <div className="container green_glass py-3 mb-3 border-success">
                    <div className="row mb-2 m-0 p-0 align-items-center">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">Deposit Method</span>
                    </div>
                    <div className="row">
                        <div className="col-6 pe-2 m-0">
                            <div className=" bg-white p-3 py-3 mb-2">
                                <div className="d-flex justify-content-between">
                                    <img src={UpiIcon} />
                                    <span className="text-danger font-weight-bold"> <input type="radio"/></span>
                                </div>
                                <div className="mt-2 fs_14 fw_700">Regular UPI</div>
                            </div>
                            
                        </div>
                        <div className="col-6 ps-0">
                            <div className="bg-white p-3 py-3 mb-2">
                                <div className="d-flex justify-content-between">
                                    <img src={BankIcon} />
                                    <span className="text-danger font-weight-bold"> <input type="radio"/></span>
                                </div>
                                <div className="mt-2 fs_14 fw_700">Bank transfer</div>
                            </div>

                        </div>

                    </div>
                    <div className="row ">
                        <div className="col-12  mb-3">
                            <div className="bg-white py-2 px-4">
                                <div className="d-flex align-items justify-content-between  mb-2">
                                    <div className="mt-2  fw_600 fs_12 text-capitalize">asdaavfbv99977@HDFCFIRST</div>
                                    <div className=""><img src={CopyIcon} className="img-fluid" /></div>
                                </div>
                                <div className="d-flex align-items justify-content-between mb-4">
                                    <div className=""><img src={UpiIconLarge} className="img-fluid" /></div>
                                    <div className=""><img src={QRIcon} className="img-fluid" /></div>
                                </div>
                            </div>
                        </div>
                        
                    </div>
                </div> */}
                <div className="container green_glass py-3 mb-3 border-success rounded-8">
                    <div className="row mb-2 m-0 p-0 align-items-center">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">Overview</span>
                    </div>
                    <div className="row">
                        <div className="col-4 pe-2 m-0 text-center">
                            <div className=" bg-white p-3 py-3 mb-2 h-100 d-flex flex-column align-items-center justify-content-end rounded-6">
                                <div className="fs_10 fw_400">
                                    <p className="mb-0 gray_black_01">Available <br />for <br />Withdrawal</p>
                                    
                                </div>
                                <p className="mb-0 lh_12">-</p>
                                <div className=" fs_12 fw_700 text-green_02">
                                    {
                                        balanceWithdraw.loader == true ?
                                            <div className="spinner-border spinner-border-sm" role="status" ></div>
                                            :
                                            "₹ " + `${balanceWithdraw?.availableBalance ? balanceWithdraw?.availableBalance : "-"}`
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-4 pe-2 m-0 text-center">
                            <div className=" bg-white p-3 py-3 mb-2 h-100 d-flex flex-column align-items-center justify-content-end rounded-6 position-relative">
                                <img id="bonus" src={FactrorialRed} className="position-absolute" style={{right: "10px", top: "10px"}} />
                                <div className="fs_10 fw_400">
                                    <p className="mb-0 gray_black_01">Withdrawal
                                        blocked due
                                        to bonus</p>

                                </div>
                                <p className="mb-0 lh_12">-</p>
                                <div className=" fs_12 fw_700 text-red_02">
                                    {
                                        balanceBonus.loader == true
                                        ?
                                        <div className="spinner-border spinner-border-sm" role="status" ></div>
                                        :
                                        `${balanceBonus?.bonus ? "₹ " + balanceBonus?.bonus : "₹ 0" }`
                                    }
                                </div>
                            </div>
                        </div>
                        <div className="col-4 pe-2 m-0 text-center">
                            <div className=" bg-white p-3 py-3 mb-2 h-100 d-flex flex-column align-items-center justify-content-end rounded-6 position-relative">
                                <img id="turnover" src={FactrorialRed} className="position-absolute" style={{right: "10px", top: "10px"}} />
                                <div className="fs_10 fw_400">
                                    <p className="mb-0 gray_black_01">Withdrawal
                                        blocked due
                                        to Turnover</p>
                                </div>
                                <p className="mb-0 lh_12">-</p>
                                <div className=" fs_12 fw_700 text-red_02">₹ 0</div>
                                {
                                    balanceTurnover?.loader
                                        ?
                                        <span className="">
                                            <span className="spinner-border spinner-border-sm mr-2" role="status" aria-hidden="true" ></span>
                                        </span>
                                        :
                                        <div className=" fs_12 fw_700 text-red_02">{balanceTurnover?.turnover?.sum ? "- ₹ " + balanceTurnover?.turnover?.sum : "- ₹ " + "0.00"}</div>
                                }
                            </div>
                        </div>


                    </div>

                </div>

                <div className="container white_glass py-3 mb-3 border-white border-succes-5 rounded-6">
                    <div className="row m-0 p-0 align-items-center">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">Withdraw Amount</span>
                    </div>
                    <div className="row m-0 p-0 mt-3">
                        <input type="number" placeholder="Enter Withdrawal Amount" autoComplete="off" value={withdrawData.amount} name="amount" onChange={handleChange} className={`${withdrawDataError.amount ? 'border border-2 border-danger' : ''} fs_14 h-42p py-2 rounded-6`} />
                    </div>
                    <div className="row m-0 p-0 mt-3">
                        <select value={withdrawData?.selectedBank?.ataccountName} className={`${withdrawDataError.selectedBank ? 'border border-2 border-danger' : ''} text-uppercase  fs_14 h-42p py-2 rounded-6`} name="accountName" onChange={handleSelect} id="exampleFormControlSelect1">
                            <option value=''>Select Bank</option>
                            {
                                bankAccounts && bankAccounts?.map(account => {
                                    return (
                                        <option className="text-uppercase" value={account?.bankname}>{account.bankname}</option>
                                    )
                                })
                            }
                        </select>
                    </div>
                    <div className='row mt-3 mb-3'>
                        <div className='col-12 d-flex'>
                            <button className='btn  btn-warning rounded-0 shadow-none btn_commn_yellow_1 px-4 me-2 rounded-6' onClick={handleSubmit} >Submit{" "}
                                {
                                    withdrawData.loader && (<div className="spinner-border spinner-border-sm" role="status" ></div>)
                                }
                            </button>
                            <button className='btn btn-light rounded-0 px-4 shadow-none rounded-6' onClick={handleClere}  >Reset</button>
                        </div>
                    </div>
                </div>
                {/* <div className="container white_glass py-3 mb-3 border-white border-succes-5">
                    <div className="row m-0 mb-3 p-0 align-items-center">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">Upload Payment Reciept</span>
                    </div>
                    <div className="row mb-3 m-0 p-0 align-items-center">
                        <div className="col-12 px-0 text-center">
                            <div className="attachmnet_wrappper p-4">
                                <div className="attachment_image_upload">
                                    <label for="file-input">
                                        <img src={UploadIcon} />
                                    </label>
                                    <input id="file-input" type="file" />
                                </div>
                                <p className="mb-1 fw_600 fs_12 black_01">
                                Click to upload or drag and drop
                                </p>
                                <p className="mb-0 fs_8 fw_400 black_01">(Maximum file size 5MB)</p>
                            </div>
                        </div>
                    </div>
                    <div className="row mb-3 m-0 p-0 align-items-center">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">Upload Payment Reciept</span>
                    </div>
                    <div className="row m-0 p-0 mt-3">
                        <input type="text" placeholder="Enter Deposit Amount" className="fs_14 h-42p py-2 rounded-6" />
                    </div>
                    <div className="row m-0 p-0 mt-3">
                        <input type="text" placeholder="UTR Number" className="fs_14 h-42p py-2" />
                    </div>
                   
                    <div className='row mt-3 mb-3'>
                        <div className='col-12 d-flex'>
                        <button className='btn  btn-warning rounded-0 shadow-none btn_commn_yellow_1 px-4 me-2'>Submit</button>
                        <button className='btn btn-light rounded-0 px-4 shadow-none'>Cancel</button>
                        </div>
                    </div>
                </div> */}

                <div className="container white_glass py-3 mb-3 border-white border-succes-5 rounded-8">
                    <div className="row m-0 p-0 align-items-center ">
                        <span className="col-auto m-0 p-0 d-flex square_green"></span>
                        <span className="col-11 t-14 text-white">Add / View Bank</span>
                    </div>
                    <div className="row m-0 p-0 mt-2">
                        <div className="col-6 p-0 m-0 h-100  ">
                            <Link to='/bank-details' className="text-decoration-none text-dark" >
                                <div className="bg-white p-3 mb-2 me-2 rounded-6">
                                    <div className="d-flex align-items-center justify-content-start align-items-center">
                                        <img className="w-16 me-2 rounded-4" src={AddBank} />
                                        <span className="fs_10">Add New Bank </span>
                                    </div>
                                </div>
                            </Link>
                        </div>
                        <div className="col-6 p-0 m-0 h-100 ">
                            <Link to='/bank-cards' className="text-decoration-none text-dark" >
                                <div className="bg-white p-3 mb-2 me-2 rounded-6">
                                    <div className="d-flex align-items-center justify-content-start align-items-center">
                                        <img className="w-16 me-2 rounded-4" src={ViewBank} />
                                        <span className="fs_10">View Bank</span>
                                    </div>
                                </div>
                            </Link>
                        </div>

                    </div>
                </div>
                <div className="container mb_4rem ">
                    <div className="row py-0  ">
                        <div className="col-12 bg-dark text-white fs_9 fw_400 p-4 note_font_size rounded-6" >
                            <p><strong>NOTE:</strong><span className="pl-2"> To Withdraw Your Remaining Balance, Turnover Of ₹0 Has To Be Completed</span></p>

                            <ul>
                                <li>Normal Withdrawal Limit: ₹1100 - ₹50000</li>
                                <li>Insta Withdrawal Limit: ₹500 - ₹500000</li>
                                <li>Withdrawal Requests Submitted Between 12:00AM And 6:00AM May Take Longer To Process Due To Banking Server Issues That Are Commonly Experienced During The Night.</li>
                            </ul>
                            <p className="border border-2 border-warning text-warning p-2 m-2">
                                Withdrawal fees per day: While the limits for withdrawal is Rs. 5 Lacs, members are allowed two free withdrawals There will be a nominal charge of 1% on the third withdrawal & 2% for any withdrawals henceforth until the day ends.

                            </p>

                        </div>
                        <div className="row">
                            <div className="col-lg-5 col-sm-12 rounded m-1">
                                {/* <img src="https://opt-v3-files.raksahb.com/static/cashsite/brand/6/image/cashBG.png" alt="" className="w-100"/> */}
                            </div>
                            <div className="col-lg-5 col-sm-12  rounded m-1" >
                                <Link to="/deposit">
                                    <img src={WithdrawBanner} alt="" className="w-100 rounded" />
                                </Link>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

            <MobileFooter />
        </div >
    );
}

export default WithDrawl;
